angular
    .module('annexaApp')
    .component('annexaBoxObjectDossier',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier.html',
        controller: ['TramNewFactory', '$filter', '$rootScope','HelperService','Language', 'GlobalDataFactory','RestService','HeaderService','$state','DialogsFactory', 'AnnexaFormlyFactory', 'globalModals', 'CustomFieldFactory', 'DccumentsFactory', '$http', '$scope',
        	function (TramNewFactory, $filter, $rootScope, HelperService, Language, GlobalDataFactory, RestService, HeaderService, $state, DialogsFactory, AnnexaFormlyFactory, globalModals, CustomFieldFactory, DccumentsFactory, $http, $scope ) {
            var vm = this;
			vm.languageColumn = Language.getActiveColumn();
            vm.loggedUser = $rootScope.LoggedUser;
			vm.dossierAlerts = [];
			vm.isUpdateDossier = false;
			vm.isArchivedDossier = false;
			vm.rightAccess = undefined;
			vm.daysexpire = undefined;
			vm.expiredate = undefined;
            vm.expiretype = "BUSINESS";
			vm.editPermissions = ['process_dossier'];
			vm.docModule = false;		
			vm.emgdeDossierMetadata = undefined;
			vm.esetMode = false;
			vm.actualDossierTransaction = undefined;
			vm.dossierArchiveClassification = undefined;
			vm.dossierCouncillor = undefined;
			vm.operationsActive = false;
        	vm.operationsActiveInDossier = false;
        	vm.userAccountInstances = [];
			vm.procedureOperationTypes = [];
			vm.isLoaded = false;
			vm.closedReceiverBox = {value:true};
			vm.closedRegisterEntryInputBox =  {value:true};
			vm.closedRegisterEntryOutputBox =  {value:true};
			vm.closedDossierBox =  {value:true};
			vm.closedNotificationBox =  {value:true};
			vm.closedOperationsAndInvoicesBox =  {value:true};
			vm.closedAddressBox =  {value:true};
			vm.closedDossierCustomFieldsBox =  {value:true};
			vm.closedRightAccessBox =  {value:true};
			vm.closedSessionBox =  {value:true};
			vm.closedDecreeBox =  {value:true};
			vm.closedDossierAuditBox =  {value:true};
			vm.closedDocumentationToProvideBox =  {value:true};
			vm.numberReceiverBox = {value:0};
			vm.numberRegisterEntryInputBox =  {value:0};
			vm.numberRegisterEntryOutputBox =  {value:0};
			vm.numberDossierBox =  {value:0};
			vm.numberNotificationBox =  {value:0};
			vm.numberOperationsAndInvoicesBox =  {value:0};
			vm.numberAddressBox =  {value:0};
			vm.numberRightAccessBox =  {value:0};
			vm.numberSessionBox =  {value:0};
			vm.numberDecreeBox =  {value:0};
			vm.comboYesNo = [
            	{ id: true, description: $filter('translate')('global.literals.yes')},
            	{ id: false, description: $filter('translate')('global.literals.no')}
            ];
			var calculateActualDossierTransaction = function () {
				if(vm.dossier && vm.dossier.dossierTransactions){
	                var actualTransaction = undefined;
					if(vm.dossierTransactionId){
						actualTransaction = $linq(vm.dossier.dossierTransactions).firstOrDefault(undefined, "x => x.id == "+Number(vm.dossierTransactionId));	
					}
	                if (actualTransaction && actualTransaction.actual) {
	                    vm.actualDossierTransaction = actualTransaction;
	                } else {
	                    actualTransaction = $linq(vm.dossier.dossierTransactions).firstOrDefault(undefined, "x => x.actual == true");
	                    if(actualTransaction){
	                        vm.actualDossierTransaction = actualTransaction;
	                    }else{
	                        actualTransaction = $linq(vm.dossier.dossierTransactions).max("x => x.id");
		                    if(actualTransaction){
		                        vm.actualDossierTransaction = actualTransaction;
		                    }else{
		                        vm.actualDossierTransaction = undefined;
		                    }
	                    }
	                }
				}else{
					vm.actualDossierTransaction = undefined;
				}
                
            }
			
			vm.returnToState = function(){
				if($state.current && $state.current.name){
					if($state.current.name == 'annexa.tram.pending.viewdossier'){
	                    $state.transitionTo('annexa.tram.pending');
					}else if($state.current.name == 'annexa.tram.view.viewdossier'){
						$state.transitionTo('annexa.tram.view');
					}else if($state.current.name == 'annexa.tram.responsable.viewdossier'){
						$state.transitionTo('annexa.tram.responsable');
					}else if($state.current.name == 'annexa.tram.dossierRightAccess.viewdossier'){
						$state.transitionTo('annexa.tram.dossierRightAccess');
					}else if($state.current.name == 'annexa.archive.finally.see'){
						$state.transitionTo('annexa.archive.finally');
					}else if($state.current.name == 'annexa.archive.close.see'){
						$state.transitionTo('annexa.archive.close');
					}else if($state.current.name == 'annexa.archive.transfer.edit'){
						$state.transitionTo('annexa.archive.transfer');
					}else if($state.current.name == 'annexa.archive.rejected.edit'){
						$state.transitionTo('annexa.archive.rejected');
					}else if($state.current.name == 'annexa.archive.archive.edit'){
						$state.transitionTo('annexa.archive.archive');
					}else if($state.current.name == 'annexa.archive.archive.see'){
						$state.transitionTo('annexa.archive.archive');
					}else{
						$state.transitionTo('annexa.tram.pending');
					}
				}else{
					$state.transitionTo('annexa.tram.pending');
				}
			}
			
			vm.updateDossier = function(data, property){
				var dossierSubjectAux = vm.dossier.subject;
	            var dossierAux = angular.copy(vm.dossier);
	            if(property == 'archiveClassification' || property === 'councillor') {
					if(property === 'councillor') {
						dossierAux.archiveClassification = ((vm.dossierArchiveClassification)?vm.dossierArchiveClassification : {});
					}else if(property == 'archiveClassification') {
	                	dossierAux.councillor = ((vm.dossierCouncillor)?vm.dossierCouncillor : {});
	                }
	                if (data && data.$selected && data.$selected.id) {
	                    dossierAux[property] = {id: data.$selected.id};
	                } else {
	                    dossierAux[property] = {};
	                }
	            }else if(property == 'dossierResponsibleProfilesAux'){
	            	dossierAux.archiveClassification = ((vm.dossierArchiveClassification)?vm.dossierArchiveClassification : {});
					dossierAux.councillor = ((vm.dossierCouncillor)?vm.dossierCouncillor : {});
	                if(data && data.length > 0){
	                    dossierAux.dossierResponsibleProfiles = [];
	                    _.forEach(data, function(value){
	                        dossierAux.dossierResponsibleProfiles.push({profile:{id:value.id}});
	                    });
	                }
	            }else if(property == 'responsibleUserAux'){
	            	dossierAux.archiveClassification = ((vm.dossierArchiveClassification)?vm.dossierArchiveClassification : {});
	                dossierAux.councillor = ((vm.dossierCouncillor)?vm.dossierCouncillor : {});
	                if(data.hasOwnProperty('user')) {
						if(data.user && data.user.id){
	                    	dossierAux.responsibleUser = {id: data.user.id};
						}else{
							return $filter('translate')('global.validation.required');
						}
	                } else if (data && data.id) {
	                	dossierAux.responsibleUser = {id: data.id};
	                } else if (data){
	                	dossierAux.responsibleUser = {id: data};
	                }
	            } else if (property == 'dossierNumber') {
	            	dossierAux.archiveClassification = ((vm.dossierArchiveClassification)?vm.dossierArchiveClassification : {});
	            	dossierAux.councillor = ((vm.dossierCouncillor)?vm.dossierCouncillor : {});
	                dossierAux.numSeqManual = data.numSeqManual;
	            	dossierAux.yearManualNumbering = data.yearManualNumbering;
	            } else if (property == 'dossierUrgent') {
	            	dossierAux.archiveClassification = ((vm.dossierArchiveClassification)?vm.dossierArchiveClassification : {});
	            	dossierAux.councillor = ((vm.dossierCouncillor)?vm.dossierCouncillor : {});
	            	dossierAux[property] = ((data && data.id)?data.id:false);
	            }else{
	            	dossierAux.archiveClassification = ((vm.dossierArchiveClassification)?vm.dossierArchiveClassification : {});
	                dossierAux.councillor = ((vm.dossierCouncillor)?vm.dossierCouncillor : {});
	                dossierAux[property] = data;
	            }
	            RestService.update('./api/tram/new/dossier/' + dossierAux.id, dossierAux).then(function(returnData) {
                    var returnDataDecoded = JSOG.decode(returnData);
                    vm.dossierArchiveClassification = returnDataDecoded.archiveClassification;
					vm.dossierCouncillor = returnDataDecoded.councillor;
					if(property == 'dossierTramitationType'){
						TramNewFactory.Dossier['dossierTramitationType'] = returnDataDecoded.dossierTramitationType;
					}
                    if(property == 'dossierTramitationType' && (!returnDataDecoded.dossierTramitationType || !returnDataDecoded.dossierTramitationType.allowUrgentProcess)){
                        dossierAux['dossierUrgent'] = false;
                        vm.dossier['dossierUrgent'] = false;
						TramNewFactory.Dossier['dossierUrgent'] = false;
                    }else if(property == 'dossierResponsibleProfilesAux') {
                        vm.dossier.dossierResponsibleProfilesAux = data;
                        vm.dossier['dossierResponsibleProfiles'] = returnDataDecoded.dossierResponsibleProfiles;
						TramNewFactory.Dossier['dossierResponsibleProfiles'] = returnDataDecoded.dossierResponsibleProfiles;
                        HeaderService.changeState($state.current);
						vm.calculateIsUpdateDossier(true);
                    }else if(property == 'responsibleUserAux'){
                        if(returnDataDecoded.responsibleUser && returnDataDecoded.responsibleUser.id) {
                            vm.dossier['responsibleUser'] = returnDataDecoded.responsibleUser;
							TramNewFactory.Dossier['responsibleUser'] = returnDataDecoded.responsibleUser;
                        }
						vm.calculateIsUpdateDossier(true);
                        HeaderService.changeState($state.current);
                    } else if (property == 'dossierNumber' || property == 'dossierUrgent') {
                        dossierAux[property] = returnDataDecoded[property];
                        vm.dossier[property] = returnDataDecoded[property];
						TramNewFactory.Dossier[property] = returnDataDecoded[property];
                    }else{
						TramNewFactory.Dossier[property] = returnDataDecoded[property];
					}
                }).catch(function (error) {
                	if(property == 'subject'){
                		vm.dossier.subject = dossierSubjectAux;
                	}
					vm.dossierAlerts.length = 0;
                	if(error && error.data && error.data.message == 'Error call to SUITE'){
                		vm.dossierAlerts.push({msg:$filter('translate')('global.errors.errorConnectSuite')});
                	} else if (error.data && error.data.message == 'Dossier exists'){
                		vm.dossierAlerts.push({msg:$filter('translate')('global.errors.existDossierNumber')});
                	}else if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
                		vm.dossierAlerts.push({msg:$filter('translate')('global.tram.errors.inRestrictiveTransaction')});
                	}else{
                		vm.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
                	}
	            });
			}

			vm.isResponsible = function () {
                var responsible = false;
                if(vm.dossier && vm.loggedUser){
                    if(vm.dossier.responsibleUser && vm.dossier.responsibleUser.id == vm.loggedUser.id){
                        responsible = true;
                    }else if(vm.dossier.dossierResponsibleProfiles && vm.dossier.dossierResponsibleProfiles.length > 0 && vm.loggedUser.userProfiles){
                        _.forEach(vm.dossier.dossierResponsibleProfiles, function(value){
                            var pr = $linq(vm.loggedUser.userProfiles).singleOrDefault(undefined, "x => x.profile.id == " + value.profile.id);
                            if(pr){
                                responsible = true;
                            }
                        });
                    }
                }
                return responsible;
            };
			
			vm.expandAllBoxes = function(){
				var value = true;
				var tramModule = $linq(GlobalDataFactory.modules).firstOrDefault(undefined, "x => x.url == 'annexa.tram'");
				var thirdModule = $linq(GlobalDataFactory.modules).firstOrDefault(undefined, "x => x.url == 'annexa.thirds'");
				var regInputModule = $linq(GlobalDataFactory.modules).firstOrDefault(undefined, "x => x.url == 'annexa.reg.input'");
				var regOutputModule = $linq(GlobalDataFactory.modules).firstOrDefault(undefined, "x => x.url == 'annexa.reg.output'");
				var secModule = $linq(GlobalDataFactory.modules).firstOrDefault(undefined, "x => x.url == 'annexa.sec.proposals'");
				var notificationModule = true;
				if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality){
					notificationModule = $rootScope.app.configuration.show_notification_functionality.value;
				}	
				var archivedDossier = false;
				if(vm.dossier && vm.dossier.dossierStatus){
					archivedDossier = _.contains(['CLOSED', 'TRANSFER', 'REJECTED', 'ARCHIVED'],vm.dossier.dossierStatus);	
				}
				var territoryModule = $linq(GlobalDataFactory.modules).firstOrDefault(undefined, "x => x.url == 'annexa.territory'");
				if(vm.closedReceiverBox && vm.closedReceiverBox.value && thirdModule && tramModule){
					value = false;
				}
				if(vm.closedRegisterEntryInputBox && vm.closedRegisterEntryInputBox.value && regInputModule){
					value = false;
				}
				if(vm.closedRegisterEntryOutputBox && vm.closedRegisterEntryOutputBox.value && regOutputModule){
					value = false;
				}
				if(vm.closedDossierBox && vm.closedDossierBox.value && tramModule){
					value = false;
				}
				if(vm.closedNotificationBox && vm.closedNotificationBox.value && regOutputModule && notificationModule){
					value = false;
				}
				if(vm.closedOperationsAndInvoicesBox && vm.closedOperationsAndInvoicesBox.value && tramModule && vm.operationsActiveInDossier){
					value = false;
				}
				if(vm.closedAddressBox && vm.closedAddressBox.value && territoryModule){
					value = false;
				}
				if(vm.closedDossierCustomFieldsBox && vm.closedDossierCustomFieldsBox.value && tramModule){
					value = false;
				}
				if(vm.closedRightAccessBox && vm.closedRightAccessBox.value && tramModule){
					value = false;
				}
				if(vm.closedSessionBox && vm.closedSessionBox.value && secModule){
					value = false;
				}
				if(vm.closedDecreeBox && vm.closedDecreeBox.value && secModule){
					value = false;
				}
				if(vm.closedDossierAuditBox && vm.closedDossierAuditBox.value && archivedDossier && tramModule){
					value = false;
				}
				if(vm.closedDocumentationToProvideBox && vm.closedDocumentationToProvideBox.value && tramModule){
					if((TramNewFactory.dossierDocumentationToProvide.procedureGlobalsOrig && TramNewFactory.dossierDocumentationToProvide.procedureGlobalsOrig.length > 0) && 
								(TramNewFactory.dossierDocumentationToProvide.procedureThirdsOrig && TramNewFactory.dossierDocumentationToProvide.procedureThirdsOrig.length > 0) &&
								(TramNewFactory.dossierDocumentationToProvide.archiveClassificationGlobalsOrig && TramNewFactory.dossierDocumentationToProvide.archiveClassificationGlobalsOrig.length > 0) &&
								(TramNewFactory.dossierDocumentationToProvide.archiveClassificationThirdsOrig && TramNewFactory.dossierDocumentationToProvide.archiveClassificationThirdsOrig.length > 0) &&
								(TramNewFactory.dossierDocumentationToProvide.dossierGlobals && TramNewFactory.dossierDocumentationToProvide.dossierGlobals.length > 0) && 
								(TramNewFactory.dossierDocumentationToProvide.dossierThirds && TramNewFactory.dossierDocumentationToProvide.dossierThirds.length > 0)){
							value = false;
						}
				}
				$scope.$broadcast('annexaObjectBoxExpandContract', {value: value});
			}
			
			vm.calculateCounters = function(counter){
				if(vm.dossier && vm.dossier.id){
					TramNewFactory.calculateDossierCounters(vm.dossier.id, counter).then(function(data) {
						if(!counter || counter && counter === 'dossierReceivers'){
							vm.numberReceiverBox.value = ((data && data.dossierReceivers)?data.dossierReceivers:0);
						}
						if(!counter || counter && counter === 'dossierRegisterInputs'){
							vm.numberRegisterEntryInputBox.value = ((data && data.dossierRegisterInputs)?data.dossierRegisterInputs:0);
						}
						if(!counter || counter && counter === 'dossierRegisterOutputs'){
							vm.numberRegisterEntryOutputBox.value = ((data && data.dossierRegisterOutputs)?data.dossierRegisterOutputs:0);
						}
						if(!counter || counter && counter === 'dossierDossiers'){
							vm.numberDossierBox.value = ((data && data.dossierDossiers)?data.dossierDossiers:0);
						}
						if(!counter || counter && counter === 'dossierNotifications'){
							vm.numberNotificationBox.value = ((data && data.dossierNotifications)?data.dossierNotifications:0);
						}
						if(!counter || counter && counter === 'dossierOperations'){
							vm.numberOperationsAndInvoicesBox.value = ((data && data.dossierOperations)?data.dossierOperations:0);
						}
						if(!counter || counter && counter === 'dossierAddresses'){
							vm.numberAddressBox.value = ((data && data.dossierAddresses)?data.dossierAddresses:0);
						}
						if(!counter || counter && counter === 'dossierRightAccess'){
							vm.numberRightAccessBox.value = ((data && data.dossierRightAccess)?data.dossierRightAccess:0);
						}
						if(!counter || counter && counter === 'dossierSessions'){
							vm.numberSessionBox.value = ((data && data.dossierSessions)?data.dossierSessions:0);
						}
						if(!counter || counter && counter === 'dossierDecrees'){
							vm.numberDecreeBox.value = ((data && data.dossierDecrees)?data.dossierDecrees:0);
						}
					}).catch(function() {
	                });
				}
			}
			
			vm.generateENIDossier = function () {
				var eniDocumentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined, "x => x.id == "+$rootScope.app.configuration.default_eni_values.eni_dossier_document_type);
				if(DccumentsFactory.sectionsUser && DccumentsFactory.sectionsUser.length > 0){
					if(eniDocumentType){
	            		DialogsFactory.confirm('global.documents.new.audit.actionType.ENI', 'global.literals.generate_dossier_ENI')
		                .then(function (data) {
		            		 var saveEniDossier = function(){
		            			 if (this.annexaFormly.form.$valid) {
		            	                var self = this;
		            	                var model = this.annexaFormly.model.modal_body;
		            	                var moduleDoc = 'EXPED';
		            	                var document = {
		            	                        createdDate: new Date(),
		            	                        modifyDate: new Date(),
		            	                        dateLastAccess: new Date(),
		            	                        urgent: false,
		            	                        favorite: false,
		            	                        downloaded: false,
		            	                        sentMail: false,
		            	                        type: {id: model.typeSelect},
		            	                        section: {id: model.section},
		            	                        profiles: [],
		            	                        module: moduleDoc,
		            	                        dateDocumentCreation: model.dateDocumentCreation
		        	                    };
		            	                var documentIndex = model.indexDocumentEni;
		            	                var dtDocumentIndex = model.dossierTransactionEni;
		            	                
		        	                    _.forEach(model.profiles, function(item) {
		        	                        document.profiles.push({ profile: { id: item } });
		        	                    });
		        	                    if(document.profiles.length == 0 && model.profile){
		        	                        document.profiles.push({ profile: { id: model.profile } });
		        	                    }
		        	                    if (model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id) {
		        	                        document.archiveClassification = { id: model.archiveClassification.$selected.id };
		        	                    }
		        	                    document.sentMail = false;

		        	                    var cf = CustomFieldFactory.getModelValues(model.documentCustomFields);
		        	                    document.customFields = [];
		        	                    var documentType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);
		        	                    if(documentType && cf && cf.length > 0) {
		        	                        _.forEach(documentType.customFields, function(docTypeField) {
		        	                            var docCustomField = {
		        	                                customField: { id: docTypeField.customField.id },
		        	                                required: docTypeField.required,
		        	                                viewOrder: docTypeField.viewOrder,
		        	                                value: docTypeField.value,
		        	                                noEditable: docTypeField.noEditable,
		        		                            descriptionLanguage1: docTypeField.descriptionLanguage1,
		        		                            descriptionLanguage2: docTypeField.descriptionLanguage2,
		        		                            descriptionLanguage3: docTypeField.descriptionLanguage3
		        	                            };

		        	                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

		        	                            if(cfValue) {
		        	                                if(docTypeField.customField.frontendType == 'CHECKBOX'){
		        	                                    var custom_field_selected = $linq(cf).where(function(x){
		        	                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
		        	                                    ).toArray();
		        	                                    if(custom_field_selected && custom_field_selected.length > 0){
		        	                                        docCustomField.value = '[';
		        	                                        _.forEach(custom_field_selected, function (item, key) {
		        	                                            if(item.value) {
		        	                                                if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
		        	                                                    var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
		        	                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
		        	                                                    ).toArray();
		        	                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
		        	                                                        docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
		        	                                                    }
		        	                                                }
		        	                                            }
		        	                                        });
		        	                                        docCustomField.value += ']';
		        	                                    }
		        	                                }else if(Array.isArray(cfValue.value)) {
		        	                                    if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
		        	                                        docCustomField.value = '[';

		        	                                        _.forEach(cfValue.value, function (item) {
		        	                                            docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
		        	                                        });

		        	                                        docCustomField.value += ']';
		        	                                    } else {
		        	                                        docCustomField.value = cfValue.value[0];
		        	                                    }
		        	                                } else {
		        	                                    if(cfValue.value instanceof Date) {
		        	                                        docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
		        	                                    } else {
		        	                                        docCustomField.value = cfValue.value;
		        	                                    }
		        	                                }
		        	                            }

		        	                            document.customFields.push(docCustomField);
		        	                        });
		        	                    }
		        	                    $http({
	    			                        url: './api/tram/new/dossier/expedienteEni/' +vm.dossier.id+'/'+documentIndex+'/'+dtDocumentIndex,
	    			                        method: 'POST',
	    			                        data: JSOG.encode(document)
	    			                    }).then(function(data) {
	    			                    	$rootScope.loading(false);
	    			                    	if(data && data.data){
		    			                    	DialogsFactory.notify($filter('translate')('global.tram.literals.eniOK'));
		    			                    	self.close();
		    			                    	$state.reload();
	    			                    	}else{
	    			                    		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
		    			                    	self.close();
	    			                    	}
	    			                    }).catch(function(error) {
											if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
												var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
												DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
											}else{
						                		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
	    	                                	console.log({msg: 'global.errors.unknownNew'});
											}
	    			                    });
		            	            }
		            	                
		            		 } 
		                	var modal = DccumentsFactory.getOpenNewDocumentModal(undefined, undefined, 'EXPED', undefined, undefined, ((vm.dossier && vm.dossier.archiveClassification)?vm.dossier.archiveClassification:undefined), eniDocumentType, vm.actualDossierTransaction, undefined, undefined, [eniDocumentType]);
			           		modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.disabled = true;
			           		modal.annexaFormly.fields[0].fieldGroup[1].hideExpression = function(){return true;}
			           		modal.annexaFormly.fields[0].fieldGroup[2].hideExpression = function(){return true;}
			           		modal.annexaFormly.fields[0].fieldGroup.push({
	                            key: 'indexDocumentEni',
	                            type: 'annexaSelectRow',
	                            className: 'col-sm-12',
	                            templateOptions: {
	                                optionsAttr: 'bs-options',
	                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                label: 'global.literals.indexDocumentEni',
	                                valueProp: 'id',
	                                labelProp: 'name',
	                                placeholder: '',
	                                options: [],
	                                required: true,
	                                clearHide: true
	                            },
	                            controller: ['$scope', '$filter', 'Language', 'GlobalDataFactory', 'TramNewFactory', function($scope, $filter, Language, GlobalDataFactory,TramNewFactory) {
									$scope.to.options = [];
	                                $scope.to.options.push({id: -1, name:$filter('translate')('global.literals.foliateTemplate')});
									TramNewFactory.getDossierTransactionDocuments(vm.dossier.id).then(function(data) {
		                                if(data && data.length > 0){
											var docs = JSOG.decode(data);
											_.forEach(docs, function(doc){
	                               				if(doc.relationType == 'DOCUMENT' && doc.document && !doc.document.physical){
	                               					$scope.to.options.push({id: doc.id, name:((doc.document)?doc.document.name:'')});
	                               				}
											}); 
										}
									}).catch(function() {
				                    })	
	                            }],
	                            data: {
	                                row: true,
	                                informed: true,
	                                colClass: ' col-sm-12',
	                                labelClass: 'label-strong'
	                            }
	                        });
			           		modal.annexaFormly.fields[0].fieldGroup.push({
	                            key: 'dossierTransactionEni',
	                            type: 'annexaSelectRow',
	                            className: 'col-sm-12',
	                            templateOptions: {
	                                optionsAttr: 'bs-options',
	                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                label: 'global.literals.dossierTransactionEni',
	                                valueProp: 'id',
	                                labelProp: 'name',
	                                placeholder: '',
	                                options: [],
	                                required: true,
	                                clearHide: true
	                            },
	                            controller: ['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
	                                $scope.to.options = [];
	                                if(vm.dossier && vm.dossier.dossierTransactions){
	                                	_.forEach(vm.dossier.dossierTransactions, function(dt){
	                                		if(dt.actual){
	                              				$scope.to.options.push({id: dt.id, name:dt[Language.getActiveColumn()]});
	                                		}
	                                	});
	                                }
	                            }],
	                            data: {
	                                row: true,
	                                informed: true,
	                                colClass: ' col-sm-12',
	                                labelClass: 'label-strong'
	                            }
	                        });
			           		AnnexaFormlyFactory.showModal('modalEniDocumentNew', modal, saveEniDossier, false, undefined);
			            }).catch(function(error) {
			            });
	            	}else{
	            		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
	            	}
				}else{
					DialogsFactory.error($filter('translate')('global.errors.noUserSection'), $filter('translate')('DIALOGS_ERROR_MSG'));
				}
			}
			
			vm.auditDossier = function () {
				if (vm.dossier && vm.dossier.id) {
                	window.open($state.href('annexa.admin.audit_dossier', {"objectId": vm.dossier.id }), '_blank');
            	}
			}
			
			vm.reopenTram = function(){
            	TramNewFactory.reopenTramModal(vm.dossier, undefined);
        	}
			
			vm.generateFoliate = function () {
				TramNewFactory.generateFoliate(vm.dossier);
			}
			
			vm.createTramResposibleModal = function () {
				TramNewFactory.createTramResposibleModal(vm.dossier, vm.actualDossierTransaction);
			}
			
			vm.openSubprocedureModal = function () {
				TramNewFactory.openSubprocedureModal(vm.dossier, vm.actualDossierTransaction);
			}
			
			vm.cancelDossierModal = function () {
				var modal = angular.copy(globalModals.cancelDossier);
	            modal.annexaFormly.model = {};
	            modal.annexaFormly.options = {};
				var submitCancelDossier = function(){
					 var modal = this;
		            if(vm.dossier && modal &&  modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.reason) {
		                TramNewFactory.cancelDossier(vm.dossier.id, modal.annexaFormly.model.modal_body.reason).then(function (data) {
	                        modal.close();
							vm.returnToState();
	                    }).catch(function (error) {
		                });
		            }
				}
	            AnnexaFormlyFactory.showModal('modalSection', modal, submitCancelDossier, false);
			}
			
			vm.endDossier = function () {
				DialogsFactory.confirm('global.literals.confirmEndDossierTitle', 'global.literals.confirmEndDossierBody').then(function (btn) {
                    TramNewFactory.endDossier(vm.actualDossierTransaction).then(function(data) {
                        if($state.current && $state.current.name && $state.current.name == 'annexa.tram.pending.viewdossier'){
	                        $state.transitionTo('annexa.tram.pending');
						}else{
							$state.transitionTo('annexa.tram.pending');
						}
                    }).catch(function (error) {
						if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							 vm.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.processTemplate'), msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
						}else if (error && error.data && error.data.message == 'No Required Custom Field Dossier Value data received in creation'){
							 vm.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.dossierCustomFields')});
						}else if (error && error.data && error.data.message == 'No Required Custom Field Value data received in creation'){
							 vm.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.dossierTransactionCustomFields')});
						}else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
							 vm.dossierAlerts.push({msg: $filter('translate')('global.documents.definitiveNotificationNoValidError')});
						}else{
		                     vm.dossierAlerts.push({ msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data )});
						}
                    });
                }).catch(function (data) {
                        //Empty
                });

            }
			vm.calculateIsUpdateDossier = function(reload){
				vm.isUpdateDossier = false;
				TramNewFactory.calculateCanUpdateDossier(vm.dossier);
				if(!(!TramNewFactory.canUpdateDossier || (vm.dossier && vm.dossier.dossierStatus != 'IN_PROGRESS' && !vm.isArchivedDossier) 
		            		|| ((vm.dossier.dossierStatus != 'TRANSFER' && vm.dossier.dossierStatus != 'REJECTED' && !(vm.dossier.dossierStatus == 'ARCHIVED' && !vm.dossier.archiveDefinitiveDate)) && vm.isArchivedDossier))){
						vm.isUpdateDossier = true;
		        }
				if(reload){
					$state.reload();
				}
			}
			this.$onInit = function() {
				try{
					vm.isArchivedDossier = ((TramNewFactory.isArchivedDossier || (vm.dossier && vm.dossier.dossierStatus && _.contains(['CLOSED','TRANSFER','REJECTED','ARCHIVED'],vm.dossier.dossierStatus)))?true:false);
					vm.calculateIsUpdateDossier(false);
					var dms = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();
					vm.docModule = ((dms && dms.length > 0)?true:false);
					vm.emgdeDossierMetadata = ((TramNewFactory.emgdeDossierMetadata)?TramNewFactory.emgdeDossierMetadata:undefined);
	       			vm.dossierArchiveClassification = TramNewFactory.DossierArchiveClassification;
					vm.dossierCouncillor = TramNewFactory.DossierCouncillor;
	                vm.rightAccess = TramNewFactory.rightAccess;
					vm.esetMode = $rootScope.esetMode;
					calculateActualDossierTransaction();
					vm.operationsActive = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active)?true:false);
	        		vm.operationsActiveInDossier = ((vm.operationsActive && vm.dossier && vm.dossier.procedure && vm.dossier.procedure.operationsActive)?true:false);
	        		vm.userAccountInstances = TramNewFactory.userAccountInstances;
					vm.procedureOperationTypes = TramNewFactory.procedureOperationTypes;
					vm.daysexpire = vm.dossier.remainingDays;
					if(vm.dossier.expireType != null){
		            	vm.expiretype = vm.dossier.expireType;
		            } else if(vm.dossier.procedure && vm.dossier.procedure.expireType != null) {
		            	vm.expiretype = vm.dossier.procedure.expireType;
		            }
					vm.expiredate = HelperService.getExpirationDate(new Date(), vm.daysexpire, vm.loggedUser.entity.holidays, vm.expiretype);
					vm.isLoaded = true;
				}catch(error){
					vm.isLoaded = true;
				}
			}
        }],
        bindings: {
            isEdit: '=',
            dossier: '=',
			dossierTransactionId: '=?'
        }
    })
    .component('annexaBoxObjectDossierHeader',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-header.html',
        require: {
            dossierComponent: '^^annexaBoxObjectDossier'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', function($rootScope, $filter, Language, GlobalDataFactory, HelperService) {
            var vm = this;
			vm.userSections = [];
			vm.showId = false; 
			vm.showview = false;
			vm.closeAlert = function (index) {
                if(vm.dossierComponent && vm.dossierComponent.dossierAlerts && vm.dossierComponent.dossierAlerts.length > index) {
                    vm.dossierComponent.dossierAlerts.splice(index, 1);
                }
            }

            vm.checkYear = function($data, item) {
                if(item.$form.$invalid) {
                	return $filter('translate')('global.validation.maxlength');
                }
            }

			this.$onInit = function() {
				if(HelperService.isSuperAdmin()) {
					vm.userSections = GlobalDataFactory.sections;
            	} else {
            		vm.userSections = $linq(vm.dossierComponent.loggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
            	}
				vm.showId = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_id_dossier && $rootScope.app.configuration.show_id_dossier.value)?true:false);
        		vm.showview = true;
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierTabs',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-tabs.html',
        require: {
            dossierComponent: '^^annexaBoxObjectDossier'
        },
        controller:['$rootScope', '$filter', 'Language', '$stateParams', function($rootScope, $filter, Language, $stateParams) {
            var vm = this;
            vm.idDossierSelectedTab = undefined;
            vm.activeTab = 0;
			vm.showview = false;
			if($stateParams.selectedTab ){
				vm.idDossierSelectedTab = $stateParams.selectedTab;
				if(vm.idDossierSelectedTab && vm.idDossierSelectedTab == 'right'){
					vm.activeTab = 0;
				}else if (vm.idDossierSelectedTab && vm.idDossierSelectedTab == 'center'){
					vm.activeTab = 1;
				}else if(vm.idDossierSelectedTab && vm.idDossierSelectedTab == 'left'){
					vm.activeTab = 2;
				}
			}else{
				vm.activeTab = 0;
				vm.idDossierSelectedTab = 'right';
			}
			vm.tabs = undefined;
			vm.dossierSelectedTab = function(selected){
	        	if(selected && selected == 'center'){
	        		vm.idDossierSelectedTab = selected;
	        	}else if(selected && selected == 'left'){
	        		vm.idDossierSelectedTab = selected;
	        		$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
	        	}else{
	        		vm.idDossierSelectedTab = selected;
	        	}
			}
			this.$onInit = function() {
				vm.tabs = {
                    left: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-tabs/documentsInfo.html',
                    center: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-tabs/dossierTransactionsInfo.html',
                    right: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-tabs/dossierInfo.html'
                }
            	vm.showview = true;
				if($stateParams.selectedTab ){
					vm.idDossierSelectedTab = $stateParams.selectedTab;
					$stateParams.selectedTab = undefined;
					if(vm.idDossierSelectedTab && vm.idDossierSelectedTab == 'right'){
						vm.activeTab = 0;
					}else if (vm.idDossierSelectedTab && vm.idDossierSelectedTab == 'center'){
						vm.activeTab = 1;
					}else if(vm.idDossierSelectedTab && vm.idDossierSelectedTab == 'left'){
						vm.activeTab = 2;
					}					
				}else{
					vm.idDossierSelectedTab = 'right';
					vm.activeTab = 0;
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierExpirationBox',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-expiration-box.html',
        require: {
            dossierHeaderComponent: '^^annexaBoxObjectDossierHeader'
        },
        controller:['$rootScope', '$filter', 'Language', function($rootScope, $filter, Language) {
            var vm = this;
			vm.canShowCIButtons = true;
			vm.monthsexpired = undefined;
			vm.yearsexpired = undefined;

            var calculateMonths = function(){
				var months;
                var d1 = new Date();
                var d2 = new Date(vm.dossierHeaderComponent.dossierComponent.expiredate);
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                months <= 0 ? 0 : months;
				return months;
			}
	
			var calculateYears = function(){
				var date1 = new Date();
                var date2 = new Date(vm.dossierHeaderComponent.dossierComponent.expiredate);
                return  date2.getFullYear() - date1.getFullYear();
			}
			
			this.$onInit = function() {
				if((vm.dossierHeaderComponent.dossierComponent.dossier && vm.dossierHeaderComponent.dossierComponent.dossier.dossierStatus != 'IN_PROGRESS' && !vm.dossierHeaderComponent.dossierComponent.isArchivedDossier) 
            		|| (vm.dossierHeaderComponent.dossierComponent.dossier &&  (vm.dossierHeaderComponent.dossierComponent.dossier.dossierStatus != 'TRANSFER' && vm.dossierHeaderComponent.dossierComponent.dossier.dossierStatus != 'REJECTED' && !(vm.dossierHeaderComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' && !vm.dossierHeaderComponent.dossierComponent.dossier.archiveDefinitiveDate)) && vm.dossierHeaderComponent.dossierComponent.isArchivedDossier)){
                	vm.canShowCIButtons = false;
            	}
				vm.monthsexpired = calculateMonths();
				
				vm.yearsexpired = calculateYears();
			
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierInfo',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-info.html',
        require: {
            dossierTabsComponent: '^^annexaBoxObjectDossierTabs'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.inverseLanguageColumn = undefined;
			vm.dossierInputFormFields = [];
			vm.actualTransaction = undefined;
        	vm.editQdcOnEsetMode = false;
			vm.printQdC = function(value) {
	            var qdc = '';
	            var getName = function(item, label) {
	                if(label) {
	                    label = ' / ' + label;
	                }
	                if(item){
	                	label = ((item[vm.languageColumn])?item[vm.languageColumn]:((item.id)?item.id:'undefined')) + label;
		                if(item.parent) {
		                	label = getName(item.parent, label);
		                }
	                }
	                return label;
	            }
	            if(value && value.model && value.model.$selected && value.model.$selected.id) {
	                var selectedQdc = $linq(GlobalDataFactory.archiveClassifications).singleOrDefault(undefined, "x => x.id == " + value.model.$selected.id);
	                if(selectedQdc) {
	                    qdc = getName(selectedQdc, qdc);
	                }
	            }else if(vm.dossierTabsComponent.dossierComponent.dossierArchiveClassification && vm.dossierTabsComponent.dossierComponent.dossierArchiveClassification.id){
	            	 var selectedQdc = $linq(GlobalDataFactory.archiveClassifications).singleOrDefault(undefined, "x => x.id == " + vm.dossierTabsComponent.dossierComponent.dossierArchiveClassification.id);
	
	                 if(selectedQdc) {
	                     qdc = getName(selectedQdc, qdc);
	                 }
	            }
	
	            return qdc;
	        }
			vm.printCouncillor = function(value) {
	            var councillor = '';
	            if(value && value.model && value.model.$selected && value.model.$selected.id) {
	                var selectedCouncillor = $linq(GlobalDataFactory.councillors).singleOrDefault(undefined, "x => x.id == " + value.model.$selected.id);
	                if(selectedCouncillor) {
	                    councillor = ((selectedCouncillor[vm.languageColumn])?selectedCouncillor[vm.languageColumn]:'');
	                }
	            }else if(vm.dossierTabsComponent.dossierComponent.dossierCouncillor && vm.dossierTabsComponent.dossierComponent.dossierCouncillor.id){
	            	 var selectedCouncillor = $linq(GlobalDataFactory.councillors).singleOrDefault(undefined, "x => x.id == " + vm.dossierTabsComponent.dossierComponent.dossierCouncillor.id);
	
	                 if(selectedCouncillor) {
	                     councillor = ((selectedCouncillor[vm.languageColumn])?selectedCouncillor[vm.languageColumn]:'');
	                 }
	            }
	            return councillor;
	        }
			vm.printDossierUrgent = function(value){
	            if(vm.dossierTabsComponent.dossierComponent.dossier && vm.dossierTabsComponent.dossierComponent.dossier.dossierUrgent){
	                return $filter('translate')('global.literals.yes');
	            }else{
	                return $filter('translate')('global.literals.no');
	            }
    		}
			vm.getProfileValue = function(value){
				var prof = '';
	            if(vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfilesAux && vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfilesAux.length > 0){
	                _.forEach(vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfilesAux, function(value){
	                    if(prof){
	                        prof += ", "+value[vm.languageColumn];
	                    }else{
	                        prof += value[vm.languageColumn];
	                    }
	                })
	            }
	            return prof;
			}
			
			vm.canEditEsetDossierResponsibleUser = function(){
	        	var res = false;
	            if ($rootScope.esetMode && 
	            		vm.dossierTabsComponent.dossierComponent.isUpdateDossier && 
	            		vm.dossierTabsComponent.dossierComponent.dossier &&
	            		vm.dossierTabsComponent.dossierComponent.dossier.procedure &&
						vm.dossierTabsComponent.dossierComponent.dossier.procedure.procedureStartProfiles) {
	            	var procedureStartProfilesIds = $linq(vm.dossierTabsComponent.dossierComponent.dossier.procedure.procedureStartProfiles).select("x => x.profile.id").toArray();
	            	var userProfilesIds = $linq(vm.dossierTabsComponent.dossierComponent.loggedUser.userProfiles).select("x => x.profile.id").toArray();
	            	var profilesIntersect = $linq(procedureStartProfilesIds).intersect(userProfilesIds).toArray();
	            	if (profilesIntersect.length > 0) {
	            		res = true;
	            	}
	            }
	            
	            return res;
	        };

			vm.printDossierResponsibleUser = function(value){
				if(!$rootScope.esetMode && vm.dossierTabsComponent.dossierComponent.dossier && vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux){
	                var responsibleUser = "";
	                if (vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.name) {
	              	  responsibleUser = responsibleUser + vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.name + " ";
	                }
	                if (vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.surename1) {
	              	  responsibleUser = responsibleUser + vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.surename1 + " ";
	                }
	                if (vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.surename2) {
	              	  responsibleUser = responsibleUser + vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.surename2;
	                }
	                
	                return responsibleUser;
				}else if(vm.dossierTabsComponent.dossierComponent.dossier && vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux && vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.user){
					var responsibleUser = "";
					if (vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.user.value){
						responsibleUser = vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.user.value;
					}else if(vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.user && vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.user.user && vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.user.user.completeName){
						responsibleUser = vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux.user.user.completeName;
					}
					return responsibleUser;
	            }else{
	                return "";
	            }
			}
			vm.allProfiles = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
            vm.requiredTramClassification = false;
			vm.showTramClassification = false;
            if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.tram_classification_dossier) {
            	vm.requiredTramClassification = $rootScope.app.configuration.tram_classification_dossier.required;
            	vm.showTramClassification = $rootScope.app.configuration.tram_classification_dossier.show;
            }
			vm.loadUsers = CommonService.loadUsers;
			
			this.$onInit = function() {
				vm.dossierTabsComponent.dossierComponent.calculateCounters();
				if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.edit_qdc_eset) {
            		vm.editQdcOnEsetMode = $rootScope.app.configuration.edit_qdc_eset.value;
            	}
				vm.languageColumn = vm.dossierTabsComponent.dossierComponent.languageColumn;
				vm.archiveClassifications = GlobalDataFactory.archiveClassifications;
				vm.councillors = GlobalDataFactory.councillors;
				vm.inverseLanguageColumn = "";
				vm.informationLanguageColumn = "";
		        if(vm.languageColumn) {
		            vm.informationLanguageColumn = "informationLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
					vm.inverseLanguageColumn = "inverseLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
		        }
				vm.comboDossierResponsibleUser = [];
				vm.operationsActiveInDossier = ((vm.dossierTabsComponent.dossierComponent.operationsActive  && vm.dossierTabsComponent.dossierComponent.dossier && vm.dossierTabsComponent.dossierComponent.dossier.procedure && vm.dossierTabsComponent.dossierComponent.dossier.procedure.operationsActive)?true:false);
        		var addedUsers = [];
            	if (vm.dossierTabsComponent.dossierComponent.isUpdateDossier && 
	            		vm.dossierTabsComponent.dossierComponent.dossier &&
	            		vm.dossierTabsComponent.dossierComponent.dossier.procedure &&
						vm.dossierTabsComponent.dossierComponent.dossier.procedure.procedureStartProfiles) {
		            _.forEach(vm.dossierTabsComponent.dossierComponent.dossier.procedure.procedureStartProfiles, function (profile) {
		                var users = $linq(profile.profile.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
		                users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
		                _.forEach(users, function (user) { 
		                	if (!vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser || user.id != vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser.id) {
		                		if(!_.contains(addedUsers, user.id)){
			                    	var responsibleUser = "";
			                        if (user.name) {
			                      	  responsibleUser = responsibleUser + user.name + " ";
			                        }
			                        if (user.surename1) {
			                      	  responsibleUser = responsibleUser + user.surename1 + " ";
			                        }
			                        if (user.surename2) {
			                      	  responsibleUser = responsibleUser + user.surename2;
			                        }
			                    	addedUsers.push(user.id);
			                    	vm.comboDossierResponsibleUser.push({id: user.id, description: responsibleUser});
		                		}
		                	}
		                });
		            });
				}
	            if(vm.comboDossierResponsibleUser && vm.comboDossierResponsibleUser.length > 0){
	            	vm.comboDossierResponsibleUser = $linq(vm.comboDossierResponsibleUser).orderBy("x => x.description", HelperService.caseInsensitiveOrNullComparer).toArray();
	            }
				if (vm.dossierTabsComponent.dossierComponent.dossier) {
			       vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux =  { user:undefined};
		            if(vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser){
		                var user_name = '';
		                user_name = vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser.name + ' ' + vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser.surename1 +  (vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser.surename2 ? ' ' +vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser.surename2 : '');
		                vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux = {user: {id: vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser.id, user: vm.dossierTabsComponent.dossierComponent.dossier.responsibleUser, value: user_name}};
		            }
		            vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfilesAux = [];
		            if(vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles && vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles.length > 0){
		                _.forEach(vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles, function(value){
		                    vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfilesAux.push(value.profile);
		                });
		            }
				}
				vm.dossierInputFormFields = [
					{ type: 'field', id: 'csv', fieldType: 'component', componentType: 'annexa-label-value-form', colClass: 'col-sm-12', label: 'global.documents.new.csv',
                            data: {
                                value: vm.dossierTabsComponent.dossierComponent.dossier.csv,
                                type: 'text',
                                rowClass: 'm-b-xs'
                            }
                    },
					{ type: 'field', id: 'councillor', fieldType: 'select-tree', colClass: 'col-sm-12', data: vm.councillors, required: false, label: 'global.literals.councillor', model: {}, printFunction: vm.printCouncillor, isReadOnly:vm.dossierTabsComponent.dossierComponent.isUpdateDossier, onlyLastLevelClick:true},
	                { type: 'field', id: 'archiveClassification', fieldType: 'select-tree', colClass: 'col-sm-12', data: vm.archiveClassifications, required: false, label: 'global.literals.classificationBox', model: {}, printFunction: vm.printQdC, isReadOnly:(vm.dossierTabsComponent.dossierComponent.isUpdateDossier && (!vm.dossierTabsComponent.dossierComponent.esetMode || vm.editQdcOnEsetMode))},
					{ type: 'field', id: 'family', fieldType: 'component', componentType: 'annexa-label-value-form', colClass: 'col-sm-12', label: 'global.literals.family',
                            data: {
                                value: vm.dossierTabsComponent.dossierComponent.dossier.procedure.family[vm.languageColumn],
                                type: 'text',
                                rowClass: 'm-b-xs'
                            }
                    },
	                { type: 'field', id: 'procedure', fieldType: 'component', componentType: 'annexa-label-value-form', colClass: 'col-sm-12', label: 'global.literals.procedure',
                            data: {
                                value: vm.dossierTabsComponent.dossierComponent.dossier.procedure[vm.languageColumn],
                                type: 'text',
                                rowClass: 'm-b-xs'
                            }
                    },
					{ type: 'field', id: 'classification', fieldType: 'select', data: vm.dossierTabsComponent.dossierComponent.dossier.procedure.procedureClassifications, colClass: 'col-sm-12', required: false, label: 'global.literals.classification', removeOnEset: false, 
							showIf: function(){
								return (vm.dossierTabsComponent.dossierComponent.dossier 
									&& vm.dossierTabsComponent.dossierComponent.dossier.procedure 
									&& vm.dossierTabsComponent.dossierComponent.dossier.procedure.showClassification 
									&& vm.dossierTabsComponent.dossierComponent.dossier.procedure.showClassification != 'DISABLED');
							}, isReadOnly: vm.dossierTabsComponent.dossierComponent.isUpdateDossier
					},
					{ type: 'field', id: 'actualTransaction', fieldType: 'component', componentType: 'annexa-label-value-form', colClass: 'col-sm-12', label: 'global.tram.literals.actual_transaction',
                            data: {
                                value: ((vm.dossierTabsComponent.dossierComponent.actualDossierTransaction && vm.dossierTabsComponent.dossierComponent.actualDossierTransaction[vm.languageColumn])?vm.dossierTabsComponent.dossierComponent.actualDossierTransaction[vm.languageColumn]:''),
                                type: 'text',
                                rowClass: 'm-b-xs'
                            }
                    },
					{ type: 'field', id: 'tramClassification', fieldType: 'select', data: GlobalDataFactory.tramClassifications, colClass: 'col-sm-12', required: vm.requiredTramClassification, label: 'global.literals.tramClassification', removeOnEset: false, 
							showIf: function(){
								return vm.showTramClassification;
							}, isReadOnly: vm.dossierTabsComponent.dossierComponent.isUpdateDossier 
					},
					{ type: 'field', id: 'dossierTramitationType', fieldType: 'select', data: GlobalDataFactory.dossierTramitationTypes, colClass: 'col-sm-12', required: true, label: 'global.literals.tramitationType', removeOnEset: true, 
							showIf: function(){
								return !vm.dossierTabsComponent.dossierComponent.dossier.procedure.guided;
							}, isReadOnly: vm.dossierTabsComponent.dossierComponent.isUpdateDossier 
					},
					{ type: 'field', id: 'dossierUrgent', fieldType: 'select', data: vm.dossierTabsComponent.dossierComponent.comboYesNo, colClass: 'col-sm-12', required: true, label: 'global.literals.dttURGENT', labelProp:'description', printFunction:vm.printDossierUrgent, removeOnEset: false, 
							showIf: function(){
								return ((vm.dossierTabsComponent.dossierComponent.dossier && vm.dossierTabsComponent.dossierComponent.dossier.dossierTramitationType && vm.dossierTabsComponent.dossierComponent.dossier.dossierTramitationType.allowUrgentProcess)?true:false);
							}, isReadOnly: vm.dossierTabsComponent.dossierComponent.isUpdateDossier 
					},
					{ type: 'field', id: 'responsibleUserAux', fieldType: 'select', data: vm.comboDossierResponsibleUser, colClass: 'col-sm-12', required: true, label: 'global.literals.responsible', labelProp:'description', printFunction:vm.printDossierResponsibleUser, removeOnEset: false, editPermissions: ['change_responsible_dossier'], 
							showIf: function(){
								return (($rootScope.esetMode)?true:false);
							}, isReadOnly: vm.canEditEsetDossierResponsibleUser() 
					},
					{ type: 'field', id: 'responsibleUserAux', fieldType: 'loadUser', colClass: 'col-sm-12', required: true, model:vm.dossierTabsComponent.dossierComponent.dossier.responsibleUserAux, label: 'global.literals.responsible',search:vm.loadUsers, removeOnEset: true, editPermissions: ['change_responsible_dossier'], 
							isReadOnly: vm.dossierTabsComponent.dossierComponent.isUpdateDossier 
					},
					{ type: 'field', id: 'dossierResponsibleProfilesAux', fieldType: 'component', componentType: 'annexa-select-multiple', data: vm.allProfiles, colClass: 'col-sm-12', required: false, label: 'global.literals.responsibleProfiles', labelProp: vm.languageColumn, printFunction:vm.getProfileValue, removeOnEset: true, editPermissions: ['change_responsible_dossier'], 
							isReadOnly: vm.dossierTabsComponent.dossierComponent.isUpdateDossier 
					},
    				{ type: 'field', id: 'observations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.literals.observations',
							showIf: function(){
								return vm.dossierTabsComponent.dossierComponent.isUpdateDossier;
							}
					},
					{ type: 'field', id: 'observations', fieldType: 'component', componentType: 'annexa-label-value-form', colClass: 'col-sm-12', label: 'global.literals.observations',
                            data: {
                                value: vm.dossierTabsComponent.dossierComponent.dossier.observations,
                                type: 'text',
                                rowClass: 'm-b-xs'
                            },
							showIf: function(){
								return !vm.dossierTabsComponent.dossierComponent.isUpdateDossier;
							}
                    },
					{ type: 'field', id: 'observations', fieldType: 'component', componentType: 'annexa-label-value-form', colClass: 'col-sm-12', label: 'global.literals.rejectReason',
                            data: {
                                value: vm.dossierTabsComponent.dossierComponent.dossier.cancelReason,
                                type: 'text',
                                rowClass: 'm-b-xs'
                            },
							showIf: function(){
								return !vm.dossierTabsComponent.dossierComponent.isUpdateDossier && vm.dossierTabsComponent.dossierComponent.dossier.cancelReason;
							}
                    }
	            ]
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierTransactions',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transactions.html',
        require: {
            dossierTabsComponent: '^^annexaBoxObjectDossierTabs'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.dossierTransactions = [];
			vm.actualTransaction = undefined;
			vm.activeTransaction = 0;
			vm.transactionActiveTab = 0;
			vm.disableTransaction = function(transaction){
				return false;
			}
			vm.idTransactionSelectedTab = undefined;
			
			vm.transactionDossierTabSelected = function(selected){
           		vm.idTransactionSelectedTab = ((selected)?selected.id:undefined);
				$("html, body").animate({ scrollTop: 0 }, 500);
			}
			
			vm.getProfileNames = function(transaction){
                var profiles = '';
                if(transaction && transaction.processProfiles){
                    _.forEach(transaction.processProfiles, function(processProfile){
                        if(processProfile && processProfile.profile){
                            if(profiles == ''){
                                profiles += processProfile.profile[vm.languageColumn];
                            }else{
                                profiles += ', '+processProfile.profile[vm.languageColumn];
                            }
                        }
                    });
                }
                return profiles;
            }
				
			this.$onInit = function() {
				vm.languageColumn = vm.dossierTabsComponent.dossierComponent.languageColumn;
				if(vm.dossierTabsComponent.dossierComponent.dossier && vm.dossierTabsComponent.dossierComponent.dossier.dossierTransactions){
					vm.dossierTransactions = $linq(vm.dossierTabsComponent.dossierComponent.dossier.dossierTransactions).orderByDescending("x => x.createdDate").thenByDescending("x => x.id").toArray();
	                if(vm.dossierTabsComponent.dossierComponent.actualDossierTransaction && vm.dossierTabsComponent.dossierComponent.actualDossierTransaction.id){
		                var actualTransactionIndex = $linq(vm.dossierTransactions).indexOf("x => x.id == "+vm.dossierTabsComponent.dossierComponent.actualDossierTransaction.id);
		                if (actualTransactionIndex != -1) {
		                	 vm.activeTransaction = actualTransactionIndex;
							 vm.idTransactionSelectedTab = vm.dossierTransactions[actualTransactionIndex].id;
		                }else{
		                	vm.activeTransaction = 0;
							vm.idTransactionSelectedTab = vm.dossierTransactions[0].id;
		                }
	                }else{
	                	vm.activeTransaction = 0;
						vm.idTransactionSelectedTab = undefined;
	                }
				}
				
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierTransaction',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction.html',
        require: {
            dossierTransactionsComponent: '^^annexaBoxObjectDossierTransactions'
        },
        controller:['$q', '$http', '$rootScope', '$stateParams', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'TramNewFactory', 'apiAdmin', 'AnnexaPermissionsFactory', 'RestService', 'DialogsFactory','AnnexaFormlyFactory', 'globalModals', '$state', 'ErrorFactory', 'SecModals', 'SecFactory', 'CommonService',
		function($q, $http, $rootScope, $stateParams, $filter, Language, GlobalDataFactory, HelperService, TramNewFactory, apiAdmin, AnnexaPermissionsFactory, RestService, DialogsFactory,AnnexaFormlyFactory, globalModals, $state, ErrorFactory, SecModals, SecFactory, CommonService) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.isUpdateDossier = false;
			vm.dossierTransaction = undefined;
			vm.dossierTransactionProcessProfiles = [];
			vm.proposal = undefined;
            vm.inspectionProposal = undefined;  
			vm.proposalsDossierTransaction = [];
	        vm.inspectionProposalsDossierTransaction = [];
			vm.proposalsDecree = [];
			vm.canModifyTransaction = false;
			vm.canModifyTransactionAndActual = false;
			vm.showProposalTab = false;
			vm.showAmendmentTab = false;
			vm.isProposalTransaction = false;
			vm.isAmendmentTransaction = false;
			vm.isAgreementTransaction = false;
			vm.isOmissionAgreementTransaction = false;
			vm.isTransferTransaction = false;
			vm.isInspectionIntervention = false;
			vm.isInspectionDepartment = false;
			vm.isStartInspectionTransaction = false;
			vm.isAgreementFreeTransaction = false;
			vm.isInspectionInterventionFree = false;
			vm.isInspectionDepartmentFree = false;
			vm.isOmission = false;
			vm.isReparation = false;
			vm.isOmissionFree = false;
			vm.isReparationFree = false;
			vm.isInternalControl = false;
			vm.existOmission = false;
			vm.existInspectionIntervention = false;
			vm.canCreateOmissionReparation = false;
			vm.canSeeDossierTransactionResponsibleUserLabel = false;
			vm.canSetDossierTransactionResponsibleUser = false;
			vm.canSeeDossierTransactionResponsibleUserSelect = false;
			vm.canEditDossierTransactionResponsibleUser = false;
			vm.comboTransactionResponsibleUser = [];
			vm.daysexpire = undefined;
			vm.expiredate = undefined;
            vm.expiretype = "BUSINESS";
			vm.isLoaded = false;
			vm.canEditDossierTransactionType = false;
			vm.isActiveCouncillor = false;
			vm.requiredAndCannotCreateDocument = [];
			vm.procedureProposalTypeInspectionInterventionActive = undefined;
			vm.setDossierTransactionResponsibleUser = function(){
				vm.dossierTransaction.responsibleUser = vm.dossierTransaction.responsibleUser ? undefined : vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser;
				RestService.update('./api/tram/new/dossierTransaction/' + vm.dossierTransaction.id, vm.dossierTransaction).then(function(returnData) {
					var returnDataDecoded = JSOG.decode(returnData);
					vm.dossierTransaction.responsibleUser = returnDataDecoded.responsibleUser;
				}).catch(function (error) { 
					vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
					if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
                		vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.tram.errors.inRestrictiveTransaction')});
                	}else{
						vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
					}
				});
			}

			vm.printTransactionResponsibleUser = function(){
				if(vm.dossierTransaction && vm.dossierTransaction.responsibleUser){
                      var responsibleUser = "";
                      if (vm.dossierTransaction.responsibleUser.name) {
                    	  responsibleUser = responsibleUser + vm.dossierTransaction.responsibleUser.name + " ";
                      }
                      if (vm.dossierTransaction.responsibleUser.surename1) {
                    	  responsibleUser = responsibleUser + vm.dossierTransaction.responsibleUser.surename1 + " ";
                      }
                      if (vm.dossierTransaction.responsibleUser.surename2) {
                    	  responsibleUser = responsibleUser + vm.dossierTransaction.responsibleUser.surename2;
                      }
                      
                      return responsibleUser;
                  }else{
                	  if(vm.dossierTransaction && vm.dossierTransaction.responsibleUser == null && !$rootScope.esetMode && $rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
							$rootScope.app.configuration.assign_responsible_user.dossierTransaction != 'Obligatoria'){
                		  return $filter("translate")("global.literals.withoutResponsible")
						}
                      return "";
                  }
			}
			
			vm.updateDossierTransactionResponsibleUser = function(property,val){
				if((property && val && !isNaN(val)) || (property == 'responsibleUser' && !val)) {
            		if(val){
						vm.dossierTransaction[property] = {id: val};
            		}else{
						vm.dossierTransaction[property] = undefined;
					}
                  	RestService.update('./api/tram/new/dossierTransaction/' + vm.dossierTransaction.id, vm.dossierTransaction).then(function(returnData) {
						var returnDataDecoded = JSOG.decode(returnData);
						vm.dossierTransaction.responsibleUser = returnDataDecoded.responsibleUser;
						vm.loadComboTransactionResponsibleUser();
					}).catch(function (error) {
						vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
						if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
                			vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.tram.errors.inRestrictiveTransaction')});
	                	}else{
							vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
						}
					});
            	  }      
			}

			vm.transactionTabSelected = function($event, tab){
				vm.dossierTransactionsComponent.transactionActiveTab = tab;
			}

			vm.isThisTransactionSubTypeFunction = function (subtype) {
				if (subtype && subtype.id && vm.dossierTransaction && vm.dossierTransaction.transactionType 
						&& vm.dossierTransaction.transactionType.transactionSubtype == subtype.id) {
					return true;
				} else {
					return false;
				}
			};
			
			vm.isInternalControlFunction = function () {
              	if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
                	return true;
              	} else {
              		return false;
            	}
			};
			
			vm.existReparationFunction = function(){
            	  if(vm.inspectionInterventionActive()){
            		  if(vm.proposal){
            			  var dts = $linq(vm.proposalsDossierTransaction).where("x => x.proposal.id == " + vm.proposal.id+" &&  x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[12].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }else if(vm.inspectionProposal){
            			  var dts = $linq(vm.inspectionProposalsDossierTransaction).where("x => x.inspectionProposal.id == " + vm.inspectionProposal.id+" && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[14].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }
            	  }
            	  return false;
			};
			
			vm.existOmissionFunction = function(){
            	  if(vm.inspectionInterventionActive()){
            		  if(vm.proposal){
            			  var dts = $linq(vm.proposalsDossierTransaction).where("x => x.proposal.id == " + vm.proposal.id+" &&  x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[11].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }else if(vm.inspectionProposal){
            			  var dts = $linq(vm.inspectionProposalsDossierTransaction).where("x => x.inspectionProposal.id == " + vm.inspectionProposal.id+" && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[13].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }
            	  }
            	  return false;
			};

			vm.existInspectionInterventionFunction = function(){
            	  if(vm.inspectionInterventionActive()){
            		  if(vm.proposal){
            			  var dts = $linq(vm.proposalsDossierTransaction).where("x => x.proposal.id == " + vm.proposal.id+" && x.dossierTransaction.actual && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[5].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }else if(vm.inspectionProposal){
            			  var dts = $linq(vm.inspectionProposalsDossierTransaction).where("x => x.inspectionProposal.id == " + vm.inspectionProposal.id+" && x.dossierTransaction.actual && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[8].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }
            	  }
            	  return false;
			};
			
			vm.canCreateOmissionReparationFunction = function(){
            	  if(vm.inspectionInterventionActive()){
            		  if(vm.proposal && vm.proposal.canCreateOmissionReparation){
            			  if (vm.dossierTransaction && vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[5].id) {
            				  return true;
    	                  }
            		  }else if(vm.inspectionProposal && vm.inspectionProposal.canCreateOmissionReparation){
            			  if (vm.dossierTransaction && vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[8].id) {
            				  return true;
    	                  }
            		  }
            	  }
            	  return false;
			};

			vm.inspectionInterventionActive = function(){
				  var active = false;
				  if(vm.procedureProposalTypeInspectionInterventionActive !== undefined){
					  if(vm.procedureProposalTypeInspectionInterventionActive){
						  active = true;
					  }
				  }else if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.inspectionIntervention != undefined){
            		  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.inspectionIntervention){
            			  active = true;
            		  }
            	  }else{
            		  var arcClassification = undefined;
            		  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected.id){
            			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected.id);
            		  }
            		  if(arcClassification && arcClassification.inspectionIntervention){
            			  active = true;
            		  }else{
            			  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification.id){
                			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification.id);
                		  }
            			  if(arcClassification && arcClassification.inspectionIntervention){
                			  active = true;
                		  }
            		  }
            	  }
            	  return active;
			}
              
			vm.isInspectionInterventionNext = function () {
            	  if(vm.inspectionInterventionActive()){
	                  if (vm.dossierTransaction && vm.dossierTransaction.transactionType 
	                  		&& (vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id 
								|| vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[4].id)) {
	                	  if($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && !$rootScope.app.configuration.secretary_properties.inspection_department){
	                		  return true;
	                	  }else{
	                		  return false;
	                	  }
	                  }else if(vm.dossierTransaction && vm.dossierTransaction.transactionType 
	                    		&& vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[6].id){
	                	  return true;
	                  } else {
	                  	  return false;
	                  }
            	  }else{
            		  return false;
            	  }
			};
              
			vm.isInspectionDepartmentNext = function () {
            	  if(vm.inspectionInterventionActive()){
	            	  if (vm.dossierTransaction && vm.dossierTransaction.transactionType 
	                    		&& (vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id 
								|| vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[4].id)) {
	                  	  if($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.inspection_department){
	                  		  return true;
	                  	  }else{
	                  		  return false;
	                  	  }
                      } else {
                    	return false;
                      }
            	  }else{
            		  return false;
            	  }
			};
              
            vm.isInspectionInterventionFreeNext = function () {
            	  if(vm.inspectionInterventionActive()){
	                  if (vm.dossierTransaction && vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[7].id) {
	                	  if($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && !$rootScope.app.configuration.secretary_properties.inspection_department){
	                		  return true;
	                	  }else{
	                		  return false;
	                	  }
	                  }else if(vm.dossierTransaction && vm.dossierTransaction.transactionType 
	                    		&& vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[9].id){
	                	  return true;
	                  } else {
	                  	return false;
	                  }
            	  }else{
            		  return false;
            	  }
			};
              
            vm.isInspectionDepartmentFreeNext = function () {
            	  if(vm.inspectionInterventionActive()){
	            	  if (vm.dossierTransaction && vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[7].id) {
	                  	  if($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.inspection_department){
	                  		  return true;
	                  	  }else{
	                  		  return false;
	                  	  }
	                    } else {
	                    	return false;
	                    }
            	  }else{
            		  return false;
            	  }
			};

            vm.haveInspectionInterventionOpen = function () {
            	  if(vm.proposal && vm.proposal.id){
	            	  var pdt = $linq(vm.proposalsDossierTransaction).lastOrDefault(undefined, "x => x.proposalDossierTransactionType == 'INSPECTION_INTERVENTION' && x.dossierTransaction && x.dossierTransaction.actual && x.proposal && x.proposal.id == "+vm.proposal.id);
	            	  if(pdt && pdt.length > 0){
	            		  return true;
	            	  }
            	  }else if(vm.inspectionProposal && vm.inspectionProposal.id){
            		  var pdt = $linq(vm.inspectionProposalsDossierTransaction).lastOrDefault(undefined, "x => x.inspectionProposalDossierTransactionType == 'INSPECTION_INTERVENTION' &&  x.dossierTransaction && x.dossierTransaction.actual && x.inspectionProposal && x.inspectionProposal.id == "+vm.inspectionProposal.id);
	            	  if(pdt && pdt.length > 0){
	            		  return true;
	            	  }
            	  }
            	  return false;
			};
			
			vm.editTramitationPhase = function(){
				var modal = angular.copy(globalModals.tramitationPhaseEdit);
				var tramitationPhases = $linq(vm.dossierTransaction.transactionType.phases).select("x => x.tramitationPhase").toArray();
				modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = { tramitationPhase: vm.dossierTransaction.phase };
                var tramitationPhaseField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'tramitationPhase'");
                if(tramitationPhaseField) {
                	tramitationPhaseField.templateOptions.options = tramitationPhases;
                	tramitationPhaseField.templateOptions.labelProp = Language.getActiveColumn();
                }
                var edit = function() {
                	TramNewFactory.editTramitationPhase(vm.dossierTransaction.id, modal.annexaFormly.model.modal_body.tramitationPhase.id).then(function(data){
                		modal.close();
                        $state.reload();
    				}).catch(function(error){
                		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
    				});
                }
                AnnexaFormlyFactory.showModal("modalEditTramitationPhase", modal, edit, false);
                
				
			}
			
			vm.editTransactionType = function(){
				TramNewFactory.getDossierTransactionCustomFields(vm.dossierTransaction.id).then(function(data){
					var customFields = JSOG.decode(data);
					var haveCustomField = false;
					_.forEach(customFields, function (value, key) {
						try{
							if (value.value) {
		                        var object = angular.fromJson(value.value);
		                        value.valueFromJSON = object.value;
		                        if (value.customField.backendType == "DATETIME" && value.valueFromJSON && value.valueFromJSON != "") {
		                            value.valueFromJSON = new Date(value.valueFromJSON);
		                        }
		                    } else {
		                        if (value.customField.frontendType == 'MULTIPLESELECT' || value.customField.frontendType == 'CHECKBOX') {
		                            value.valueFromJSON = [];
		                        } else {
		                            value.valueFromJSON = '';
		                        }
		                    }
		                    if (value && value.customField && value.customField.backendType == "DECIMAL") {
		                        if (value.customField.decimalPositions) {
		                            value.step = '0.' + Array(value.customField.decimalPositions).join('0') + '1';
		                        } else {
		                            value.step = '0.01'
		                        }
		                    }
		                    if(value.valueFromJSON && value.valueFromJSON != ''){
		                    	haveCustomField = true;
		                    }
						}catch(e){
							console.error(e);
							if(value){
								value.valueFromJSON = '';
							}
						}
	                });
					var modal = angular.copy(globalModals.transactionTypeEdit);
					var transactionsTypes = $linq(GlobalDataFactory.transactiontypes).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id).toArray();
	                
					if(vm.dossierTransactionsComponent && vm.dossierTransactionsComponent.dossierTabsComponent && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent 
							&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure 
							&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes 
							&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes.length > 0 && transactionsTypes && transactionsTypes.length > 0){
						transactionsTypes = $linq(transactionsTypes).intersect(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes, function(x,y){
	                        if(y.id && x.transactionType && x.transactionType.id) {
	                            return y.id == x.transactionType.id;
	                        } else {
	                            return false;
	                        }
	                    }).toArray();
					}
					
	                modal.annexaFormly.model = {};
	                modal.annexaFormly.model.modal_body = { transactionType: vm.dossierTransaction.transactionType };
	                var transactionTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'transactionType'");
	                if(transactionTypeField) {
	                	transactionTypeField.templateOptions.options = transactionsTypes;
	                	transactionTypeField.templateOptions.labelProp = Language.getActiveColumn();
	                }
	                var customFieldsInfoField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'customFieldsInfo'");
	                if(customFieldsInfoField && haveCustomField) {
	                	customFieldsInfoField.templateOptions.label = $filter("translate")("global.literals.editTransactionTypeCustomFieldInfo");
	                }
	                var edit = function() {
	                	TramNewFactory.editTransactionType(vm.dossierTransaction.id, modal.annexaFormly.model.modal_body.transactionType.id).then(function(data){
	                		modal.close();
                            $state.reload();
	    				}).catch(function(error){
	                		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
	    				});
	                }
	                AnnexaFormlyFactory.showModal("modalEditTransactionType", modal, edit, false);
				}).catch(function(error){
            		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
				});
			}
                 
			vm.inSubprocedureNotGuided = function(dt){
				var inSubNG = false;
				if(vm.dossierTransactionsComponent && vm.dossierTransactionsComponent.dossierTabsComponent && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent 
						&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure 
						&& !vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.guided && dt && dt.subprocedure && dt.subprocedure.id){
					inSubNG = true;			
				}
				return inSubNG;	
			}   
			        
			var canModifyTransactionFunction = function () {
            	var modifyTransaction = false;
				var modifyTransactionAndActual = false;
				var isResponsableInProfiles = function(profiles){
					var modifyByProfiles = false;
					var existProfile = false;
					if(profiles && profiles.length > 0){
	                    _.forEach(profiles, function (value, key) {
	                        if (!existProfile && value.profile && value.profile.id) {
	                            var profiles = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles).where("x => x.profile.id == " + value.profile.id).toArray();
	                            if (profiles && profiles.length > 0) {
	                                existProfile = true;
	                            }
	                        }
	                    });
	                    if (existProfile) {
	                        if ($rootScope.esetMode && vm.dossierTransaction && vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.finallyType && !vm.inSubprocedureNotGuided(vm.dossierTransaction)) {
	                            if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isResponsible()) {
	                                modifyByProfiles = true;
	                            }
	                        } else {
	                            modifyByProfiles = true;
	                        }
	                    }
					}
					return modifyByProfiles;
				}

                if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isArchivedDossier 
						&& (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'TRANSFER' 
						|| vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'REJECTED' 
						|| (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' && !vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveDefinitiveDate))){
                	 modifyTransaction = true;
					 modifyTransactionAndActual = true;
                }else{
					if (vm.dossierTransaction && vm.dossierTransaction.processProfiles && vm.dossierTransaction.processProfiles.length > 0) {
                        if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles) {
                            modifyTransaction = isResponsableInProfiles(vm.dossierTransaction.processProfiles);
							modifyTransactionAndActual = ((vm.dossierTransaction.actual)?modifyTransaction:false);
                        }
					}
					if (!modifyTransaction && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.procedureTramitationProfiles && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.procedureTramitationProfiles.length > 0) {
                        if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles) {
							modifyTransaction = isResponsableInProfiles(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.procedureTramitationProfiles);
							modifyTransactionAndActual = ((vm.dossierTransaction.actual)?modifyTransaction:false);
                        }
					}
                }
                vm.canModifyTransaction = modifyTransaction;
				vm.canModifyTransactionAndActual = modifyTransactionAndActual;
            }
	
			vm.loadComboTransactionResponsibleUser = function () {
            	vm.comboTransactionResponsibleUser = [];
				if(vm.dossierTransaction.processProfiles){
	                _.forEach(vm.dossierTransaction.processProfiles, function (profile) {
	                	if(vm.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
	    					var dtpp = $linq(vm.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
	    					if(dtpp){
		        				var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
			                    users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
								if(!$rootScope.esetMode && $rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
									$rootScope.app.configuration.assign_responsible_user.dossierTransaction != 'Obligatoria'){
									if(!$linq(vm.comboTransactionResponsibleUser).contains({id: undefined}, "(x,y) => x.id == y.id")){
										vm.comboTransactionResponsibleUser.push({id: undefined, description: $filter("translate")("global.literals.withoutResponsible")});
									}	
								}
								var selectableUsersConf = undefined;
								if(vm.dossierTransactionsComponent && vm.dossierTransactionsComponent.dossierTabsComponent && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent 
										&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure 
										&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes 
										&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes.length > 0) {
									var cttConf = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes).firstOrDefault(undefined,"x => x.transactionType.id == "+vm.dossierTransaction.transactionType.id);
									if(cttConf) {
										selectableUsersConf = $linq(cttConf.users).select("x => x.user.id").toArray();
									}
								}
			                    _.forEach(users, function (user) {
			                    	if (!vm.dossierTransaction.responsibleUser || user.id != vm.dossierTransaction.responsibleUser.id) {
			                    		var addUser = true;
			                    		if(selectableUsersConf && selectableUsersConf.length > 0 && !$linq(selectableUsersConf).contains(user.id, "(x,y) => x == y")) {
			                    			addUser = false;
			                    		}
										if(!$linq(vm.comboTransactionResponsibleUser).contains(user.id, "(x,y) => x == y.id") && addUser){
											var responsibleUser = "";
					                        if (user.name) {
					                      	  responsibleUser = responsibleUser + user.name + " ";
					                        }
					                        if (user.surename1) {
					                      	  responsibleUser = responsibleUser + user.surename1 + " ";
					                        }
					                        if (user.surename2) {
					                      	  responsibleUser = responsibleUser + user.surename2;
					                        }
					                    	
					                    	vm.comboTransactionResponsibleUser.push({id: user.id, description: responsibleUser});
										}
			                    	}
			                    });
	    					}
	                	}
			        });
				}
            };  
 
			vm.updateDossierTransaction = function(property, data){
				var reloadDossier = false;
				if(property != 'language'){
					if(property == 'delayEstimationDays'){
						vm.dossierTransaction.maxDays = vm.dossierTransaction.maxDays + (data - vm.dossierTransaction[property]);
                		reloadDossier = vm.dossierTransaction.affectDossierExpireDate ? vm.dossierTransaction.affectDossierExpireDate : false;
					}else if(property == 'affectDossierExpireDate'){
						reloadDossier = true;
					}
					vm.dossierTransaction[property] = data;
				}
                RestService.update('./api/tram/new/dossierTransaction/' + vm.dossierTransaction.id, vm.dossierTransaction).then(function(returnData) {
					if(reloadDossier){
						$state.reload();
					}else{
						vm.updateTransaction();	
						if(returnData){
							returnData = JSOG.decode(returnData);
							vm.expiretype = ((returnData && returnData.expireType)?returnData.expireType:((returnData && returnData.transactionType && returnData.transactionType.expireType)?returnData.transactionType.expireType:"BUSINESS"));
							vm.daysexpire = ((returnData && returnData.transactionRemainingDays)?returnData.transactionRemainingDays:0);
							vm.expiredate =  HelperService.getExpirationDate(new Date(), vm.daysexpire, vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.entity.holidays, vm.expiretype);
							
							vm.dossierTransaction.expireType = returnData.expireType;
							vm.dossierTransaction.transactionRemainingDays = returnData.transactionRemainingDays;
		                }
					}
				  }).catch(function (error) {
					if(property === 'language'){
						vm.dossierTransaction.language1 = vm.transaction.language1;
						vm.dossierTransaction.language2 = vm.transaction.language2;
						vm.dossierTransaction.language3 = vm.transaction.language3;
					}
					vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
					if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
                		vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.tram.errors.inRestrictiveTransaction')});
                	}else{
	            		vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
					}
            	});
			}
			
			vm.updateTransaction = function(){
				if(vm.dossierTransaction && vm.dossierTransaction.id && vm.transaction && vm.transaction.id && vm.transaction.id == vm.dossierTransaction.id){
					vm.transaction.language1 = vm.dossierTransaction.language1;
					vm.transaction.language2 = vm.dossierTransaction.language2;
					vm.transaction.language3 = vm.dossierTransaction.language3;
					if(vm.dossierTransaction.transactionType){
						if(!vm.transaction.transactionType){
							vm.transaction.tansactionType = {};
						}
						vm.transaction.transactionType.id = vm.dossierTransaction.transactionType.id;
						vm.transaction.transactionType.language1 = vm.dossierTransaction.transactionType.language1;
						vm.transaction.transactionType.language2 = vm.dossierTransaction.transactionType.language2;
						vm.transaction.transactionType.language3 = vm.dossierTransaction.transactionType.language3;
					}
					if(vm.dossierTransaction.subprocedure){
						if(!vm.transaction.subprocedure){
							vm.transaction.subprocedure = {};
						}
						vm.transaction.subprocedure.id = vm.dossierTransaction.subprocedure.id;
						vm.transaction.subprocedure.language1 = vm.dossierTransaction.subprocedure.language1;
						vm.transaction.subprocedure.language2 = vm.dossierTransaction.subprocedure.language2;
						vm.transaction.subprocedure.language3 = vm.dossierTransaction.subprocedure.language3;
					}
					if(vm.dossierTransaction.processProfiles && vm.dossierTransaction.processProfiles.length > 0){
						if(!vm.transaction.processProfiles){
							vm.transaction.processProfiles = [];
						}else{
							vm.transaction.processProfiles.length = 0;
						}
						_.forEach(vm.dossierTransaction.processProfiles, function(profile){
							vm.transaction.processProfiles.push(profile);
						});
					}
					vm.transaction.endDate = vm.dossierTransaction.endDate;
				}
			}
			
			var canEditDossierTransactionResponsibleUser = function() {
				var res = false;
				if(vm.isUpdateDossier){
					if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.responsibleUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.responsibleUser.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
						res = true;
					} else {
						if(AnnexaPermissionsFactory.havePermission("change_responsible_dossier_transaction")){
							if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles) {
								if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles){
									_.forEach(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles, function (profile) {
										if(profile.profile && profile.profile.id){
											var profiles = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles).where("x => x.profile.id == " + profile.profile.id).toArray();
			                                if (profiles && profiles.length > 0) {
			                                    res = true;
			                                }
										}
									});
			
								}
							}
							if(!res){
								_.forEach(vm.dossierTransaction.processProfiles, function (profile) {
			        				if(vm.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
			        					var dtpp = $linq(vm.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
			        					if(dtpp){
			        						var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
			        						users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
			        						_.forEach(users, function (user) {
			        							if (user.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
			        								res = true;
			        							}
			        						});
			        					}
			        				}
			        			});
							}
						}
			    	}
				}
				return res;
			};
			
			var canEditDossierTransactionType = function() {
				var res = false;
				if(vm.isUpdateDossier && vm.dossierTransaction.actual && vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[0].id
						&& !vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.guided){
					if (vm.dossierTransaction.responsibleUser && vm.dossierTransaction.responsibleUser.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
						res = true;
					} else {
						if(vm.dossierTransaction.creatorUser.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
							res = true;
						}
						if(!res && vm.dossierTransaction.processProfiles.length > 0) {
							_.forEach(vm.dossierTransaction.processProfiles, function (profile) {
		        				if(vm.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
		        					var dtpp = $linq(vm.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
		        					if(dtpp){
		        						var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
		        						users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
		        						_.forEach(users, function (user) {
		        							if (user.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
		        								res = true;
		        							}
		        						});
		        					}
		        				}
		        			});
						}
					}
					if(!res){
						if(AnnexaPermissionsFactory.havePermission("change_transaction_type")){
							if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles) {
								if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles){
									_.forEach(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles, function (profile) {
										if(profile.profile && profile.profile.id){
											var profiles = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles).where("x => x.profile.id == " + profile.profile.id).toArray();
			                                if (profiles && profiles.length > 0) {
			                                    res = true;
			                                }
										}
									});
								}
								if(!res && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.procedureTramitationProfiles) {
									_.forEach(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.procedureTramitationProfiles, function (profile) {
										if(profile.profile && profile.profile.id){
											var profiles = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles).where("x => x.profile.id == " + profile.profile.id).toArray();
			                                if (profiles && profiles.length > 0) {
			                                    res = true;
			                                }
										}
									});
								}
							}
						}
					}
				}
				return res;
			}

			var canSeeDossierTransactionResponsibleUserLabel = function() {
            	var res = false;
            	if (!vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.esetMode && (!$rootScope.app || !$rootScope.app.configuration || !$rootScope.app.configuration.assign_responsible_user || !$rootScope.app.configuration.assign_responsible_user.dossierTransaction || 
						$rootScope.app.configuration.assign_responsible_user.dossierTransaction != 'Obligatoria')) {
					if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.responsibleUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.responsibleUser.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
						res = false;
					}else if(AnnexaPermissionsFactory.havePermission("change_responsible_dossier_transaction")){
						if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles) {
							var isInResponsibleProfiles = false;//Los usuarios del perfíl responsabe del expediente simpre que tengan el permiso, pueden modificar el usuario responsable del trámite
	            			if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles){
								_.forEach(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles, function (profile) {
									if(!isInResponsibleProfiles && profile.profile && profile.profile.id){
										var profiles = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles).where("x => x.profile.id == " + profile.profile.id).toArray();
	                                    if (profiles && profiles.length > 0) {
	                                        isInResponsibleProfiles = true;
	                                    }
									}
								});
	    
							}
							if(!isInResponsibleProfiles){
								_.forEach(vm.dossierTransaction.processProfiles, function (profile) {
		            				if(vm.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
		            					var dtpp = $linq(vm.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
		            					if(dtpp){
		            						var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
		            						users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
		            						_.forEach(users, function (user) {
		            							if (user.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
		            								isInResponsibleProfiles = true;
		            							}
		            						});
		            					}
		            				}
		            			});
							}
							if(!isInResponsibleProfiles && (!vm.dossierTtransaction.responsibleUser || vm.dossierTransaction.responsibleUser.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id)){
								res = true;
							}else{
								res = false;
							}
						}else{
							res = false;
						}
            		} else {
                		if(!vm.dossierTransaction.responsibleUser || vm.dossierTransaction.responsibleUser.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id){
							res = true;
						}   
                	}
            	}
            	return res;
            };                

			var canSeeDossierTransactionResponsibleUserSelect = function() {
            	var res = false;
        		if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.responsibleUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.responsibleUser.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
					res = true;
				} else {
					if(AnnexaPermissionsFactory.havePermission("change_responsible_dossier_transaction")){
						if (vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles) {
							if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles){
								_.forEach(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfiles, function (profile) {
									if(profile.profile && profile.profile.id){
										var profiles = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.userProfiles).where("x => x.profile.id == " + profile.profile.id).toArray();
	                                    if (profiles && profiles.length > 0) {
	                                        res = true;
	                                    }
									}
								});
	    
							}
						}
						if(!res){
							_.forEach(vm.dossierTransaction.processProfiles, function (profile) {
	            				if(vm.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
	            					var dtpp = $linq(vm.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
	            					if(dtpp){
	            						var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
	            						users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
	            						_.forEach(users, function (user) {
	            							if (user.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
	            								res = true;
	            							}
	            						});
	            					}
	            				}
	            			});
						}
					}
					if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.esetMode || ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
						$rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria')){
						res = true;
					}else{
						if(!res && vm.dossierTransaction.responsibleUser && vm.dossierTransaction.responsibleUser.id != vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id){
							res = true;
						}							
					}
            	}
            	return res;
            };                
				
			var canSetDossierTransactionResponsibleUser = function () {
				var res = false;
            	if (!vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.esetMode && vm.isUpdateDossier) {
            		//El usuario actual se puede hacer responsable del trámite, aunque no tenga el permiso y simpre que se encuentre dentro del perfil responsable del trámitre
        			if(vm.dossierTransaction && vm.dossierTransaction.processProfiles){
						_.forEach(vm.dossierTransaction.processProfiles, function (profile) {
            				if(vm.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
            					var dtpp = $linq(vm.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
            					if(dtpp){
		            				var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
		            				users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
		    	                    _.forEach(users, function (user) {
		    	                    	if (user.id == vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.id) {
		    	                    		res = true;
		    	                    	}
		    	                    });
            					}
            				}
            			});
					}
            	}
				return res;
			}
			
			vm.reopen = function(){
				DialogsFactory.confirm('global.tram.literals.reopen_dossier_transaction', 'global.tram.literals.reopen_dossier_transaction_confirm').then(function () {
	                 TramNewFactory.reopenDossierTransaction(vm.dossierTransaction.id).then(function (data) {
	                    if (data != null) {
                            $state.transitionTo('annexa.tram.pending.viewdossier', {
                                "dossier": vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id,
                                "dossierTransaction": data.id,
                                "selectedTab": "center"
                            });
							DialogsFactory.notify($filter('translate')('global.tram.literals.reopen_dossier_transaction_OK'));
	                    }
	                }).catch(function (error) {
	                    DialogsFactory.notify($filter('translate')('global.tram.literals.reopen_dossier_transaction_error'));
                    });
	    		}).catch(function () {
					//Empty
                });
			}
			
			vm.back = function(){
            	if (vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype 
                		&& vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
                    DialogsFactory.confirm('global.tram.literals.goback_transaction_title', 'global.tram.literals.confirmGoBackTransaction').then(function () {
                    	vm.existsDocumentsGoBackConfirm();
                    }).catch(function () {
                    });
                } else {
                	vm.existsDocumentsGoBackConfirm();
                }
			}
			
			vm.existsDocumentsGoBackConfirm = function () {
                TramNewFactory.getCompleteData(vm.dossierTransaction.id).then(function(data){
					if (data && data.haveDocuments) {
	                    DialogsFactory.confirm('global.tram.literals.dialog_exists_documents_goback', 'global.tram.literals.dialog_exists_documents_goback_confirm').then(function () {
	                        vm.goBackOK();
	                    }).catch(function () {
	                    });
	                } else {
	                    vm.goBackOK();
	                }
				}).catch(function(error){
					DialogsFactory.confirm('global.tram.literals.dialog_exists_documents_goback', 'global.tram.literals.dialog_exists_documents_goback_confirm').then(function () {
                        vm.goBackOK();
                    }).catch(function () {
                    });
				});                	
            }

			vm.goBackOK = function(){
				var data = {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: ''
                };
                var actualTransactionsNum = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions).count("x => x.actual");
                if (actualTransactionsNum > 1) {
                    var modal = angular.copy(globalModals.goBackTransactionPopup);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    AnnexaFormlyFactory.showModal('modalGoBackTransaction', modal, vm.goBackSubmitPopup, false);
                } else {
                    var modal = angular.copy(globalModals.goBackTransactionPopupWizardNew);
                    _.forEach(modal.wizard.steps, function (step, key) {
                        modal.wizard.steps[key].annexaFormly.model = {};
                        modal.wizard.steps[key].annexaFormly.options = {};
                        if (key == 1) {
                            modal.wizard.steps[key].annexaFormly.model = {
                                transactionNoGuiadaList: [],
                                haveReopenPermission: AnnexaPermissionsFactory.havePermission("reopen_dossier_transaction")
                            };
                            modal.wizard.steps[key].annexaFormly.fields[1].templateOptions.numActuals = actualTransactionsNum;
							modal.wizard.steps[key].annexaFormly.fields[1].data = {
    							dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
		                        transaction: undefined,	
		                        omission: false,
		                        reparation: false,
								free: false, 
								omissionOrReparation:false,
								loadFunction: TramNewFactory.searchProfiles,
								saveFunction: function(){},
								inputType:  'Profile',
							    buttonLabel:  'global.literals.select',
							    placeholder: 'global.reg.literals.profileOrUserName',
							    showTitle: false,
							    showOr: false,
								type: 'text',
								procedureProposalTypeInspectionInterventionActive:vm.procedureProposalTypeInspectionInterventionActive
				        	}            
							modal.wizard.steps[key].annexaFormly.fields[2].templateOptions.closedDossierTransactions = $linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions).where("x => !x.actual && !x.subprocedure").toArray();
                        }
                    });
                    modal.wizard.modal = modal;
                    modal.extra = {transaction: vm.dossierTransaction};
                    AnnexaFormlyFactory.showModal('modalGoBackTransaction', modal, vm.goBackSubmitPopup, false, true);
                }
			}
			
			vm.goBackSubmitPopup = function(){
                var self = this;
                var motiu = "";
                if (this.wizard) {
                    motiu = this.wizard.steps[0].annexaFormly.model.motiu;
                } else {
                    motiu = this.annexaFormly.model.motiu;
                }

                var okFields = false;
                var transactionLists = [];
                var dossierTransactionToReopen = null;
                if (this.wizard && this.wizard.steps && this.wizard.steps[1] && this.wizard.steps[1].annexaFormly && this.wizard.steps[1].annexaFormly.fields && this.wizard.steps[1].annexaFormly.fields[1] && this.wizard.steps[1].annexaFormly.fields[1].templateOptions && this.wizard.steps[1].annexaFormly.fields[1].templateOptions.numActuals < 2) {
                    if (this.wizard.steps[1].annexaFormly.model.dossierTransactionType == 1 || !AnnexaPermissionsFactory.havePermission("reopen_dossier_transaction")) {
                        if (this.wizard.steps[1].annexaFormly.fields[1].data && this.wizard.steps[1].annexaFormly.fields[1].data.transactionNoGuiadaList &&  this.wizard.steps[1].annexaFormly.fields[1].data.transactionNoGuiadaList.length > 0) {
                            okFields = true;
                            transactionLists =  this.wizard.steps[1].annexaFormly.fields[1].data.transactionNoGuiadaList;
                        }
                    } else if (this.wizard.steps[1].annexaFormly.model.dossierTransactionType == 2) {
                        if (this.wizard.steps[1].annexaFormly.model && this.wizard.steps[1].annexaFormly.model.dossierTransactions) {
                            okFields = true;
                            dossierTransactionToReopen = this.wizard.steps[1].annexaFormly.model.dossierTransactions;
                        }
                    }
                } else {
                    okFields = true;
                }
                if (okFields) {
                    TramNewFactory.deleteDossierTransaction(vm.dossierTransaction.id, transactionLists, motiu, dossierTransactionToReopen).then(function (data) {
                        if (data != null) {
                            self.close();
                            $state.reload();
                        }
                    }).catch(function (error) {
                        self.alerts.push({msg: 'global.errors.unknownDelete'});
                    });
                } else {
                    this.alerts.push({msg: 'global.tram.errors.noTransactionsFinal'});
                }
			}
			
			vm.complete = function(){
				TramNewFactory.filterListTransactionTypeByPostSpel(vm.dossierTransaction.dossierId, vm.dossierTransaction.id).then(function(data) {
				TramNewFactory.getCompleteData(vm.dossierTransaction.id).then(function(data){
					if(data){
						if (vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype 
		                		&& vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
		                    DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmCompleteTransaction').then(function () {
		                    	vm.pendingSingDocumentsFunction(data);
		                    }).catch(function () {
		                        //Empty
		                    });
		                } else if (vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype 
		                		&& vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id && !data.notificationCertificate) {
									var confirmCompleteTransactionTransfer = 'global.tram.literals.confirmCompleteTransactionTransfer';
									if(data.notificationCertificateHaveSon){
										confirmCompleteTransactionTransfer = 'global.tram.literals.confirmCompleteTransactionTransferHaveSon';
									}
									DialogsFactory.confirm('global.tram.literals.complete_transaction_title', confirmCompleteTransactionTransfer).then(function () {
				                    	vm.pendingSingDocumentsFunction(data);
				                    }).catch(function () {
				                        //Empty
				                    });							
			            } else if (vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype 
		                		&& vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[7].id) {
		                    DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmInspectionCompleteTransaction').then(function () {
		                    	vm.pendingSingDocumentsFunction(data);
		                    }).catch(function () {
		                        //Empty
		                    });
		                } else {
		                	vm.pendingSingDocumentsFunction(data);
		                }
					}
				}).catch(function(error){
					DialogsFactory.error('global.tram.literals.complete_certification_notification_error_or_pending_sign');
				});
				}).catch(function (error) {
					if(error.message.includes("error-requestCompleteDossier-invalid-postSpel")) {
	                	var splitMessage = error.message.split("+");
	                	var message = ErrorFactory.getErrorMessage('dossier', 'complete', splitMessage[0]);
	                	if(splitMessage && splitMessage.length > 1) {
		                	message = $filter('translate')(message) +". " + splitMessage[1];
			            }else{
			                message = $filter('translate')(message) +". ";
			}
	                	DialogsFactory.error(message);
	                }
				});
			}
			
			vm.pendingSingDocumentsFunction = function (data) {
                if (data && data.pendingSingDocuments) {
                    DialogsFactory.error('global.errors.pendingSingDocumentsOrNew', $filter('translate')('DIALOGS_ERROR_MSG'));
                } else {
					if(vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype  
							&& (vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id || 
							vm.dossierTransaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id) 
							&& vm.dossierTransaction.fiscalizationInterventionAmendmentReason && !vm.dossierTransaction.fiscalizationDepartmentAmendmentReason){
						DialogsFactory.error('global.errors.fiscalizationDepartmentAmendmentReasonRequired', $filter('translate')('DIALOGS_ERROR_MSG'));
					}else{
	                    if (data && data.notDefinitiveDocuments) {
	                        DialogsFactory.confirm('global.literals.completeDocument', 'global.tram.literals.not_definitive_documents').then(function () {
	                            if (vm.dossierTransaction.graphNodeId != undefined && vm.dossierTransaction.graphNodeId != "") {
	                                vm.completeTransactionPopupGuiats(data);
	                            }
	                            else {
	                                vm.completeTransactionPopupNoGuiats(data);
	                            }
	                        }).catch(function () {
	                            //Empty
	                        });
	                    } else {
	                        if (vm.dossierTransaction.graphNodeId != undefined && vm.dossierTransaction.graphNodeId != "") {
	                           	vm.completeTransactionPopupGuiats(data);
	                        }
	                        else {
	                            vm.completeTransactionPopupNoGuiats(data);
	                        }
	                    }
					}
                }
            };
			
			vm.completeTransactionPopupNoGuiats = function(data){
                if (data.obligCFOk) {
					if(vm.dossierTransaction && vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.transactionSubtype && (vm.dossierTransaction.transactionType.transactionSubtype == 12 || vm.dossierTransaction.transactionType.transactionSubtype == 14)){
						DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmCompleteTransactionReparation').then(function () {
                        	TramNewFactory.completeDossierTransaction(vm.dossierTransaction, undefined, false, []).then(function (dataAux) {
								vm.completeTransactionResult(dataAux);                                
                            }).catch(function (error) {
                                if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
									vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
									vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg: $filter('translate')('global.documents.definitiveNotificationNoValidError')});
								}else{
									vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
									vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierA.alerts.push({msg: $filter('translate')(ErrorFactory.getErrorMessage('dossier', 'complete', error.data))});
								}
                            });
                        }).catch(function (error) {
                        });
					}else{
				    	var modal = angular.copy(globalModals.createDossierTransactionPopup);
		                modal.alerts = [];
		                modal.annexaFormly = new AnnexaFormly();
		                var fields = [];
						fields.push({
		                    key: 'completeDossierTransaction',
		                    type: 'annexaComponent',
		                    templateOptions: {
		                        type: 'annexa-complete-unguided-dossier-transaction'
		                    },
							data: {
		                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
		                        transaction: vm.dossierTransaction,	
		                        omission: false,
		                        reparation: false,
								free: false, 
								omissionOrReparation: false,
								loadFunction: TramNewFactory.searchProfiles,
								saveFunction: function(){},
								inputType:  'Profile',
							    buttonLabel:  'global.literals.select',
							    placeholder: 'global.reg.literals.profileOrUserName',
							    showTitle: false,
							    showOr: false,
								type: 'text',
								proposalsDossierTransaction: vm.proposalsDossierTransaction,
								inspectionProposalsDossierTransaction: vm.inspectionProposalsDossierTransaction,
								isCompleteTransaction: true,
								procedureProposalTypeInspectionInterventionActive:vm.procedureProposalTypeInspectionInterventionActive
		                    }
		            	});
		                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
		                modal.annexaFormly.model = {};
		                modal.annexaFormly.model.modal_body = {};
		                modal.annexaFormly.options = {};
		                modal.annexaFormly.options.formState = {readOnly: false};
		                AnnexaFormlyFactory.showModalPromise("modalCloseTransaction", modal, vm.completeTransaction, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
		                    modal.modal = modalAux;
		                    modal.close = modalAux.element.scope().close;
		                    modalAux.element.modal();
		                    angular.element(".modal-title").focus();
		                }).catch(function () {
		                    var a = 0;
		                });
					}
                } else {
                    DialogsFactory.error('global.literals.customFieldOblig', $filter('translate')('DIALOGS_ERROR_MSG'));
                }				
			}
			
			vm.completeTransactionPopupGuiats = function(dataComplete){
				if (dataComplete.obligCFOk) {
                	var showDossiersNonAgreementTrasactions = function(node){
                		if (node) {
                            var dossiersNonAgreementTransactionsInfo = 
                          	  $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype != " 
                          			  + apiAdmin.transactionSubtypes[2].id + " && x.dossierTransactionDefinition.transactionType.transactionSubtype != " + apiAdmin.transactionSubtypes[5].id + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[6].id
                          			 + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[8].id + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[9].id
                          			 + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[10].id + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[11].id 
                          			+ "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[12].id + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[13].id 
                          			+ "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[14].id +")").toArray();
                            dossiersNonAgreementTransactionsInfo = $linq(dossiersNonAgreementTransactionsInfo).where(function(x){
                          	  var isOkDTI = function(dti){
                          		  var dossiersNonAgreementTransactionsInfoAux =  ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[2].id 
                          			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[5].id && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[6].id
                           			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[8].id && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[9].id
                           			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[10].id && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[11].id 
                           			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[12].id && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[13].id 
                           			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[14].id))?true:false);
                          		  	if(!dossiersNonAgreementTransactionsInfoAux && !dti.dossierTransactionDefinition){
                          		  	if(dti.dossiersTransactionsInfo){
                            			  var isOk = false;
                            			  _.forEach(dti.dossiersTransactionsInfo, function(dtiAux){
                            				  if(!isOk){
                            					  isOk = isOkDTI(dtiAux);
                            				  }
                            			  });
                            			  return isOk;
                            		  }else{
                            			  return false;
                            		  }
                          		  	}
                                    return dossiersNonAgreementTransactionsInfoAux;
                          	  }
                          	  if(x && !x.dossierTransactionDefinition ){
                          		  if(x.dossiersTransactionsInfo){
                          			  var isOk = false;
                          			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                          				  if(!isOk){
                          					  isOk = isOkDTI(dti);
                          				  }
                          			  });
                          			  return isOk;
                          		  }else{
                          			  return false;
                          		  }
                          	  }else{
                          		  return true;
                          	  }
                            }).toArray();
                            node.dossiersTransactionsInfo = dossiersNonAgreementTransactionsInfo;
                            
							var modal = angular.copy(globalModals.createDossierTransactionPopup);
			                modal.alerts = [];
			                modal.annexaFormly = new AnnexaFormly();
			                var fields = [];
							fields.push({
			                    key: 'completeDossierTransaction',
			                    type: 'annexaComponent',
			                    templateOptions: {
			                        type: 'annexa-complete-guided-dossier-transaction'
			                    },
								data: {
			                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
			                        transaction: vm.dossierTransaction,	
									dossierTransactionInfo: node, 
									level: 1
			                    }
			            	});
			                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
			                modal.annexaFormly.model = {};
			                modal.annexaFormly.model.modal_body = {};
			                modal.annexaFormly.options = {};
			                modal.annexaFormly.options.formState = {readOnly: false};
			                AnnexaFormlyFactory.showModalPromise("modalCloseTransaction", modal, vm.completeTransactionGuiat, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
			                    modal.modal = modalAux;
			                    modal.close = modalAux.element.scope().close;
			                    modalAux.element.modal();
			                    angular.element(".modal-title").focus();
			                }).catch(function () {
			                    var a = 0;
			                });
                        } else {
							if(vm.dossierTransactionsComponent && vm.dossierTransactionsComponent.dossierTabsComponent 
									&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent 
									&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier 
									&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure 
									&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.guided){
							    TramNewFactory.isFinalTransaction(vm.dossierTransaction);
							}else{
								vm.completeTransactionPopupNoGuiats(dataComplete);
							}
                        }
                	}
                	if(vm.existReparation && (vm.isInspectionIntervention || vm.isInspectionInterventionFree)){
                		DialogsFactory.confirm('global.tram.literals.existReparation', 'global.tram.literals.existReparationClose').then(function () {
                    		TramNewFactory.getNextDossierTransactions(vm.dossierTransaction.id).then(function (data) {
                                if (data) {
                                    var node = JSOG.decode(data);
                                    if(node.graphNodeType == "SelectAllClose" && node.dossiersTransactionsInfo && node.dossiersTransactionsInfo.length > 0 && node.dossiersTransactionsInfo[0].graphNodeType == "SelectOne"){
                                    	showDossiersNonAgreementTrasactions(node.dossiersTransactionsInfo[0]);
                                    }else{
                                    	showDossiersNonAgreementTrasactions(node);
                                    }
                                } else {
									if(vm.dossierTransactionsComponent && vm.dossierTransactionsComponent.dossierTabsComponent 
											&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent 
											&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier 
											&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure 
											&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.guided){
	                                    TramNewFactory.isFinalTransaction(vm.dossierTransaction);
									}else{
										vm.completeTransactionPopupNoGuiats(dataComplete);
									}
                                }
                            });
                   	 	}).catch(function () {
                            //Empty
                        });
                	}else{
                		TramNewFactory.getNextDossierTransactions(vm.dossierTransaction.id).then(function (data) {
                            if (data) {
                                var node = JSOG.decode(data);
                                if((vm.isOmission || vm.isOmissionFree) && !vm.existInspectionIntervention && node.graphNodeType == "SelectAllClose"){
                                   	showDossiersNonAgreementTrasactions(node.dossiersTransactionsInfo[0]);
                                }else if((vm.isInspectionInterventionFree || vm.isInspectionIntervention) && node.graphNodeType == "SelectAllClose"){
                                	showDossiersNonAgreementTrasactions(node.dossiersTransactionsInfo[0]);
                                }else{
                                	showDossiersNonAgreementTrasactions(node);
                                }
                            } else {
								if(vm.dossierTransactionsComponent && vm.dossierTransactionsComponent.dossierTabsComponent 
										&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent 
										&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier 
										&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure 
										&& vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.guided){
	                                TramNewFactory.isFinalTransaction(vm.dossierTransaction);
								}else{
									vm.completeTransactionPopupNoGuiats(dataComplete);
								}
                            }
                        });	
                	}
                } else {
                	DialogsFactory.error('global.literals.customFieldOblig', $filter('translate')('DIALOGS_ERROR_MSG'));
                }			
			}
			
			vm.completeTransactionGuiat = function(){
				var self = this;
				if(self && self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup && self.annexaFormly.fields[0].fieldGroup.length > 0){
					var completeDossierTransactionField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'completeDossierTransaction'");
					if(completeDossierTransactionField && completeDossierTransactionField.data && completeDossierTransactionField.data.dossierTransactionInfo){
						var copyDTI = angular.copy(completeDossierTransactionField.data.dossierTransactionInfo);
						vm.changeFreeNodes(copyDTI);
						TramNewFactory.completeDossierTransactionGuiat(completeDossierTransactionField.data.transaction, copyDTI).then(function (data) {
		                    if (data && data.id) {
		                        completeDossierTransactionField.data.transaction.actual = false;
		                        if (data.id && $stateParams.dossierTransaction != data.id) {
		                            $state.transitionTo('annexa.tram.pending.viewdossier', {
		                                "dossier": completeDossierTransactionField.data.dossier.id,
		                                "dossierTransaction": data.id,
		                                "selectedTab": "center"
		                            });
		                        } else {
		                        	$state.returnToState();
		                        }
		                    } else {
		                       vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.returnToState();
		                    }
		                    self.close();
		                }).catch(function (error) {
		                    if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
								self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
							}else if(error && error.data && error.data.message && error.data.message == 'error-Missing-Fiscalization-Ammendment-Reason not found'){
								self.alerts.push({msg: 'global.errors.fiscalizationInterventionAmendmentReasonRequired'});
							}else{
								self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
							}
		                });
					}
				}
			}
			
			vm.changeFreeNodes = function (dossierTransactionInfo) {
                if (dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal" || dossierTransactionInfo.noResponsibleProfileAndInheritWithResponsibleProfile) {
                    if (dossierTransactionInfo && dossierTransactionInfo.profile && dossierTransactionInfo.profile.id) {
                        dossierTransactionInfo.profiles = [];
                        dossierTransactionInfo.profiles.push({id: dossierTransactionInfo.profile.id});
                    }
                }
				if(dossierTransactionInfo.dossiersTransactionsInfo){
	                _.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
	                    vm.changeFreeNodes(value);
	                });
				}
            };

			vm.completeTransaction = function(){
				var self = this;
				if(self && self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup && self.annexaFormly.fields[0].fieldGroup.length > 0){
					var completeDossierTransactionField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'completeDossierTransaction'");
					if(completeDossierTransactionField && completeDossierTransactionField.data){
						if(completeDossierTransactionField.data.transactionNoGuiadaList && completeDossierTransactionField.data.transactionNoGuiadaList.length > 0){
							if ($rootScope.esetMode && completeDossierTransactionField.data.transaction && completeDossierTransactionField.data.transaction.transactionType && completeDossierTransactionField.data.transaction.transactionType.finallyType && !vm.inSubprocedureNotGuided(completeDossierTransactionField.data.transaction)) {
		                        self.alerts.push({msg: 'global.tram.errors.noCompleteFinallyTransactionHaveOpenTransactions'});
		                    } else {
		                        TramNewFactory.completeDossierTransaction(completeDossierTransactionField.data.transaction, undefined, false, completeDossierTransactionField.data.transactionNoGuiadaList).then(function (data) {
		                            vm.completeTransactionResult(data, self);
		                        }).catch(function (error) {
		                            if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
										self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
									} else if(error && error.data && error.data.message && 
											(error.data.message == 'error-requestCompleteDossier-invalid-postSpel' || error.data.message.includes("error-requestCompleteDossier-invalid-preSpel"))) {
										if(completeDossierTransactionField.data.dossier && completeDossierTransactionField.data.dossier.procedure 
												&& completeDossierTransactionField.data.dossier.procedure.createTransactionTypes) {
							                if(error.data.message == 'error-requestCompleteDossier-invalid-postSpel') {
							                	var message = ErrorFactory.getErrorMessage('dossier', 'complete', error.data);
							                	var ctt = $linq(completeDossierTransactionField.data.dossier.procedure.createTransactionTypes).firstOrDefault(undefined, "x => x.transactionType.id == " + completeDossierTransactionField.data.transaction.transactionType.id);
								                if(ctt && ctt.postSpel) {
								                	if(ctt.descriptionNoPostSpel){
								                		message = $filter('translate')(message) + ". " + ctt.descriptionNoPostSpel + "";
								                	}else{
								                	message = $filter('translate')(message) + ". '" + ctt.postSpel + "'";
								                }
								                	
								                }
							                } else if(error.data.message.includes("error-requestCompleteDossier-invalid-preSpel")) {
							                	var splitMessage = error.data.message.split("+");
							                	var message = ErrorFactory.getErrorMessage('dossier', 'complete', splitMessage[0]);
							                	if(splitMessage && splitMessage.length > 1) {
								                	var ctt = $linq(completeDossierTransactionField.data.dossier.procedure.createTransactionTypes).firstOrDefault(undefined, "x => x.transactionType.id == " + splitMessage[1]);
									                if(ctt && ctt.preSpel) {
									                	if(ctt.descriptionNoPreSpel){
									                		message = $filter('translate')(message) + " (" + ctt.transactionType[Language.getActiveColumn()] + "). " + ctt.descriptionNoPreSpel + "";
									                	}else{
									                	message = $filter('translate')(message) + " (" + ctt.transactionType[Language.getActiveColumn()] + "). '" + ctt.preSpel + "'";
									                }
									                	
									                } 
							                	}
							                }
										}
										self.alerts.push({msg: message});
									} else{
										self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
									}
		                        });
		                    }
						}else{
							if (TramNewFactory.isResponsible(completeDossierTransactionField.data.dossier) || AnnexaPermissionsFactory.havePermission('close_dossier_transaction') || (completeDossierTransactionField.data.transaction && completeDossierTransactionField.data.transaction.transactionType 
	                    		&& (completeDossierTransactionField.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id || 
	                    				completeDossierTransactionField.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id ||
	                    				(vm.haveInspectionInterventionOpen() && 
	                    				(completeDossierTransactionField.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id ||
	                    				completeDossierTransactionField.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))))) {
		                        var dossierTransactionIsFinal = false;
		                        if (completeDossierTransactionField.data.transaction && completeDossierTransactionField.data.transaction.transactionType && completeDossierTransactionField.data.transaction.transactionType.finallyType && !vm.inSubprocedureNotGuided(completeDossierTransactionField.data.transaction)) {
		                            dossierTransactionIsFinal = true;
		                        }
		                        var openTransactions = $linq(completeDossierTransactionField.data.dossier.dossierTransactions).where("x => x.actual == true").toArray();
		                        var canCompleteEset = false;
		                        var canCompleteNoEset = false;
		                        if ($rootScope.esetMode) {
		                            if ((openTransactions && openTransactions.length > 1 && !dossierTransactionIsFinal) || (openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal)) {
		                                canCompleteEset = true;
		                            }
		                        } else {
		                            if ((openTransactions && openTransactions.length > 1) || (openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal)) {
		                                canCompleteNoEset = true;
		                            }
		                        }
		                        if (canCompleteEset || canCompleteNoEset) {
		                            TramNewFactory.completeDossierTransaction(completeDossierTransactionField.data.transaction, undefined, false, undefined, true).then(function (data) {
		                                completeDossierTransactionField.data.transaction.actual = false;
		                                var reloadStateTram = function(){
		                                	if (TramNewFactory.isResponsible(completeDossierTransactionField.data.dossier)) {
		                                		$state.transitionTo('annexa.tram.pending.viewdossier', {
		                                            "dossier": vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id,
		                                            "dossierTransaction": completeDossierTransactionField.data.transaction.id,
		                                            "selectedTab": "center"
		                                        });
		                                    } else {
		                                        vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.returnToState();
		                                    }
		                                    self.close();
		                                }
		                                if(completeDossierTransactionField.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id || completeDossierTransactionField.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id){
		                                	DialogsFactory.confirm('', 'global.tram.literals.closeReparation').then(function () {
                        						reloadStateTram();
											}).catch(function (error) {
												reloadStateTram();
                        					});
		                                }else if(completeDossierTransactionField.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id || completeDossierTransactionField.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id){
		                                	DialogsFactory.confirm('', 'global.tram.literals.closeOmission').then(function () {
                        						reloadStateTram();
											}).catch(function (error) {
												reloadStateTram();
                        					});
		                                }else{
		                                	reloadStateTram();
		                                }
		                            }).catch(function (error) {
		                                if (error.data && error.data.message && error.data.message == 'error-requestCompleteDossier-endDossier not found') {
		                                    DialogsFactory.confirm('global.literals.confirmEndDossierTitle', 'global.literals.confirmEndDossierBody').then(function () {
												TramNewFactory.endDossier(completeDossierTransactionField.data.transaction).then(function (data) {
		                                            vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.returnToState();
		                                            self.close();
		                                        }).catch(function (error) {
		                                            if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
														self.alerts.push({msg:  $filter('translate')('global.tram.errors.processTemplate'), msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
													}else if (error && error.data && error.data.message == 'No Required Custom Field Dossier Value data received in creation'){
														 self.alerts.push({msg: $filter('translate')('global.tram.errors.dossierCustomFields')});
													}else if (error && error.data && error.data.message == 'No Required Custom Field Value data received in creation'){
														 self.alerts.push({msg: $filter('translate')('global.tram.errors.dossierTransactionCustomFields')});
													}else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
														 self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
													}else{
													  	 self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
													}
		                                        });
											}).catch(function (error) {
					                        });
		                                }else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
											self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
										} else {
		                                    self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
		                                }
		                            });
		                        } else {
		                            if (dossierTransactionIsFinal) {
		                                self.alerts.push({msg: 'global.tram.errors.noCompleteFinallyTransactionHaveOpenTransactions'});
		                            } else {
		                                self.alerts.push({msg: 'global.tram.errors.noTransactions'});
		                            }
		                        }
		                    } else {
		                        var openTransactions = $linq(completeDossierTransactionField.data.dossier.dossierTransactions).where("x => x.actual == true").toArray();
		                        if (openTransactions && openTransactions.length > 1) {
		                            self.alerts.push({msg: 'global.tram.errors.noTransactions'});
		                        } else {
		                            self.alerts.push({msg: 'global.tram.errors.onlyResponsible'});
		                        }
		                    }
						}
					}else{
						self.alerts.push({msg: 'global.tram.errors.noTransactions'});
					}
				}else{
					self.alerts.push({msg: 'global.tram.errors.noTransactions'});
				}
			}
			
			vm.completeTransactionResult = function(data, self){
				var actualTransactionsWithPermission = [];
                if (data && data.actualsTransaction) {
                    data = JSOG.decode(data);
                    _.forEach(data.actualsTransaction, function (value) {
						var added = false;
                        if (value.processProfiles) {
                            _.forEach(value.processProfiles, function (value2) {
                                if (actualTransactionsWithPermission.length == 0 && AnnexaPermissionsFactory.haveProfile(value2.profile)) {
                                    actualTransactionsWithPermission.push(value);
									added = true;
                                }
                            })
                        }
						if (!added && value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                			actualTransactionsWithPermission.push(value);
                		}
                    });
					if(actualTransactionsWithPermission.length == 0 && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions && data.finishedTransaction) {
                        _.forEach($linq(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions).where("x => x.actual").toArray(), function (value) {
                            if (data.finishedTransaction.id != value.id && value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                            	actualTransactionsWithPermission.push(value);
                            }
                        });
                    }
                }
                if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                    vm.dossierTransaction.actual = false;
                    $state.transitionTo('annexa.tram.pending.viewdossier', {
                        "dossier": vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id,
                        "dossierTransaction": actualTransactionsWithPermission[0].id,
                        "selectedTab": "center"
                    });
                } else {
                    vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.returnToState();
                }
                if(self && self.close){
					self.close();
				}
			}
			
			vm.sendProposalDossierTransaction = function(){
                var titleConfirm = "global.tram.literals.confirmSendProposal";
                if(vm.isInspectionDepartmentNext()){
                	titleConfirm = "global.tram.literals.confirmSendProposalInspectionDepartment";
                }else if(vm.isInspectionInterventionNext()){
                 	titleConfirm = "global.tram.literals.confirmSendProposalInspectionIntervention";
                }
            	DialogsFactory.confirm('global.tram.literals.sendProposal', titleConfirm).then(function () {
					var promises = [];
	                promises.push(TramNewFactory.validateSendProposalTransaction(vm.dossierTransaction.id, vm.proposal.id));
	                promises.push($http({url: './api/tram/new/dossier/haveSons/'+vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id, method: 'GET'}));
	                $q.all(promises).then(function(data){
						var sendProposalIfOk = function(sendSecType){
							if (vm.dossierTransaction.graphNodeId != undefined && vm.dossierTransaction.graphNodeId != "") {
						      TramNewFactory.getNextDossierTransactions(vm.dossierTransaction.id).then(function (data) {
						          if (data) {
						              var node = JSOG.decode(data);
						              if (node) {
						            	  	var promises = [];
											if(sendSecType === 'SON') {
												promises.push(SecFactory.getDossierThirdsAndSons([vm.proposal.id], sendSecType));
											}else {
												promises.push(TramNewFactory.getDossierThirds(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id));
											}
											promises.push(TramNewFactory.getDossierTransactionDocuments(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id));
											promises.push($http({url: './api/sec/sec_notification/isNotificableFromSecretary/'+vm.proposal.id, method: 'GET'}));
											$q.all(promises).then(function(data) {
												if(data && data[2] && data[2].data && ((vm.proposal && vm.proposal.state && _.contains(['DRAFT'], vm.proposal.state) && vm.dossierTransaction && vm.dossierTransaction.actual && vm.dossierTransaction.transactionType &&
														 (vm.dossierTransaction.transactionType.transactionSubtype == 1 || vm.dossierTransaction.transactionType.transactionSubtype == 4)) ? true : false)) {
													vm.showModalSendProposalSelectThirds(data, sendSecType, node);
												} else {
													vm.showModalSendProposalDossierTransactionGuidedPopup(sendSecType, undefined, undefined, undefined, undefined, node);
												}
											}).catch(function(error) {
											    console.error(error);
											});
						              } else {
						                  TramNewFactory.isFinalTransaction(propDossierTransaction);
						              }
						          } else {
						              TramNewFactory.isFinalTransaction(propDossierTransaction);
						          }
						      });
						  } else {
							var promises = [];
							if(sendSecType === 'SON') {
								promises.push(SecFactory.getDossierThirdsAndSons([vm.proposal.id], sendSecType));
							}else {
								promises.push(TramNewFactory.getDossierThirds(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id));
							}
							promises.push(TramNewFactory.getDossierTransactionDocuments(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id));
							promises.push($http({url: './api/sec/sec_notification/isNotificableFromSecretary/'+vm.proposal.id, method: 'GET'}));
							$q.all(promises).then(function(data) {
								if(data && data[2] && data[2].data && ((vm.proposal && vm.proposal.state && _.contains(['DRAFT'], vm.proposal.state) && vm.dossierTransaction && vm.dossierTransaction.actual && vm.dossierTransaction.transactionType &&
										 (vm.dossierTransaction.transactionType.transactionSubtype == 1 || vm.dossierTransaction.transactionType.transactionSubtype == 4)) ? true : false)) {
									vm.showModalSendProposalSelectThirds(data, sendSecType, undefined);
								} else {
									vm.showModalSendProposalDossierTransactionPopup(sendSecType, undefined, undefined, undefined, undefined);
								}
							}).catch(function(error) {
							    console.error(error);
							})
						  }
						}
						if(data && data.length > 1  && data[1].data){
							if(vm.proposal && vm.proposal.workWithDossierSons == undefined){
								var modalSendType = angular.copy(globalModals.sendProposalType);
								modalSendType.size = 'modal-md';
			                    modalSendType.annexaFormly.model = {};
								modalSendType.annexaFormly.model.modal_body = {type:'NORMAL'};
			                    modalSendType.annexaFormly.options = {};
		                        modalSendType.annexaFormly.options.formState = {
				            		readOnly: false, 
				            	};
			                    var submitFunction = function() {
									var model = this.annexaFormly.model.modal_body;
									sendProposalIfOk(((model.type === "SON")?"SON":"NORMAL"));
									this.close();
								}  
			                    AnnexaFormlyFactory.showModal("modalSearchAdd", modalSendType, submitFunction, false);
							}else {
								sendProposalIfOk(((vm.proposal && vm.proposal.workWithDossierSons === true)?"SON":"NORMAL"));
							}
						}else{
							sendProposalIfOk("NORMAL");
						}
					}).catch(function(error){
						var errorToShow = "";
						if(error && error.data && error.data.message){
							var errorToShow = "";
							if(error.data.message.indexOf('--proposalType--') > 0 || error.data.message.indexOf('--extract--') > 0 || error.data.message.indexOf('--profile--') > 0 
										|| error.data.message.indexOf('--notificatorProfile--') > 0 || error.data.message.indexOf('--notificatorResponsible--') > 0 
										|| error.data.message.indexOf('--anonymousThirds--') > 0 || error.data.message.indexOf('--organs--') > 0 || error.data.message.indexOf('--documents--') > 0 
										|| error.data.message.indexOf('--amendmentTransactionType--') > 0 || error.data.message.indexOf('--transferTransactionType--') > 0 
										|| error.data.message.indexOf('--cancelTransactionType--') > 0 || error.data.message.indexOf('--urgent--') > 0 || error.data.message.indexOf('--documentsNotCorrectType--') > 0
										|| error.data.message.indexOf('--dossierCouncillor--') > 0 || error.data.message.indexOf('--delegationType--') > 0 || error.data.message.indexOf('--organsDelegationType--') > 0
										|| error.data.message.indexOf('--transactionInternalProfilesAlert--') > 0 || error.data.message.indexOf('--internalCommunications--') > 0){
								errorToShow += $filter('translate')('global.tram.errors.requiredSendProposalFields');
								errorToShow += '<ul>';
								if(error.data.message.indexOf('--proposalType--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.proposalType') + '</li>';
								}
								if(error.data.message.indexOf('--extract--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.literals.title') + '</li>';
								}
								if(error.data.message.indexOf('--profile--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.created_profile') + '</li>';
								}
								if(error.data.message.indexOf('--notificatorProfile--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.notificator_profile') + '</li>';
								}
								if(error.data.message.indexOf('--notificatorResponsible--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.notificator_responsible') + '</li>';
								}
								if(error.data.message.indexOf('--anonymousThirds--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.anonymReceiver') + '</li>';
								}
								if(error.data.message.indexOf('--organs--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.agreementOrgan') + '</li>';
								}
								if(error.data.message.indexOf('--documents--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.proposalDocs') + '</li>';
								}
								if(error.data.message.indexOf('--documentsNotCorrectType--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.proposalDocsNoCorrectType') + '</li>';
								}
								if(error.data.message.indexOf('--amendmentTransactionType--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.amendmentProposal') + '</li>';
								}
								if(error.data.message.indexOf('--transferTransactionType--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.transferProposal') + '</li>';
								}
								if(error.data.message.indexOf('--cancelTransactionType--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.cancelProposal') + '</li>';
								}
								if(error.data.message.indexOf('--urgent--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.urgentProposal') + '</li>';
								}
								if(error.data.message.indexOf('--dossierCouncillor--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.dossierCouncillor') + '</li>';
								}
								if(error.data.message.indexOf('--delegationType--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.delegationType') + '</li>';
								}
								if(error.data.message.indexOf('--organsDelegationType--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.sec.literals.organsDelegationType') + '</li>';
								}
								if(error.data.message.indexOf('--internalCommunications--') > 0){
									errorToShow += '<li>(' + $filter('translate')('global.literals.internalCommunication') + ') ' + $filter('translate')('global.literals.profiles') + '</li>';
								}
								if(error.data.message.indexOf('--transactionInternalProfilesAlert--') > 0){
									errorToShow += '<li>' + $filter('translate')('global.literals.transactionInternalProfilesAlert') + '</li>';
								}
								errorToShow += '</ul>';
							}else if(error.data.message.toUpperCase() === "No proposal Document created".toUpperCase()){
								errorToShow += $filter('translate')('global.sec.errors.noProposalDocumentCreated');
							}else if(error.data.message.toUpperCase() === "No proposal document signed".toUpperCase()){
								errorToShow += $filter('translate')('global.sec.errors.noProposalDocumentSigned');
							}else {
								errorToShow += $filter('translate')('global.errors.unknownUpdate');
							}
						}
						DialogsFactory.error(errorToShow, $filter('translate')('global.tram.literals.sendProposal'));
					});
                }).catch(function () {
                	//Empty
				});
			}
			
			vm.showModalSendProposalSelectThirds = function (data, sendSecType, node) {
				var thirdsOk = [];
				var documentsOk = [];
				if(sendSecType === 'SON') {
					if(data && data[0]) {
						_.forEach(JSOG.decode(data[0]), function(sonThirds){
							_.forEach(sonThirds, function(sonThird){
								thirdsOk.push(sonThird);
							});
						});
					}
					documentsOk = ((data && data[1])?JSOG.decode(data[1]):undefined);
				}else {
					thirdsOk = ((data && data[0])?JSOG.decode(data[0]):undefined);
					documentsOk = ((data && data[1])?JSOG.decode(data[1]):undefined);
				}
				var archiveClassifications = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
			    var modalSendProposalSelectThirds =  TramNewFactory.modalSendProposalSelectThirds(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier, thirdsOk, documentsOk, true);
			
			    var submitModalSendProposalSelectThirds = function () {
			        var modal = this;
			        var isValid = true;
			        var thirdsField = $linq(modal.fieldsDefinition).firstOrDefault(undefined, "x => x.id == 'thirds'");
			        var thirdsSelected = [];
			        var isPublish = modal.model.isPublish_true ? modal.model.isPublish_true : false;
			        var publishSite = modal.model.publishSite ? modal.model.publishSite : [];
			        modalSendProposalSelectThirds.alerts = [];
			        if(modal.model.isPublish_true && (!modal.model.publishSite || modal.model.publishSite.length == 0)) {
			        	isValid = false;
			        	modalSendProposalSelectThirds.alerts.push({msg: 'global.publishSite.errors.noPublishSiteSelected'});
			        }
			        if(isValid && thirdsField) {
			        	thirdsSelected = $linq(thirdsField.data.thirds).where("x => x.selected").toArray();
				        if(!modal.model.isPublish_true && (!thirdsSelected || thirdsSelected.length == 0)) {
			        		isValid = false;
			        		modalSendProposalSelectThirds.alerts.push({msg: 'global.publishSite.errors.noThirdsSelected'});
			        	}
	                }
			        if(isValid) {
			        	if(node) {
			        		vm.showModalSendProposalDossierTransactionGuidedPopup(sendSecType, isPublish, publishSite, thirdsSelected, modalSendProposalSelectThirds, node);
			        	} else {
			        		vm.showModalSendProposalDossierTransactionPopup(sendSecType, isPublish, publishSite, thirdsSelected, modalSendProposalSelectThirds);
				        }
			        }
			    }
			
			    modalSendProposalSelectThirds.submitModal = submitModalSendProposalSelectThirds;
			
			    AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', modalSendProposalSelectThirds);
			}
			
			vm.showModalSendProposalDossierTransactionPopup = function (sendSecType, isPublish, publishSite, thirdsSelected, modalSendProposalSelectThirds) {
	        	var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
                modal.alerts = [];
                modal.annexaFormly = new AnnexaFormly();
				modal.annexaFormly.extra = {sendSecType: sendSecType, isPublish: isPublish, publishSite: publishSite, thirdsSelected: thirdsSelected};
                var fields = [];
				fields.push({
                    key: 'completeDossierTransaction',
                    type: 'annexaComponent',
                    templateOptions: {
                        type: 'annexa-complete-unguided-dossier-transaction'
                    },
					data: {
                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
                        transaction: vm.dossierTransaction,	
						proposal: vm.proposal,
                        omission: false,
                        reparation: false,
						free: false, 
						omissionOrReparation: false,
						loadFunction: TramNewFactory.searchProfiles,
						saveFunction: function(){},
						sendProposal:true,
						nextIntervention:vm.isInspectionInterventionNext(),
						nextDepartment:vm.isInspectionDepartmentNext(),
						proposalsDossierTransaction: vm.proposalsDossierTransaction,
						inputType:  'Profile',
					    buttonLabel:  'global.literals.select',
					    placeholder: 'global.reg.literals.profileOrUserName',
					    showTitle: false,
					    showOr: false,
						type: 'text',
						procedureProposalTypeInspectionInterventionActive:vm.procedureProposalTypeInspectionInterventionActive
                    }
            	});
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModalPromise("sendProposalDossierTransaction", modal, vm.sendNoGuidedProposalDossierTransaction, false, false, 'global.literals.create').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
	        	if(modalSendProposalSelectThirds) {
	        		modalSendProposalSelectThirds.close();
	        	}
			}
			
			vm.showModalSendProposalDossierTransactionGuidedPopup = function (sendSecType, isPublish, publishSite, thirdsSelected, modalSendProposalSelectThirds, node) {
	          	  if(node.graphNodeType == "SelectAllClose" && node.dossiersTransactionsInfo && node.dossiersTransactionsInfo.length > 0 && node.dossiersTransactionsInfo[0].graphNodeType == "SelectOne" && vm.existReparation){
	        		  node = node.dossiersTransactionsInfo[0];
	        	  }else if((vm.isInspectionInterventionFree || vm.isInspectionIntervention) && node.graphNodeType == "SelectAllClose"){
	        		  node = node.dossiersTransactionsInfo[0];
	              }
	              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[2].id + ")").toArray();
	              if(vm.isInspectionDepartmentNext()){
	            	  dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[6].id + ")").toArray();
	              }else if(vm.isInspectionInterventionNext()){
	            	  dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[5].id + ")").toArray();
	              }
	              dossiersAgreementTransactionsInfo = $linq(dossiersAgreementTransactionsInfo).where(function(x){
	            	  var isOkDTI = function(dti){
	            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[10].id))?true:false);
	                      if(vm.isInspectionDepartmentNext()){
	                    	  dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[9].id))?true:false);
	                      }else if(vm.isInspectionInterventionNext()){
	                    	  dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[8].id))?true:false);
	                      }
	                      return dossiersAgreementTransactionsInfoAux;
	            	  }
	            	  if(x && !x.dossierTransactionDefinition ){
	            		  if(x.dossiersTransactionsInfo){
	            			  var isOk = false;
	            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
	            				  if(!isOk){
	                				isOk = isOkDTI(dti);
	            				  }
	            			  });
	            			  return isOk;
	            		  }else{
	            			  return false;
	            		  }
	            	  }else{
	            		  return true;
	            	  }
	              }).toArray();
	              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
				  var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
	              modal.alerts = [];
	              modal.annexaFormly = new AnnexaFormly();
	              var fields = [];
				  fields.push({
	                key: 'completeDossierTransaction',
	                type: 'annexaComponent',
	                templateOptions: {
	                    type: 'annexa-complete-guided-dossier-transaction'
	                },
					data: {
	                    dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
	                    transaction: vm.dossierTransaction,	
						dossierTransactionInfo: node, 
						level: 1
	                }
	        	  });
	              modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
	              modal.annexaFormly.model = {};
	              modal.annexaFormly.model.modal_body = {};
				  modal.annexaFormly.extra = {sendSecType: sendSecType, isPublish: isPublish, publishSite: publishSite, thirdsSelected: thirdsSelected};
	              modal.annexaFormly.options = {};
	              modal.annexaFormly.options.formState = {readOnly: false};
	              AnnexaFormlyFactory.showModalPromise("sendProposalDossierTransaction", modal, vm.sendGuidedProposalDossierTransaction, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
	                modal.modal = modalAux;
	                modal.close = modalAux.element.scope().close;
	                modalAux.element.modal();
	                angular.element(".modal-title").focus();
	              }).catch(function () {
	                var a = 0;
	              });
	              if(modalSendProposalSelectThirds) {
	        		modalSendProposalSelectThirds.close();
	        	}
			}
			
			 vm.sendNoGuidedProposalDossierTransaction = function (modal) {
                var self = this;
   				var completeDossierTransactionField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'completeDossierTransaction'");
				if(completeDossierTransactionField && completeDossierTransactionField.data){
					if(completeDossierTransactionField.data.transactionNoGuiadaList && completeDossierTransactionField.data.transactionNoGuiadaList.length > 0){
	                  TramNewFactory.sendProposalDossierTransaction(self, vm.dossierTransaction, completeDossierTransactionField.data.transactionNoGuiadaList, vm.proposal).then(function (data) {
	                	  if(data.id && $stateParams.dossierTransaction != data.id){
	                		  $state.transitionTo('annexa.tram.pending.viewdossier', {
	                                "dossier": completeDossierTransactionField.data.dossier.id,
	                                "dossierTransaction": data.id,
	                                "selectedTab": "center"
	                            });
	                	  }else{
	                		  $state.reload(); 
	                	  }
		                  self.close();
	                  }).catch(function (error) {
						if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							self.alerts.push({msg: 'global.tram.errors.processTemplate', msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
						}else if(error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
							self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
						}else if(error.data && error.data.message && error.data.message == 'Dossier councillor is required'){
							self.alerts.push({msg: 'global.literals.selectCouncillorInDossier'});
						}else if(error.data && error.data.message && error.data.message == 'error-requestCompleteDossier-is-in-restictive-transaction not found'){
							self.alerts.push({msg: 'global.tram.errors.inRestrictiveTransaction'});
						}else{
		                	self.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
						}
	                  });
					}else{
						self.alerts.push({msg: 'global.tram.errors.noTransactions'});
					}
				}else{
					self.alerts.push({msg: 'global.tram.errors.noTransactions'});
				}
              };

			  vm.sendGuidedProposalDossierTransaction = function (modal) {
				var self = this;
				if(self && self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup && self.annexaFormly.fields[0].fieldGroup.length > 0){
					var completeDossierTransactionField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'completeDossierTransaction'");
					if(completeDossierTransactionField && completeDossierTransactionField.data && completeDossierTransactionField.data.dossierTransactionInfo){
						var copyDTI = angular.copy(completeDossierTransactionField.data.dossierTransactionInfo);
						vm.changeFreeNodes(copyDTI);
						var isPublish = false;
						var publishSite = [];
						var proposalNotificationThirdConfiguration = [];
						if(self && self.annexaFormly && self.annexaFormly.extra) {
	                		isPublish = self.annexaFormly.extra.isPublish ? self.annexaFormly.extra.isPublish : false;
	                		if(self.annexaFormly.extra.isPublish && self.annexaFormly.extra.publishSite) {
	                			publishSite = [];
	                			_.forEach(self.annexaFormly.extra.publishSite, function(pSite){
	                				publishSite.push({id:pSite});
	                			});
	                		}
	                		if(self.annexaFormly.extra.thirdsSelected && self.annexaFormly.extra.thirdsSelected.length > 0) {
	                			proposalNotificationThirdConfiguration = [];
	                			_.forEach(self.annexaFormly.extra.thirdsSelected, function(thi){
	                				var sendThird = {
	                						dossierThird:{id:thi.id},
	                						proposalNotificationThirdDocumentConfiguration:[],
	                						thirdAddress: undefined,
	                						notificationType: undefined
	                					};
	                				var addressSelected = $linq(thi.addressesOrig).firstOrDefault(undefined, "x => x.selected");
	                				if(addressSelected.id) {
	                					sendThird.thirdAddress = {id:addressSelected.id};
	                				}
	                				if(addressSelected.selectedNotificationType && addressSelected.selectedNotificationType.id) {
	                					sendThird.notificationType = {id:addressSelected.selectedNotificationType.id};
	                				}
	                				var documentsSelected = $linq(thi.documentsToSelect).where("x => x.selected").toArray();
	                				if(documentsSelected && documentsSelected.length > 0) {
		                				_.forEach(documentsSelected, function(dSel){
		                					sendThird.proposalNotificationThirdDocumentConfiguration.push({document:{id:dSel.id}});
		                				});
		              				}
	                				proposalNotificationThirdConfiguration.push(sendThird);
	                			});
	                		}
	                	}
						var workWithDossierSons = ((self && self.annexaFormly && self.annexaFormly.extra && self.annexaFormly.extra.sendSecType && self.annexaFormly.extra.sendSecType === 'SON')?true:false);
                    	TramNewFactory.sendGuidedProposalDossierTransaction(completeDossierTransactionField.data.transaction, vm.proposal, copyDTI, workWithDossierSons, isPublish, publishSite, proposalNotificationThirdConfiguration).then(function (data) {
		                    if (data && data.id) {
		                        completeDossierTransactionField.data.transaction.actual = false;
		                        if (data.id && $stateParams.dossierTransaction != data.id) {
		                            $state.transitionTo('annexa.tram.pending.viewdossier', {
		                                "dossier": completeDossierTransactionField.data.dossier.id,
		                                "dossierTransaction": data.id,
		                                "selectedTab": "center"
		                            });
		                        } else {
		                            $state.reload();
		                        }
		                    } else {
		                        vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.returnToState();
		                    }
		                    self.close();
		                }).catch(function (error) {
		                    if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							modal.alerts.push({msg: 'global.tram.errors.processTemplate', msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
							}else if(error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
								modal.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
							}else if(error.data && error.data.message && error.data.message == 'Dossier councillor is required'){
								modal.alerts.push({msg: 'global.literals.selectCouncillorInDossier'});
							}else if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
		                		modal.alerts.push({msg: 'global.tram.errors.inRestrictiveTransaction'});
		                	}else{
								modal.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
							}
		                });
					}
				}
			}                  
			
			vm.sendInspectionProposalDossierTransaction = function(){
				var titleConfirm = "global.tram.literals.confirmSendInspectionProposal";
                if(vm.isInspectionDepartmentFreeNext()){
                	titleConfirm = "global.tram.literals.confirmSendInspectionProposalInspectionDepartment";
                }else if(vm.isInspectionInterventionFreeNext()){
                	titleConfirm = "global.tram.literals.confirmSendInspectionProposalInspectionIntervention";
                }
            	DialogsFactory.confirm('global.tram.literals.sendInspectionProposal', titleConfirm).then(function () {
					if (vm.dossierTransaction.graphNodeId != undefined && vm.dossierTransaction.graphNodeId != "") {
						TramNewFactory.getNextDossierTransactions(vm.dossierTransaction.id).then(function (data) {
	                      if (data) {
	                          var node = JSOG.decode(data);
	                          if (node) {
	                        	  if(node.graphNodeType == "SelectAllClose" && node.dossiersTransactionsInfo && node.dossiersTransactionsInfo.length > 0 && node.dossiersTransactionsInfo[0].graphNodeType == "SelectOne" && vm.existReparation){
	                        		  node = node.dossiersTransactionsInfo[0];
	                        	  }else if((vm.isInspectionInterventionFree || vm.isInspectionIntervention) && node.graphNodeType == "SelectAllClose"){
	                        		  node = node.dossiersTransactionsInfo[0];
	                              }
	                              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[10].id + ")").toArray();
	                              if(vm.isInspectionDepartmentFreeNext()){
	                            	  dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[9].id + ")").toArray();
	                              }else if(vm.isInspectionInterventionFreeNext()){
	                            	  dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[8].id + ")").toArray();
	                              }
	                              dossiersAgreementTransactionsInfo = $linq(dossiersAgreementTransactionsInfo).where(function(x){
	                            	  var isOkDTI = function(dti){
	                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[10].id))?true:false);
	                                      if(vm.isInspectionDepartmentFreeNext()){
	                                    	  dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[9].id))?true:false);
	                                      }else if(vm.isInspectionInterventionFreeNext()){
	                                    	  dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[8].id))?true:false);
	                                      }
	                                      return dossiersAgreementTransactionsInfoAux;
	                            	  }
	                            	  if(x && !x.dossierTransactionDefinition ){
	                            		  if(x.dossiersTransactionsInfo){
	                            			  var isOk = false;
	                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
	                            				  if(!isOk){
	                                				isOk = isOkDTI(dti);
	                            				  }
	                            			  });
	                            			  return isOk;
	                            		  }else{
	                            			  return false;
	                            		  }
	                            	  }else{
	                            		  return true;
	                            	  }
	                              }).toArray();
	                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
	                              var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
				                  modal.alerts = [];
				                  modal.annexaFormly = new AnnexaFormly();
				                  var fields = [];
								  fields.push({
				                    key: 'completeDossierTransaction',
				                    type: 'annexaComponent',
				                    templateOptions: {
				                        type: 'annexa-complete-guided-dossier-transaction'
				                    },
									data: {
				                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
				                        transaction: vm.dossierTransaction,	
										dossierTransactionInfo: node, 
										level: 1
				                    }
				            	  });
				                  modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
				                  modal.annexaFormly.model = {};
				                  modal.annexaFormly.model.modal_body = {};
				                  modal.annexaFormly.options = {};
				                  modal.annexaFormly.options.formState = {readOnly: false};
				                  AnnexaFormlyFactory.showModalPromise("modalCloseTransaction", modal, vm.sendGuidedInspectionProposalDossierTransaction, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
				                    modal.modal = modalAux;
				                    modal.close = modalAux.element.scope().close;
				                    modalAux.element.modal();
				                    angular.element(".modal-title").focus();
 				                  }).catch(function () {
				                    var a = 0;
				                  });
	                          } else {
	                              TramNewFactory.isFinalTransaction(vm.dossierTransaction);
	                          }
	                      } else {
	                          TramNewFactory.isFinalTransaction(vm.dossierTransaction);
	                      }
	                  	});
					}else{
						var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
		                modal.alerts = [];
		                modal.annexaFormly = new AnnexaFormly();
		                var fields = [];
						fields.push({
		                    key: 'completeDossierTransaction',
		                    type: 'annexaComponent',
		                    templateOptions: {
		                        type: 'annexa-complete-unguided-dossier-transaction'
		                    },
							data: {
		                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
		                        transaction: vm.dossierTransaction,	
								inspectionProposal: vm.inspectionProposal,
		                        omission: false,
		                        reparation: false,
								free: false, 
								omissionOrReparation: false,
								loadFunction: TramNewFactory.searchProfiles,
								saveFunction: function(){},
								sendInspectionProposal:true,
								nextIntervention:vm.isInspectionInterventionFreeNext(),
								nextDepartment:vm.isInspectionDepartmentFreeNext(),
								inspectionProposalsDossierTransaction: vm.inspectionProposalsDossierTransaction,
								inputType:  'Profile',
							    buttonLabel:  'global.literals.select',
							    placeholder: 'global.reg.literals.profileOrUserName',
							    showTitle: false,
							    showOr: false,
								type: 'text',
								procedureProposalTypeInspectionInterventionActive:vm.procedureProposalTypeInspectionInterventionActive
		                    }
		            	});
		                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
		                modal.annexaFormly.model = {};
		                modal.annexaFormly.model.modal_body = {};
		                modal.annexaFormly.options = {};
		                modal.annexaFormly.options.formState = {readOnly: false};
		                AnnexaFormlyFactory.showModalPromise("sendProposalDossierTransaction", modal, vm.sendNoGuidedInspectionProposalDossierTransaction, false, false, 'global.literals.create').then(function (modalAux) {
		                    modal.modal = modalAux;
		                    modal.close = modalAux.element.scope().close;
		                    modalAux.element.modal();
		                    angular.element(".modal-title").focus();
		                }).catch(function () {
		                    var a = 0;
		                });
					}
            	}).catch(function () {
                	//Empty
                });
			}
			
			vm.sendNoGuidedInspectionProposalDossierTransaction = function (modal) {
				var self = this;
   				var completeDossierTransactionField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'completeDossierTransaction'");
				if(completeDossierTransactionField && completeDossierTransactionField.data){
					if(completeDossierTransactionField.data.transactionNoGuiadaList && completeDossierTransactionField.data.transactionNoGuiadaList.length > 0){
	                  TramNewFactory.sendInspectionProposalDossierTransaction(self, vm.dossierTransaction, completeDossierTransactionField.data.transactionNoGuiadaList, vm.inspectionProposal).then(function (data) {
	                	  if (data.id && $stateParams.dossierTransaction != data.id) {
	                            $state.transitionTo('annexa.tram.pending.viewdossier', {
	                                "dossier": completeDossierTransactionField.data.dossier.id,
	                                "dossierTransaction": data.id,
	                                "selectedTab": "center"
	                            });
	                        } else {
	                            $state.reload();
	                        }
		                  self.close();
	                  }).catch(function (error) {
						if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							self.alerts.push({msg: 'global.tram.errors.processTemplate', msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
						}else if(error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
							self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
						}else{
		                	self.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
						}
	                  });
					}else{
						self.alerts.push({msg: 'global.tram.errors.noTransactions'});
					}
				}else{
					self.alerts.push({msg: 'global.tram.errors.noTransactions'});
				}                
            }

			vm.sendGuidedInspectionProposalDossierTransaction = function (modal) {
				var self = this;
				if(self && self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup && self.annexaFormly.fields[0].fieldGroup.length > 0){
					var completeDossierTransactionField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'completeDossierTransaction'");
					if(completeDossierTransactionField && completeDossierTransactionField.data && completeDossierTransactionField.data.dossierTransactionInfo){
						var copyDTI = angular.copy(completeDossierTransactionField.data.dossierTransactionInfo);
						vm.changeFreeNodes(copyDTI);
						TramNewFactory.sendGuidedInspectionProposalDossierTransaction(completeDossierTransactionField.data.transaction, vm.inspectionProposal, copyDTI).then(function (data) {
		                    if (data && data.id) {
		                        completeDossierTransactionField.data.transaction.actual = false;
		                        if (data.id && $stateParams.dossierTransaction != data.id) {
		                            $state.transitionTo('annexa.tram.pending.viewdossier', {
		                                "dossier": completeDossierTransactionField.data.dossier.id,
		                                "dossierTransaction": data.id,
		                                "selectedTab": "center"
		                            });
		                        } else {
		                            $state.reload();
		                        }
		                    } else {
		                        vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.returnToState();
		                    }
		                    self.close();
		                }).catch(function (error) {
		                     self.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
		                });
					}
				}                  
			}
			
			vm.ammendmentProposal = function(){
				var prop = vm.proposal;
                var modal = angular.copy(SecModals.amendmentProposal);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmGenerateAmendment = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                	   	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.amendmentPopupProposal', 'global.sec.literals.confirmAmendmentProposal').then(function (dataAux) {
                          	SecFactory.updateStateProposal(prop.id, 'AMENDMENT', secretariatObservations).then(function(data) {
                          		$state.transitionTo('annexa.tram.pending.viewdossier', {
  	                                "dossier": $stateParams.dossier,
  	                                "dossierTransaction": data.id,
  	                                "selectedTab": "center"
  	                            });
                              }).catch(function(error) {
                              	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
                              })
                          }).catch(function (data) {
                          	//Empty
                          });        	
                          modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalAmendmentProposal", modal, confirmGenerateAmendment, false);
			}
			
			var getDtAux = function(dtinfs, subtype){
            	  var dossiersAgreementTransactionsInfo = $linq(dtinfs).where(function(x){
                	  var isOkDTI = function(dti){
                		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == subtype))?true:false);
                          return dossiersAgreementTransactionsInfoAux;
                	  }
                	  if(x && !x.dossierTransactionDefinition ){
                		  if(x.dossiersTransactionsInfo){
                			  var isOk = false;
                			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                				  if(!isOk){
                    				isOk = isOkDTI(dti);
                				  }
                			  });
                			  return isOk;
                		  }else{
                			  return false;
                		  }
                	  }else{
                		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))?true:false);
                	  }
                  }).toArray();
            	  return dossiersAgreementTransactionsInfo;
            }
			
			vm.createOmission = function(){
				if(vm.canCreateOmissionReparation){
            		  DialogsFactory.confirm('global.tram.literals.createOmissionText', 'global.tram.literals.confirmCreateOmissionText').then(function () {
            			  if(vm.proposal){
            				  if (vm.dossierTransaction.graphNodeId != undefined && vm.dossierTransaction.graphNodeId != "") {
            					  TramNewFactory.getNextDossierTransactions(vm.dossierTransaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id))?true:false);
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id))?true:false);
                                            	  }
                                              }).toArray();
                                              if(dossiersAgreementTransactionsInfo && dossiersAgreementTransactionsInfo.length == 1 && dossiersAgreementTransactionsInfo[0].graphNodeType == "SelectOne"){
                                                  if(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo && dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo.length > 0){
                                                  	dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo = getDtAux(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo, apiAdmin.transactionSubtypes[11].id);
                                                  }	
                                              }
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
							                  modal.alerts = [];
							                  modal.annexaFormly = new AnnexaFormly();
							                  var fields = [];
											  fields.push({
							                    key: 'completeDossierTransaction',
							                    type: 'annexaComponent',
							                    templateOptions: {
							                        type: 'annexa-complete-guided-dossier-transaction'
							                    },
												data: {
							                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
							                        transaction: vm.dossierTransaction,	
													dossierTransactionInfo: node, 
													level: 1
							                    }
							            	  });
							                  modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
							                  modal.annexaFormly.model = {};
							                  modal.annexaFormly.model.modal_body = {};
							                  modal.annexaFormly.options = {};
							                  modal.annexaFormly.options.formState = {readOnly: false};
							                  AnnexaFormlyFactory.showModalPromise("sendInspectionProposalDossierTransaction", modal, vm.completeTransactionGuiat, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
							                    modal.modal = modalAux;
							                    modal.close = modalAux.element.scope().close;
							                    modalAux.element.modal();
							                    angular.element(".modal-title").focus();
			 				                  }).catch(function () {
							                    var a = 0;
							                  });
                                          }
                                      }
                                  });
            				  }else{
            					  TramNewFactory.createTramResposibleModal(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier, vm.dossierTransaction, true, false, false, vm.proposalsDossierTransaction, undefined, vm.procedureProposalTypeInspectionInterventionActive);
            				  }
                		  }else if(vm.inspectionProposal){
                			  //lliure
                			  if (vm.dossierTransaction.graphNodeId != undefined && vm.dossierTransaction.graphNodeId != "") {
                				  TramNewFactory.getNextDossierTransactions(vm.dossierTransaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))?true:false);
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))?true:false);
                                            	  }
                                              }).toArray();
                                              if(dossiersAgreementTransactionsInfo && dossiersAgreementTransactionsInfo.length == 1 && dossiersAgreementTransactionsInfo[0].graphNodeType == "SelectOne"){
                                                  if(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo && dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo.length > 0){
                                                  	dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo = getDtAux(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo, apiAdmin.transactionSubtypes[13].id);
                                                  }	
                                              }
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
							                  modal.alerts = [];
							                  modal.annexaFormly = new AnnexaFormly();
							                  var fields = [];
											  fields.push({
							                    key: 'completeDossierTransaction',
							                    type: 'annexaComponent',
							                    templateOptions: {
							                        type: 'annexa-complete-guided-dossier-transaction'
							                    },
												data: {
							                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
							                        transaction: vm.dossierTransaction,	
													dossierTransactionInfo: node, 
													level: 1
							                    }
							            	  });
							                  modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
							                  modal.annexaFormly.model = {};
							                  modal.annexaFormly.model.modal_body = {};
							                  modal.annexaFormly.options = {};
							                  modal.annexaFormly.options.formState = {readOnly: false};
							                  AnnexaFormlyFactory.showModalPromise("sendInspectionProposalDossierTransaction", modal, vm.completeTransactionGuiat, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
							                    modal.modal = modalAux;
							                    modal.close = modalAux.element.scope().close;
							                    modalAux.element.modal();
							                    angular.element(".modal-title").focus();
			 				                  }).catch(function () {
							                    var a = 0;
							                  });
                                          }
                                      }
                                  });
            				  }else{
            					  TramNewFactory.createTramResposibleModal(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier, vm.dossierTransaction, true, false, true, undefined, vm.inspectionProposalsDossierTransaction);
            				  }
                		  }
            		  }).catch(function () {
                          //Empty
                      });
            	  }else{
            		  DialogsFactory.error($filter('translate')('global.tram.literals.sendInspectionProposal'));
            	  } 
			}
			
			vm.createReparation = function(){
				if(vm.canCreateOmissionReparation){
            		  DialogsFactory.confirm('global.tram.literals.createReparationText', 'global.tram.literals.confirmCreateReparationText').then(function () {
            			  if(vm.proposal){
            				  if (vm.dossierTransaction.graphNodeId != undefined && vm.dossierTransaction.graphNodeId != "") {
            					  TramNewFactory.getNextDossierTransactions(vm.dossierTransaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                        	  var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id))?true:false);
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id))?true:false);
                                            	  }
                                              }).toArray();
                                              if(dossiersAgreementTransactionsInfo && dossiersAgreementTransactionsInfo.length == 1 && dossiersAgreementTransactionsInfo[0].graphNodeType == "SelectOne"){
                                                  if(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo && dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo.length > 0){
                                                  	dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo = getDtAux(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo, apiAdmin.transactionSubtypes[12].id);
                                                  }	
                                              }
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
							                  modal.alerts = [];
							                  modal.annexaFormly = new AnnexaFormly();
							                  var fields = [];
											  fields.push({
							                    key: 'completeDossierTransaction',
							                    type: 'annexaComponent',
							                    templateOptions: {
							                        type: 'annexa-complete-guided-dossier-transaction'
							                    },
												data: {
							                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
							                        transaction: vm.dossierTransaction,	
													dossierTransactionInfo: node, 
													level: 1
							                    }
							            	  });
							                  modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
							                  modal.annexaFormly.model = {};
							                  modal.annexaFormly.model.modal_body = {};
							                  modal.annexaFormly.options = {};
							                  modal.annexaFormly.options.formState = {readOnly: false};
							                  AnnexaFormlyFactory.showModalPromise("sendInspectionProposalDossierTransaction", modal, vm.completeTransactionGuiat, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
							                    modal.modal = modalAux;
							                    modal.close = modalAux.element.scope().close;
							                    modalAux.element.modal();
							                    angular.element(".modal-title").focus();
			 				                  }).catch(function () {
							                    var a = 0;
							                  });
                                          }
                                      }
                                  });
            				  }else{
            					  TramNewFactory.createTramResposibleModal(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier, vm.dossierTransaction, false, true, false, vm.proposalsDossierTransaction, undefined, vm.procedureProposalTypeInspectionInterventionActive);
            				  }
                		  }else if(vm.inspectionProposal){
                			  //lliure
                			  if (vm.dossierTransaction.graphNodeId != undefined && vm.dossierTransaction.graphNodeId != "") {
                				  TramNewFactory.getNextDossierTransactions(vm.dossierTransaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id))?true:false);
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id))?true:false);
                                            	  }
                                              }).toArray();
                                              if(dossiersAgreementTransactionsInfo && dossiersAgreementTransactionsInfo.length == 1 && dossiersAgreementTransactionsInfo[0].graphNodeType == "SelectOne"){
                                                  if(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo && dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo.length > 0){
                                                  	dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo = getDtAux(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo, apiAdmin.transactionSubtypes[14].id);
                                                  }	
                                              }
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
							                  modal.alerts = [];
							                  modal.annexaFormly = new AnnexaFormly();
							                  var fields = [];
											  fields.push({
							                    key: 'completeDossierTransaction',
							                    type: 'annexaComponent',
							                    templateOptions: {
							                        type: 'annexa-complete-guided-dossier-transaction'
							                    },
												data: {
							                        dossier: vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier,
							                        transaction: vm.dossierTransaction,	
													dossierTransactionInfo: node, 
													level: 1
							                    }
							            	  });
							                  modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
							                  modal.annexaFormly.model = {};
							                  modal.annexaFormly.model.modal_body = {};
							                  modal.annexaFormly.options = {};
							                  modal.annexaFormly.options.formState = {readOnly: false};
							                  AnnexaFormlyFactory.showModalPromise("sendInspectionProposalDossierTransaction", modal, vm.completeTransactionGuiat, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
							                    modal.modal = modalAux;
							                    modal.close = modalAux.element.scope().close;
							                    modalAux.element.modal();
							                    angular.element(".modal-title").focus();
			 				                  }).catch(function () {
							                    var a = 0;
							                  });
                                          }
                                      }
                                  });
            				  }else{
            					  TramNewFactory.createTramResposibleModal(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier, vm.dossierTransaction, false, true, true, undefined, vm.inspectionProposalsDossierTransaction);
            				  }
                		  }
            		  }).catch(function () {
                          //Empty
                      });
            	  }else{
            		  DialogsFactory.error($filter('translate')('global.tram.literals.sendInspectionProposal'));
            	  }
			}
			
			var openInternalControlUrl = function(method, internalControlType) {
				var url = angular.copy($rootScope.app.configuration.internal_control_properties_client.urlBase);
				var credentials = angular.copy($rootScope.app.configuration.internal_control_properties_client.credentials);
				var authentication = angular.copy($rootScope.app.configuration.internal_control_properties_client.authentication);
				credentials.id = $rootScope.LoggedUser.id+"";
				credentials.token = $rootScope.LoggedUser.token;
				credentials = JSON.stringify(credentials);
				var methodUrl = calculateUrlIC(method, internalControlType);
				window.open(url+methodUrl+authentication+encodeURIComponent(credentials), '_blank');
			}
			
			vm.openInternalControl = function(){
				if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.internal_control_properties_client){
					if($rootScope.app.configuration.internal_control_properties_client.urlBase
          				  && $rootScope.app.configuration.internal_control_properties_client.urlBase != ""
          				  && $rootScope.app.configuration.internal_control_properties_client.credentials
          				  && $rootScope.app.configuration.internal_control_properties_client.authentication 
          				  && $rootScope.app.configuration.internal_control_properties_client.draft) {
						var internalControlType = calculateInternalControlType();
						openInternalControlUrl(angular.copy($rootScope.app.configuration.internal_control_properties_client.draft), internalControlType);
					} else if($rootScope.app.configuration.internal_control_properties_client.urlToCall
            				  && $rootScope.app.configuration.internal_control_properties_client.credentials
            				  && $rootScope.app.configuration.internal_control_properties_client.callbackUrlParam 
            				  && $rootScope.app.configuration.internal_control_properties_client.callbackUrl){
						//TODO 8/10/2024 (aarques) aquest else if s'haurà d'eliminar en un futur quan tots els clients utilitzin els nous paràmetres amb les noves urls.
            			  var url = angular.copy($rootScope.app.configuration.internal_control_properties_client.urlToCall);
                    	  var credentials = angular.copy($rootScope.app.configuration.internal_control_properties_client.credentials);
                    	  credentials.id = $rootScope.LoggedUser.id+"";
                    	  credentials.token = $rootScope.LoggedUser.token;
                    	  credentials = JSON.stringify(credentials);
                    	  var callbackUrlParam = angular.copy($rootScope.app.configuration.internal_control_properties_client.callbackUrlParam);
                    	  var callbackUrl = angular.copy($rootScope.app.configuration.internal_control_properties_client.callbackUrl);
                    	  var internalControlType = 'permanentControl';
                    	  var internalControlType = calculateInternalControlType();
						  callbackUrl = calculateCallBackUrlIC(callbackUrl, internalControlType);
                    	  window.open(url+encodeURIComponent(credentials)+callbackUrlParam+encodeURIComponent(callbackUrl), '_blank');
            		  }
            	  } 
			}
			
			vm.openInternalControlRequest = function(){
				if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.internal_control_properties_client){
					if($rootScope.app.configuration.internal_control_properties_client.urlBase
							&& $rootScope.app.configuration.internal_control_properties_client.urlBase != ""
							&& $rootScope.app.configuration.internal_control_properties_client.credentials
	          				&& $rootScope.app.configuration.internal_control_properties_client.authentication 
	          				&& $rootScope.app.configuration.internal_control_properties_client.detail) {
						var internalControlType = calculateInternalControlType();
						openInternalControlUrl(angular.copy($rootScope.app.configuration.internal_control_properties_client.detail), internalControlType);
					} else if($rootScope.app.configuration.internal_control_properties_client.urlToCall
            				  && $rootScope.app.configuration.internal_control_properties_client.credentials
            				  && $rootScope.app.configuration.internal_control_properties_client.callbackUrlParam 
            				  && $rootScope.app.configuration.internal_control_properties_client.requestDetail){
						//TODO 8/10/2024 (aarques) aquest else if s'haurà d'eliminar en un futur quan tots els clients utilitzin els nous paràmetres amb les noves urls.
            			  var url = angular.copy($rootScope.app.configuration.internal_control_properties_client.urlToCall);
                    	  var credentials = angular.copy($rootScope.app.configuration.internal_control_properties_client.credentials);
                    	  credentials.id = $rootScope.LoggedUser.id+"";
                    	  credentials.token = $rootScope.LoggedUser.token;
                    	  credentials = JSON.stringify(credentials);
                    	  var callbackUrlParam = angular.copy($rootScope.app.configuration.internal_control_properties_client.callbackUrlParam);
                    	  var callbackUrl = angular.copy($rootScope.app.configuration.internal_control_properties_client.requestDetail);
                    	  var internalControlType = calculateInternalControlType();
						  callbackUrl = calculateCallBackUrlIC(callbackUrl, internalControlType);
						  window.open(url+encodeURIComponent(credentials)+callbackUrlParam+encodeURIComponent(callbackUrl), '_blank');   
            		  }
            	  }
			}
			
			vm.openInternalControlReparation = function(){
            	  if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.internal_control_properties_client){
            		  if($rootScope.app.configuration.internal_control_properties_client.urlBase
  							&& $rootScope.app.configuration.internal_control_properties_client.urlBase != ""
  							&& $rootScope.app.configuration.internal_control_properties_client.credentials
  	          				&& $rootScope.app.configuration.internal_control_properties_client.authentication 
  	          				&& $rootScope.app.configuration.internal_control_properties_client.repair) {
            			  var internalControlType = calculateInternalControlType();
            			  openInternalControlUrl(angular.copy($rootScope.app.configuration.internal_control_properties_client.repair), internalControlType);
  					} else if($rootScope.app.configuration.internal_control_properties_client.urlToCall
            				  && $rootScope.app.configuration.internal_control_properties_client.credentials
            				  && $rootScope.app.configuration.internal_control_properties_client.callbackUrlParam 
            				  && $rootScope.app.configuration.internal_control_properties_client.requestRepair){
  					//TODO 8/10/2024 (aarques) aquest else if s'haurà d'eliminar en un futur quan tots els clients utilitzin els nous paràmetres amb les noves urls.
            			  var url = angular.copy($rootScope.app.configuration.internal_control_properties_client.urlToCall);
                    	  var credentials = angular.copy($rootScope.app.configuration.internal_control_properties_client.credentials);
                    	  credentials.id = $rootScope.LoggedUser.id+"";
                    	  credentials.token = $rootScope.LoggedUser.token;
                    	  credentials = JSON.stringify(credentials);
                    	  var callbackUrlParam = angular.copy($rootScope.app.configuration.internal_control_properties_client.callbackUrlParam);
                    	  var callbackUrl = angular.copy($rootScope.app.configuration.internal_control_properties_client.requestRepair);
                    	  var internalControlType = calculateInternalControlType();
						  callbackUrl = calculateCallBackUrlIC(callbackUrl, internalControlType);
						  window.open(url+encodeURIComponent(credentials)+callbackUrlParam+encodeURIComponent(callbackUrl), '_blank');
            		  }
            	  }
			}
			
			//TODO 8/10/2024 (aarques) aquest mètode s'haurà d'eliminar en un futur quan tots els clients utilitzin els nous paràmetres amb les noves urls.
			var calculateInternalControlType = function(){
				  var internalControlType = 'permanentControl';
				  if(vm.procedureProposalTypeInspectionInterventionActive !== undefined){
					  if(vm.procedureProposalTypeInspectionInterventionActive){
						  if(vm.proposal && !vm.proposal.procedureProposalType){
							  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.inspectionIntervention != undefined){
				        		  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.inspectionIntervention){
				        			 if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
				        				 internalControlType = 'auditingFunction';
				        			 }
				        		  }
				        	  }else{
				        		  var arcClassification = undefined;
				        		  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected.id){
				        			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected.id);
				        		  }
				        		  if(arcClassification && arcClassification.inspectionIntervention){
				        			  if(arcClassification.inspectionInterventionType == 'AUDITING_FUNCTION'){
				        				  internalControlType = 'auditingFunction';
				         			 }
				        		  }else{
				        			  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification.id){
				            			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification.id);
				            		  }
				        			  if(arcClassification && arcClassification.inspectionIntervention){
				        				  if(arcClassification.inspectionInterventionType == 'AUDITING_FUNCTION'){
				        					 internalControlType = 'auditingFunction';
				             			 }
				            		  }
				        		  }
				        	  }
						  }else if(vm.proposal && vm.proposal.procedureProposalType && vm.proposal.procedureProposalType.inspectionInterventionType == 'AUDITING_FUNCTION'){
	        				 internalControlType = 'auditingFunction';
	        			 }
					  }
				  }else if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.inspectionIntervention != undefined){
	        		  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.inspectionIntervention){
	        			 if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
	        				 internalControlType = 'auditingFunction';
	        			 }
	        		  }
	        	  }else{
	        		  var arcClassification = undefined;
	        		  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected.id){
	        			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveClassification.model.$selected.id);
	        		  }
	        		  if(arcClassification && arcClassification.inspectionIntervention){
	        			  if(arcClassification.inspectionInterventionType == 'AUDITING_FUNCTION'){
	        				  internalControlType = 'auditingFunction';
	         			 }
	        		  }else{
	        			  if(vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification.id){
	            			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.archiveClassification.id);
	            		  }
	        			  if(arcClassification && arcClassification.inspectionIntervention){
	        				  if(arcClassification.inspectionInterventionType == 'AUDITING_FUNCTION'){
	        					 internalControlType = 'auditingFunction';
	             			 }
	            		  }
	        		  }
	        	  }
				return internalControlType;
			}
			
			//TODO 8/10/2024 (aarques) aquest mètode s'haurà d'eliminar en un futur quan tots els clients utilitzin els nous paràmetres amb les noves urls.
			var calculateCallBackUrlIC = function(callbackUrl, internalControlType){
				  callbackUrl = callbackUrl.replace('--permanentControl--',internalControlType);
	        	  if(vm.proposalsDossierTransaction && vm.proposalsDossierTransaction.length > 0 && vm.proposalsDossierTransaction[0].proposal){
	        		  callbackUrl = callbackUrl.replace('--externalRecordId--',vm.proposalsDossierTransaction[0].proposal.externalRecordId);
	        	  }else if(vm.inspectionProposalsDossierTransaction && vm.inspectionProposalsDossierTransaction.length > 0 && vm.inspectionProposalsDossierTransaction[0].inspectionProposal){
	        		  callbackUrl = callbackUrl.replace('--externalRecordId--',vm.inspectionProposalsDossierTransaction[0].inspectionProposal.externalRecordId);
	        	  }
	        	  return callbackUrl;  
			}
			
			var calculateUrlIC = function(method, internalControlType) {
				method = method.replace('--permanentControl--',internalControlType)
				if(vm.proposalsDossierTransaction && vm.proposalsDossierTransaction.length > 0 && vm.proposalsDossierTransaction[0].proposal){
					method = method.replace('--externalRecordId--',vm.proposalsDossierTransaction[0].proposal.externalRecordId);
				}else if(vm.inspectionProposalsDossierTransaction && vm.inspectionProposalsDossierTransaction.length > 0 && vm.inspectionProposalsDossierTransaction[0].inspectionProposal){
					method = method.replace('--externalRecordId--',vm.inspectionProposalsDossierTransaction[0].inspectionProposal.externalRecordId);
	        	}
				return method;
			}
			
			vm.calculateRequiredAndCannotCreateDocument = function(proposalType, delegationType, organ, deletedOrgans){
				if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active && $rootScope.app.configuration.councillor_proposal_type.validate){
					if(proposalType){
						vm.proposal.proposalType = ((proposalType && proposalType.id)?proposalType:undefined);
					}
					if(delegationType){
						vm.proposal.delegationType = ((delegationType && delegationType.id)?delegationType:undefined);
					}
					if(organ && organ.organ){
						if(vm.proposal.organs && vm.proposal.organs.length > 0){
							var index = $linq(vm.proposal.organs).indexOf("x => x.organ && x.organ.id == "+organ.organ.id);
							if(index < 0){
								vm.proposal.organs.push(organ);
							}else{
								vm.proposal.organs[index] = organ;
							}
						}else{
							if(!vm.proposal.organs){
								vm.proposal.organs = [];
							}
							vm.proposal.organs.push(organ);
						}
					}
					if(deletedOrgans && deletedOrgans.length > 0 && vm.proposal.organs){
						var orgs = $linq(vm.proposal.organs).where(function(x){
							if(x && _.contains(deletedOrgans, x.id)){
								return false;
							}else{
								return true;
							}
						}).toArray();
						vm.proposal.organs.length = 0;
						if(orgs && orgs.length > 0){
							_.forEach(orgs, function(org){
								vm.proposal.organs.push(org);
							})
						}
					}
					
					vm.requiredAndCannotCreateDocument.length = 0;
					if(vm.proposal){
						if($rootScope.app.configuration.councillor_proposal_type.validate.proposal_type && (!vm.proposal.proposalType || !vm.proposal.proposalType.id)){
							vm.requiredAndCannotCreateDocument.push("global.sec.literals.proposalType");
						}
						if($rootScope.app.configuration.councillor_proposal_type.validate.delegation_type){
							if(vm.dossierTransaction && vm.dossierTransaction.proposalSubtype == 'DECREE' && (!vm.proposal.delegationType || !vm.proposal.delegationType.id)){
								vm.requiredAndCannotCreateDocument.push("global.commonAdmin.literals.councillorDelegationType");	
							}else if(vm.dossierTransaction && vm.dossierTransaction.proposalSubtype != 'DECREE'){
								if(!vm.proposal.organs || vm.proposal.organs.length == 0){
									vm.requiredAndCannotCreateDocument.push("global.commonAdmin.literals.councillorDelegationType");	
								}else{
									var oWDT = $linq(vm.proposal.organs).where( "x => !x.delegationType || !x.delegationType.id").toArray();
									if(oWDT && oWDT.length > 0 && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor && vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor.id){
										var councillor = $linq(GlobalDataFactory.councillors).firstOrDefault(undefined, "x => x.id == "+vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor.id);
										if(councillor && councillor.councillorProposalTypes && vm.proposal.proposalType && vm.proposal.proposalType.id){
											var pt = $linq(councillor.councillorProposalTypes).firstOrDefault(undefined, "x => x.proposalType && x.proposalType.id == "+vm.proposal.proposalType.id);
											if(pt && pt.councillorProposalTypeDelegationOrgans){
												var organError = $linq(pt.councillorProposalTypeDelegationOrgans).firstOrDefault(undefined, function(x){
													if(x && x.organ && x.organ.id &&  x.delegated && $linq(oWDT).firstOrDefault(undefined, "x => x.organ && x.organ.id == "+x.organ.id)){
														return true;
													}else{ 
														return false;
													}
												});
												if(organError){
													vm.requiredAndCannotCreateDocument.push("global.commonAdmin.literals.councillorDelegationType");	
												}
											}
										}
									}
								}
							}	
						}
					}
				}
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.isUpdateDossier = vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isUpdateDossier 
										|| (vm.transaction && vm.transaction.actual && vm.transaction.transactionType 
										&& (vm.transaction.transactionType.transactionSubtype == 11 || vm.transaction.transactionType.transactionSubtype == 12 
										|| vm.transaction.transactionType.transactionSubtype == 13 || vm.transaction.transactionType.transactionSubtype == 14));
				if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
					vm.isActiveCouncillor = true;
                }else{
					vm.isActiveCouncillor = false;
				}
				if(vm.transaction && vm.transaction.id){
					TramNewFactory.getDossierTransaction(vm.transaction.id, vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id).then(function(data){
						try{
							vm.dossierTransaction = JSOG.decode(data[0].data);
							vm.dossierTransactionProcessProfiles = JSOG.decode(data[1].data);
							vm.proposalsDossierTransaction = JSOG.decode(data[2].data);
					        vm.inspectionProposalsDossierTransaction = JSOG.decode(data[3].data);
					        vm.procedureProposalTypeInspectionInterventionActive = ((data[4].data !== '' && data[4].data !== undefined)?JSOG.decode(data[4].data):undefined);
							vm.proposalsDecree = [];
							if (vm.proposalsDossierTransaction && vm.proposalsDossierTransaction.length > 0) {
								var proposals = $linq(vm.proposalsDossierTransaction).where("x => x.dossierTransaction.id == " + vm.dossierTransaction.id).select("x => x.proposal").toArray();
								if (proposals && proposals.length > 0) {
									vm.proposal = proposals[0];
									if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
										vm.dossierTransaction.proposalSubtype = 'DECREE';
									}
									vm.calculateRequiredAndCannotCreateDocument();
								}
							}
							if (vm.inspectionProposalsDossierTransaction && vm.inspectionProposalsDossierTransaction.length > 0) {
								var inspectionProposals = $linq(vm.inspectionProposalsDossierTransaction).where("x => x.dossierTransaction.id == " + vm.dossierTransaction.id).select("x => x.inspectionProposal").toArray();
								if (inspectionProposals && inspectionProposals.length > 0) {
									vm.inspectionProposal = inspectionProposals[0];
								}
							}
							canModifyTransactionFunction();
							vm.loadComboTransactionResponsibleUser();
							vm.updateTransaction();
							vm.isProposalTransaction = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[1]);
							vm.isAmendmentTransaction = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[4]);
							vm.isAgreementTransaction = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[2]);
							vm.isOmissionAgreementTransaction = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[15]);
							vm.isTransferTransaction = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[3]);
							vm.isInspectionIntervention = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[5]);
							vm.isInspectionDepartment = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[6]);
							vm.isStartInspectionTransaction = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[7]);
							vm.isAgreementFreeTransaction = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[10]);
							vm.isInspectionInterventionFree = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[8]);
							vm.isInspectionDepartmentFree = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[9]);
							vm.isOmission = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[11]);
							vm.isReparation = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[12]);
							vm.isOmissionFree = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[13]);
							vm.isReparationFree = vm.isThisTransactionSubTypeFunction(apiAdmin.transactionSubtypes[14]);
							vm.isInternalControl = vm.isInternalControlFunction();
							vm.existReparation = vm.existReparationFunction();
							vm.existOmission = vm.existOmissionFunction();
							vm.existInspectionIntervention = vm.existInspectionInterventionFunction();
							vm.canCreateOmissionReparation = vm.canCreateOmissionReparationFunction();
							if(vm.isProposalTransaction || vm.isAmendmentTransaction || vm.isAgreementTransaction || vm.isTransferTransaction  || vm.isInspectionIntervention || vm.isInspectionDepartment || vm.isOmission || vm.isReparation || vm.isOmissionAgreementTransaction){
								vm.showProposalTab = true;
								vm.showProposalReloadTab = true;
							}else if(vm.dossierTransactionsComponent.transactionActiveTab === 4){
								vm.dossierTransactionsComponent.transactionActiveTab = 0;
							}
							if((vm.isReparation || vm.isReparationFree) && vm.dossierTransaction.fiscalizationInterventionAmendmentReason){
								vm.showAmendmentTab = true	
							}else if(vm.dossierTransactionsComponent.transactionActiveTab === 5){
								vm.dossierTransactionsComponent.transactionActiveTab = 0;
							}
							vm.canSeeDossierTransactionResponsibleUserLabel = canSeeDossierTransactionResponsibleUserLabel();
							vm.canSetDossierTransactionResponsibleUser = canSetDossierTransactionResponsibleUser();
							vm.canSeeDossierTransactionResponsibleUserSelect = canSeeDossierTransactionResponsibleUserSelect();
							vm.canEditDossierTransactionResponsibleUser = canEditDossierTransactionResponsibleUser();
							vm.canEditDossierTransactionType = canEditDossierTransactionType();
				            vm.expiretype = ((vm.dossierTransaction && vm.dossierTransaction.expireType)?vm.dossierTransaction.expireType:((vm.dossierTransaction && vm.dossierTransaction.transactionType && vm.dossierTransaction.transactionType.expireType)?vm.dossierTransaction.transactionType.expireType:"BUSINESS"));
							vm.daysexpire = ((vm.dossierTransaction)?vm.dossierTransaction.transactionRemainingDays:0);
							vm.expiredate =  HelperService.getExpirationDate(new Date(), vm.daysexpire, vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.entity.holidays, vm.expiretype);
							vm.isLoaded = true;
						}catch(error){
							vm.isLoaded = true;
						}
            		}).catch(function(error){
						vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
                		vm.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.errorGetDossierTransaction')});
						vm.isLoaded = true;
					});
				}else{
					vm.isLoaded = true;
				}
			}
        }],
        bindings: {
            transaction: '=',
		}
    })
	.component('annexaBoxObjectDossierTransactionExpirationBox',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction-expiration-box.html',
        require: {
            dossierTransactionComponent: '^^annexaBoxObjectDossierTransaction'
        },
        controller:['$rootScope', '$filter', 'Language', '$state', function($rootScope, $filter, Language, $state) {
            var vm = this;
			vm.canShowCIButtons = true;
			vm.monthsexpired = undefined;
			vm.yearsexpired = undefined;
			vm.isUpdate = false;
			
            var calculateMonths = function(){
				var months;
                var d1 = new Date();
                var d2 = new Date(vm.dossierTransactionComponent.expiredate);
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                months <= 0 ? 0 : months;
				return months;
			}
	
			var calculateYears = function(){
				var date1 = new Date();
                var date2 = new Date(vm.dossierTransactionComponent.expiredate);
                return  date2.getFullYear() - date1.getFullYear();
			}
			
			vm.auditDossierTransaction = function () {
                if (vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.id) {
                    window.open($state.href('annexa.admin.audit_dossier_transaction', {"objectId": vm.dossierTransactionComponent.dossierTransaction.id}), '_blank');
                }
            };

			this.$onInit = function() {
				vm.isUpdate = vm.dossierTransactionComponent.isUpdateDossier && vm.dossierTransactionComponent.canModifyTransaction;
				if((vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier 
						&& vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus != 'IN_PROGRESS' 
						&& !vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isArchivedDossier) 
            		|| (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier 
						&&  (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus != 'TRANSFER' 
						&& vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus != 'REJECTED' 
						&& !(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' 
							&& !vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveDefinitiveDate)
						) && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isArchivedDossier)){
                	vm.canShowCIButtons = false;
            	}
				vm.monthsexpired = calculateMonths();
				vm.yearsexpired = calculateYears();
			
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierTransactionInformation',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction-information.html',
        require: {
            dossierTransactionComponent: '^^annexaBoxObjectDossierTransaction'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'TramNewFactory', 'apiAdmin', 'AnnexaPermissionsFactory', function($rootScope, $filter, Language, GlobalDataFactory, HelperService, TramNewFactory, apiAdmin, AnnexaPermissionsFactory) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.informationLanguageColumn = undefined;
			vm.canEditResponsibleProfile = false;
		    vm.procProfiles = [];
			vm.allProfiles = []; 
			vm.isProfileOpen = false; 
			vm.isUpdate = false;
			vm.loadSelectableProfiles = function() {
            	var selectableOptions = [];
                _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                	var haveProfile = false;
                	if(vm.dossierTransactionComponent.dossierTransaction.processProfiles) {
                		haveProfile = vm.dossierTransactionComponent.dossierTransaction.processProfiles.some(function (resProfile) {
                			  return resProfile.profile.id === value.id;
                			});
                	}
                    if(value.expiryDate == null || haveProfile) {
                        selectableOptions.push(value);
                    }
                });
                return selectableOptions;
            }
			
			vm.loadProcProfiles = function(){
				vm.procProfiles = [];
				if(vm.dossierTransactionComponent.dossierTransaction.processProfiles){
					_.forEach(vm.dossierTransactionComponent.dossierTransaction.processProfiles, function (profile) {
	                	if(vm.dossierTransactionComponent.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionComponent.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
        					var dtpp = $linq(vm.dossierTransactionComponent.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
        					if(dtpp){
		        				var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
			                    users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
			
			                    vm.procProfiles.push({profile: profile, users: users});
        					}
	                	}
	                });
                }				
			}
			
			vm.updateResponsibleProfile = function(prop,val){
				TramNewFactory.updateDossierTransactionProcessProfile(vm.dossierTransactionComponent.dossierTransaction.processProfiles[0].id, val.id)
	                 .then(function (data) {
						var profile = JSOG.decode(data);
						var profileAux = angular.copy(profile);
						if(profileAux && profileAux.profile && profileAux.profile.userProfiles){
							profileAux.profile.userProfiles = undefined;
						}
                    	vm.dossierTransactionComponent.dossierTransaction.processProfiles[0] = profileAux;
						vm.dossierTransactionComponent.updateTransaction();
						if(vm.dossierTransactionComponent.dossierTransaction.processProfiles[0] && vm.dossierTransactionComponent.dossierTransaction.processProfiles[0].profile 
								&& vm.dossierTransactionComponent.dossierTransaction.processProfiles[0].profile.id
								&& $linq(vm.dossierTransactionComponent.dossierTransactionProcessProfiles).count("x => x.id == "+vm.dossierTransactionComponent.dossierTransaction.processProfiles[0].profile.id) == 0){
                    		vm.dossierTransactionComponent.dossierTransactionProcessProfiles.push(profile.profile);
                    	}
						vm.allProfiles = $linq(vm.loadSelectableProfiles()).orderBy("x => x." + vm.languageColumn, linq.caseInsensitiveComparer).toArray();
                    	vm.loadProcProfiles();
						vm.dossierTransactionComponent.loadComboTransactionResponsibleUser();
					}).catch(function (error) {
						vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
                		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.errorUpdateResponsibleUser')});
	                });
			}
			
			vm.updateOpenFrofileStatus = function () {
				vm.isProfileOpen = vm.procProfiles.some(function (item) {
					return item.isProfileOpen;
				})
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.informationLanguageColumn = "";
		        if(vm.languageColumn) {
		            vm.informationLanguageColumn = "informationLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
		        }
				vm.isUpdate = vm.dossierTransactionComponent.isUpdateDossier && vm.dossierTransactionComponent.canModifyTransaction;
				vm.canEditResponsibleProfile = AnnexaPermissionsFactory.havePermission('change_responsible_profile_dossier_transaction');
				vm.allProfiles = $linq(vm.loadSelectableProfiles()).orderBy("x => x." + vm.languageColumn, linq.caseInsensitiveComparer).toArray();
               	vm.loadProcProfiles();
			}
        }],
        bindings: {
		}
    })
	.component('annexaBoxObjectDossierTransactionCustomFields',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction-custom-fields.html',
        require: {
            dossierTransactionComponent: '^^annexaBoxObjectDossierTransaction'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'TramNewFactory', 'apiAdmin', 'AnnexaPermissionsFactory', 'AnnexaEntityFactory', 'globalModals', 'AnnexaFormlyFactory', 'CommonAdminModals', 'AnnexaModalFactory','CommonService','$http', 'CustomFieldFactory', function($rootScope, $filter, Language, GlobalDataFactory, HelperService, TramNewFactory, apiAdmin, AnnexaPermissionsFactory, AnnexaEntityFactory, globalModals, AnnexaFormlyFactory, CommonAdminModals, AnnexaModalFactory, CommonService, $http, CustomFieldFactory) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.customFields = [];
			vm.isArchivedDossier = false;
			vm.isUpdate = false;
			vm.isLoaded = false;
			vm.groupData = { 
    			extra:{
    				isEdit:false,
    				seeSelectedLinked:true,
					valuesFromLinkedCustomFields:{parentId:undefined, objectParent:'DOSSIER_TRANSACTION', inConfig:false}
    			}
    		}
			vm.updateField = function(field,data,isLinked){
				var fieldAux = undefined;
				if(field){
					fieldAux = angular.copy(field);
				}
				if (!field.required || (field.required && data)) {
                    var fieldValue = undefined;
                    try{
                    	fieldValue = angular.fromJson(field.value);
                    }catch(e){
						console.error(e);
					}
                    if (fieldValue == null || fieldValue == undefined) {
                        fieldValue = {id: 'cf_' + field.customField.id, value: ''};
                    }
                    if (data && data instanceof Date) {
                        fieldValue.value = new Date(Date.UTC(data.getFullYear(), data.getMonth(), data.getDate(), 00, 00, 00));
                    }else if(field && field.customField && field.customField.frontendType  === 'STRUCTURAL_SELECT'){
                    	if(data && data.model && data.model.$selected && data.model.$selected.id){
                    		fieldValue.value = data.model.$selected.id;
                    	}else{
                    		fieldValue.value = undefined;
                    	}
                    } else {
                        fieldValue.value = data;
                    }
					if (fieldValue.value && field.customField.frontendType == 'JSON' || field.customField.frontendType == 'CF_GROUP') {
                    	try {
                    		JSON.parse(fieldValue.value);
                        } catch (error) {
                			return $filter('translate')("global.validation.json");
                		}
                    }
                    field.value = angular.toJson(fieldValue);
                    if(!isLinked && field.relatedCustomFields && field.relatedCustomFields.length > 0){
                    	field.valuesRelatedCustomFields = {};
                    	field.jsonValuesRelatedCustomFields = {};
                    	var okRelated = true;
                    	var lvalue = undefined;
                    	if(field && field.customField && (field.customField.backendType === 'MULTIPLESELECT' || field.customField.backendType === 'CHECKBOX')){
                    		if(fieldValue && fieldValue.value && fieldValue.value.length != 1){
                    			okRelated = false;
                    		}
                    		if(okRelated){
                    			if(field.customField && field.customField.listValues){
                    				lvalue = $linq(field.customField.listValues).firstOrDefault(undefined, "x=> x.id == "+fieldValue.value[0]);
                    			}
                    		}
                    	}else{
                    		if(okRelated){
                    			if(field.customField && field.customField.listValues){
                    				lvalue = $linq(field.customField.listValues).firstOrDefault(undefined, "x=> x.id == "+fieldValue.value);
                    			}
                    		}
                    	}
                    	if(lvalue){
                    		_.forEach(field.relatedCustomFields, function(rc){
                    			if(rc.customFieldTemplateTag){
                    				var cffieldUpdated = $linq(vm.customFields).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+rc.customFieldTemplateTag+"'");
                    				if(cffieldUpdated){
                    					if(lvalue[rc.customFieldTemplateTag]){
                    						var relatedFieldValue = undefined;
                    						if(cffieldUpdated.value){
                    							relatedFieldValue = angular.fromJson(cffieldUpdated.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
                    						}
                    						if(relatedFieldValue == null || relatedFieldValue == undefined){
                    							relatedFieldValue = {id:'cf_'+cffieldUpdated.customField.id,value:'' };
                    						}
                    						try{
                    							if(cffieldUpdated.customField.backendType === 'DATETIME'){
                    								try{
                    									var dateParts = lvalue[cffieldUpdated.customField.templateTag].split("/");
                    									var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                    									if(dateObject != "Invalid Date") {
                    										relatedFieldValue.value = dateObject;
                    									} else {
                    										dateParts = HelperService.dateMaskToDate(dateParts);
                    										relatedFieldValue.value = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                    									}
                    								}catch(e){
                    									var dateTime = Date.parse(lvalue[cffieldUpdated.customField.templateTag]);
                    									if(dateTime){
                    										relatedFieldValue.value = new Date(dateTime);
                    									}else{
                    										relatedFieldValue.value = undefined;
                    									}
                    								}
                    							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'INTEGER'){
                    								relatedFieldValue.value = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
                    							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'DECIMAL'){
                    								var valueLiteral_parts = (lvalue[cffieldUpdated.customField.templateTag]).split('.');
                    								if(valueLiteral_parts && valueLiteral_parts.length > 0){
                    									if(valueLiteral_parts[1]){
                    										if(cffieldUpdated.customField.decimalPositions){
                    											relatedFieldValue.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(cffieldUpdated.customField.decimalPositions));
                    										}else{
                    											relatedFieldValue.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                    										}
                    									}else{
                    										if(cffieldUpdated.customField.decimalPositions){
                    											relatedFieldValue.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(cffieldUpdated.customField.decimalPositions));
                    										}else {
                    											relatedFieldValue.value =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                    										}
                    									}
                    								}
                    							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'ADDRESS'){
                    								//TODO no pot arribar un address des de BBDD
                    							}else if((cffieldUpdated.customField.frontendType === 'SELECT' || cffieldUpdated.customField.frontendType === 'SELECT_LINKED'  || cffieldUpdated.customField.frontendType === 'RADIO')){
                    								relatedFieldValue.value = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
                    							} else if(cffieldUpdated.customField.frontendType === 'MULTIPLESELECT' || cffieldUpdated.customField.frontendType === 'CHECKBOX'){
                    								relatedFieldValue.value = [parseInt(lvalue[cffieldUpdated.customField.templateTag])];
                    							} else if(cffieldUpdated.customField.frontendType === 'STRUCTURAL_SELECT'){
                    								var aux = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
                    								if(aux){
                    									var lvaux = $linq(cffieldUpdated.customField.listValues).firstOrDefault(undefined, "x => x.id == aux");
                    									if(lvaux){
                    										relatedFieldValue.value = lvaux.id;											
                    									}
                    								}
                    							}else{
                    								relatedFieldValue.value = lvalue[cffieldUpdated.customField.templateTag];
                    							}
                    						}catch(e){
                    							if(cffieldUpdated && cffieldUpdated.customField && (cffieldUpdated.customField.backendType === 'MULTIPLESELECT' || cffieldUpdated.customField.backendType === 'CHECKBOX')){
                    								relatedFieldValue.value = [];
                    							}else{
                    								relatedFieldValue.value = undefined;
                    							}
                    						}
                    					}else{
                    						if(cffieldUpdated && cffieldUpdated.customField && (cffieldUpdated.customField.backendType === 'MULTIPLESELECT' || cffieldUpdated.customField.backendType === 'CHECKBOX')){
                    							relatedFieldValue.value = [];
                    						}else{
                    							relatedFieldValue.value = undefined;
                    						}
                    					}
                    					field.valuesRelatedCustomFields[cffieldUpdated.customField.templateTag] = relatedFieldValue.value;
                    					field.jsonValuesRelatedCustomFields[cffieldUpdated.customField.templateTag] = angular.toJson(relatedFieldValue);
                    				}
                    			}
                    		});
                    	}
                    }
                    TramNewFactory.updateDossierTransactionCustomField(field).then(function (dataUPDATE) {
                    	if(field.relatedCustomFields && field.relatedCustomFields.length > 0){
                        	_.forEach(field.relatedCustomFields,function(related){
                        		var rel = $linq(vm.customFields).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+related.customFieldTemplateTag+"'");
                        		if(rel && rel.customField && rel.customField.templateTag){
                        			if(field.valuesRelatedCustomFields && field.valuesRelatedCustomFields[rel.customField.templateTag]){
                        				 rel.valueFromJSON = field.valuesRelatedCustomFields[rel.customField.templateTag];
                        			}
                        			if(field.jsonValuesRelatedCustomFields && field.jsonValuesRelatedCustomFields[rel.customField.templateTag]){
                        				rel.value = field.jsonValuesRelatedCustomFields[rel.customField.templateTag];
                        			}
                        		}
                        	});
                        }
                        if (field.customField.frontendType == 'SELECT') {
                            if (field.id && field.customField && field.customField.id) {
		                        var linkedFields = $linq(vm.customFields).where("x => x.customField.linkedCustomField && x.customField.linkedCustomField.id == " + field.customField.id).toArray();
		                        _.forEach(linkedFields, function (linkedField) {
		                            vm.updateField(linkedField, '', true);
		                            linkedField.valueFromJSON = '';
		                            if(linkedField && linkedField.customField.fromQuery && linkedField.customField.query && linkedField.customField.query.id){
		                        		if(field.id && data && linkedField.identifierField && linkedField.labelPropField) {
	                        				$http({method: 'GET',url: './api/tram/new/dossierTransaction/calculateCustomFields/'+vm.dossierTransactionComponent.dossierTransaction.id+'/'+linkedField.customField.id+'/'+data+'/'+field.id}).then(function(dataCF){
		                            		  if(dataCF){
		                            			  var opts = ((dataCF && dataCF.data && dataCF.data.length > 0)?JSOG.decode(dataCF.data):[]);
		                            			  var newopts = [];
		                            			  _.forEach(opts, function(lv){
		                            					if(lv[linkedField.identifierField]){
		                            						var label = '';
		                            						var labelPropFieldS = linkedField.labelPropField.split("--@--@--");
		                            						_.forEach(labelPropFieldS, function(lp){
		                            							label = label + ((label)?((linkedField.labelFieldsSeparator)?linkedField.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
		                            						});
		                            						newopts.push({id: lv[linkedField.identifierField], language1:label, language2:label, language3:label, value:lv[linkedField.identifierField] });
		                            					}
		                            				});
		                            		  }
		                            		  linkedField.customField.listValues = newopts;
	                        				}).catch(function(error){
	                        					linkedField.customField.listValues = [];
	                        				});
	                        			} else {
	                        				linkedField.customField.listValues = [];
		                                }
		                        	}
		                        });
		                    }
                        }
                        if(dataUPDATE){
                        	if(dataUPDATE.showCFs){
								var keys = Object.keys(dataUPDATE.showCFs);
	                            _.forEach(keys, function(key){
	                            	var indexCFk = $linq(vm.customFields).indexOf("x => x.id == "+key );
	                            	if(indexCFk > -1){
	                            		if(dataUPDATE.showCFs[key]){
	                            			vm.customFields[indexCFk].invisible = true;
		            					}else{
		            						vm.customFields[indexCFk].invisible = false;
		            					}
	                            	}
	                            });
							}
							if(dataUPDATE.showCFGs){
								var deleteGCFFromGroup = function(groups, key, show){
									var deleted = false;
									if(groups){
										var indexCFk = $linq(groups).indexOf("x => x.id == "+key );
										if(indexCFk > -1){
											if(show){
												groups[indexCFk].invisible = true;
											}else{
												groups[indexCFk].invisible = false;
											}
											deleted = true;
										}else{
											_.forEach(groups, function(cfAux){
												if(!deleted && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
													deleted = deleteGCFFromGroup(cfAux.groups, key, show);
												}
											});
										}
									}
									return deleted;
								}
								var keys = Object.keys(dataUPDATE.showCFGs);
	                            _.forEach(keys, function(key){
	                           		var deleted = false;
	           						_.forEach(vm.customFields, function(cfAux){
	           							if(!deleted && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	           								deleted = deleteGCFFromGroup(cfAux.groups, key, dataUPDATE.showCFGs[key]);
	           							}
	           						});
	                            });
							}
	                        if(dataUPDATE.calculatedCFs && vm.customFields){
								var keys = Object.keys(dataUPDATE.calculatedCFs);
	                            _.forEach(keys, function(key){
	                            	var cfToChange = $linq(vm.customFields).firstOrDefault(undefined, "x => x.id == "+key );
	            					if(cfToChange){
	            						cfToChange.value = dataUPDATE.calculatedCFs[key];
	            						CustomFieldFactory.calculateValueJSONCustomField(cfToChange);
	            					}
	                            });
							}
							if(dataUPDATE.calculatedCFGs){
								var calculateGCFFromGroup = function(groups, key, calculateds){
									var calc = false;
									if(groups){
										var cFk = $linq(groups).firstOrDefault(undefined, "x => x.id == "+key );
										if(cFk){
											cFk.value = calculateds[key];
											CustomFieldFactory.calculateValueJSONGroupCustomField(cFk);
											calc = true;
										}else{
											_.forEach(groups, function(cfAux){
												if(!calc && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
													calc = calculateGCFFromGroup(cfAux.groups, key, calculateds);
												}
											});
										}
									}
									return calc;
								}
								var keys = Object.keys(dataUPDATE.calculatedCFGs);
	                            _.forEach(keys, function(key){
	    	 						var calc = false;
	        						_.forEach(vm.customFields, function(cfAux){
	        							if(!calc && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	        								calc = calculateGCFFromGroup(cfAux.groups, key, dataUPDATE.calculatedCFGs);
	        							}
	        						});
	                        	});
							}
                        }
                    }).catch(function (error) {
						if(fieldAux && fieldAux.id){
							var index = $linq(vm.customFields).indexOf("x => x.id == "+fieldAux.id);
							if(index >= 0){
								vm.customFields[index] = fieldAux;
							}
						}
						vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
                		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.errorUpdateCustomField')});
                    });
                }
			}
			vm.getFieldValue = function(field){
				var valueLiteral = '';
                if (field.customField.frontendType == 'MULTIPLESELECT' || field.customField.frontendType == 'CHECKBOX') {
                    _.forEach(field.valueFromJSON, function (valueJ, keyJ) {
                        _.forEach(field.customField.listValues, function (value, key) {
                            if (value.value == valueJ) {
                                if (valueLiteral) {
                                    valueLiteral = valueLiteral + ', ' + value[vm.languageColumn];
                                } else {
                                    valueLiteral = value[vm.languageColumn];
                                }
                            }
                        });
                    });
                } else if (field.customField.frontendType == 'SELECT' || field.customField.frontendType == 'SELECT_LINKED' || field.customField.frontendType == 'RADIO') {
                    _.forEach(field.customField.listValues, function (value, key) {
                        if (value.value == field.valueFromJSON) {
                            valueLiteral = value[vm.languageColumn];
                        }
                    });
                } else if (field.customField.frontendType == 'INPUT') {
                    if (field.customField.backendType == "DATETIME") {
                    	if(field.valueFromJSON){
                    		valueLiteral = $filter('date')(field.valueFromJSON, 'dd/MM/yyyy');
                    	}
                    } else if (field.customField.backendType == "DECIMAL") {
                        if (field.valueFromJSON) {
                            valueLiteral = field.valueFromJSON;
                            var valueLiteral_parts = ("" + valueLiteral).split('.');
                            if (valueLiteral_parts && valueLiteral_parts.length > 0) {
                                if (valueLiteral_parts[1]) {
                                    if (field.customField && field.customField.decimalPositions) {
                                        valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0." + valueLiteral_parts[1]).toFixed(field.customField.decimalPositions).replace('0.', ',');
                                    } else {
                                        valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0." + valueLiteral_parts[1]).toFixed(2).replace('0.', ',');
                                    }
                                } else {
                                    if (field.customField && field.customField.decimalPositions) {
                                        valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0.00").toFixed(field.customField.decimalPositions).replace('0.', ',');
                                    } else {
                                        valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + ",00";
                                    }
                                }
                            }
                        } else {
                            valueLiteral = field.valueFromJSON;
                        }
                    } else if (field.customField.backendType == "INTEGER") {
                        if (field.valueFromJSON) {
                            valueLiteral = field.valueFromJSON.toLocaleString();
                        } else {
                            valueLiteral = field.valueFromJSON;
                        }
                    } else if (field.customField.backendType == "ADDRESS"){
                    	if (field.valueFromJSON) {
                            valueLiteral = AnnexaEntityFactory.postalAddressRender(field.valueFromJSON);
                        } else {
                            valueLiteral = field.valueFromJSON;
                        }
                    } else {
                        valueLiteral = field.valueFromJSON;
                    }
                } else if(field.customField.frontendType == 'JSON' || field.customField.frontendType == 'CF_GROUP'){
                	var maxLengthReadModeCustomFieldJson = 100;
                	if ($rootScope.app.configuration.max_length_read_mode_custom_field_json && $rootScope.app.configuration.max_length_read_mode_custom_field_json.value > 0) {
                		maxLengthReadModeCustomFieldJson = $rootScope.app.configuration.max_length_read_mode_custom_field_json.value;
                    }
                    if(field.valueFromJSON && field.valueFromJSON.length > maxLengthReadModeCustomFieldJson){
                        valueLiteral = field.valueFromJSON.substr(0,maxLengthReadModeCustomFieldJson) + '...';
                    }else{
                        valueLiteral = field.valueFromJSON;
                    }
                }else if(field.customField.frontendType == 'STRUCTURAL_SELECT'){
                	if(field && field.customField && field.customField.listValues){
	                    angular.forEach(field.customField.listValues, function (value, key) {
	                        if (field.valueFromJSON && field.valueFromJSON.model && field.valueFromJSON.model.$selected && field.valueFromJSON.model.$selected.id && field.valueFromJSON.model && value.value == field.valueFromJSON.model.$selected.id) {
	                            valueLiteral = value[vm.languageColumn];
	                        }
	                    });
                	}
                }
                return valueLiteral;
			}
			
			vm.openJSONViewer = function(modelValue, name) {
    			var modal = angular.copy(CommonAdminModals.jsonViewer);
    			modal.data = modelValue;
    			modal.title = name;
    			AnnexaModalFactory.showModal('modalJsonViewer', modal);
            }
			
			vm.openModalAddress = function(field){
				var modal = angular.copy(globalModals.editAddressCF);
            	modal.field = field;
            	modal.updateField = vm.updateField;
            	var defaultCountry = $rootScope.app.configuration.default_country;
            	if(defaultCountry && defaultCountry.value){
                    defaultCountry = defaultCountry.value;
                }else{
                    defaultCountry = '';
                }
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.model.modal_body = field.valueFromJSON;
                if(!modal.annexaFormly.model.modal_body.country && defaultCountry){
                    modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
                }
                modal.annexaFormly.model.modal_body.hasStates = true;
                modal.annexaFormly.model.modal_body.hasCities = true;
                modal.annexaFormly.model.modal_body.hasStreetTypes = true;
                modal.extra = this;

                var submitFunction = function() { 
                	var self = this;
                	var address = {};
                    var addressAux = this.annexaFormly.model.modal_body;
                    address.country = addressAux.country;
                    if(addressAux.hasStates) {
                        if (addressAux.validState && addressAux.validState.id) {
                        	address.validState = addressAux.validState;
                        }
                    }else{
                    	address.stateAddress = addressAux.stateAddress;
                    }
                    if(addressAux.hasCities){
                        if(addressAux.validCity && addressAux.validCity.id) {
                        	address.validCity = addressAux.validCity;
                        }
                    }else{
                    	address.city = addressAux.city;
                    }
                    if(addressAux.hasStreetTypes && addressAux.streetType) {
                    	address.streetType = addressAux.streetType;
                    }
                    address.street = addressAux.street;
                    address.isValidNumber = false;
                    address.numberAddres = addressAux.numberAddres;
                    address.floor = addressAux.floor;
                    address.block = addressAux.block;
                    address.door = addressAux.door;
                    address.stair = addressAux.stair;
                    address.postalCode = addressAux.postalCode;
                    this.updateField(this.field, address);
                	self.close();
                }
                AnnexaFormlyFactory.showModal("modalNewAddress", modal, submitFunction, false);
			}
			
			var canOpenWindow = function(modelValue){
				var exist = false;
            	if(modelValue){
            		var startUrls = ['http://','https://'];
            		if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.url_editable_inputs && $rootScope.app.configuration.url_editable_inputs.urls && $rootScope.app.configuration.url_editable_inputs.urls.length > 0){
            			_.forEach($rootScope.app.configuration.url_editable_inputs.urls, function(item){
            				startUrls.push(item);
            			});
            		}
            		var modelValueString = ((modelValue && vm.languageColumn && modelValue[vm.languageColumn])?modelValue[vm.languageColumn]:modelValue);
            		if(modelValueString){
                		_.forEach(startUrls, function(item){
                			if(modelValueString.startsWith(item.toLowerCase())){
                				exist = true;
                			}
                		});
            		}
            	}
            	return exist;
			}
			
			vm.openWindow = function(element, modelValue, field){
				if(canOpenWindow(modelValue)){
            		var modelValueString = ((modelValue && vm.languageColumn && modelValue[vm.languageColumn])?modelValue[vm.languageColumn]:modelValue);
            		if(modelValueString){
            			window.open(modelValueString);
            		}else{
            			if(((vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.actual && vm.dossierTransactionComponent.isUpdateDossier) || vm.isArchivedDossier) && field && !field.noEditable){
            				element.$show();
            			}
            		}
            	}else{
            		if(((vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.actual && vm.dossierTransactionComponent.isUpdateDossier) || vm.isArchivedDossier) && field && !field.noEditable){
        				element.$show();
        			}
            	}
			}
			
			vm.getOptions = function(field){
			 	if (field.customField.frontendType == 'SELECT_LINKED') {
			 		var options = [];
                	if(field.customField.fromQuery && field.customField.query && field.customField.query.id) {
                		options = field.customField.listValues;
                	}else{
                		var parentField = $linq(vm.customFields).singleOrDefault(undefined, "x => x.customField.id == " + field.customField.linkedCustomField.id);
                		if (parentField) {
                			var parentValue = $linq(parentField.customField.listValues).singleOrDefault(undefined, "x => x.value == " + parentField.valueFromJSON);
                			
                			if (parentValue) {
                				options = $linq(field.customField.listValues).where("x => x.parentValue.id == " + parentValue.id).toArray();
                			}
                		}
                	}
                    return options;
                }
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.descriptionLanguageColumn = "descriptionLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
				vm.nameLanguageColumn = "nameLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
				vm.groupData.extra.valuesFromLinkedCustomFields.parentId = vm.dossierTransactionComponent.dossierTransaction.id;
				TramNewFactory.getDossierTransactionCustomFields(vm.dossierTransactionComponent.dossierTransaction.id).then(function(data){
					try{
						vm.customFields = JSOG.decode(data);
						vm.isUpdate = vm.dossierTransactionComponent.isUpdateDossier && vm.dossierTransactionComponent.canModifyTransaction && vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.actual;
						vm.groupData.extra.isEdit = vm.isUpdate;
						if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isArchivedDossier && (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'TRANSFER' || vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'REJECTED' || (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' && !vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveDefinitiveDate))){
		        			vm.isArchivedDossier = true;
		    			}
						_.forEach(vm.customFields, function (value, key) {
							try{
			                    if (value.value) {
			                        var object = angular.fromJson(value.value);
			                        value.valueFromJSON = object.value;
			                        if (value.customField.backendType == "DATETIME") {
			                            if(value.valueFromJSON){
			                            	value.valueFromJSON = new Date(value.valueFromJSON);
			                            }
			                        }
			                        if(value.customField.frontendType === 'STRUCTURAL_SELECT'){
			                        	var selected = undefined;
			                        	var options = angular.copy(value.customField.listValues); 
			                        	_.forEach(options, function(val){
			                        		if(val.parentValue && val.parentValue.id){
			                        			val.parent = {id:val.parentValue.id};
			                        		}
			                        	});
			                        	options = CommonService.getTreeData(options, Language.getActiveColumn());
			                            var found = false;
			                            if(options){
				                            angular.forEach(options, function (val, key) {
				                                 if (!found && value.valueFromJSON) {
				                                     selected = $linq(val).singleOrDefault(undefined, "x => x.id == " + value.valueFromJSON);
				                                     if (selected) {
				                                         found = true;
				                                     }
				                                 }
				                            });
			                            }
			                        	value.valueFromJSON = {
			                    			model: ((selected)?{$selected: selected}:{}),
			                                options: options,
			                                required: value.required
			                        	}
			                        }
			                    } else {
			                        if (value.customField.frontendType == 'MULTIPLESELECT' || value.customField.frontendType == 'CHECKBOX') {
			                            value.valueFromJSON = [];
			                        }else if(value.customField.frontendType === 'STRUCTURAL_SELECT'){
			                        	var options = angular.copy(value.customField.listValues); 
			                        	_.forEach(options, function(val){
			                        		if(val.parentValue && val.parentValue.id){
			                        			val.parent = {id:val.parentValue.id};
			                        		}
			                        	});
			                        	options = CommonService.getTreeData(options, Language.getActiveColumn());
			                            value.valueFromJSON = {
			                    			model: {},
			                                options: options,
			                                required: value.required
			                        	}
			                        } else {
			                            value.valueFromJSON = '';
			                        }
			                    }
			                    if (value && value.customField && value.customField.backendType == "DECIMAL") {
			                        if (value.customField.decimalPositions) {
			                            value.step = '0.' + Array(value.customField.decimalPositions).join('0') + '1';
			                        } else {
			                            value.step = '0.01'
			                        }
			                    }
			                    if(value && value.customField.frontendType === 'SELECT'){
			                    	if(!value.required){
			                    		if(!$linq(value.customField.listValues).firstOrDefault(undefined, "x => x.id == -1")){
			                    			value.customField.listValues.unshift({id:-1, language1:$filter('translate')('global.literals.notAssign'), language2:$filter('translate')('global.literals.notAssign'), language3:$filter('translate')('global.literals.notAssign')});
			                    		}
			                    	}
			                    }
							}catch(e){
								console.error(e);
								if(value){
									value.valueFromJSON = '';
								}
							}
		                });
						vm.isLoaded = true;
					}catch(error){
						vm.isLoaded = true;
					}
				}).catch(function(error){
					vm.customFields = [];
					vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
            		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.errorGetCustomFields')});
					vm.isLoaded = true;
				});
			}
        }],
        bindings: {
		}
    })
	.component('annexaBoxObjectDossierTransactionFiscalizationAmendment',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction-fiscalization-amendment.html',
        require: {
            dossierTransactionComponent: '^^annexaBoxObjectDossierTransaction'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'TramNewFactory', 'apiAdmin', 'AnnexaPermissionsFactory', function($rootScope, $filter, Language, GlobalDataFactory, HelperService, TramNewFactory, apiAdmin, AnnexaPermissionsFactory) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.isUpdate = false;
			vm.isArchivedDossier = false;
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.isUpdate = vm.dossierTransactionComponent.isUpdateDossier && vm.dossierTransactionComponent.canModifyTransaction && vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.actual;
				if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isArchivedDossier && (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'TRANSFER' || vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'REJECTED' || (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' && !vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveDefinitiveDate))){
    				vm.isArchivedDossier = true;
    			}
			}
        }],
        bindings: {
		}
    })
	.component('annexaBoxObjectDossierTransactionOther',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction-other.html',
        require: {
            dossierTransactionComponent: '^^annexaBoxObjectDossierTransaction'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'TramNewFactory', 'apiAdmin', 'AnnexaPermissionsFactory', function($rootScope, $filter, Language, GlobalDataFactory, HelperService, TramNewFactory, apiAdmin, AnnexaPermissionsFactory) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.isUpdate = false;
			vm.isArchivedDossier = false;
			vm.printYesNo = function (value) {
            	if (value) {
            		return $filter('translate')('global.literals.yes')
            	} else if(!value) {
            		return $filter('translate')('global.literals.no')
            	} else {
            		'';
            	}
            }
			this.$onInit = function() {
				vm.languageColumn = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.isUpdate = vm.dossierTransactionComponent.isUpdateDossier && vm.dossierTransactionComponent.canModifyTransaction && vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.actual;
				if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isArchivedDossier && (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'TRANSFER' || vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'REJECTED' || (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' && !vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveDefinitiveDate))){
    				vm.isArchivedDossier = true;
    			}
			}
        }],
        bindings: {
		}
    })
	.component('annexaBoxObjectDossierTransactionProposal',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction-proposal.html',
        require: {
            dossierTransactionComponent: '^^annexaBoxObjectDossierTransaction'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'TramNewFactory', 'apiAdmin', 'AnnexaPermissionsFactory', 'AnnexaEntityFactory', 'globalModals', 'AnnexaFormlyFactory', 'RestService', '$scope','ErrorFactory', 'DialogsFactory', 'SecFactory', 'WizardHandler', 'DccumentsFactory', 'CustomFieldFactory', '$q', '$state',
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, TramNewFactory, apiAdmin, AnnexaPermissionsFactory, AnnexaEntityFactory, globalModals, AnnexaFormlyFactory, RestService, $scope, ErrorFactory, DialogsFactory, SecFactory, WizardHandler, DccumentsFactory, CustomFieldFactory, $q, $state) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.isArchivedDossier = false;
			vm.isUpdate = false;
			vm.isProposalTypeEditable = true;
			vm.isOrgansAgreementEditable = true;
			vm.isProposalTitleEditable = true;
			vm.isNotificatorProfileEditable = true;
			vm.isAmmendmentTransactionTypeEditable = true;
			vm.isTransferTransactionTypeEditable = true;
			vm.isCancelTransactionTypeEditable = true;
			vm.isOrgansOpinionEditable = true;
			vm.isFooterClaimsEditable = true;
			vm.isInternalProfilesEditable = true;
			vm.isSecNotificationEditable = true;
			vm.validProcedureProposalTypes = [];
			vm.proposal = undefined;
			vm.proposalsDecree = [];
			vm.decree = undefined;
			vm.proposalTypes = [];
			vm.notificatorProfiles = [];
			vm.dtProfiles = [];
		    vm.decreeDateSelected = undefined;
			vm.decreeDelegatedTypeSelected = undefined;
			vm.allFooterClaims = [];
			vm.propDocument = undefined;
           	vm.proposalDocuments = [];
   			vm.proposalSelectableDocuments = [];
			vm.notificatorResponsibles = [];
			vm.footerClaimsAux = [];
			vm.amendmentTransactionTypes = [];
			vm.transferTransactionTypes = [];
			vm.cancelTransactionTypes = [];
			vm.operationsActive = false;
			vm.allDossierDocuments = [];
			vm.isLoaded = false;
			vm.canAddedSecConfiguration = false;
			vm.isNotificableFromSecretary = false;
			vm.secretaryProfile = undefined;
			vm.docsNoOk = [];
			vm.showTransferButtons = false;
			vm.showSessionSecNotifications = {DOCUMENT: false, SIGN: false, NOTIFICATION: false, CLOSE_TRANSFER: false};
			vm.proposalId = undefined;
			vm.proposalTypeSelected = function(){
				if(vm.proposal && vm.proposal.proposalType) {
    				return vm.proposal.proposalType[vm.languageColumn];
                } else {
        			return '';
                }
			}
			vm.procedureProposalTypeSelected = function(){
				if(vm.proposal && vm.proposal.procedureProposalType) {
    				return vm.proposal.procedureProposalType[vm.languageColumn];
                } else {
        			return '';
                }
			}
			vm.creatorProfileSelected = function(){
				if(vm.proposal && vm.proposal.profile) {
    				return vm.proposal.profile[vm.languageColumn];
                } else {
                	return '';
                }
			}
			vm.notificatorProfileSelected = function(){
				if(vm.proposal && vm.proposal.notificatorProfile) {
    				return vm.proposal.notificatorProfile[vm.languageColumn];
                } else {
                	return '';
                }
			}
			vm.popupNotificatorResponsibles = function(){
				var dataModal = {
                    row: true,
                    colClass: 'col-sm-12',
                    labelClass: ''
                };
                var modal = {
                    title: 'global.tram.literals.selectResponsible',
                    size: 'modal-sm',
                    icon: '',
                    submitModal: function () {
                    },
                    alerts: []		
                }
                modal.annexaFormly = new AnnexaFormly();
                
                var saveProfilesAndResponsibles = function(){
                	if (modal.annexaFormly.model.modal_body.profile && modal.annexaFormly.model.modal_body.selectedResponsible) {
                		vm.proposal.notificatorProfile = modal.annexaFormly.model.modal_body.profile;
                		vm.proposal.notificatorResponsible = modal.annexaFormly.model.modal_body.selectedResponsible;
                		
                        RestService.update('./api/sec/proposal/' + vm.proposal.id, vm.proposal).then(function (data) {
                        	vm.proposal.proposalNumber = data.proposalNumber;
                        	if (vm.proposal.notificatorResponsible) vm.proposal.notificatorResponsible.completeName = vm.proposal.notificatorResponsible.name + ' ' + vm.proposal.notificatorResponsible.surename1 + ((vm.proposal.notificatorResponsible.surename2) ? ' ' + vm.proposal.notificatorResponsible.surename2 + '\n' : '\n');
                        	vm.getNotificatorResponsibles();
                        	modal.close();
                        }).catch(function (error) {
                            console.error(error);
                        });
                	} else {
                		modal.alerts.push({msg: 'global.tram.errors.noProfileNoResponsibleSelected'});
                	} 
                }
                
                var fields = [];
                fields.push(modal.annexaFormly.createField('profile','annexaSelectResponsibleRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.tram.literals.selectProfileAndResponsible','id', vm.languageColumn, vm.notificatorProfiles, true), dataModal));
                fields[0].templateOptions.profileId = vm.proposal.notificatorProfile ? vm.proposal.notificatorProfile.id : undefined;
                fields[0].templateOptions.responsibleId = vm.proposal.notificatorResponsible ? vm.proposal.notificatorResponsible.id : undefined;
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModalPromise("updateNotificatorResponsible", modal, saveProfilesAndResponsibles, false, false, 'global.literals.select').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                }); 
			}
			vm.notificatorResponsibleSelected = function(){
				if(vm.proposal && vm.proposal.notificatorResponsible && vm.proposal.notificatorResponsible.completeName) {
    				return vm.proposal.notificatorResponsible.completeName;
                } else {
        			return '';
                }
			}
			vm.printAddSecNotification = function(){
				if(vm.proposal && vm.proposal.addSecNotification){
                    return $filter('translate')('global.literals.yes');
                } else {
                    return $filter('translate')('global.literals.no');
                }
			}
			vm.printAnonymizeThirds = function(){
				if(vm.proposal && vm.proposal.anonymousThirds){
                    return $filter('translate')('global.literals.yes');
                } else {
                    return $filter('translate')('global.literals.no');
                }
			}
			vm.printUrgentProposal = function(){
				if(vm.proposal && vm.proposal.urgent){
                    return $filter('translate')('global.literals.yes');
                } else {
                    return $filter('translate')('global.literals.no');
                }
			}
			vm.printFooterClaims = function(){
				var content = '';
    			if(vm.footerClaimsAux && vm.footerClaimsAux.length > 0) {
                    _.forEach(vm.footerClaimsAux, function(footerClaim){
                    	content += ((content == '')?'':', ') + footerClaim[vm.languageColumn];
                    });
                }
    			return content;
			}
			vm.printAnonymizeThirds = function(){
				if(vm.proposal && vm.proposal.anonymousThirds){
                    return $filter('translate')('global.literals.yes');
                } else {
                    return $filter('translate')('global.literals.no');
                }
			}
			vm.proposalDocumentSelected = function(){
				if(vm.propDocument && vm.propDocument.length > 0) {
    				return vm.propDocument[0].name;
                } else if (vm.propDocument) {
                	return vm.propDocument.name;
                } else {
                	return '';
                }
			}
			vm.getIconClassTramStatus = function(status){
				if(status){
					if(status == 'PENDING' || status == 'PENDING_SIGN'){
						return 'blue';
					}else if(status == 'SIGNED'){
						return 'success';
					}else if(status == 'CANCELLED'){
						return 'warn';
					}else if(status == 'REJECTED' || status == 'ANNULLED'){
						return 'danger';
					} else {
						return'text-grey';
					}
				}else{
					return 'text-grey';
				}
			}
			vm.getNameTramStatus = function(status){
				if(status){
                	return $filter('translate')('global.sec.literals.status.' + status);
				}else{
					return '';
				} 
			}
			vm.amendmentProposalSelected = function(status){
                if (vm.proposal && vm.proposal.amendmentTransactionType) {
                	var tt = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+vm.proposal.amendmentTransactionType.id);
                	return ((tt)?tt[vm.languageColumn]:'');
                } else {
                	return '';
                }
			}
			vm.transferProposalSelected = function(status){
                if (vm.proposal && vm.proposal.transferTransactionType) {
                	var tt = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+vm.proposal.transferTransactionType.id);
                	return ((tt)?tt[vm.languageColumn]:'');
                } else {
                	return '';
                }
			}
			vm.cancelProposalSelected = function(status){
                if (vm.proposal && vm.proposal.cancelTransactionType) {
                	var tt = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+vm.proposal.cancelTransactionType.id);
                	return ((tt)?tt[vm.languageColumn]:'');
                } else {
                	return '';
                }
			}
			vm.showComplementaryDocuments = function(){
				if(vm.proposal && vm.proposalSelectableDocuments && vm.proposalSelectableDocuments.length > 0) {
        			if(vm.proposal.documents && vm.proposal.documents.length > 0 && !vm.isUpdate) {
        				return $linq(vm.proposal.documents).contains("ADJ", "(x, y) => x.proposalDocumentType == y");
        			} else {
            			return true;
        			}
        		} else if(vm.proposal && vm.proposal.documents && vm.proposal.documents.length > 0) {
        			return $linq(vm.proposal.documents).contains("ADJ", "(x, y) => x.proposalDocumentType == y");
        		}
        		return false;
			}
			
			vm.deleteComplementaryDocument = function($index, doc) {
				if(doc && doc.document && doc.document.id){
	        		var index = $linq(vm.proposal.documents).indexOf("x => x.document.id == " + doc.document.id);
	        		var selected = $linq(vm.proposal.documents).single("x => x.document.id == " + doc.document.id);
	        		if(index != -1) {
	        			vm.proposal.documents.splice(index,1);
	        		}
	        		vm.updateProposal(selected, 'complementaryDocument');
	        		vm.proposalSelectableDocuments.push(doc.document);
				}
        	}
        	
        	vm.selectComplementaryDocument = function(selected) {
        		if(vm.proposal.documents) {
        			vm.proposal.documents.push({document: selected, proposalDocumentType: 'ADJ'});
        		} else {
        			vm.proposal.documents = [];
        			vm.proposal.documents.push({document: selected, proposalDocumentType: 'ADJ'});
        		}

    			vm.updateProposal(selected, 'complementaryDocument');
        		var index = $linq(vm.proposalSelectableDocuments).indexOf("x => x.id == " + selected.id);
        		if(index != -1) {
        			vm.proposalSelectableDocuments.splice(index,1);
        		}
        	}

			vm.getNotificatorResponsibles = function(profile) {
        		vm.notificatorResponsibles = [];
				if(profile && profile.id){
	                if (profile && profile.userProfiles) {
	                    angular.forEach(profile.userProfiles, function (userProfile) {
	                    	if (userProfile && userProfile.user && userProfile.user.name) {
	                    		userProfile.user.completeName = userProfile.user.name + ' ' + userProfile.user.surename1 + ((userProfile.user.surename2) ? ' ' + userProfile.user.surename2 + '\n' : '\n');
	                    		vm.notificatorResponsibles.push(userProfile.user);
	                    	}
	                    });
	                }	
				}else{
	                if (vm.proposal && vm.proposal.notificatorProfile && vm.proposal.notificatorProfile.userProfiles) {
	                    angular.forEach(vm.proposal.notificatorProfile.userProfiles, function (userProfile) {
	                    	if (userProfile && userProfile.user && userProfile.user.name) {
	                    		userProfile.user.completeName = userProfile.user.name + ' ' + userProfile.user.surename1 + ((userProfile.user.surename2) ? ' ' + userProfile.user.surename2 + '\n' : '\n');
	                    		vm.notificatorResponsibles.push(userProfile.user);
	                    	}
	                    });
	                }	
				}
            };

			vm.proposalDocumentList = function(){
				if(vm.proposalDocuments){
        			vm.proposalDocuments.length = 0;
        		} else {
        			vm.proposalDocuments = [];
        		}
        		if(vm.proposalSelectableDocuments){
        			vm.proposalSelectableDocuments.length = 0;
        		} else {
        			vm.proposalSelectableDocuments = [];
        		}
				
				if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype &&  vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
        			var documentTypeProp = $rootScope.app.configuration.sec_document_types["SPD"];
        		}else{            			
        			var documentTypeProp = $rootScope.app.configuration.sec_document_types["SPR"];
        		}
            	if(vm.allDossierDocuments && documentTypeProp){
					_.forEach(vm.allDossierDocuments, function(documentDT){
            			if(documentDT && documentDT.document && documentDT.document.type && _.contains(documentTypeProp.id, documentDT.document.type.id+"")){
            				if((!vm.docsNoOk || vm.docsNoOk.length == 0 || (vm.docsNoOk && vm.docsNoOk.length > 0 && !_.contains(vm.docsNoOk, documentDT.document.id))) && documentDT.paperBin == false){
								vm.proposalDocuments.push(documentDT.document);
							}
            			} else if(vm.proposal && !$linq(vm.proposal.documents).contains(documentDT.document, "(x, y) => x.document.id == y.id")) {
                			vm.proposalSelectableDocuments.push(documentDT.document);
            			}
            		});

            		if(vm.proposalDocuments.length > 1) {
            			_.forEach(vm.proposalDocuments, function(documentDT){
            				if(vm.proposal && !$linq(vm.proposal.documents).contains(documentDT, "(x, y) => x.document.id == y.id")) {
                    			vm.proposalSelectableDocuments.push(documentDT);
                			}
            			});
            		}else if(vm.allDossierDocuments.length > 1 && vm.proposalDocuments.length == 1 && ((vm.proposal.documents && vm.proposal.documents.length == 0) || (vm.proposal.documents && vm.proposal.documents.length == 1 && vm.proposal.documents[0].document.id != vm.proposalDocuments[0].id))){
            			vm.updateProposal(vm.proposalDocuments[0], 'documents');
            		}
            	}
            	            	
        		if(vm.proposal && vm.proposal.state && _.contains(['DRAFT'], vm.proposal.state) 
        				&& vm.allDossierDocuments && vm.proposal.documents && vm.proposal.documents.length > 0) {
        			var removeDocuments = [];
        			_.forEach(vm.proposal.documents, function(proposalDocument){
        				var found = false;
            			if(proposalDocument && proposalDocument.document) {
                    		_.forEach(vm.allDossierDocuments, function(documentDT){
                    			if(documentDT && documentDT.document && proposalDocument.document.id == documentDT.document.id){
                    				found = true;
                    			}
                			});
                    		
                    		if (!found) {
                    			removeDocuments.push(proposalDocument.document)
                    		}
            			}
            		});
                	_.forEach(removeDocuments, function(removeDocument){
                		var index = $linq(vm.proposal.documents).indexOf("x => x.document.id == " + removeDocument.id);
                        if(index != -1) {
                            vm.proposal.documents.splice(index,1);
                        }
                	});
                	
                	if (removeDocuments && removeDocuments.length > 0) {
            			vm.updateProposal(vm.proposal.documents.document, 'documents');
            			if(vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
                			vm.propDocument = $linq(vm.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPD'").document;
                		}else{
                			vm.propDocument = $linq(vm.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPR'").document;
                		}
                	}
        		}
			}
			
			vm.updateProcedureProposalType = function(val, prop){
				if(prop == 'procedureProposalType') {
					vm.dossierTransactionComponent.showProposalReloadTab = false;
					TramNewFactory.updateProcedureProposalTypeInProposal(vm.proposal.id, ((val && val.id)?val.id:-1), vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id).then(function (data) {
						TramNewFactory.isProcedureProposalTypeinspectionInterventionActive(vm.dossierTransactionComponent.dossierTransaction.id).then(function (data) {
							vm.dossierTransactionComponent.procedureProposalTypeInspectionInterventionActive = ((data !== '' && data !== undefined)?data:undefined);
							vm.dossierTransactionComponent.showProposalReloadTab = true;
	                    }).catch(function (error) {
	                    	vm.dossierTransactionComponent.showProposalReloadTab = true;
	                        console.error(error);
		                });
                    }).catch(function (error) {
                    	vm.dossierTransactionComponent.showProposalReloadTab = true;
                        console.error(error);
	                });
				}
			}
			
			vm.updateProposal = function(val, prop){
				if(prop == 'footerClaims') {
               		var footerClaims = [];
            		_.forEach(val, function(fc){
            			var fcAct = $linq(vm.proposal.footerClaims).where("x => x.footerClaim.id == "+fc.id).toArray();
            			if(fcAct && fcAct.length > 0){
            				footerClaims.push(fcAct[0]);
            			}else{
            				footerClaims.push({proposal:{id:vm.proposal.id}, footerClaim:{id:fc.id}});
            			}
            		});
            		vm.proposal.footerClaims = footerClaims;
            	} else if(prop == 'internalCommunications') {
            		vm.proposal[prop] = [];
            		if(val) {
                		_.forEach(val,function(profile){
                			vm.proposal[prop].push({profile:{id:profile.id}});
                		});                			
            		}
            	} else if(prop == 'documents') {
            		if(val) {
            			if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
            				var index = $linq(vm.proposal.documents).indexOf("x => x.proposalDocumentType == 'SPD'");
                		}else{
                			var index = $linq(vm.proposal.documents).indexOf("x => x.proposalDocumentType == 'SPR'");
                		}
            			
                		if(index != -1) {
                			vm.proposal.documents.splice(index,1);
                		}
                		index = -1;
                		index = $linq(vm.proposal.documents).indexOf("x => x.document.id == " + val.id + " && x.proposalDocumentType == 'ADJ'");
                		if(index != -1) {
                			vm.proposal.documents.splice(index,1);
                		}
                		vm.proposal[prop].push({document:{id:val.id}});
            		}
            	} else if(prop == 'complementaryDocument') {
            		if(val) {
                		vm.proposal[prop] = [{document:{id:val.id}, proposalDocumentType: 'ADJ'}];
            		} else {
            			vm.proposal[prop] = [];
            		}
            	}
            	else if(prop == 'notificatorProfile' || prop == 'notificatorResponsible' || prop == 'profile') {
            		vm.proposal[prop] = ((val && val.id)?{id:val.id}:undefined);
            	} else if(prop == 'amendmentTransactionType' || prop == 'transferTransactionType' || prop == 'cancelTransactionType' || prop == 'transactionInternalProfilesAlert') {
            		vm.proposal[prop] = ((val && val.id)?{id:val.id}:((val)?{id:val}:undefined));
            	} else {
                	vm.proposal[prop] = val;
            	}
                RestService.update('./api/sec/proposal/' + vm.proposal.id, vm.proposal)
                    .then(function (data) {
                    	vm.proposal.proposalNumber = data.proposalNumber;
                    	if(prop == 'profile') {
                    		vm.proposal[prop] = val;
                    	}else if(prop == 'notificatorProfile') {
                    		vm.proposal[prop] = val;
                    		if (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.esetMode) vm.getNotificatorResponsibles();
                    	} else if(prop == 'notificatorResponsible') {
                    		vm.proposal[prop] = val;
                    		if (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.esetMode) vm.getNotificatorResponsibles(data.notificatorProfile);
                    	} else if (prop == 'amendmentTransactionType' || prop == 'transferTransactionType' || prop == 'cancelTransactionType') {
                    		vm.proposal[prop] = $linq(GlobalDataFactory.transactiontypes).singleOrDefault(undefined, "x => x.id == " + ((val.id)?val.id:val));
                    	}else if(prop == 'proposalType'){
    	                	if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
    	                		vm.dossierTransactionComponent.dossierTransaction.proposalSubtype = 'DECREE';
								vm.proposal.delegationType = ((data.delegationType && data.delegationType.id)?data.delegationType:undefined);
					    	}else{
    	                		vm.dossierTransactionComponent.dossierTransaction.proposalSubtype = undefined;
								vm.proposal.delegationType = undefined;
    	                	}
							vm.dossierTransactionComponent.calculateRequiredAndCannotCreateDocument(data.proposalType, data.delegationType, undefined);
                    	} else if(prop == 'documents') {
                    		vm.proposal.documents = data.documents;
                    		if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
                    			vm.propDocument = $linq(vm.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPD'").document;
                    		}else{
                    			vm.propDocument = $linq(vm.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPR'").document;
                    		}
                    		
                    		vm.proposalDocumentList();
                    	}
						if(data.proposalType && vm.dossierTransactionComponent.proposal){
							vm.dossierTransactionComponent.proposal.proposalType = angular.copy(data.proposalType);
						}
						if(data.delegationType && vm.dossierTransactionComponent.proposal){
							vm.dossierTransactionComponent.proposal.delegationType = angular.copy(data.delegationType);
						}
                    }).catch(function (error) {
                        console.error(error);
                });
			}
			
			vm.configureSecNotificacions = function(){
				if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier 
						&& vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure
						&& vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.guided){
					TramNewFactory.configureSecNotifications(undefined, 'Proposal', vm.proposal.id, false, !vm.isUpdate);	
				}else{
					TramNewFactory.configureSecNotifications(undefined, 'Proposal', vm.proposal.id, true, !vm.isUpdate);				
				}
			}
			
			vm.seeInfoNotificationPublish = function(){
				var dossier = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier;
				TramNewFactory.seeInfoNotificationPublish(vm.proposal.workWithDossierSons, vm.proposal.id, dossier.id).then(function(data){
					var thirdsOk = [];
					var documentsOk = [];
					var proposal = data[2].data;
					if(vm.proposal.workWithDossierSons) {
						if(data && data[0]) {
							_.forEach(JSOG.decode(data[0]), function(sonThirds){
								_.forEach(sonThirds, function(sonThird){
									thirdsOk.push(sonThird);
								});
							});
						}
						documentsOk = ((data && data[1])?JSOG.decode(data[1]):undefined);
					}else {
						thirdsOk = ((data && data[0])?JSOG.decode(data[0]):undefined);
						documentsOk = ((data && data[1])?JSOG.decode(data[1]):undefined);
					}
				    var modalSendProposalSelectThirds =  TramNewFactory.modalSendProposalSelectThirds(dossier, thirdsOk, documentsOk, false, proposal);
				
				    var submitModalSendProposalSelectThirds = function () {
				        var modal = this;
				        var proposalPublishSites = {};
				        _.forEach(proposal.proposalPublishSite, function(pps){
				        	proposalPublishSites[pps.id] = modal.model['publishSitesDone_' + pps.id];
						});
				        
				        if(proposal.proposalPublishSite && proposal.proposalPublishSite.length > 0) {
					        TramNewFactory.updateNotificationPublish(proposal.id, proposalPublishSites).then(function (data) {
					        	modalSendProposalSelectThirds.close();
	                        }).catch(function (error) {
	                        	DialogsFactory.error($filter('translate')('global.publishSite.errors.updatePublishSiteStatus'));
	                        });
				        } else {
				        	modalSendProposalSelectThirds.close();
				        }
				    }
				
				    modalSendProposalSelectThirds.submitModal = submitModalSendProposalSelectThirds;
				
				    AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', modalSendProposalSelectThirds);
				}).catch(function(error){
				});
			}
			
			vm.validationCircuitObservations = function(){
				var dataModal = {
	                    row: true,
	                    colClass: 'col-sm-12',
	                    labelClass: ''
	                };
	                var modal = {
	                    title: 'global.literals.validationCircuitObservations',
	                    size: 'modal-md',
	                    icon: '',
	                    submitModal: function () {
	                    },
	                    alerts: []		
	                }
	                modal.annexaFormly = new AnnexaFormly();
	                
	                var fields = [];
	                if(vm.proposal.reviewObservations) {
	                	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewDisagreements',  vm.proposal.reviewDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewObservations',  vm.proposal.reviewObservations),dataModal));
	                }
	                if(vm.proposal.validateObservations) {
	                	fields.push(modal.annexaFormly.createField('reviewDisagreements2', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateDisagreements',  vm.proposal.validateDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateObservations',  vm.proposal.validateObservations),dataModal));
	                }
	                if(vm.proposal.acceptObservations) {
	                	fields.push(modal.annexaFormly.createField('reviewDisagreements3', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptDisagreements',  vm.proposal.acceptDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptObservations',  vm.proposal.acceptObservations),dataModal));
	                }
	                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
	                modal.annexaFormly.options = {};
	                modal.annexaFormly.options.formState = {readOnly: false};
	                AnnexaFormlyFactory.showModal("validationCircuitObservationsModal", modal, undefined, false, true); 
			}
			
			vm.createDocuments = function(){
				var openDocument = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var openModalDocumentWithThirds = function(secNotOk){
							var steps = [];
							_.forEach(secNotOk, function(sn){
								if(sn && sn.docs && sn.docs.length > 0 && sn.relatedProposal && sn.relatedProposal.id){
									var sections = [];
									if(HelperService.isSuperAdmin()) {
						            	sections = GlobalDataFactory.sections;
						            } else {
						            	sections = $linq($rootScope.LoggedUser.userSections).select("x => x.section").toArray();
						            }
									var profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
						            var profilesExp = [];
									var defaultProfile = undefined;
									if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.processProfiles && sn.relatedProposal.notificationTransaction.processProfiles.length > 0){
										profilesExp = $linq(profiles).intersect($linq(sn.relatedProposal.notificationTransaction.processProfiles).select("x => x.profile").toArray(), "(x, y) => x.id == y.id").toArray();
									}
									if(profilesExp && profilesExp.length > 0){
							            profilesExp =  new SortedArray(profilesExp, vm.languageColumn).sort();
										if ($rootScope.LoggedUser.userDefaultEntityProfiles) {
							                var profilesDef = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).where("x => x.entityType == 'DOC'").select("x => x.profile").toArray();
							                if (profilesDef && profilesDef.length > 0) {
							                    defaultProfile = profilesDef[0];
							                }
							            }
						            }else{
										profilesExp = [];
									}
									_.forEach(sn.docs, function(doc, index){
										var hasThirds = ((doc.template && doc.template.allowThirds && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.thirds && sn.relatedProposal.notificationTransaction.dossier.thirds.length > 0)?true:false);
										var thirds = ((hasThirds)?sn.relatedProposal.notificationTransaction.dossier.thirds:[]);
										var modalSelectMultReceivers = function() {
						                	var modal = angular.copy(globalModals.selectMultipleReceiversCheckbox);
						                    modal.annexaFormly.model = {};
						                    modal.annexaFormly.model.modal_body = {};
						                    modal.annexaFormly.options = {};
						                    modal.annexaFormly.model.modal_body.selectValues = {};
                    						thirds = $linq(thirds).orderBy("x => x.third.completeName", linq.caseInsensitiveComparer).toArray();
                    						var selectableOptions = [];
                    						_.forEach(thirds, function (value, key) {
                    							modal.annexaFormly.model.modal_body.selectValues[value.id] = false;
                    						});
											if(step.annexaFormly.model && step.annexaFormly.model.seletctedThirds){
												_.forEach(step.annexaFormly.model.seletctedThirds, function (value, key) {
							                        modal.annexaFormly.model.modal_body.selectValues[value.id] = true;
							                    });
											}
                    						_.forEach(thirds, function (value, key) {
                        						var receiver = angular.copy(value);
                        						var name = ((receiver.third.name)?receiver.third.name+" ":"") + ((receiver.third.surename1)?receiver.third.surename1+" ":"") + ((receiver.third.surename2)? receiver.third.surename2+" " : "" ) + ((receiver.third.corporateName)?receiver.third.corporateName:"");
                        						var destinatariType = '';
                        						if(receiver.interested){
                        							var roleInterested = '';
                            						if(receiver.roleInterested) {
                            							roleInterested = '(' + receiver.roleInterested[Language.getActiveColumn()] + ')';
                            						}
                            						destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
                        						}else{
						                            var representated = '';
						                            if(value.representated && value.representated.identificationDocument){
						                                if(value.representated.name){
						                                    representated= '(' + value.representated.name + ' ' + value.representated.surename1 + ((value.representated.surename2)?' '+value.representated.surename2 : '' ) + ' (' + value.representated.identificationDocument + ')';
						                                }else{
						                                    representated= '(' + value.representated.corporateName + ' (' + value.representated.identificationDocument + ')';
						                                }
						                            }
						                            destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
                        						}
						                        selectableOptions.push({ id: value.id, value: name, typeName: destinatariType});
                    						});
						                    modal.annexaFormly.model.modal_body.thirds = thirds;
                    						modal.annexaFormly.model.modal_body.selectableOptions = selectableOptions;
                    						modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.options = selectableOptions;
						                    var submitFunction = function () {
                    							var selectedOptions = [];
                    							var slectedThirdsLabel = '';
                    							var labels = [];
                    							if(this.annexaFormly.model.modal_body.selectValues){
						                        	var keys = Object.keys(modal.annexaFormly.model.modal_body.selectValues);
						                            if(keys && keys.length > 0){
						                                _.forEach(keys, function(key){
					                                    	if(modal.annexaFormly.model.modal_body.selectValues[key]){
						                                    	var sel = $linq(thirds).firstOrDefault(function (x) { return x.id == parseInt(key); });
						                                    	selectedOptions.push(sel);
						                                    	var name = ((sel.third.name)?sel.third.name+" ":"") + ((sel.third.surename1)?sel.third.surename1+" ":"") + ((sel.third.surename2)? sel.third.surename2+" " : "" ) + ((sel.third.corporateName)?sel.third.corporateName:"");
						                                        var destinatariType = '';
						                                        if(sel.interested){
						                                        	var roleInterested = '';
						                                            if(sel.roleInterested) {
						                                            	roleInterested = '(' + sel.roleInterested[Language.getActiveColumn()] + ')';
						                                            }
						                                            destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
						                                        }else{
						                                            var representated = '';
						                                            if(sel.representated && sel.representated.identificationDocument){
						                                                if(sel.representated.name){
						                                                    representated= '(' + sel.representated.name + ' ' + sel.representated.surename1 + ((sel.representated.surename2)?' '+sel.representated.surename2 : '' ) + ' (' + sel.representated.identificationDocument + '))';
						                                                }else{
						                                                    representated= '(' + sel.representated.corporateName + ' (' + sel.representated.identificationDocument + '))';
						                                                }
						                                            }
						                                            destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
						                                        }
						                                        slectedThirdsLabel = name + '[' + destinatariType + '] ';
		                				                        labels.push({label: name, type: destinatariType});
		                                				    }
	                                					});
	                            					}
	                        					}
												step.annexaFormly.fields[8].templateOptions.labels = labels;
												step.annexaFormly.model.seletctedThirds = selectedOptions;
					                    		this.close();
				                    		}
				                			AnnexaFormlyFactory.showModal("selectMultipleCheckbox", modal, submitFunction, false);
				                		}
										var step = {
											title: $filter('translate')('global.sec.literals.proposalDocuments')+': '+sn.relatedProposal.proposalNumber+' '+sn.relatedProposal.extract,
					                        key: 'docs'+sn.relatedProposal.id+'_'+index,
					                        disabled: false,
					                        disableNext: function(model, wizard) {
					                        	try{
						                    		var key = this.key;
						                    		var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == key); });
						    	                    if (indexNextStep != undefined && indexNextStep > -1 && WizardHandler.wizard('modal-wizard') && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
							                    		if(model && model.docTypeId && !model.origDocumentType){
							                    			if(model.modal_body_key && this.key && model.modal_body_key === this.key && !model.callToModifyCustomFields){
							                    				model.callToModifyCustomFields = true;
							                    				DccumentsFactory.modifyCustomFields(model.docTypeId, wizard.steps[indexNextStep].annexaFormly);
						                                    }
							                    		}
						    	                    }
						                    	}catch(e){
						                    		 console.log(e);
						                    	}
					                            var selected = false;
					                            if(wizard.steps[index].annexaFormly.form.$valid){
					                                selected = true;
					                            }
					                            return !selected;
											},
					                        annexaFormly: {
						 						fields: [
							                        {
						                                key: 'type',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.type',
						                                    value:doc.type[vm.languageColumn],
							                        		modal_body_key:'docs'+sn.relatedProposal.id+'_'+index
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                }
						                            },
	                        						{
						                                key: 'template',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.template',
						                                    value:doc.template[vm.languageColumn]
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                }
						                            },
	                        						{
						                                key: 'title',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.title',
						                                    value:doc.name
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                }
						                            },
													{
						                                key: 'section',
						                                type: 'annexaSelectRow',
						                                className: 'col-sm-12',
						                                data: {
						                                    informed:true,
						                                    row:true,
						                                    clear: function($event,model,key, $select) {
						                                        $event.stopPropagation();
						                                        model[key] = undefined;
						                                        if($select) {
						                                            $select.selected = undefined;
						                                            $select.search = undefined;
						                                        }
						                                    }
						                                },
						                                templateOptions: {
						                                    optionsAttr: 'bs-options',
						                                    ngOptions: 'option in to.options | filter: $select.search',
						                                    label: 'global.literals.section',
						                                    valueProp: 'id',
						                                    labelProp: vm.languageColumn,
						                                    placeholder: '',
						                                    options: ((sections && sections.length > 0)?new SortedArray(sections, vm.languageColumn).sort():[]),
						                                    required: true,
						                                    focus: false
						                                },
						                                hideExpression: function($viewValue, $modelValue, scope) {
						                                	if(sections && sections.length === 1){
																scope.model.section = sections[0];
															}
															return (sections && sections.length === 1);
						                                }
						                            },
													{
						                                key: 'profiles',
						                                type: 'annexaMultipleSelectRow',
						                                className: 'col-sm-12',
						                                data: {
						                                    informed:true,
						                                    row:true,
						                                    clear: function($event,model,key, $select) {
						                                        $event.stopPropagation();
						                                        model[key] = undefined;
						                                        if($select) {
						                                            $select.selected = undefined;
						                                            $select.search = undefined;
						                                        }
						                                    }
						                                },
						                                templateOptions: {
						                                    optionsAttr: 'bs-options',
						                                    ngOptions: 'option in to.options | filter: $select.search',
						                                    label: 'global.literals.profiles',
						                                    valueProp: 'id',
						                                    labelProp: vm.languageColumn,
						                                    placeholder: '',
						                                    options: ((profilesExp && profilesExp.length > 0)?new SortedArray(profilesExp, vm.languageColumn).sort():[]),
						                                    required: true,
						                                    focus: false
						                                },
						                                hideExpression: function($viewValue, $modelValue, scope) {
															if(profilesExp && profilesExp.length === 1){
																scope.model.profiles = [profilesExp[0].id];
															}
						                                	return (profilesExp && profilesExp.length === 1);
						                                }
						                            },
	                        						{
						                                key: 'archiveClassification',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.classificationBox',
						                                    value:doc.archiveClassification[vm.languageColumn]
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                }
						                            },
	                        						{
						                                key: 'thirdsName',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.receivers'
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                },
						                                hideExpression: function($viewValue, $modelValue, scope) {
						                                	return !hasThirds;
						                                }
						                            },
						                            { 
						                                key: 'receiversBtn',
						                                type: 'annexaLabelButton',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                	buttonLabel: 'global.literals.addReceiversBtn',
						                                	executeFunction: modalSelectMultReceivers,
															atributes:"btn-sm",
															type:"text",
															validate:true,
															required: false,
															keystepId: 'docs'+doc.id
						                                },
						                                data: { 
						                                    row: true,
									                        colClass: 'col-sm-12',
									                        labelClass: 'label-strong',
									                        extralabel: true,
															informed: true
									                    },
						                                hideExpression:function($viewValue, $modelValue, scope){
						                                   return !hasThirds;
						                                }
						                            },
						                            { 
						                                key: 'receivers',
						                                type: 'annexaLabelsRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    labels: []
						                                },
						                                data: { 
						                                    row: true,
									                        colClass: 'col-sm-12',
									                        labelClass: 'label-value',
									                        extralabel: true,
									                        type: 'HTMLOneModel'
									                    },
						                                hideExpression:function($viewValue, $modelValue, scope){
						                                   return !hasThirds;
						                                }
						                            },
					                                {
					                                    key: 'documentCustomFields',
					                                    className: 'col-sm-12',
					                                    templateOptions: {},
					                                    fieldGroup: [],
						                                data: { 
						                                    row: true,
									                        colClass: 'col-sm-12',
									                        labelClass: 'label-strong',
									                        extralabel: true,
															informed: true
									                    },
					                                    hideExpression: function ($viewValue, $modelValue, scope) {
					                                        if(scope.model && scope.model.documentCustomFieldsShow){
					                                            return false;
					                                        }else{
					                                            return true;
					                                        }
					                                    }
					                                }
												],
												model:{documentCustomFields: {},origDocumentType:undefined, modal_body_key: 'docs'+sn.relatedProposal.id+'_'+index, docTypeId:((doc && doc.type)?doc.type.id:undefined)}
											},
                							options:{},
											secNotification: sn,
											secNotificationDoc: doc,
											hasThirds: hasThirds
										};
										if(profilesExp && profilesExp.length > 0){
						                    if(defaultProfile) {
						                        if($linq(profilesExp).count("x => x.id == " + defaultProfile.id) > 0) {
						                            step.annexaFormly.model.profiles = [defaultProfile.id];
						                        }
						                    }
							            }
										if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sections && sections.length > 0
												&& sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.section
												&& $linq(sections).contains(sn.relatedProposal.notificationTransaction.dossier.section, "(x, y) => x.id == y.id")) {
											step.annexaFormly.model.section = sn.relatedProposal.notificationTransaction.dossier.section;
										}
										if(hasThirds && thirds && thirds.length > 0){
											var thsAux = angular.copy(thirds);
							                var selectedOptionsAux = [];
							                var labelsAux = [];
							                if(thsAux && thsAux.length > 0){
							                	_.forEach(thsAux, function(thAux){
							                		var selectedThird = false;
							                		if(sn.relatedProposal.proposalNotificationThirdConfiguration && sn.relatedProposal.proposalNotificationThirdConfiguration.length > 0) {
							                			_.forEach(sn.relatedProposal.proposalNotificationThirdConfiguration, function(pntc){
							                				if(pntc.dossierThird.id == thAux.id) {
							                					selectedThird = true;
							                				}
							                			});
							                		} else {
							                			selectedThird = true;
							                		}
							                		if(thAux.notifiable && selectedThird){
							                			selectedOptionsAux.push(thAux);
							                        	var name = ((thAux.third.name)?thAux.third.name+" ":"") + ((thAux.third.surname1Particles)?thAux.third.surname1Particles+" ":"") + ((thAux.third.surename1)?thAux.third.surename1+" ":"") + ((thAux.third.surname2Particles)?thAux.third.surname2Particles+" ":"") + ((thAux.third.surename2)? thAux.third.surename2+" " : "" ) + ((thAux.third.corporateName)?thAux.third.corporateName:"");
							                			var destinatariType = '';
							                            if(thAux.interested){
							                            	var roleInterested = '';
							                                if(thAux.roleInterested) {
							                                	roleInterested = '(' + thAux.roleInterested[Language.getActiveColumn()] + ')';
							                                }
							                                destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
							                            }else{
							                            	var representated = '';
							                                if(thAux.representated && thAux.representated.identificationDocument){
							                                    if(thAux.representated.name){
							                                        representated= '(' + thAux.representated.name + ((thAux.representated.surname1Particles)?' '+ thAux.representated.surname1Particles : '') + ' ' + thAux.representated.surename1 + ((thAux.representated.surname2Particles)?' '+ thAux.representated.surname2Particles : '') + ((thAux.representated.surename2)?' '+thAux.representated.surename2 : '') + ' (' + thAux.representated.identificationDocument + '))';
							                                    }else{
							                                        representated= '(' + thAux.representated.corporateName + ' (' + thAux.representated.identificationDocument + '))';
							                                    }
							                                }
							                                destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
							                            }
							                            labelsAux.push({label: name, type: destinatariType});
							                		}
							                	});
							                }
							                if(labelsAux && labelsAux.length > 0 && step && step.annexaFormly && step.annexaFormly.fields){
							                	var receiversField = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'receivers'");
							                	if(receiversField && receiversField.templateOptions){
							                		receiversField.templateOptions.labels = $linq(labelsAux).orderBy("x => x.label", linq.caseInsensitiveComparer).toArray();
							                	}
							                	step.annexaFormly.model.seletctedThirds = selectedOptionsAux;
							                } 
										}
										steps.push(step);
									});
								}
							});
							if(steps && steps.length > 0){
								var finishCreateDocuments = function(){
									var selfFinishCreateDocument = this;
									var fromTemplateRequests = {};
									if(this.wizard && this.wizard.steps){
										var noErrors = true;
										var calculateCFTGroups = function(newGroups){
					                    	var groups = [];
					                    	if(newGroups){
					                    		_.forEach(newGroups, function(ng){
					                    			_.forEach(ng.groups, function(g){
					                    				g.groups = calculateCFTGroups(g.newGroups); 
					                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
					                    				groups.push(g);
					                    			})
					                    		})
					                    	}
					                    	return groups;
					                    }
					                	_.forEach(this.wizard.steps, function(step){
											if(step && step.secNotification && step.secNotification.relatedProposal && step.secNotification.relatedProposal.id && step.secNotification.relatedProposal.notificationTransaction && step.secNotification.relatedProposal.notificationTransaction.dossier && step.secNotification.relatedProposal.notificationTransaction.dossier.id){
												if(!fromTemplateRequests[step.secNotification.relatedProposal.id]){
													fromTemplateRequests[step.secNotification.relatedProposal.id] = [];
												}
												if(step.hasThirds && step.annexaFormly && step.annexaFormly.model && (!step.annexaFormly.model.seletctedThirds || step.annexaFormly.model.seletctedThirds.length === 0)){
													modalCreateDocuments.alerts.push({msg: 'global.errors.noDestinataris'});
													noErrors = false;
												}else{
													var fromTemplateRequest = {};
													var thirdsSelected = [];
													_.forEach(step.annexaFormly.model.seletctedThirds, function(th){
														thirdsSelected.push({id:th.id, dossier:{id:((th.dossier && th.dossier.id && th.dossier.id !== step.secNotification.relatedProposal.notificationTransaction.dossier.id)?th.dossier.id:step.secNotification.relatedProposal.notificationTransaction.dossier.id)}, third:{id:th.third.id}});
													});	
													fromTemplateRequest.thirdsSelected = thirdsSelected;
													fromTemplateRequest.tramTemplate = ((step.secNotificationDoc && step.secNotificationDoc.template && step.secNotificationDoc.template.id)?step.secNotificationDoc.template.id:undefined);
													fromTemplateRequest.title = ((step.secNotificationDoc && step.secNotificationDoc.name)?step.secNotificationDoc.name:'');
													fromTemplateRequest.section = ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.section && step.annexaFormly.model.section.id)?step.annexaFormly.model.section.id:undefined);
													fromTemplateRequest.profiles = ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.profiles)?step.annexaFormly.model.profiles:[]);
													fromTemplateRequest.archiveClassification = ((step.secNotificationDoc && step.secNotificationDoc.archiveClassification && step.secNotificationDoc.archiveClassification.id)?{id:step.secNotificationDoc.archiveClassification.id}:undefined);
													fromTemplateRequest.type = ((step.secNotificationDoc && step.secNotificationDoc.type && step.secNotificationDoc.type.id)?{id:step.secNotificationDoc.type.id}:undefined);
													fromTemplateRequest.transaction = ((step.secNotification.relatedProposal.notificationTransaction)?{id:step.secNotification.relatedProposal.notificationTransaction.id, dossier:{id:step.secNotification.relatedProposal.notificationTransaction.dossier.id}}:undefined);
													fromTemplateRequest.user = (($rootScope.LoggedUser)?{id:$rootScope.LoggedUser.id}:undefined);
													fromTemplateRequest.langColumn = vm.languageColumn;
									                fromTemplateRequest.translations = {
									                    "third_relation_interested": $filter('translate')('global.literals.interested'),
									                    "third_relation_representative": $filter('translate')('global.literals.representative'),
									                    "yes": $filter('translate')('global.literals.yes'),
									                    "no": $filter('translate')('global.literals.no')
									                };
													var cf = CustomFieldFactory.getModelValues(step.annexaFormly.model.documentCustomFields);
													fromTemplateRequest.customFields = [];
													var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + fromTemplateRequest.type.id);
                        							if(documentType && cf && cf.length > 0) {
														_.forEach(documentType.customFields, function(docTypeField) {
															var groupField = undefined;
															if(step && step.annexaFormly && step.annexaFormly.fields){
																var docCFFields = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
																if(docCFFields && docCFFields.fieldGroup){
																	groupField = $linq(docCFFields.fieldGroup).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
																}
															}
							                            	var docCustomField = undefined;
							                            	if(groupField && groupField.data && groupField.data.customField){
							                            		docCustomField = angular.copy(groupField.data.customField);
							                            	}else{
							                            		docCustomField = angular.copy(docTypeField);	
							                            	}
															docCustomField.id = undefined;
															docCustomField.customField = { id: docTypeField.customField.id };
															docCustomField.documentType = { id: documentType.id };
															docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
															var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");
															if(cfValue) {
							                                    if(docTypeField.customField.frontendType == 'CHECKBOX'){
							                                        var custom_field_selected = $linq(cf).where(function(x){
							                                            if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
							                                        ).toArray();
							                                        if(custom_field_selected && custom_field_selected.length > 0){
							                                            docCustomField.value = '[';
							                                            _.forEach(custom_field_selected, function (item, key) {
							                                                if(item.value) {
							                                                    if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
							                                                        var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
							                                                            if(item.id.endsWith("_"+x.value)){return true}else{return false}}
							                                                        ).toArray();
							                                                        if(custom_field_value_selected && custom_field_value_selected.length > 0){
							                                                            docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
							                                                        }
							                                                    }
							                                                }
							                                            });
							                                            docCustomField.value += ']';
							                                        }
							                                    }else if(Array.isArray(cfValue.value)) {
							                                        if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
							                                            docCustomField.value = '[';
							
							                                            _.forEach(cfValue.value, function (item) {
							                                                docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
							                                            });
							
							                                            docCustomField.value += ']';
							                                        } else {
							                                            docCustomField.value = cfValue.value[0];
							                                        }
							                                    } else {
							                                        if(cfValue.value instanceof Date) {
							                                            docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
							                                        } else {
							                                            docCustomField.value = cfValue.value;
							                                        }
							                                    }
							                                }
							                                fromTemplateRequest.customFields.push(docCustomField);

														});
                        							}
												  	fromTemplateRequests[step.secNotification.relatedProposal.id].push(JSOG.encode(fromTemplateRequest));
												}
											}
										});
										if(noErrors){
											SecFactory.generateSecNotificationDocuments(-1, fromTemplateRequests).then(function(dataCall, status) {
												if(dataCall){
													SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okDocs', 'global.sec.literals.koDocs', true);
													selfFinishCreateDocument.close();
												} else {
													selfFinishCreateDocument.close();
													$state.reload();
												}
											}).catch(function(error) {
												DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
									        });
										}
									}
									
								}
								var modalCreateDocuments = angular.copy(globalModals.secNotificationCreateDocument);
								modalCreateDocuments.wizard.steps = steps;
								modalCreateDocuments.wizard.modal = modalCreateDocuments;
								AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalCreateDocuments, finishCreateDocuments, false, true);
							}
						}
						var propsWithSons = $linq(secNotOk).where("x => x.relatedProposal && x.relatedProposal.workWithDossierSons").select("x => x.relatedProposal.id").toArray();
						if(propsWithSons && propsWithSons.length > 0){
							SecFactory.getDossierThirdsAndSons(propsWithSons, undefined).then(function(dataThirdsAndSons, status) {
								var secNotOkAux = []; 
								_.forEach(secNotOk, function(sn){
									var snAux = angular.copy(sn);
									if(sn.relatedProposal && sn.relatedProposal.id && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && dataThirdsAndSons[sn.relatedProposal.id]){
										snAux.relatedProposal.notificationTransaction.dossier.thirds = dataThirdsAndSons[sn.relatedProposal.id];
									}
									secNotOkAux.push(snAux);
								});
								openModalDocumentWithThirds(secNotOkAux);
							}).catch(function(error) {
								openModalDocumentWithThirds(secNotOk);
					        });		
						}else{
							openModalDocumentWithThirds(secNotOk);
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("DOCUMENT", vm.proposal.id).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						openDocument(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			vm.sendToSignDocuments = function(){
				var sendToSignModal = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var documentsWithSNot = [];
						var selectedDocuments = [];
						_.forEach(secNotOk, function(sn){
							if(sn && sn.docs && sn.docs.length > 0 && sn.relatedProposal && sn.relatedProposal.id){
								if(sn.relatedProposal && sn.relatedProposal.workWithDossierSons && sn.relatedProposal.workWithDossierSons === true){
									if(sn.allDocuments && sn.allDocuments.length > 0){
										_.forEach(sn.allDocuments, function(doc){
											if(doc.generateFromSecNotification && doc.document && doc.document.id && _.contains(['NEW_AND_ATTACHMENT', 'COMPLETED'],doc.document.docStatus) && !$linq(selectedDocuments).firstOrDefault(undefined, "x => x.id == "+doc.document.id)){
												if(doc.proposalId && sn.relatedProposal.id == doc.proposalId) {
													documentsWithSNot.push({document:doc.document, sn: sn});
													selectedDocuments.push({ id: doc.document.id, value: doc.document.name+" - ("+sn.relatedProposal.proposalNumber+")", typeName:''});
												} else if(!doc.proposalId){
													documentsWithSNot.push({document:doc.document, sn: sn});
													selectedDocuments.push({ id: doc.document.id, value: doc.document.name+" - ("+sn.relatedProposal.proposalNumber+")", typeName:''});
												}
		                            		}
										});				
									}
								}else{
									if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.documents && sn.relatedProposal.notificationTransaction.documents.length > 0){
										_.forEach(sn.relatedProposal.notificationTransaction.documents, function(doc){
											if(doc.generateFromSecNotification && doc.document && doc.document.id && _.contains(['NEW_AND_ATTACHMENT', 'COMPLETED'],doc.document.docStatus) && !$linq(selectedDocuments).firstOrDefault(undefined, "x => x.id == "+doc.document.id)){
												if(doc.proposalId && sn.relatedProposal.id == doc.proposalId) {
													documentsWithSNot.push({document:doc.document, sn: sn});
													selectedDocuments.push({ id: doc.document.id, value: doc.document.name+" - ("+sn.relatedProposal.proposalNumber+")", typeName:''});
												} else if(!doc.proposalId){
													documentsWithSNot.push({document:doc.document, sn: sn});
													selectedDocuments.push({ id: doc.document.id, value: doc.document.name+" - ("+sn.relatedProposal.proposalNumber+")", typeName:''});
												}
		                            		}
										});				
									}
								}
							}
						});
						if(documentsWithSNot && documentsWithSNot.length > 0){
							var finishSendToSignDocuments = function(){
								var selfModalSendToSignDocuments = this;
								if(this.annexaFormly.model && this.annexaFormly.model.secNotificationsDocuments){
									var keys = Object.keys(this.annexaFormly.model.secNotificationsDocuments);
									if(keys && keys.length > 0){
										var mapSelected = {};
										var noPresetSelected = false;
										var added = false;
										_.forEach(keys, function(key){
											var doc = $linq(documentsWithSNot).firstOrDefault(undefined, "x => x.document && x.document.id == "+ Number(key));
											if(doc && doc.document && doc.document.id && doc.sn && doc.sn.relatedProposal && doc.sn.relatedProposal.id){
												if(mapSelected && !mapSelected[doc.sn.relatedProposal.id]){
													mapSelected[doc.sn.relatedProposal.id] = [];
												} 
												if(selfModalSendToSignDocuments.annexaFormly.model.secNotificationsDocuments[key] && documentsWithSNot){
													if(!noPresetSelected && selfModalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id] && selfModalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id].id){
														var snAux = angular.copy(doc.sn);
														snAux.relatedProposal = undefined;
														snAux.relatedProposal = {id:doc.sn.relatedProposal.id}
														var toSign = {
															secNotification: snAux,
															document: {id: doc.document.id},
															presetSignCircuit:{id:selfModalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id].id}
														};
														mapSelected[doc.sn.relatedProposal.id].push(toSign);
														added = true;
													}else{
														noPresetSelected = true;
														mapSelected = undefined
														added = false;
													}
												}
											}
										});
										if(noPresetSelected){
											DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotficationNoPresetSelect'));
										}else if(added){
											SecFactory.sendToSignSecNotificationDocuments(-1, mapSelected).then(function(dataCall, status) {
												if(dataCall){
													SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okSign', 'global.sec.literals.koSign', true);
													selfModalSendToSignDocuments.close();
												} else {
													selfModalSendToSignDocuments.close();
													$state.reload();
												}
											}).catch(function(error) {
												DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
									        });
										}else{
											DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotficationNoDocuments'));
										}
									}else{
										DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotficationNoDocuments'));
									}
								}else{
									DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotficationNoDocuments'));
								}
							}
							
							var modalSendToSignDocuments = angular.copy(globalModals.secNotificationSendToSign);
							modalSendToSignDocuments.annexaFormly.model = {secNotificationsDocuments:{}};
						    _.forEach(selectedDocuments, function (value2, key2) {
	                        	modalSendToSignDocuments.annexaFormly.model.secNotificationsDocuments[value2.id] = true;
	                        });
						    var secNotificationsDocumentsField = $linq(modalSendToSignDocuments.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'secNotificationsDocuments'");
				            if(secNotificationsDocumentsField && secNotificationsDocumentsField.templateOptions){
								secNotificationsDocumentsField.templateOptions.options = selectedDocuments;
							}
			                var promises = [];
			                var docTemplSignCircuit = [];
							_.forEach(documentsWithSNot, function(doc){
								var field = {
	                                key: 'presetSign'+doc.document.id,
	                                type: 'annexaSelectRow',
	                                className: 'col-sm-12',
	                                data: {
	                                    informed:true,
	                                    row:true,
	                                    clear: function($event,model,key, $select) {
	                                        $event.stopPropagation();
	                                        model[key] = undefined;
	                                        if($select) {
	                                            $select.selected = undefined;
	                                            $select.search = undefined;
	                                        }
	                                    }
	                                },
	                                templateOptions: {
	                                    optionsAttr: 'bs-options',
	                                    ngOptions: 'option in to.options | filter: $select.search',
	                                    label: $filter('translate')('global.literals.presetSignCircuits') +" - "+doc.document.name+" ("+doc.sn.relatedProposal.proposalNumber+")",
	                                    valueProp: 'id',
	                                    labelProp: vm.languageColumn,
	                                    placeholder: '',
	                                    options: [],
	                                    required: true,
	                                    focus: false
	                                },
	                                hideExpression: function($viewValue, $modelValue, scope) {
										return (!scope.model.secNotificationsDocuments[doc.document.id]);
	                                }
	                            }
								if(doc.document.tramTemplate && doc.document.tramTemplate.templateSignCircuit && doc.document.tramTemplate.templateSignCircuit.length > 0){
		                        	promises.push(RestService.findByUrl('./api/doc/templateSignCircuit/getTemplateSignCircuit/' + doc.document.id + '/tramTemplate'));
		                        	docTemplSignCircuit.push({docId: doc.document.id, templProp: 'tramTemplate'});
								}else{
									if(doc.document.type && doc.document.type.presetSignCircuits && doc.document.type.presetSignCircuits.length > 0){
										if(doc.document.type.presetSignCircuits.length > 1){
											field.templateOptions.options = doc.document.type.presetSignCircuits;
											modalSendToSignDocuments.annexaFormly.fields.push(field);
										}else{
											modalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id] = doc.document.type.presetSignCircuits[0];
										}	
									}
								}
							});
							
							$q.all(promises).then(function(data) {
			                	
			                	for(var i = 0; i < promises.length; i++) {
			                		var doc = $linq(documentsWithSNot).firstOrDefault(undefined, "x => x.document.id == " + docTemplSignCircuit[i].docId);
			                		if(doc) {
			                			var tsc = $linq(doc.document[docTemplSignCircuit[i].templProp].templateSignCircuit).firstOrDefault(undefined, "x => x.id == " + data[i].data.id);
			    	                    modalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id] = tsc.presetSignCircuit;
			                		}
			                    }

								AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalSendToSignDocuments, finishSendToSignDocuments, false);
		                    }).catch(function(error) {});
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("SIGN", vm.proposal.id).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						sendToSignModal(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			vm.createNotifications = function(){
				var createNotificationsModal = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var createNotificationsModalFinal = function(secNotOk){
							var validDocStatus = ["SIGNED","COMPLETE"];
			        		var validRelationType = ["DOCUMENT","WORK_DOCUMENT"];
				        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.notification_valid_document_status) {
				        		validDocStatus = $rootScope.app.configuration.notification_valid_document_status.status;
				        		validRelationType = $rootScope.app.configuration.notification_valid_document_status.relation_type;
				        	}
					    	var haveAllNotDocs = true;
							_.forEach(secNotOk, function (value2, key2) {
                            	if(value2.allDocuments && value2.allDocuments.length > 0){
									var totalNotDocs = 0;
									var docsOk = 0;
									_.forEach(value2.allDocuments, function(doc){
										if(doc.generateFromSecNotification){
											if(!doc.proposalId || (doc.proposalId && value2.relatedProposal.id == doc.proposalId)) {
												totalNotDocs++;
												if(_.contains(validDocStatus, doc.document.docStatus) && _.contains(validRelationType, doc.relationType) && doc.document.type && doc.document.type.includeNotification && !doc.document.physical) {
													docsOk++;
												}
											}
										}
									});
									haveAllNotDocs = totalNotDocs == docsOk;
								}
	                        });
							if(!haveAllNotDocs) {
								DialogsFactory.error($filter('translate')('global.sec.literals.secNotificationProposalWithoutDocs'));
							} else {
								var steps = [];
								_.forEach(secNotOk, function(sn, index){
					        		var documentsWithSNot = [];
									var selectedDocuments = [];
									var thirdsWithSNot = [];
									var selectedThirds = [];
									var thirdsEnabled = {};
									var dtWithoutThirds = [];
					            	var documentsIdSelected = {};
					            	var certDocuments = [];
									if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.thirds && sn.relatedProposal.notificationTransaction.dossier.thirds.length > 0){
										thirdsWithSNot = $linq(sn.relatedProposal.notificationTransaction.dossier.thirds).where("x => x.notifiable === true").toArray();
										if(thirdsWithSNot && thirdsWithSNot.length > 0){
											_.forEach(thirdsWithSNot, function(thwsn){
												selectedThirds.push(thwsn.third.id);
											});
										}
									}
									if(sn.allDocuments && sn.allDocuments.length > 0){
										_.forEach(sn.allDocuments, function(doc){
										if(_.contains(validDocStatus, doc.document.docStatus) && _.contains(validRelationType, doc.relationType) && doc.document.type && doc.document.type.includeNotification && !doc.document.physical){
												doc.document.dossierTransactionDocumentId = doc.id;
												if(doc.certificateFromThisProposal){
													doc.document.name = ((doc.document.name)?doc.document.name:'')+' ('+sn.relatedProposal.proposalNumber+')';
													certDocuments.push(doc);
												}
												documentsWithSNot.push(doc.document);
												if(!doc.dossierThird || !doc.dossierThird.third || !doc.dossierThird.third.id){
													dtWithoutThirds.push(doc);
												}
												if(doc.generateFromSecNotification){
													if(doc.proposalId && sn.relatedProposal.id == doc.proposalId) {
														selectedDocuments.push(doc);
													} else if(!doc.proposalId){
														selectedDocuments.push(doc);
													}
												}
											}
										});
									}
									if(thirdsWithSNot && thirdsWithSNot.length > 0 && sn.allDocuments){
										_.forEach(thirdsWithSNot, function(third){
											var docs = $linq(sn.allDocuments).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true && x.dossierThird && x.dossierThird.id == "+third.id).select("x => x.document.id").toArray();
											thirdsEnabled[third.id] = {enabled:true, docs:docs};
										});
									}
									if(sn.relatedProposal && sn.relatedProposal.proposalNotificationThirdConfiguration && sn.relatedProposal.proposalNotificationThirdConfiguration.length > 0) {
					            		_.forEach(sn.relatedProposal.proposalNotificationThirdConfiguration, function(pntc){
											documentsIdSelected[pntc.dossierThird.id] = pntc.proposalNotificationThirdDocumentConfiguration;
											if(certDocuments && certDocuments.length > 0) {
												_.forEach(certDocuments, function(cd){
													documentsIdSelected[pntc.dossierThird.id].push(cd);
												});
											}
										});
					            		_.forEach(thirdsWithSNot, function(third){
											third.selected = false;
											if(documentsIdSelected && documentsIdSelected[third.id]) {
                                                _.forEach(selectedDocuments, function(cd){
                                                	documentsIdSelected[third.id].push(cd);
                                                });
												third.selected = true;
											}
										});
					            	} else {
					            		_.forEach(thirdsWithSNot, function(third){
					            			documentsIdSelected[third.id] = selectedDocuments;
										});
					            	}
									var sections = [];
									if(HelperService.isSuperAdmin()) {
						            	sections = GlobalDataFactory.sections;
						            } else {
						            	sections = $linq($rootScope.LoggedUser.userSections).select("x => x.section").toArray();
						            }
									var showTramClassification = false;
						            var requiredTramClassification = false;
						            if($rootScope.app.configuration.tram_classification_register_output) {
						        		requiredTramClassification = $rootScope.app.configuration.tram_classification_register_output.required;
						        		showTramClassification = $rootScope.app.configuration.tram_classification_register_output.show;
						        	}
						            if(showTramClassification) {
						            	var defaultTramClassification = undefined;
						            	if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.tramClassification) {
						            		defaultTramClassification = sn.relatedProposal.notificationTransaction.dossier.tramClassification;
						            	}
						            }	
									var step = {
										title: $filter('translate')('global.literals.new_notification_title')+': '+sn.relatedProposal.proposalNumber+' '+sn.relatedProposal.extract,
										key: 'not'+sn.relatedProposal.id+'_'+index,
										disabled: false,
										disableNext: function(model, wizard) {
											var selected = false;
											if(wizard.steps[index].annexaFormly.form.$valid){
												selected = true;
											}
											return !selected;
										},
										annexaFormly: {
											fields: [
												{
					                                key: 'thirds',
					                                type: 'annexaComponent',
					                                templateOptions: {
					                                    type: 'annexa-select-thirds-documents',
					                                    first: true
					                                },
					                                data: {
					                                    title: 'global.literals.receivers',
								                        thirds: thirdsWithSNot,
														removeAddressesWithoutSelectedNotificationType: true,
														thirdsEnabled: thirdsEnabled,
														selectedThirds: selectedThirds,
								                        titleDocuments: 'global.publishSite.literals.attachedDocuments',
								                        documents: documentsWithSNot,
								                        documentsIdSelected: documentsIdSelected
					                                }            
												},
												{
					                                key: 'section',
					                                type: 'annexaSelectRow',
					                                className: 'col-sm-12',
					                                data: {
					                                    informed:true,
					                                    row:true,
					                                    clear: function($event,model,key, $select) {
					                                        $event.stopPropagation();
					                                        model[key] = undefined;
					                                        if($select) {
					                                            $select.selected = undefined;
					                                            $select.search = undefined;
					                                        }
					                                    }
					                                },
					                                templateOptions: {
					                                    optionsAttr: 'bs-options',
					                                    ngOptions: 'option in to.options | filter: $select.search',
					                                    label: 'global.literals.section',
					                                    valueProp: 'id',
					                                    labelProp: vm.languageColumn,
					                                    placeholder: '',
					                                    options: ((sections && sections.length > 0)?new SortedArray(sections, vm.languageColumn).sort():[]),
					                                    required: true,
					                                    focus: false
					                                },
					                                hideExpression: function($viewValue, $modelValue, scope) {
					                                	if(sections && sections.length === 1){
															scope.model.section = sections[0];
														}
														return (sections && sections.length === 1);
					                                }
					                            },
												{
					                                key: 'tramClassification',
					                                type: 'annexaSelectRow',
					                                className: 'col-sm-12',
					                                data: {
					                                    informed:true,
					                                    row:true,
					                                    clear: function($event,model,key, $select) {
					                                        $event.stopPropagation();
					                                        model[key] = undefined;
					                                        if($select) {
					                                            $select.selected = undefined;
					                                            $select.search = undefined;
					                                        }
					                                    }
					                                },
					                                templateOptions: {
					                                    optionsAttr: 'bs-options',
					                                    ngOptions: 'option in to.options | filter: $select.search',
					                                    label: 'global.literals.tramClassification',
					                                    valueProp: 'id',
					                                    labelProp: vm.languageColumn,
					                                    placeholder: '',
					                                    options: ((GlobalDataFactory.tramClassifications && GlobalDataFactory.tramClassifications.length > 0)?new SortedArray(GlobalDataFactory.tramClassifications, vm.languageColumn).sort():[]),
					                                    required: requiredTramClassification,
					                                    focus: false, 
														defaultValue: defaultTramClassification
					                                },
					                                hideExpression: function($viewValue, $modelValue, scope) {
														return !showTramClassification;
					                                }
												}
											],
											model:{}
										},
										options:{},
										secNotification: sn
									}
                                    if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sections && sections.length > 0
                                            && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.section
                                            && $linq(sections).contains(sn.relatedProposal.notificationTransaction.dossier.section, "(x, y) => x.id == y.id")) {
                                        step.annexaFormly.model.section = sn.relatedProposal.notificationTransaction.dossier.section;
                                    }
									steps.push(step);
								});
								if(steps && steps.length > 0){
									var finishCreateNotifications = function(){
										var selfFinishCreateNotifications = this;
										var sendNotifications = {};
										if(this.wizard && this.wizard.steps){
											var notsOk = true;
											_.forEach(this.wizard.steps, function(step){
												if(notsOk && step && step.secNotification && step.secNotification.relatedProposal && step.secNotification.relatedProposal.id && step.secNotification.relatedProposal.notificationTransaction && step.secNotification.relatedProposal.notificationTransaction.dossier && step.secNotification.relatedProposal.notificationTransaction.dossier.id){
													if(!sendNotifications[step.secNotification.relatedProposal.id]){
														sendNotifications[step.secNotification.relatedProposal.id] = [];
													}
													var thsToN = [];
													if(step.annexaFormly && step.annexaFormly.fields){
														var fieldThirds = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");
														if(fieldThirds) {
															var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();
										                    if(selectedThirds && selectedThirds.length > 0) {
																var addressesSelected = [];
																_.forEach(selectedThirds, function(item) {
										                            var selectedAddress = $linq(item.addressesOrig).firstOrDefault(undefined, "x => x.selected");
										                            if(selectedAddress) {
										                                addressesSelected.push({ address: selectedAddress, third: ((item.third)?item.third:{id:item.id}), dossierId:((item.dossier && item.dossier.id)?item.dossier.id:undefined)});
										                            }
										                        });
										                        if(addressesSelected.length == selectedThirds.length) {
																	thsToN = addressesSelected;
										                        }
															}
														}
													}
													if(!thsToN || thsToN.length === 0){
														notsOk = false;
														var fieldThirds = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");
														if(fieldThirds) {
															var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();
										                    if(selectedThirds && selectedThirds.length > 0) {
																selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.channelNoValidSendNotifications' });
															}else{
																selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.noDestinataris' });
															}
														}
													}
													var docsToN = [];
													if(step.annexaFormly && step.annexaFormly.fields){
								                        if(selectedThirds && selectedThirds.length > 0) {
								                            _.forEach(selectedThirds, function(thi){
								                                  var documentsSelected = $linq(thi.documentsToSelect).where("x => x.selected").toArray();
								                                  if(notsOk && (!documentsSelected || documentsSelected.length == 0)) {
								                                	  notsOk = false;
								                                	  selfFinishCreateNotifications.alerts.push({msg: 'global.publishSite.errors.noThirdDocumentSelected'});
								                                  } else {
								                                	  _.forEach(documentsSelected, function(docSelected){
								                                		  docsToN.push({
								                                			  documentId:docSelected.id,
								                                			  thirdId:thi.third.id,
								                                			  dossierTransactionDocumentId:docSelected.dossierTransactionDocumentId
								                                		  });
								                                	  });
								                                  }
								                              });
								                        }
													}
													if(notsOk && (!docsToN || docsToN.length === 0)){
														notsOk = false;
														selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.noDocuments' });
													}
													if(notsOk){
														thsToN = angular.copy(thsToN);
														var docsToNAux = [];
														_.forEach(docsToN,function(dtn){
															docsToNAux.push({
																document:{id:dtn.documentId}, 
																third:{id:dtn.thirdId},
																dossierTransactionDocumentId:dtn.dossierTransactionDocumentId
															});
														});
											            
														_.forEach(thsToN, function(item){
															var status = undefined;
									                        if(item && item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.statuses && item.address.selectedNotificationType.statuses.length > 0){
									                        	status = item.address.selectedNotificationType.statuses[0];
									                        }
									                        var valueAlertAddress = undefined;
									                        if(item.address.selectedNotificationType.selectedAlertAddress && item.address.selectedNotificationType.selectedAlertAddress.id != 0) {
									                        	var alertAddress = item.address.selectedNotificationType.selectedAlertAddress;
									                        	if(alertAddress && alertAddress.addressType && alertAddress.addressType == 'POSTAL' && alertAddress.address) {
									                        		valueAlertAddress = alertAddress.address[($rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value?'address':'shortAddress')+Language.getActiveColumn()];
									                        	} else if(alertAddress){
									                        		valueAlertAddress = (alertAddress.phoneInternationalPrefix?alertAddress.phoneInternationalPrefix:'') + alertAddress.telematicValue;
									                        	}
									                        }
									                    	delete item.address.selectedNotificationType.selectedAlertAddress;
									                    	delete item.address.selectedNotificationType.alertAddresses;
															var sendNotification = {
																secNotification:angular.copy(step.secNotification),
																documents:angular.copy(docsToNAux),
																thirds: [{
																	third: {id:item.third.id},
																	address: ((item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.addressType == 'OTHER')?undefined:{id:item.address.id}), 
	                            									statuses: [((status && status.id)?{createdDate: new Date(), status: {id:status.id}}:undefined)],
	                            									telematicNumber: valueAlertAddress,
																	dossierId: item.dossierId
																}],
																notificationType: ((item.address && item.address.selectedNotificationType)?{id:item.address.selectedNotificationType.id}:undefined),
																section: ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.section && step.annexaFormly.model.section.id)?{id:step.annexaFormly.model.section.id}:undefined),
																tramClassification: ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.tramClassification && step.annexaFormly.model.tramClassification.id)?{id:step.annexaFormly.model.tramClassification.id}:undefined)
															}
															if(sendNotification.secNotification){
																sendNotification.secNotification.allDocuments = undefined;
															}
														  	sendNotifications[step.secNotification.relatedProposal.id].push(JSOG.encode(sendNotification));
														});
													}
												}
											});
											if(notsOk){
												SecFactory.createNotificationsSecNotificationDocuments(-1, sendNotifications).then(function(dataCall, status) {
													if(dataCall){
														SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okNot', 'global.sec.literals.koNot', true);
														selfFinishCreateNotifications.close();
													} else {
														selfFinishCreateNotifications.close();
														$state.reload();
													}
												}).catch(function(error) {
													DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
										        });
											}
										}
									}
									var modalCreateNotifications = angular.copy(globalModals.secNotificationCreateNotifications);
									modalCreateNotifications.wizard.steps = steps;
									modalCreateNotifications.wizard.modal = modalCreateNotifications;
									AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalCreateNotifications, finishCreateNotifications, false, true);
								}
							}
						}
						var propsWithSons = $linq(secNotOk).where("x => x.relatedProposal && x.relatedProposal.workWithDossierSons").select("x => x.relatedProposal.id").toArray();
						if(propsWithSons && propsWithSons.length > 0){
							SecFactory.getDossierThirdsAndSons(propsWithSons, undefined).then(function(dataThirdsAndSons, status) {
								var secNotOkAux = []; 
								_.forEach(secNotOk, function(sn){
									var snAux = angular.copy(sn);
									if(sn.relatedProposal && sn.relatedProposal.id && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && dataThirdsAndSons[sn.relatedProposal.id]){
										snAux.relatedProposal.notificationTransaction.dossier.thirds = dataThirdsAndSons[sn.relatedProposal.id];
									}
									secNotOkAux.push(snAux);
								});
								createNotificationsModalFinal(secNotOkAux);
							}).catch(function(error) {
								createNotificationsModalFinal(secNotOk);
					        });		
						}else{
							createNotificationsModalFinal(secNotOk);
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("NOTIFICATION", vm.proposal.id).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						createNotificationsModal(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			vm.completeDossierTransactions = function(){
				var createDossierTransactions = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var secNotNotExistUser = $linq(secNotOk).where(function(x){
							if(x.transactions && $linq(x.transactions).firstOrDefault(undefined, "x => !x.existUser")){
								return true;
							}else{
								return false;
							}
						}).toArray();
						if(!secNotNotExistUser || (secNotNotExistUser && secNotNotExistUser.length == 0)){
							var message = $filter('translate')('global.sec.literals.secNotificationTransferClose')+'</br>';
							_.forEach(secNotOk, function(sn, index){
								message += '&nbsp;&nbsp;&nbsp;&nbsp;'+$filter('translate')('global.sec.literals.proposal')+': '+sn.relatedProposal.proposalNumber+'</br>';
							});
							DialogsFactory.confirm('global.sec.literals.secNotificationTransferCloseTitle', message)
	        	            .then(function (data) {
        						SecFactory.createNotificationsSecTransactions(-1, JSOG.encode(secNotOk)).then(function(dataCall, status) {
									if(dataCall){
										SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okTrans', 'global.sec.literals.koTrans', true);
									} else {
										$state.reload();
									}
								}).catch(function(error) {
									DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
								});
				           }).catch(function (data) {
	        		       });
						}else{
							var finishedSelectUsers = function(){
								var selfFinishedSelectUsers = this;
								_.forEach(secNotOk, function (value) {
									if(value.transactions && selfFinishedSelectUsers.annexaFormly && selfFinishedSelectUsers.annexaFormly.model){
										_.forEach(value.transactions, function (value2, index) {
											if(value.relatedProposal && value.relatedProposal.id && !value2.existUser){
												if(selfFinishedSelectUsers.annexaFormly.model && selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id] && selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id].id){
													value2.responsibleUser = {id:selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id].id};
												}
											}
										});
									}
								});
								SecFactory.createNotificationsSecTransactions(-1, JSOG.encode(secNotOk)).then(function(dataCall, status) {
									if(dataCall){
										SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okTrans', 'global.sec.literals.koTrans', true);
										selfFinishedSelectUsers.close();
									} else {
										selfFinishedSelectUsers.close();
										$state.reload();
									}
								}).catch(function(error) {
									DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
								});
				           }
							var modalSelectUsers = angular.copy(globalModals.secNotificationResponsibleUsers);
							modalSelectUsers.annexaFormly.model = {};
							_.forEach(secNotNotExistUser, function (value) {
								if(value.transactions){
									_.forEach(value.transactions, function (value2, index) {
										if(value.relatedProposal && value.relatedProposal.id && !value2.existUser){
				                        	modalSelectUsers.annexaFormly.model[index+"_Profile_"+value.profile.id] = value.profile.id;
											modalSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id] = undefined;
											var field = {
							                    key: index+"_User_"+value.relatedProposal.id,
							                    type: 'annexaSelectRow',
							                    className: 'col-sm-12',
							                    data: {
							                        informed:true,
							                        row:true,
							                        colClass: '',
							                        labelClass: 'label-strong',
							                        clear: function($event,model,key, $select) {
							                            $event.stopPropagation();
							                            model[key] = undefined;
							                            if($select) {
							                                $select.selected = undefined;
							                                $select.search = undefined;
							                            }
							                        }
							                    },
							                    templateOptions: {
							                        optionsAttr: 'bs-options',
							                        ngOptions: 'option in to.options | filter: $select.search',
							                        label: $filter('translate')('global.literals.responsibleUser')+" "+((value.relatedProposal && value.relatedProposal.proposalNumber)?value.relatedProposal.proposalNumber:'')+" - "+((value2.type && value2.type[vm.languageColumn])?value2.type[vm.languageColumn]:''),
							                        valueProp: 'id',
							                        labelProp: 'completeName',
							                        placeholder: '',
							                        options: [],
							                        required: true,
							                        focus: false
							                    },
							                    controller: ['$scope', '$filter', 'Language', 'GlobalDataFactory', 'SecFactory', function($scope, $filter, Language, GlobalDataFactory, SecFactory) {
				                                     $scope.to.options = [];
													 if($scope.model && $scope.model[index+"_Profile_"+value.profile.id]){
														var profile = $linq(GlobalDataFactory.allProfiles).firstOrDefault(undefined, "x => x.id == "+$scope.model[index+"_Profile_"+value.profile.id]);
														if(profile){
															SecFactory.getUserProfiles(profile.id).then(function(data){
																if(data && data.length > 0){
																	$scope.to.options = data;
																}
															}).catch(function(error){});
														}	
													 }
				                                }]
							                }
											modalSelectUsers.annexaFormly.fields.push(field);
										}
									});
								}
	                        });
							AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalSelectUsers, finishedSelectUsers, false);
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("CLOSE_TRANSFER", vm.proposal.id).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						createDossierTransactions(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.languageColumn;
				if(vm.dossierTransactionComponent.proposal && vm.dossierTransactionComponent.proposal.id){
					TramNewFactory.getProposal(vm.dossierTransactionComponent.proposal.id, vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.id).then(function(data){
						try {
							vm.proposal = JSOG.decode(data[0].data); 
							vm.proposalsDecree = JSOG.decode(data[1].data);
							vm.allDossierDocuments = JSOG.decode(data[2].data);
							vm.canAddedSecConfiguration = JSOG.decode(data[3].data);
							vm.docsNoOk = JSOG.decode(data[4].data);
							vm.isNotificableFromSecretary = JSOG.decode(data[6].data);
							vm.showSessionSecNotifications = JSOG.decode(data[7].data);
							if(vm.proposal){
								if(vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
			                		vm.dossierTransactionComponent.dossierTransaction.proposalSubtype = 'DECREE';
						    	}else{
			                		vm.dossierTransactionComponent.dossierTransaction.proposalSubtype = undefined;
			                	}
								if(vm.dossierTransactionComponent.proposal){
									vm.dossierTransactionComponent.proposal.organs = ((vm.proposal.organs)?angular.copy(vm.proposal.organs):[]);
								}
								vm.dossierTransactionComponent.calculateRequiredAndCannotCreateDocument(vm.proposal.proposalType, vm.proposal.delegationType, undefined);
								if(vm.proposal.proposalType && vm.dossierTransactionComponent.proposal){
									vm.dossierTransactionComponent.proposal.proposalType = angular.copy(vm.proposal.proposalType);
								}
								if(vm.proposal.delegationType && vm.dossierTransactionComponent.proposal){
									vm.dossierTransactionComponent.proposal.delegationType = angular.copy(vm.proposal.delegationType);
								}
								if(vm.proposal.procedureProposalType && vm.dossierTransactionComponent.proposal){
									vm.dossierTransactionComponent.proposal.procedureProposalType = angular.copy(vm.proposal.procedureProposalType);
								}
							}
							
							var editablesProperties =  JSOG.decode(data[5].data);
							if(vm.proposal && vm.proposal.procedureProposalType && vm.proposal.procedureProposalType.enabledType){
								vm.isProposalTypeEditable = true;
								vm.isOrgansAgreementEditable = true;
								vm.isProposalTitleEditable = true;
								vm.isNotificatorProfileEditable = true;
								vm.isAmmendmentTransactionTypeEditable = true;
								vm.isTransferTransactionTypeEditable = true;
								vm.isCancelTransactionTypeEditable = true;
								vm.isOrgansOpinionEditable = true;
								vm.isFooterClaimsEditable = true;
								vm.isInternalProfilesEditable = true;
								vm.isSecNotificationEditable = true;
							}else{
								vm.isProposalTypeEditable = editablesProperties.proposalTypeEditable;
								vm.isOrgansAgreementEditable = editablesProperties.organsAgreementEditable;
								vm.isProposalTitleEditable = editablesProperties.proposalTitleEditable;
								vm.isNotificatorProfileEditable = editablesProperties.notificatorProfileEditable;
								vm.isAmmendmentTransactionTypeEditable = editablesProperties.ammendmentTransactionTypeEditable;
								vm.isTransferTransactionTypeEditable = editablesProperties.transferTransactionTypeEditable;
								vm.isCancelTransactionTypeEditable = editablesProperties.cancelTransactionTypeEditable;
								vm.isOrgansOpinionEditable = editablesProperties.organsOpinionEditable;
								vm.isFooterClaimsEditable = editablesProperties.footerClaimsEditable;
								vm.isInternalProfilesEditable = editablesProperties.internalProfilesEditable;
								vm.isSecNotificationEditable = editablesProperties.secNotificationEditable;
							}
							if(editablesProperties.validProcedureProposalTypes && editablesProperties.validProcedureProposalTypes.length > 0){
								vm.validProcedureProposalTypes = editablesProperties.validProcedureProposalTypes;
								vm.validProcedureProposalTypes.unshift({id:-1, language1:$filter('translate')('global.literals.empty-editable'), language2:$filter('translate')('global.literals.empty-editable'), language3:$filter('translate')('global.literals.empty-editable')});
							}
							if(vm.dossierTransactionComponent.isActiveCouncillor){
								if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor.id){
									var councillor = $linq(GlobalDataFactory.councillors).firstOrDefault(undefined, "x => x.id == "+vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor.id);
									if(councillor && councillor.councillorProposalTypes){
										_.forEach(councillor.councillorProposalTypes, function(pt){
											if(pt && pt.proposalType && pt.proposalType.id){
												var ptAux = $linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined, "x => x.id =="+pt.proposalType.id);
												if(ptAux){
													vm.proposalTypes.push(angular.copy(ptAux));
												}
											}
										});
									}
									if(vm.proposalTypes && vm.proposalTypes.length == 0){
										DialogsFactory.notify($filter('translate')('global.literals.selectCouncillorWithoutProposalTypes'));
									}
								}else{
									vm.proposalTypes = [];
									DialogsFactory.notify($filter('translate')('global.literals.selectCouncillorInDossier'));
								}
							}else{
								vm.proposalTypes = 	GlobalDataFactory.proposalTypes;
							}
							vm.allFooterClaims = angular.copy(GlobalDataFactory.footerClaims);
							vm.operationsActive = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.operationsActiveInDossier;
							vm.isUpdate = ((vm.proposal && vm.proposal.state && _.contains(['DRAFT'], vm.proposal.state) && vm.dossierTransactionComponent.dossierTransaction 
								&& vm.dossierTransactionComponent.dossierTransaction.actual && vm.dossierTransactionComponent.dossierTransaction.transactionType &&
								 (vm.dossierTransactionComponent.dossierTransaction.transactionType.transactionSubtype == 1 || vm.dossierTransactionComponent.dossierTransaction.transactionType.transactionSubtype == 4)) ? true : false);
			                
							if(vm.proposal && vm.proposal.id && vm.proposalsDecree && vm.proposalsDecree.length > 0){
								vm.decree = $linq(vm.proposalsDecree).firstOrDefault(undefined, "x => x.proposal && x.proposal.id == "+vm.proposal.id); 
								if(vm.decree){
									vm.decreeDateSelected = ((vm.decree.decreeDate)?$filter('date')(new Date(vm.decree.decreeDate), 'dd/MM/yyyy HH:mm:ss'):'');
									vm.decreeDelegatedTypeSelected = ((vm.decree.decreeDelegatedType && vm.decree.decreeDelegatedType[vm.languageColumn])?vm.decree.decreeDelegatedType[vm.languageColumn]:''); 
								}
							}
							var notificatorProfilesTransaction = [];
	            			if(vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.processProfiles){
			            		_.forEach(vm.dossierTransactionComponent.dossierTransaction.processProfiles, function (profile) {
			        				if(vm.dossierTransactionComponent.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionComponent.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
			        					var dtpp = $linq(vm.dossierTransactionComponent.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
			        					if(dtpp){
			        						notificatorProfilesTransaction.push(dtpp);
			        					}
			        				}
			        			});  
	                		}
							var secretary = GlobalDataFactory.secretaryProfile;
			            	if(!secretary){
			            		secretary = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile);
			            	}
							if(notificatorProfilesTransaction) {
			            		_.forEach(notificatorProfilesTransaction, function(notificatorProfileTransaction){
			            			if(notificatorProfileTransaction && secretary 
			            					&& notificatorProfileTransaction.id != secretary.id){
			            				vm.notificatorProfiles.push(notificatorProfileTransaction);
			                        }
			                    });
			            		if(secretary){
			            			vm.notificatorProfiles.push(secretary);
			            		}
			            	}
							vm.secretaryProfile = secretary;
							if(vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.processProfiles){
			            		_.forEach(vm.dossierTransactionComponent.dossierTransaction.processProfiles, function (profile) {
			        				if(vm.dossierTransactionComponent.dossierTransactionProcessProfiles && $linq(vm.dossierTransactionComponent.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
			        					var dtpp = $linq(vm.dossierTransactionComponent.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
			        					if(dtpp){
			        						vm.dtProfiles.push(dtpp);
			        					}
			        				}
			        			});
			                }
			            	if(vm.proposal && vm.proposal.footerClaims){
			            		vm.footerClaimsAux = $linq(vm.proposal.footerClaims).select("x => x.footerClaim").toArray();
			            	}
							if (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTramitationType && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTramitationType.id) {
								var selectableTransactionTypes = [];
		                        _.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
		                            if($linq(value.tramitationTypes).contains(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTramitationType.id, "(x, y) => x.tramitationType.id == y")) {
		                            	selectableTransactionTypes.push(value);
		                            }
		                        });
								
								var selectTransferTransactionTypes = "x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[3].id;
			                    vm.transferTransactionTypes = $linq(selectableTransactionTypes).where(selectTransferTransactionTypes).toArray();
			                    
			                    var selectAmendmentTransactionTypes = "x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[4].id;
			                    vm.amendmentTransactionTypes = $linq(selectableTransactionTypes).where(selectAmendmentTransactionTypes).toArray();
			                    
			                    var selectCancelTransactionTypes = "x => (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + ")";
			                    vm.cancelTransactionTypes = $linq(selectableTransactionTypes).where(selectCancelTransactionTypes).toArray();
			                    
			                    
			                    if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure 
			                            && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes 
			                            && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes.length > 0 && vm.cancelTransactionTypes && vm.cancelTransactionTypes.length > 0){
			                    	vm.cancelTransactionTypes = $linq(vm.cancelTransactionTypes).intersect(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.procedure.createTransactionTypes, function(x,y){
			                            if(y.id && x.transactionType && x.transactionType.id) {
			                                return y.id == x.transactionType.id;
			                            } else {
			                                return false;
			                            }
			                        }).toArray();
			                    }
			                    
			                }
							if (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.esetMode && vm.proposal && vm.proposal.notificatorProfile && vm.proposal.notificatorProfile && vm.proposal.notificatorProfile.userProfiles) {
								vm.getNotificatorResponsibles();
			                }	
							vm.proposalDocumentList();
							if(vm.proposal && vm.proposal.documents && vm.proposal.documents.length > 0){
								if(vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
	                    			vm.propDocument = $linq(vm.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPD'").document;
	                    		}else{
	                    			vm.propDocument = $linq(vm.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPR'").document;
	                    		}
			            	}
							vm.organsBox = {
			                    content: ((vm.proposal && vm.proposal.organs) ? vm.proposal.organs : []),
			                    permissions: {},
			                    new: {},
			                    config: {proposal:vm.proposal, councillorId:((vm.dossierTransactionComponent.isActiveCouncillor && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor.id)?vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierCouncillor.id:undefined)},
			                    origin: 'transactionProposal-' + vm.dossierTransactionComponent.dossierTransaction.id
			                }
							if(vm.proposal && vm.proposal.organs && vm.proposal.organs.length > 0) {
								var executed = $linq(vm.proposal.organs).firstOrDefault(undefined, "x => x.organ.resolutionType == 'AGREEMENT' && x.session && x.session.state && x.session.state == 'EXECUTED'");
								if(executed) {
									vm.showTransferButtons = true;
									vm.proposalId = vm.proposal.id;
								}
							} else if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype 
									&& vm.proposal.proposalType.proposalTypeSubtype == 'DECREE') {
								vm.showTransferButtons = true;
								vm.proposalId = vm.proposal.id;
							}
			                $scope.$on('annexaBoxOrgansAddOpinionOrgan', function (event, args) {
			                    if(args.origin && args.origin == ('transactionProposal-' + vm.dossierTransactionComponent.dossierTransaction.id) && vm.isUpdate) {
			                    	if(args.organ && args.organ && args.modal){
			                    		SecFactory.addProposalOrgan(vm.proposal.id, args.organ, args.delegationType).then(function(data) {
			    	                        if(data) {
			    	                        	 vm.organsBox.content.push(data);
												 vm.dossierTransactionComponent.calculateRequiredAndCannotCreateDocument(undefined, undefined, data);
			    	                        }
			    	                        args.modal.close();
			    	                    }).catch(function(error) {
			    	                    	args.modal.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'addOrgan', error)});
			    	                    })
			    	                }else{
			    	            		DialogsFactory.error('global.sec.literals.error_required_files_organ');
			    	            	}
			                    }
			                });
			        		$scope.$on('annexaBoxOrgansModifyOpinionOrgan', function (event, args) {
			                    if(args.origin && args.origin == ('transactionProposal-' + vm.dossierTransactionComponent.dossierTransaction.id) && vm.isUpdate) {
			                    	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
			    	                	SecFactory.updateProposalOrgan(vm.proposal.id, args.organ.id, args.organ.organ.id, ((args.organ.delegationType && args.organ.delegationType.id)?args.organ.delegationType.id:undefined)).then(function(data) {
			    	                		if(data) {
			    		                		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + data.id);
			    		                        if(index != -1) {
			    		                            vm.organsBox.content[index] = data;
													vm.dossierTransactionComponent.calculateRequiredAndCannotCreateDocument(undefined, undefined, data);
			    		                        }
							        		}
											if(args.modal){
												args.modal.close();
											}
			    	                    }).catch(function(error) {
			    	                    	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
			    	                    })
			                    	}else{
			                    		DialogsFactory.error('global.sec.literals.error_required_files_organ');
			                    	}
			                    }
			                });
			        		$scope.$on('annexaBoxOrgansModifyAgreementOrgan', function (event, args) {
			                    if(args.origin && args.origin == ('transactionProposal-' + vm.dossierTransactionComponent.dossierTransaction.id) && vm.isUpdate) {
			                    	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
			    	                	SecFactory.updateProposalOrgan(vm.proposal.id, args.organ.id, args.organ.organ.id, ((args.organ.delegationType && args.organ.delegationType.id)?args.organ.delegationType.id:undefined)).then(function(data) {
			    		                	if(data){
			    		                		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + data.id);
			    			                    if(index != -1) {
			    			                        vm.organsBox.content[index] = data;
													vm.dossierTransactionComponent.calculateRequiredAndCannotCreateDocument(undefined, undefined, data);
													if(args.modifyLocalOrgan){
														args.modifyLocalOrgan(data);	
													}
			    			                    }
			    		                	}
											if(args.modal){
												args.modal.close();
											}
			    	                    }).catch(function(error) {
			    	                    	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
			    	                    })
			                    	} else if (args.organ && args.organ.organ && args.organ.organ.id){
			                    		SecFactory.addProposalOrgan(vm.proposal.id, args.organ.organ.id, ((args.organ.delegationType && args.organ.delegationType.id)?args.organ.delegationType.id:undefined)).then(function(data) {
			    	                        if(data) {
			    	                        	vm.organsBox.content.push(data);
												vm.dossierTransactionComponent.calculateRequiredAndCannotCreateDocument(undefined, undefined, data);
												if(args.modifyLocalOrgan){
													args.modifyLocalOrgan(data);	
												}
			    	                        }
			    	                        if(args.modal){
												args.modal.close();
											}
			    	                    }).catch(function(error) {
			    	                    	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg: ErrorFactory.getErrorMessage('sec', 'addOrgan', error)});
			    	                    })                    		
			                    	}else{
			                    		DialogsFactory.error('global.sec.literals.error_required_files_organ');
			                    	}
			                    }
			                });
			        		$scope.$on('annexaBoxOrgansDelete', function (event, args) {
			                    if(args.origin && args.origin == ('transactionProposal-' + vm.dossierTransactionComponent.dossierTransaction.id) && vm.isUpdate) {
			                    	if(args.removedIds && args.removedIds.length > 0){
			    	                	SecFactory.deleteProposalOrgan(vm.proposal.id, args.removedIds).then(function(data) {
			    	                        if(data) {
			    	                        	_.forEach(args.removedIds, function(id){
			    	                        		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + id);
			    	                                if(index != -1) {
			    	                                    vm.organsBox.content.splice(index,1);
			    	                                }
			    	                        	});
												vm.dossierTransactionComponent.calculateRequiredAndCannotCreateDocument(undefined, undefined, undefined, args.removedIds);
			    	                        	
			    	                        }
			    	                    }).catch(function(error) {
			    	                    	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg: ErrorFactory.getErrorMessage('sec', 'deleteOrgan', error)});
			    	                    })
			                    	}else{
			                    		DialogsFactory.error('global.sec.literals.error_required_files_removedIds');
			                    	}
			                    }
			                });
			                vm.internalCommunicationBox = {
			            		content: vm.proposal,
			                    permissions: {},
			                    new: {},
			                    config: {},
			                    origin: 'transactionProposal-' + vm.dossierTransactionComponent.dossierTransaction.id
			                }
			                $scope.$on('annexaBoxInternalCommunicationAdd', function (event, args) {
			                	if(args.origin && args.origin == ('transactionProposal-' + vm.dossierTransactionComponent.dossierTransaction.id)) {
			                		vm.updateProposal(args.val, args.prop);
			                	}
			                });
			        		var proposalOperationsAndInvoices = [];
							if(vm.proposal && vm.proposal.operations && vm.proposal.operations.length > 0){
								proposalOperationsAndInvoices = $linq(vm.proposal.operations).select("x=>x.operation").toArray();
							}
				 			vm.proposalOperationsAndInvoicesBox = {
				            	boxTitle: 'global.operationsAndInvoices.title',
				                permissions: ((vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.userAccountInstances && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.userAccountInstances.length > 0 && vm.isUpdate)?{new:'process_dossier'}:{}),
				                content:  proposalOperationsAndInvoices,
				                config: { proposal: vm.proposal },
								onlyAddOperations: true,
								canRemoveOperations: (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.userAccountInstances && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.userAccountInstances.length > 0 && vm.isUpdate),
								newTitle: 'global.literals.add',
				                origin: 'tram'
				            };
							vm.isLoaded = true;
		                } catch (error) {
							vm.isLoaded = true;
		        		}
					}).catch(function(error){
						vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
		        		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.errorGetProposal')});
						vm.isLoaded = true;
					});
				}else{
					vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	        		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.errorGetProposal')});
					vm.isLoaded = true;
				}
			}
        }],
        bindings: {
		}
    })
	.component('annexaBoxObjectDossierTransactionDocuments',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction-documents.html',
        require: {
            dossierTransactionComponent: '^^annexaBoxObjectDossierTransaction'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'DccumentsFactory', 'TramNewFactory', 'apiAdmin', 'AnnexaPermissionsFactory', 'AnnexaEntityFactory', 'globalModals', 'AnnexaFormlyFactory', 'RestService', '$scope','ErrorFactory', 'DialogsFactory', 'SecFactory', 'NotificationFactory', '$state', 'AnnexaDocumentActionsButtonFactory',
		function($rootScope, $filter, Language, GlobalDataFactory, DccumentsFactory, TramNewFactory, apiAdmin, AnnexaPermissionsFactory, AnnexaEntityFactory, globalModals, AnnexaFormlyFactory, RestService, $scope, ErrorFactory, DialogsFactory, SecFactory, NotificationFactory, $state, AnnexaDocumentActionsButtonFactory) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.isArchivedDossier = false;
			vm.isUpdate = false;
			vm.isLoaded = false;
			vm.legend = undefined;
			vm.tableDefinition = undefined;
			vm.counters = {
				docs: 0,
				work: 0,
				internal: 0,
				paperBin: 0
			}
			vm.labels = {
				docs: '',
				work: '',
				internal: '',
				paperBin: '',
				documentTransactionActiveTab: 'DOCUMENT'
			}
			vm.archiveClassification = undefined;
			
			vm.documentTransactionTabSelected = function(tab){
				if(tab != "DOCUMENT" &&  tab != "WORK_DOCUMENT" && tab != "INTERNAL_DOCUMENT" && tab != "PAPER_BIN"){
					tab = "DOCUMENT"
				}
				vm.generateTableData(tab);
				vm.labels.documentTransactionActiveTab = tab;
			}
			vm.openDocument = function(documentId){
                var windowReference = window.open();
                DccumentsFactory.getDocContent(documentId).then(function (data) {
                    windowReference.location = data;
                }).catch(function (error) {
                    windowReference.close();
                });
            }
            vm.authCopyDocument = function(documentId){
                var windowReference = window.open();
                DccumentsFactory.getAuthCopyContent(documentId)
                .then(function(data) {
                    windowReference.location = data;
                }).catch(function (error) {
                    windowReference.close();
                });
           }
			vm.addDocumentSave = function(newDoc){
				if(newDoc && newDoc.length > 0){
					var newDocAux = angular.copy(newDoc);
					_.forEach(newDocAux, function(doc){
						if(doc.document && doc.document.id){
							doc.document = {id: doc.document.id};	
						}
					});
					var dossierTransactionId = ((newDoc[0].dossierTransaction)? newDoc[0].dossierTransaction.id : vm.dossierTransactionComponent.dossierTransaction.id);
					TramNewFactory.addDocs(dossierTransactionId, newDoc).then(function (data) {
						if(vm.labels.documentTransactionActiveTab == "DOCUMENT" && vm.tableDefinition){
							vm.tableDefinition.reloadInternalData(true, true);	
						}
						vm.calculateCounters();
                    }).catch(function (error) {
                    	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
        				if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
	                     	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.tram.errors.inRestrictiveTransaction')});
                        }else{
							vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
						}
                    });
                }
			}
			
			var docStatusRender = function(data, type, full, meta) {
	            var content = '<div class="text-center">';
	            if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
	                if(full.document && full.document.publicDocument){
						content += '<span><i title="' + $filter('translate')('global.documentState.public') + '" class="m-l-xs m-r-lg fa fa-check-square-o"></i></span>';
					}else{
						content += '<span><i title="' + $filter('translate')('global.documentState.no_public') + '" class="m-l-xs m-r-lg text-grey fa fa-square-o"></i></span>';
					}
					var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");
	                if(status) {
	                    content += '    <span class="fa-stack" title=" ' + $filter('translate')('global.documentState.' + status.name) + '">';
	                    content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
	                    content += '    </span>';
	                }
	            }
	            content += '</div>';
	            return content;
	        }

			var actionsTitle = function () {
                return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
            }

            var actionsRender = function (data, type, full, meta) {
                return '<annexa-document-actions-button module="EXPED" dossier-transaction-document="tableObjects[' + full.id + ']" transaction-component="$ctrl.dossierTransactionComponent" list="true" object-to-call="$ctrl.archiveClassification"></annexa-document-actions-button>';
            }

			vm.generateTableData = function(tab){
				if(!vm.tableDefinition){
					vm.tableDefinition = {};
				}
				var columns = [
					{ id: 'document.docStatus', width: '53px', title: $filter('translate')('global.literals.state'), render: docStatusRender},
					{ id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.document.id; if(full.document.code) { code = full.document.code.substr((full.document.code.indexOf(full.document.id) - 2), full.document.id.toString().length + 2); } code;', true,undefined,(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.showArchivedDocument)?'$ctrl.openDocument([data])':undefined)) },
                    { id: 'document.name', 		  column: new UIRouterStateColumnDataLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'full.document.id', true,(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.showArchivedDocument)?'$ctrl.openDocument([data])':undefined), '$ctrl.openDocument([data])', '$ctrl.authCopyDocument([data])')},
                    { id: 'document.signProcesses', width: '140px', column: new LastSignColumn($filter, 'global.literals.lastSignDate'), sortable: false },
                    { id: 'actions', columnName: 'document.id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
		        ];
				var tableOrderProperties = {sortName: 'document.name', sort: [[2,'desc']]};
		        vm.tableDefinition.id = 'tableDTDocuments';
				vm.tableDefinition.origin = 'tram';
		        vm.tableDefinition.objectType = 'DossierTransactionDocument';
				vm.tableDefinition.objectIdToSelectOrigin ='tram';
				vm.tableDefinition.objectIdToSelectType = 'DossierTransactionDocument';
		        vm.tableDefinition.sortName = tableOrderProperties.sortName;
		        vm.tableDefinition.sort = tableOrderProperties.sort;
				vm.tableDefinition.columns = columns;
		        vm.tableDefinition.containerScope = $scope;
				vm.tableDefinition.useTableObjects = true;
				vm.tableDefinition.multipleActions = (($state.current.name == 'annexa.tram.pending.viewdossier' || $state.current.name == 'annexa.tram.responsable.viewdossier' || ($state.current.name == 'annexa.tram.view.viewdossier' && TramNewFactory && TramNewFactory.Dossier && TramNewFactory.Dossier.dossierStatus == 'FINISHED')) ? true : false);
				vm.tableDefinition.functionMultipleActionsColumnShow = function(item) {
                    if (item && item.document) {
                        var havePermission = false;
                        if(item.dossierTransaction){
                            _.forEach(item.dossierTransaction.processProfiles, function (itemProf) {
                                if(!havePermission){
                                    havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', itemProf.profile)
                                }
                            })
                            if(!havePermission && TramNewFactory.Dossier && TramNewFactory.Dossier.dossierTransactions){
                                _.forEach(TramNewFactory.Dossier.dossierTransactions, function (dt) {
                                    _.forEach(dt.processProfiles, function (dtItem) {
                                        if (!havePermission) {
                                            havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', dtItem.profile);
                                        }
                                    });
                                });
                            }
                        }
                        if(!havePermission && item.document){
                            if(item.document.globalProcessProfileIds){
                                var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile").toArray();
                                var intersect = $linq(userProfiles).intersect(item.document.globalProcessProfileIds, "(x,y) => x == y.id").toArray();
                                _.forEach(intersect, function (dtItem) {
                                    if (!havePermission) {
                                        havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', dtItem);
                                    }
                                });
                            }
                            if(!havePermission) {
                                havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',item.document.profiles);
                            }
                        }
                        return havePermission;
                    }
                };
                vm.tableDefinition.functionMultipleActionsReload = function(items) {
                    var options = [];
                    var optionItems = [];

                    _.forEach(items, function(item) {
                        if(item.selected) {
                            var evaluatedOptions = AnnexaDocumentActionsButtonFactory.getShowOptions(item.document, item, item.dossierTransactionIsActual, true, undefined, 'EXPED');

                            if (options.length == 0) {
                                options = evaluatedOptions;
                            } else {
                                options = $linq(options).intersect(evaluatedOptions, "(x, y) => x == y").toArray();
                            }

                            optionItems.push(item);
                        }
                    });

                    if(options.length != 0) {
                        vm.tableDefinition.multipleActionsFunction(AnnexaDocumentActionsButtonFactory.getMultipleActionsButton(options, optionItems, 'EXPED', undefined, TramNewFactory.Dossier));
                    } else {
                        vm.tableDefinition.multipleActionsFunction();
                    }

                };
				vm.tableDefinition.addToAdditionalFilter = {"documentBoxNormalSearch": true}
				if(tab == "DOCUMENT"){
            		vm.labels.docs = 'success';
            		vm.labels.work = '';
            		vm.labels.internal = '';
            		vm.labels.paperBin = '';
					vm.tableDefinition.objectIdToSelect = angular.fromJson({dossierTransaction:{id:vm.dossierTransactionComponent.dossierTransaction.id}, paperBin: false, relationType:"DOCUMENT"});
				}else if(tab == "WORK_DOCUMENT"){
            		vm.labels.docs = '';
            		vm.labels.work = 'success';
            		vm.labels.internal = '';
            		vm.labels.paperBin = '';
					vm.tableDefinition.objectIdToSelect = angular.fromJson({dossierTransaction:{id:vm.dossierTransactionComponent.dossierTransaction.id}, paperBin: false, relationType:"WORK_DOCUMENT"});
				}else if(tab == "INTERNAL_DOCUMENT"){
            		vm.labels.docs = '';
            		vm.labels.work = '';
            		vm.labels.internal = 'success';
            		vm.labels.paperBin = '';
					vm.tableDefinition.objectIdToSelect = angular.fromJson({dossierTransaction:{id:vm.dossierTransactionComponent.dossierTransaction.id}, paperBin: false, relationType:"INTERNAL_DOCUMENT"});
				}else if(tab == "PAPER_BIN"){
	        		vm.labels.docs = '';
	        		vm.labels.work = '';
	        		vm.labels.internal = '';
	        		vm.labels.paperBin = 'success';
					vm.tableDefinition.objectIdToSelect = angular.fromJson({dossierTransaction:{id:vm.dossierTransactionComponent.dossierTransaction.id}, paperBin: true});
				}else{
            		vm.labels.docs = 'success';
            		vm.labels.work = '';
            		vm.labels.internal = '';
            		vm.labels.paperBin = '';
					vm.tableDefinition.objectIdToSelect = angular.fromJson({dossierTransaction:{id:vm.dossierTransactionComponent.dossierTransaction.id}, paperBin: false, relationType:"DOCUMENT"});
				}
				vm.tableDefinition.objectIdToAdded = vm.dossierTransactionComponent.dossierTransaction.id;
				vm.calculateCounters();
			}
			
			vm.calculateCounters = function(){
				TramNewFactory.calculateDossierTransactionDocumentsCounters(vm.dossierTransactionComponent.dossierTransaction.id).then(function(data){
					var counters = JSOG.decode(data);
					if(counters){
						if(counters.DOCUMENT){
							vm.counters.docs = counters.DOCUMENT;
						}else{
							vm.counters.docs = 0;
						}
						if(counters.WORK_DOCUMENT){
							vm.counters.work = counters.WORK_DOCUMENT;
						}else{
							vm.counters.work = 0;
						}
						if(counters.INTERNAL_DOCUMENT){
							vm.counters.internal = counters.INTERNAL_DOCUMENT;
						}else{
							vm.counters.internal = 0;
						}
						if(counters.PAPER_BIN){
							vm.counters.paperBin = counters.PAPER_BIN;
						}else{
							vm.counters.paperBin = 0;
						}
					}
				}).catch(function(error){
					vm.counters.docs = 0;
					vm.counters.work = 0;
					vm.counters.internal = 0;
					vm.counters.paperBin = 0;
				});
			}
			
			vm.reloadBox = function(){
				if(vm.tableDefinition && vm.tableDefinition.reloadInternalData) {
					vm.tableDefinition.reloadInternalData(true, true);	
					vm.calculateCounters();
				}
			}
			this.$onInit = function() {
				vm.languageColumn = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.counters.docs = 0;
				vm.counters.work = 0;
				vm.counters.internal = 0;
				vm.counters.paperBin = 0;
				vm.archiveClassification = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierArchiveClassification;
				vm.isUpdate = vm.dossierTransactionComponent.isUpdateDossier && vm.dossierTransactionComponent.canModifyTransaction && vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.actual;
				if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isArchivedDossier && (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'TRANSFER' || vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'REJECTED' || (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' && !vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveDefinitiveDate))){
    				vm.isArchivedDossier = true;
    			}
				vm.legend = undefined;
		        vm.generateTableData("DOCUMENT");
				vm.labels.documentTransactionActiveTab = "DOCUMENT";
				var dtAux = angular.copy(vm.dossierTransactionComponent.dossierTransaction);
				var dAux = angular.copy(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier);
				dtAux.dossier = dAux;
				vm.documentBox = {
                    permissions: (vm.isUpdate || vm.isArchivedDossier) ? { new: 'process_dossier', view: 'process_dossier' } : {},
                    search: { placeholder: 'global.literals.placeholser_search_documents', advanced: true, saveSearch: ((vm.isUpdate || vm.isArchivedDossier) ? vm.addDocumentSave : undefined)},
					tableDefinition: vm.tableDefinition,
					boxTitle: 'global.literals.documents',
                    new: {multiple:{scanner:true}, showAssociateThirdDocuments:true, template:true, showDossierOptions: true},
                    isEdit: true,
                    origin: 'tram',
					alerts: vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: vm.legend,
					labels: vm.labels,
					counters: vm.counters,
					documentTransactionTabSelected: vm.documentTransactionTabSelected,
					config: {
						transaction: dtAux,
						module:"EXPED",
						documentTitle: undefined,
						requiredAndCannotCreateDocument: vm.dossierTransactionComponent.requiredAndCannotCreateDocument
					}
                }
			
				$scope.$on('annexaBoxDossierTransactionDocumentsReloadTable', function(event, args) {
			    	vm.reloadBox();
		        });
 				
				$scope.$on('documentActionButtonExecuted', function(event, args) {
			    	vm.reloadBox();
		        });

				$scope.$on('documentActionButtonExecuting', function(event, args) {
			    	vm.reloadBox();
		        });

				$scope.$on('SendToSignModalFinished', function(event, args) {
			    	vm.reloadBox();
		        });

				$scope.$on('createdRegisterEntry', function (event, args) {
					vm.reloadBox();
	            });

				$scope.$on('created_multiple_notifications', function (event, args) {
					if(args && args.notifications && args.parentIsModal=="isNotModal") {
						var notifications = args.notifications;
						if(!Array.isArray(notifications)){
							notifications = [notifications];
						}					
		                if(notifications && notifications.length > 0) {
		                    NotificationFactory.createMultipleNotifications(notifications).then(function(data) {
								vm.reloadBox();
	                        }).catch(function (error) {
	                       		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	                        	if(error && error.data && error.data.message == 'No notification type'){
		       						vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.sec.errors.noNotificationType')});
	                        	}else if(error && error.data && error.data.message == 'channelNoValidSendNotifications'){
	                        		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.channelNoValidSendNotifications')});
								}else if(error && error.data && error.data.message == 'No documents to this third notification'){
	                        		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.noDocumentsForThird')});
	                        	}else if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:msg});
								}else if(error && error.data && error.data.message === "Is not valid document from template not found"){
									vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.documents.definitiveNotificationNoValidError')});
								}else{
	                        		vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                        	}
	                        	console.error(error);
		                    });
		                }
                	}
                });
	            
				$scope.$on('allowGenerateFromContentDocumentTypeExecutedTram', function(event, args){
	            	if(args && args.transactionId && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions && args.data && args.data.id){
	            		var transactionToAllow = $linq(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions).firstOrDefault(undefined, "x => x.id == "+args.transactionId);
	            		if(transactionToAllow && transactionToAllow.id){
	            			var saveDocumentToDossierTransaction = function(transaction, document){
		            			var doc = {
		                             dossierTransaction: {id:transaction.id},
		                             document: document,
		                             publicDocument: false
		                        }
								vm.addDocumentSave([doc]);
	                    	}
	                    	saveDocumentToDossierTransaction(transactionToAllow, args.data);
	            		}
	            	}
	            });
				
				$scope.$on('allowGenerateFromContentDocumentTypeTemplateExecutedTram', function(event, args){
	            	if(args && args.documents && args.documents.length > 0){
	            		vm.addDocumentSave(args.documents);
	            	}
	            });
				
				$scope.$on('associateThirdDocumentsTramFinish', function (event, args) {
                    if (args.docs && args.docs.length > 0 && args.origin === 'tram') {
						var newDocs = [];
						_.forEach(args.docs, function(value){
							if(value && value.id){
		                        newDocs.push({
	                                document:{id:value.id},
		                            relationType: 'DOCUMENT',
		                            publicDocument: false
	                        	});
							}
	                    });
						if(newDocs && newDocs.length > 0){
							TramNewFactory.addDocs(vm.dossierTransactionComponent.dossierTransaction.id, newDocs).then(function (data) {
								if(vm.labels.documentTransactionActiveTab == "DOCUMENT" && vm.tableDefinition){
									vm.tableDefinition.reloadInternalData(true, true);	
								}
								vm.calculateCounters();
								if(args.modal){
									args.modal.close()
								}
		                    }).catch(function (error) {
		                    	if(error && error.data && error.data.message == 'Error call to SUITE'){
	                        		DialogsFactory.error($filter('translate')('global.errors.errorConnectSuite'));
	                        	}else if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
	                        		DialogsFactory.error($filter('translate')('global.tram.errors.inRestrictiveTransaction'));
	                        	}else{
	                        		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
	                        	}
		                    });
						}
					}
                });

				vm.isLoaded = true;
			}
        }],
        bindings: {
		}
    })
	.component('annexaBoxObjectDossierTransactionTasks',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-transaction-tasks.html',
        require: {
            dossierTransactionComponent: '^^annexaBoxObjectDossierTransaction'
        },
        controller:['$rootScope', '$filter', 'TaskFactory', '$scope', 'HelperService', 'AnnexaPermissionsFactory', 'DialogsFactory', 'CommonService', 'TramNewFactory', 
		function($rootScope, $filter, TaskFactory, $scope, HelperService, AnnexaPermissionsFactory, DialogsFactory, CommonService, TramNewFactory) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.isArchivedDossier = false;
			vm.isUpdate = false;
			vm.isLoaded = false;
			vm.legend = undefined;
			
			 
            vm.delete = function(id) {
            	var titleDeleteMessage = '';
            	var confirmDeleteMessage = '';
            	
            	titleDeleteMessage = 'global.literals.delete';
            	confirmDeleteMessage = 'global.literals.deleteTaskBox';
            	
            	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
                    .then(function (data) {
                        if(vm.taskBox && vm.taskBox.isEdit) {
							TramNewFactory.deleteTask(vm.dossierTransactionComponent.dossierTransaction.id, id).then(function (data) {
								if(vm.tableDefinition){
									vm.tableDefinition.reloadInternalData(true, true);	
								}
	                        }).catch(function (error) {
	                        	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
                				vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                        });
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
       		var addTaskSave = function(newTask) {
                if(newTask && newTask.length > 0){
					TramNewFactory.addTasks(vm.dossierTransactionComponent.dossierTransaction.id, CommonService.getParameterList($linq(newTask).select("x => x.id").toArray())).then(function (data) {
						if(vm.tableDefinition){
							vm.tableDefinition.reloadInternalData(true, true);	
						}
                    }).catch(function (error) {
                    	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
        				vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
                    });
                }
            }
   
			var taskStatusRender = function(data, type, full, meta) {
	            var content = '';
				if(TaskFactory.taskStatuses && TaskFactory.taskStatuses.length > 0){
					var status = $linq(TaskFactory.taskStatuses).singleOrDefault(undefined, "x => x.id == '" + data.globalStatus + "'");
                    if(status) {
	                    content += '<div class="text-center">';
						content += '<span class="fa-stack" title="'+((data && data[vm.languageColumn])?data[vm.languageColumn]:'')+'">';
						content += '<i class="fa fa-' + status.icon + ' ' + status.style + ' fa-lg fa-stack-1x"></i>';
						content += '</span>';
		            	content += '</div>';
					}
				}
	            return content;
	        }

			var taskDaysRender = function(data, type, full, meta) {
				var getMaxDays = function(task){
	                var holidays = [];
	                var workinDays = 0;
	
	                if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser &&
							vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.entity && 
							vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.entity.holidays){
	                    holidays = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.loggedUser.entity.holidays;
	                }
	
	                workinDays = HelperService.getWorkingDays(new Date(task.createdDate), new Date(), holidays);
	
	                return task.days - workinDays;
	            }
	            var getClass = function(task) {
	                return HelperService.getWorkingDaysClass(getMaxDays(task));
	            }
	            var getLabel = function(task) {
	                var maxDays = getMaxDays(task);
	
	                if(maxDays > 5) {
	                    return '+' + maxDays;
	                } else {
	                    return maxDays;
	                }
	            }
	            var taskEnd = false;
	            var content = '';
	            if(full.endDate){
	                taskEnd = true;
	            }
	            if(!taskEnd) {
	            	content += '<span class="label '+getClass(full)+'">';
	            	content += getLabel(full);
	            	content += '</span>';
	            }
	            return content;
	        }

			var actionsTitle = function () {
                return '<div class="column-actions"></div>';
            }

            var actionsRender = function (data, type, full, meta) {
				var content = '';
				if((vm.isUpdate || vm.isArchivedDossier) && AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', undefined)){
					content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.delete('+full.id+')" annexa-permission annexa-permission-only="[\'process_dossier\']">';
	                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
	                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
	                content += '</button>';
				}
                return content;
            }
            
            var taskNumberRender = function (data, type, full, meta) {
				var content = '';
				if(data && full && full.id){
					content += '<a class="hellip text-primary" ui-sref="annexa.tasks.assignedTasks.edit({ task:' + full.id + '})" target="_blank" rel="noopener">'+ data + '</a>'
				}
                return content;
            }

			vm.generateTableData = function(tab){
				if(!vm.tableDefinition){
					vm.tableDefinition = {};
				}
				var columns = [
					{ id: 'status', width: '53px', title: $filter('translate')('global.literals.state'), render: taskStatusRender},
					{ id: 'endedTask', width: '53px', title: $filter('translate')('global.literals.days'), render: taskDaysRender},
					{ id: 'taskNumber', width: '250px', title: $filter('translate')('global.literals.code'), render: taskNumberRender},
                    { id: 'description', width: '250px', column: new StrippedColumn($filter, 'global.literals.task_name',200)},
                    { id: 'createdUser', width: '250px', column:new UserColumn($filter, 'global.literals.created_for', false, true)},
					{ id: 'user', width: '250px', column: new UserOrProfileColumn($filter, 'global.literals.assigned_to', 'profile', vm.languageColumn, true)},
					{ id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
		        ];
				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.tableDefinition.id = 'tableDTTasks';
				vm.tableDefinition.origin = 'tasks';
		        vm.tableDefinition.objectType = 'Task';
				vm.tableDefinition.objectIdToSelectOrigin ='tram';
				vm.tableDefinition.objectIdToSelectType = 'DossierTransaction';
		        vm.tableDefinition.sortName = tableOrderProperties.sortName;
		        vm.tableDefinition.sort = tableOrderProperties.sort;
				vm.tableDefinition.columns = columns;
		        vm.tableDefinition.containerScope = $scope;
				vm.tableDefinition.useTableObjects = true;
				vm.tableDefinition.objectIdToSelect = angular.fromJson({id:vm.dossierTransactionComponent.dossierTransaction.id});
				vm.tableDefinition.objectIdToAdded = vm.dossierTransactionComponent.dossierTransaction.id;
			}

			this.$onInit = function() {
				vm.languageColumn = vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.isUpdate = vm.dossierTransactionComponent.isUpdateDossier && vm.dossierTransactionComponent.canModifyTransaction && vm.dossierTransactionComponent.dossierTransaction && vm.dossierTransactionComponent.dossierTransaction.actual;
				if(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.isArchivedDossier && (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'TRANSFER' || vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'REJECTED' || (vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' && !vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier.archiveDefinitiveDate))){
    				vm.isArchivedDossier = true;
    			}
				vm.legend = undefined
				vm.generateTableData();
				var dtAux = angular.copy(vm.dossierTransactionComponent.dossierTransaction);
				var dAux = angular.copy(vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier);
				dtAux.dossier = dAux;
				vm.taskBox = {
                    permissions: (vm.isUpdate || vm.isArchivedDossier) ? { new: 'process_dossier', view: 'process_dossier' } : {},
                    search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: ((vm.isUpdate || vm.isArchivedDossier) ? addTaskSave : undefined)},
					tableDefinition: vm.tableDefinition,
                    new: {},
                    isEdit: true,
                    config: { isTramBox:  dtAux},
                    origin: 'annexa-dossier-transaction-tasks',
					alerts: vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: vm.legend
                }

				$scope.$on('annexaBoxDossierTransactionTasksReloadTable', function(event, args) {
			    	if(vm.tableDefinition && vm.tableDefinition.reloadInternalData) {
						vm.tableDefinition.reloadInternalData(true, true);	
					}
		        });

				vm.isLoaded = true;
			}
        }],
        bindings: {
		}
    })
	.component('annexaBoxObjectDossierReceivers',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-receivers.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.showLongAddressLanguage = $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value;
			vm.receiverBox = {};
						
			var generateReceiverBox= function(){
				if(!vm.receiverTableDefinition){
					vm.receiverTableDefinition = {};
				}

				vm.deleteReceiver= function(id) {
		        	DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteThirdBox2').then(function (data) {
						if(vm.receiverBox && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier) {
							TramNewFactory.deleteDossierThird(id).then(function (data) {
								var dossierThirdDeleted = undefined;
								if(vm.receiverTableDefinition && vm.receiverTableDefinition.reloadInternalData){
									dossierThirdDeleted = $linq(vm.receiverTableDefinition.list).firstOrDefault(undefined, "x => x.id == "+id);
									vm.receiverTableDefinition.reloadInternalData(true, true);	
								}
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierReceivers');
								TramNewFactory.updateThirdEMGDE(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id);	
								TramNewFactory.getPrincipalDossierThird(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id).then(function (data) {
									if(data){
										vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.principalThird = data;	
									}
								}).catch(function (error) {
									
								});
								$rootScope.$broadcast('annexaBoxObjectDossierDocumentationToProvideUpdate', { thirds:[dossierThirdDeleted], type: 'DELETE' });
	                        }).catch(function (error) {
	                        	vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
								if(error && error.data && error.data.message === 'No deleted third in notification'){
									vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.thirdInNotification')});
								}else if(error && error.data && error.data.message === 'No deleted third have representated'){
									vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.thirdHaveRepresentated')});
								}else{
	            					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
								}
	                        });
	                    }
	                }).catch(function (data) {
	                    //Empty
		            });
		        }

				vm.editReceiver= function(receiverBBDD) {
					if(receiverBBDD && receiverBBDD.third){
						TramNewFactory.getDossierThirds(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id).then(function (data) {
							var receiver = angular.copy(receiverBBDD);
							var receiverThirdType = undefined;
							if (receiver.interested) {
				        		receiverThirdType = 'interested';
				        	} else if (receiver.representative) {
				        		receiverThirdType = 'representative';
				        	}
							var idReceiverRepresentated = undefined; 
							if (receiver.representated) {
				        		idReceiverRepresentated = receiver.representated.id;
				        		if (!receiver.representated.label) {
				                    receiver.representated.label = receiver.representated.completeName + ' (' + receiver.representated.identificationDocument + ')';
				        		}
				        	}
							var modal = angular.copy(globalModals.receiverAdd);
		                    modal.size = 'modal-md';
		                    modal.annexaFormly.model = {};
							modal.annexaFormly.model.modal_body = {
				            		idReceiver: receiver.id,
				            		thirdType: receiverThirdType, 
				            		representated: receiver.representated, 
				            		roleInterested: receiver.roleInterested,
				            		third: receiver.third.id,
									notifiable: receiver.notifiable,
									additionalInformation: receiver.additionalInformation,
									canSelectNotifiable: true
				            };
		                    modal.annexaFormly.options = {};
	                        modal.annexaFormly.options.formState = {
			            		readOnly: false, 
			            		data: ((data)?JSOG.decode(data):[]), 
			            		editReceiver: true, 
			            		idReceiver: receiver.id, 
			            		idThird: receiver.third.id,
			            		idReceiverRepresentated: idReceiverRepresentated
			            	};
		                    var submitFunction = function() {
								var model = this.annexaFormly.model.modal_body;
				            	var idReceiver = model.idReceiver;
				            	var thirdType = model.thirdType;
				            	var representated = model.representated;
				            	var roleInterested = model.roleInterested; 

								if (thirdType == 'interested') {
			                    	receiverBBDD.interested = true;
			                    	receiverBBDD.representated = undefined;
			                    	if (roleInterested) {
			                        	receiverBBDD.roleInterested = roleInterested;
			                        }
			                    } else {
			                    	receiverBBDD.interested = false;
			                    }
			                    if (thirdType == 'representative') {
			                    	receiverBBDD.representative = true;
			                    	if (representated) {
			                        	receiverBBDD.representated = representated;
			                        }
			                    	receiverBBDD.roleInterested = undefined;
			                    } else {
			                    	receiverBBDD.representative = false;
			                    }
								receiverBBDD.notifiable = model.notifiable;
								receiverBBDD.additionalInformation = model.additionalInformation;
  								TramNewFactory.updateDossierThird(idReceiver, receiverBBDD).then(function(data){
									if(vm.receiverTableDefinition && vm.receiverTableDefinition.reloadInternalData){
										vm.receiverTableDefinition.reloadInternalData(true, true);	
									}
									vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierReceivers');
									TramNewFactory.updateThirdEMGDE(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id);	
									$rootScope.$broadcast('annexaBoxObjectDossierDocumentationToProvideUpdate', { thirds:[data], type: 'UPDATE' });
			                        modal.close();
								}).catch(function(error){
									modal.alerts.length = 0;
					            	modal.alerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
								});    
							}  
		                    AnnexaFormlyFactory.showModal("modalSearchAdd", modal, submitFunction, false);
						}).catch(function (data) {
							vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	           				vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		           		});
					}
		        }

				vm.setPrincipalReceiver= function(id) {
		        	TramNewFactory.setPrincipalDossierThird(id).then(function(data){
						if(vm.receiverTableDefinition && vm.receiverTableDefinition.reloadInternalData){
							vm.receiverTableDefinition.reloadInternalData(false, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierReceivers');
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.principalThird = data;
                    }).catch(function (error) {
                        vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                });
		        }
	            var relatedThirdRender = function (data, type, full, meta) {
					var content = '';
					if(full && full.third){
						var dossierThird = new ThirdAddress(full.third, vm.languageColumn, vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier, vm.showLongAddressLanguage);
						var addresses = undefined;
			            if(dossierThird){
							addresses = dossierThird.getAdressesHtml();
						}
						content += '   <div class="row">';
			            content += '       <div class="col-xs-11">';
			            content += '           <div class="row m-b-xs _600">';
			            content += '               <div class="col-xs-1">';
						if(full.principal){
				            content += '                   <i class="fa fa-lg text-warn fa-flag " title="{{\'global.literals.principalDest\' | translate}}"></i>';
						}else{
				            content += '                   <a ng-click="$ctrl.setPrincipalReceiver('+full.id+')" title="{{\'global.literals.makePrincipalDest\' | translate}}">';
				            content += '                       <i class="fa fa-lg text-grey fa-flag-o"></i>';
				            content += '                   </a>';
						}
			            content += '               </div>';
			            content += '               <div class="pull-left m-l m-r-xs">';
			            content += '                   <href-permission class-href="text-primary _600"  link-href="annexa.thirds.edit" attr-compare="id" id-href="'+full.third.id+'" name-href="tableObjects[' + full.id + '].third.identificationDocument" href-permission-only="\'view_thirds\'"/>';
			            content += '               </div>';
			            content += '               <div class="col-sm-4 col-xxl-5">';
			            content += '                   <span>'+full.third.completeName+'</span>';
			            content += '               </div>';
			            if(full.interested){
							content += '               <div class="col-sm-2 col-lg-offset-1 col-lg-3 col-xl-offset-0 col-xl-2">';
				            content += '                   <span class="label label-xxs" translate="global.literals.interested">Interessted</span>'+((full.notifiable)?' <i title="'+$filter('translate')('global.tram.literals.secretary_notifiable')+'" class="fa fa-solid fa-bell text-warn"></i>':'');
				            if(full.roleInterested){
								content += '                   <span class="label label-xxs">'+full.roleInterested[vm.languageColumn]+'</span>';
							}
				            content += '               </div>';
						}
			            content += '               <div class="col-sm-2 col-lg-3 col-xl-2">';
			            if(full.representative && full.representated){
							var representatedName = '('+full.representated.completeName+'('+full.representated.identificationDocument+'))';
							content += '          		<span class="label label-xxs" translate="global.literals.representative" translate-values="{ representated: \''+representatedName+'\'}">Representative</span>'+((full.notifiable)?' <i title="'+$filter('translate')('global.tram.literals.secretary_notifiable')+'" class="fa fa-solid fa-bell text-warn"></i>':'');
						}
			            content += '               </div>';
			            content += '           </div>';
						if(addresses && addresses.length > 0){
							_.forEach(addresses, function(address){
								content += '           <div class="row m-b-xs">';
					            content += '               <div class="col-sm-11 col-sm-offset-1">'+address+'</div>';
						        content += '           </div>';
							});
						}
						if(full.additionalInformation){
							content += '       <div class="row m-b-xs">';
							content += '			<div class="col-sm-11 col-sm-offset-1"> ';
							content += '				<i class="fa fa-info-circle fa-fw text-black-lt pull-left" title="{{\'global.tram.literals.additional_information\' | translate}}"></i>';
							content += '				<span class="pull-left-fa"> '+full.additionalInformation+'</span>';
							content += '			</div>';
							content += '       </div>';
						}
			            content += '       </div>';
			            if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
							content += '       <div>';
				            content += '           <button class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.editReceiver(tableObjects[' + full.id + '])"><i class="fa fa-edit" title="{{ \'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.edit\' | translate}}</span></button>';
				            if(!((vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.procedure && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.procedure.forceHaveThird) && (vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberReceiverBox && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberReceiverBox.value < 2))){
				            	content += '           <button class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteReceiver('+full.id+')"  title="{{ \'global.literals.remove\' | translate}}"><i class="fa fa-remove" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate}}</span></button>';
				            }
				            content += '       </div>';
						}
			            content += '   </div>';
					}
	                return content;
	            }
				
				var addReceiverSave = function(modal) {
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
						var model = modal.annexaFormly.model.modal_body;
						var isPrincipal = false;  
						var boxThird = new BoxThird(model.object, vm.languageColumn, vm.showLongAddressLanguage);
						var newContent = {
							dossier: {id: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id},
                            third: model.object,
                            interested: model.thirdType == 'interested' ? true : false,
                            representative: model.thirdType == 'representative' ? true : false,
                            principal: isPrincipal,
                            addresses: boxThird.getAdressesHtml(),
                            representated: model.representated,
                            roleInterested: model.roleInterested,
							notifiable: model.notifiable,
							additionalInformation: model.additionalInformation
                        };
						if(modal.extra){
	                        modal.extra.searchedValue = {id: -1, value: ''};
						}
                        TramNewFactory.createDossierThird(newContent).then(function(data){
							if(vm.receiverTableDefinition && vm.receiverTableDefinition.reloadInternalData){
								vm.receiverTableDefinition.reloadInternalData(true, true);	
							}
							vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierReceivers');
							TramNewFactory.updateThirdEMGDE(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id);	
							if(data && data.principal){
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.principalThird = data;	
							}
							$rootScope.$broadcast('annexaBoxObjectDossierDocumentationToProvideUpdate', { thirds:[data], type: 'INSERT' });
	                        modal.close();
						}).catch(function(error){
							modal.alerts.length = 0;
			            	modal.alerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
						});
					}else{
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	       				vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
					}
				}
				var completeAddReceiverSave = function(){
					if(this.searchedValue && this.searchedValue.object){
						var selfReceiver = this;
						TramNewFactory.getDossierThirds(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id).then(function (data) {
							var receiverObject = selfReceiver.searchedValue.object;
							if(receiverObject && Array.isArray(receiverObject)){
								receiverObject = receiverObject[0];
							}
						    var modal = angular.copy(globalModals.receiverAdd);
		                    modal.size = 'modal-md';
		                    modal.annexaFormly.model = {};
		                    var defaultNotificable = false;
		                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_notification_configuration && $rootScope.app.configuration.sec_notification_configuration.defaultThirdSecNotification) {
		                    	defaultNotificable = true;
		                    }
		                    modal.annexaFormly.model.modal_body = { third: receiverObject.id, interested: false, representative: false, object: receiverObject, notifiable: defaultNotificable, canSelectNotifiable: true };		                    	
		                    modal.annexaFormly.options = {};
	                        modal.annexaFormly.options.formState = {readOnly: false, data: ((data)?JSOG.decode(data):[])};
		                    modal.extra = selfReceiver;
		                    AnnexaFormlyFactory.showModal("modalSearchAdd", modal, selfReceiver.saveSearch, false);
						}).catch(function (data) {
							selfReceiver.searchedValue = {id: -1, value: ''};
							vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	           				vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		           		});
					}
				}
				
				var columnsReceiver = [
					{ id: 'third.completeName', width: '95%', title: $filter('translate')('global.literals.name'), render:relatedThirdRender}
		        ];

				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.receiverTableDefinition.id = 'tableDReceivers';
				vm.receiverTableDefinition.origin = 'tram';
		        vm.receiverTableDefinition.objectType = 'DossierThird';
				vm.receiverTableDefinition.objectIdToSelectOrigin ='tram';
				vm.receiverTableDefinition.objectIdToSelectType = 'DossierThird';
		        vm.receiverTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.receiverTableDefinition.sort = tableOrderProperties.sort;
				vm.receiverTableDefinition.columns = columnsReceiver;
		        vm.receiverTableDefinition.containerScope = $scope;
				vm.receiverTableDefinition.useTableObjects = true;
				vm.receiverTableDefinition.callOrigin = 'tram';
				vm.receiverTableDefinition.objectIdToSelect = angular.fromJson({dossier:{id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id}});
				
				vm.receiverBox = {
				    boxTitle: 'global.literals.receivers',
					permissions: (vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier) ? { new:['process_dossier'], view: ['process_dossier']} : {},
					tableDefinition: vm.receiverTableDefinition,
                    isEdit: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier,
                    config: {dossier: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier},
                    origin: 'tram', 
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedReceiverBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberReceiverBox
                }
				if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
                    vm.receiverBox.search= { placeholder: 'global.literals.placeholder_search_dossiers', advanced: true, saveSearch: addReceiverSave, completeAdd: completeAddReceiverSave};
					vm.receiverBox.new= {}
					vm.receiverBox.importThirds = {}
				}
			}
						
			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.receiverBox = {};
				try{
					generateReceiverBox();
					
					$scope.$on('annexaBoxDossierReceiversReloadTable', function(event, args) {
			            if(vm.receiverTableDefinition && vm.receiverTableDefinition.reloadInternalData) {
							vm.receiverTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierReceivers');
			        });
				}catch(error){
					 console.error(error);
				}
        	}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierRegisterInputs',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-register-inputs.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.registerEntryInputBox = {};
			
			vm.deleteRegEntry = function(id, type) {
	        	var titleDeleteMessage = '';
	        	var confirmDeleteMessage = '';
	        	
	        	titleDeleteMessage = 'global.literals.delete';
	        	confirmDeleteMessage = 'global.literals.deleteRegisterEntryBox';
	        	
	        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
	                .then(function (data) {
						var canDeleteRegister = false;
						if(vm.registerEntryInputBox && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier) {
							canDeleteRegister = true;								
						}
						if(canDeleteRegister) {
							TramNewFactory.deleteRegister(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id, id, type).then(function(data){
								if(vm.regInpuTableDefinition && vm.regInpuTableDefinition.reloadInternalData) {
									vm.regInpuTableDefinition.reloadInternalData(true, true);								
								}	
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterInputs');
							}).catch(function (error) {
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
            					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		                    });
	                    }
	                }).catch(function (data) {
	                    //Empty
	            });
	        }
			var entryTypeRender = function(data, type, full, meta) {
				var content = '';
				if(data && data == 'INPUT'){
					content += '<i class="fa fa-sign-in" title="{{ \'global.literals.input\' | translate }}"></i>';
                }else{
					content += '<span class="fa-transX-3px">';
                    content += '<i class="fa fa-sign-out fa-flip-horizontal" title="{{ \'global.literals.output\' | translate }}"></i>';
                	content += '</span>';	
				}
				return content;
			}
			var actionsTitle = function () {
                return '<div class="column-actions"></div>';
            }
			var addRegisterEntrySave = function(registerEntry) {
				if(!Array.isArray(registerEntry)){
					registerEntry = [registerEntry];
				}
				DialogsFactory.confirm('global.tram.literals.addDocumentsThirdsMessage', 'global.tram.literals.addDocumentsThirdsMessageConfirm').then(function (data) {
					$http({
	                    url: './api/reg/register_entry_diligence/thirds_documents_from_register_entry/' + $linq(registerEntry).select("x => x.id").toArray(),
	                    method: 'GET'
	                }).then(function (dataAux) {
						if(dataAux && dataAux.data){
							dataAux = JSOG.decode(dataAux.data);
							var receivers = [];
							if(dataAux.thirds && dataAux.thirds.length > 0){
								_.forEach(dataAux.thirds, function(item) {
		                            receivers.push({
		                                id: item.third.id,
		                                allName: '<span style="display:inline">' + ThirdFactory.getThirdCompleteName(item.third) + ' <span class="label label-xxs">' + ThirdFactory.getRelatedThirdType(item) + '</span></span>'
		                            });
		                        });
							}
							var documentToAttach = [];
							if(dataAux.documents && dataAux.documents.length > 0){
							 	documentToAttach = $linq(dataAux.documents).select("x => x.document").toArray();
							}
		                    var dossierTransactionsToAttach = [];
		                    if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions){
								dossierTransactionsToAttach = $linq(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions).where("x => x.actual").toArray();
								dossierTransactionsToAttach = angular.copy(dossierTransactionsToAttach);
							}
		                    var attachDossierModal = {
		                        title: 'global.reg.literals.attachExp',
		                        size: '',
		                        icon: 'fa fa-paperclip',
		                        fieldsDefinition: [
		                            { type: 'field', id: 'receivers', fieldType: 'select_multiple_html', colClass: 'daughter-label-strong', labelProp: 'allName', data: receivers, label: 'global.literals.remitents' },
		                            { type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-select-documents',
		                                data: {
		                                    title: 'global.literals.documents',
		                                    documents: documentToAttach,
											documentsIdSelected: (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.diligence_attach_to_dossier_documents_all_default && $rootScope.app.configuration.diligence_attach_to_dossier_documents_all_default.value && documentToAttach && documentToAttach.length > 0)? $linq(documentToAttach).select("x => x.id").toArray() : undefined)	
		                                }
		                            },
		                            { type: 'field', id: 'dossierTransaction', fieldType: 'select', colClass: 'daughter-label-strong', data: dossierTransactionsToAttach, label: 'global.tram.literals.transactions', required: true}
		                        ],
		                        alerts: [],
		                        submitModal: function () {
		                        }
		                    }
		
		                    var submitAttachDossier = function() {
		                        var selfSubmitAttachDossier = this;
		                        this.form.$setSubmitted();
		                        var selectedDocumentsAux = angular.copy($linq(this.fieldsDefinition[1].data.documents).where("x => x.selected").toArray());
		                        var selectedDocuments = [];
		                        if(selectedDocumentsAux && selectedDocumentsAux.length > 0){
		                        	_.forEach(selectedDocumentsAux, function(doc){
										var docAux = $linq(dataAux.documents).firstOrDefault(undefined, "x => x.document.id == "+doc.id);
		                        		if(docAux){
											selectedDocuments.push(docAux.id);
										}
		                        	});
		                        }
								var selectedReceivers = [];
		                        if(this.model.receivers && this.model.receivers.length > 0){
		                        	_.forEach(this.model.receivers, function(third){
										var thirdAux = $linq(dataAux.thirds).firstOrDefault(undefined, "x => x.third.id == "+third);
		                        		if(thirdAux){
											selectedReceivers.push(thirdAux.id);
										}
		                        	});
		                        }
								var addedRegisters = [];
								if(registerEntry && registerEntry.length){
									_.forEach(registerEntry, function(reg){
										addedRegisters.push(reg.id);
		                        	});
								}
								if (this.form.$valid) {
									TramNewFactory.createRelatedRegisterDossierWithDocsAndReceivers(this.model.dossierTransaction, addedRegisters, selectedReceivers, selectedDocuments).then(function(data){
										if(vm.regInpuTableDefinition && vm.regInpuTableDefinition.reloadInternalData) {
											vm.regInpuTableDefinition.reloadInternalData(true, true);
										}
										vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterInputs');
										if(selectedReceivers && selectedReceivers.length > 0){
											$rootScope.$broadcast('annexaBoxDossierReceiversReloadTable', { reload:true });
											var thirdsAdded = [];
											_.forEach(selectedReceivers, function(third){
												var thAux = $linq(dataAux.thirds).firstOrDefault(undefined, "x => x.third.id == "+third);
												if(thAux){
													thirdsAdded.push(thAux);
												}
											});
											$rootScope.$broadcast('annexaBoxObjectDossierDocumentationToProvideUpdate', { thirds: thirdsAdded, type: 'INSERT' });
										}
										attachDossierModal.close();
						            }).catch(function(error) {
						                attachDossierModal.alerts.push({ msg: 'global.errors.unknownAttachtoExp' });
						            });
		                        }
			                }
			                AnnexaFormlyFactory.showAnnexaFormModal('modalAttachDossier', attachDossierModal, submitAttachDossier);
						}
					}).catch(function (error) {
						console.log(error);
					});
	            }).catch(function (data) {
					TramNewFactory.createRelatedRegisterDossier(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id, $linq(registerEntry).select("x => x.id").toArray()).then(function(data){
						if(vm.regInpuTableDefinition && vm.regInpuTableDefinition.reloadInternalData) {
							vm.regInpuTableDefinition.reloadInternalData(true, true);
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterInputs');
					}).catch(function(error) {
	                    vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
    					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                });
		        });
			}
			
			var generateRegisterEntryInputBox= function(){
				if(!vm.regInpuTableDefinition){
					vm.regInpuTableDefinition = {};
				}
				
	            var actionsInputRender = function (data, type, full, meta) {
					var content = '';
					if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
						content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteRegEntry('+full.id+', \'INPUT\')" annexa-permission annexa-permission-only="[\'new_input_entry_register\',\'view_input_register\',\'view_all_input_register\']">';
		                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
		                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
		                content += '</button>';
					}
	                return content;
	            }
	            
	            var entryNumberRender = function(data, type, full, meta) {
					var content = '';
					if(data && data.id && data.entryNumber) {
						content = '<a class="text-primary" href="" ui-sref="annexa.reg.input.entries.edit({input: '+data.id+'})" target="_blank">'+data.entryNumber+'</a>';
					}
	                return content;
				}
				
				var columnsInput = [
					{ id: 'registerEntry.entryType', width: '53px', title: $filter('translate')('global.literals.type'), render: entryTypeRender},
					{ id: 'registerEntry.registerDate', width: '53px', column: new DateTimeColumn($filter, 'global.reg.datatable.registerDate')},
					{ id: 'registerEntry', width: '250px', title: $filter('translate')('global.literals.number'), render: entryNumberRender},
	                { id: 'registerEntry.extract', width: '250px', column: new TextColumn($filter, 'global.literals.extract')},
	                { id: 'registerEntry.registerEntryOffice', width: '250px', column:new DatabaseTranslatedParentColumn($filter, 'global.literals.office', vm.languageColumn)},
					{ id: 'registerEntry.principalThird', width: '250px', column: new ThirdColumn($filter, 'global.literals.principalThirdRegisterEntryInput',false), sortable: false},
					{ id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsInputRender, sortable: false }
		        ];
				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.regInpuTableDefinition.id = 'tableDRegInput';
				vm.regInpuTableDefinition.origin = 'reg';
		        vm.regInpuTableDefinition.objectType = 'RegisterEntryDossier';
				vm.regInpuTableDefinition.objectIdToSelectOrigin ='reg';
				vm.regInpuTableDefinition.objectIdToSelectType = 'RegisterEntryDossier';
		        vm.regInpuTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.regInpuTableDefinition.sort = tableOrderProperties.sort;
				vm.regInpuTableDefinition.columns = columnsInput;
				vm.regInpuTableDefinition.entryType = 'INPUT';
		        vm.regInpuTableDefinition.containerScope = $scope;
				vm.regInpuTableDefinition.useTableObjects = true;
				vm.regInpuTableDefinition.callOrigin = 'tramInput';
				vm.regInpuTableDefinition.objectIdToSelect = angular.fromJson({dossier:{id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id}});
				vm.regInpuTableDefinition.objectIdToAdded = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id;
				
				vm.registerEntryInputBox = {
				    boxTitle: 'global.literals.reg_input',
					permissions: (vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier) ? { new: 'new_input_entry_register', view: ['view_input_register','view_all_input_register']} : {},
					tableDefinition: vm.regInpuTableDefinition,
                    isEdit: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier,
                    config: {dossier: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier},
                    origin: 'tram',
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					searchByEntryType: true,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedRegisterEntryInputBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberRegisterEntryInputBox
                }
				if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
                    vm.registerEntryInputBox.search = { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: addRegisterEntrySave};
                    vm.registerEntryInputBox.new = {};
				}
			}

			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.registerEntryInputBox = {};
        		try{
					generateRegisterEntryInputBox();	
					$scope.$on('annexaBoxDossierRegisterInputsReloadTable', function(event, args) {
			            if(vm.regInpuTableDefinition && vm.regInpuTableDefinition.reloadInternalData) {
							vm.regInpuTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterInputs');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierRegisterOutputs',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-register-outputs.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.registerEntryOutputBox = {};
        
			vm.deleteRegEntry = function(id, type) {
	        	var titleDeleteMessage = '';
	        	var confirmDeleteMessage = '';
	        	
	        	titleDeleteMessage = 'global.literals.delete';
	        	confirmDeleteMessage = 'global.literals.deleteRegisterEntryBox';
	        	
	        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
	                .then(function (data) {
						var canDeleteRegister = false;
						if(vm.registerEntryOutputBox && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier) {
							canDeleteRegister = true;
						}
						if(canDeleteRegister) {
							TramNewFactory.deleteRegister(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id, id, type).then(function(data){
								if(vm.regOutputTableDefinition && vm.regOutputTableDefinition.reloadInternalData) {
									vm.regOutputTableDefinition.reloadInternalData(true, true);	
								}
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
							}).catch(function (error) {
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
            					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		                    });
	                    }
	                }).catch(function (data) {
	                    //Empty
	            });
	        }
			var entryTypeRender = function(data, type, full, meta) {
				var content = '';
				if(data && data == 'INPUT'){
					content += '<i class="fa fa-sign-in" title="{{ \'global.literals.input\' | translate }}"></i>';
                }else{
					content += '<span class="fa-transX-3px">';
                    content += '<i class="fa fa-sign-out fa-flip-horizontal" title="{{ \'global.literals.output\' | translate }}"></i>';
                	content += '</span>';	
				}
				return content;
			}
			var actionsTitle = function () {
                return '<div class="column-actions"></div>';
            }
			var addRegisterEntrySave = function(registerEntry) {
				if(!Array.isArray(registerEntry)){
					registerEntry = [registerEntry];
				}
				DialogsFactory.confirm('global.tram.literals.addDocumentsThirdsMessage', 'global.tram.literals.addDocumentsThirdsMessageConfirm').then(function (data) {
					$http({
	                    url: './api/reg/register_entry_diligence/thirds_documents_from_register_entry/' + $linq(registerEntry).select("x => x.id").toArray(),
	                    method: 'GET'
	                }).then(function (dataAux) {
						if(dataAux && dataAux.data){
							dataAux = JSOG.decode(dataAux.data);
							var receivers = [];
							if(dataAux.thirds && dataAux.thirds.length > 0){
								_.forEach(dataAux.thirds, function(item) {
		                            receivers.push({
		                                id: item.third.id,
		                                allName: '<span style="display:inline">' + ThirdFactory.getThirdCompleteName(item.third) + ' <span class="label label-xxs">' + ThirdFactory.getRelatedThirdType(item) + '</span></span>'
		                            });
		                        });
							}
							var documentToAttach = [];
							if(dataAux.documents && dataAux.documents.length > 0){
							 	documentToAttach = $linq(dataAux.documents).select("x => x.document").toArray();
							}
		                    var dossierTransactionsToAttach = [];
		                    if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions){
								dossierTransactionsToAttach = $linq(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.dossierTransactions).where("x => x.actual").toArray();
								dossierTransactionsToAttach = angular.copy(dossierTransactionsToAttach);
							}
		                    var attachDossierModal = {
		                        title: 'global.reg.literals.attachExp',
		                        size: '',
		                        icon: 'fa fa-paperclip',
		                        fieldsDefinition: [
		                            { type: 'field', id: 'receivers', fieldType: 'select_multiple_html', colClass: 'daughter-label-strong', labelProp: 'allName', data: receivers, label: 'global.literals.remitents' },
		                            { type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-select-documents',
		                                data: {
		                                    title: 'global.literals.documents',
		                                    documents: documentToAttach,
											documentsIdSelected: (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.diligence_attach_to_dossier_documents_all_default && $rootScope.app.configuration.diligence_attach_to_dossier_documents_all_default.value && documentToAttach && documentToAttach.length > 0)? $linq(documentToAttach).select("x => x.id").toArray() : undefined)	
		                                }
		                            },
		                            { type: 'field', id: 'dossierTransaction', fieldType: 'select', colClass: 'daughter-label-strong', data: dossierTransactionsToAttach, label: 'global.tram.literals.transactions', required: true}
		                        ],
		                        alerts: [],
		                        submitModal: function () {
		                        }
		                    }
		
		                    var submitAttachDossier = function() {
		                        var selfSubmitAttachDossier = this;
		                        this.form.$setSubmitted();
		                        var selectedDocumentsAux = angular.copy($linq(this.fieldsDefinition[1].data.documents).where("x => x.selected").toArray());
		                        var selectedDocuments = [];
		                        if(selectedDocumentsAux && selectedDocumentsAux.length > 0){
		                        	_.forEach(selectedDocumentsAux, function(doc){
										var docAux = $linq(dataAux.documents).firstOrDefault(undefined, "x => x.document.id == "+doc.id);
		                        		if(docAux){
											selectedDocuments.push(docAux.id);
										}
		                        	});
		                        }
								var selectedReceivers = [];
		                        if(this.model.receivers && this.model.receivers.length > 0){
		                        	_.forEach(this.model.receivers, function(third){
										var thirdAux = $linq(dataAux.thirds).firstOrDefault(undefined, "x => x.third.id == "+third);
		                        		if(thirdAux){
											selectedReceivers.push(thirdAux.id);
										}
		                        	});
		                        }
								var addedRegisters = [];
								if(registerEntry && registerEntry.length){
									_.forEach(registerEntry, function(reg){
										addedRegisters.push(reg.id);
		                        	});
								}
								if (this.form.$valid) {
									TramNewFactory.createRelatedRegisterDossierWithDocsAndReceivers(this.model.dossierTransaction, addedRegisters, selectedReceivers, selectedDocuments).then(function(data){
										if(vm.regOutputTableDefinition && vm.regOutputTableDefinition.reloadInternalData) {
											vm.regOutputTableDefinition.reloadInternalData(true, true);	
										}
										vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
										if(selectedReceivers && selectedReceivers.length > 0){
											$rootScope.$broadcast('annexaBoxDossierReceiversReloadTable', { reload:true });
											var thirdsAdded = [];
											_.forEach(selectedReceivers, function(third){
												var thAux = $linq(dataAux.thirds).firstOrDefault(undefined, "x => x.third.id == "+third);
												if(thAux){
													thirdsAdded.push(thAux);
												}
											});
											$rootScope.$broadcast('annexaBoxObjectDossierDocumentationToProvideUpdate', { thirds: thirdsAdded, type: 'INSERT' });
										}
										attachDossierModal.close();
						            }).catch(function(error) {
						                attachDossierModal.alerts.push({ msg: 'global.errors.unknownAttachtoExp' });
						            });
		                        }
			                }
			                AnnexaFormlyFactory.showAnnexaFormModal('modalAttachDossier', attachDossierModal, submitAttachDossier);
						}
					}).catch(function (error) {
						console.log(error);
					});
	            }).catch(function (data) {
					TramNewFactory.createRelatedRegisterDossier(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id, $linq(registerEntry).select("x => x.id").toArray()).then(function(data){
						if(vm.regOutputTableDefinition && vm.regOutputTableDefinition.reloadInternalData) {
							vm.regOutputTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
					}).catch(function(error) {
	                    vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
    					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                });
		        });
			}
			
			
			var generateRegisterEntryOutputBox= function(){
				if(!vm.regOutputTableDefinition){
					vm.regOutputTableDefinition = {};
				}
				
	            var actionsOutputRender = function (data, type, full, meta) {
					var content = '';
					if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
						content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteRegEntry('+full.id+', \'OUTPUT\')" annexa-permission annexa-permission-only="[\'new_input_entry_register\',\'view_input_register\',\'view_all_input_register\']">';
		                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
		                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
		                content += '</button>';
					}
	                return content;
	            }
	            
	            var entryNumberRender = function(data, type, full, meta) {
					var content = '';
					if(data && data.id && data.entryNumber) {
						content = '<a class="text-primary" href="" ui-sref="annexa.reg.output.entries.edit({output: '+data.id+'})" target="_blank">'+data.entryNumber+'</a>';
					}
	                return content;
				}
	            
				var columnsOutput = [
					{ id: 'registerEntry.entryType', width: '53px', title: $filter('translate')('global.literals.type'), render: entryTypeRender},
					{ id: 'registerEntry.registerDate', width: '53px', column: new DateTimeColumn($filter, 'global.reg.datatable.registerDate')},
					{ id: 'registerEntry', width: '250px', title: $filter('translate')('global.literals.number'), render: entryNumberRender},
	                { id: 'registerEntry.extract', width: '250px', column: new TextColumn($filter, 'global.literals.extract')},
	                { id: 'registerEntry.registerEntryOffice', width: '250px', column:new DatabaseTranslatedParentColumn($filter, 'global.literals.office', vm.languageColumn)},
					{ id: 'registerEntry.principalThird', width: '250px', column: new ThirdColumn($filter, 'global.literals.principalThirdRegisterEntryInput',false), sortable: false},
					{ id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsOutputRender, sortable: false }
		        ];
				
				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.regOutputTableDefinition.id = 'tableDRegOutput';
				vm.regOutputTableDefinition.origin = 'reg';
		        vm.regOutputTableDefinition.objectType = 'RegisterEntryDossier';
				vm.regOutputTableDefinition.objectIdToSelectOrigin ='reg';
				vm.regOutputTableDefinition.objectIdToSelectType = 'RegisterEntryDossier';
		        vm.regOutputTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.regOutputTableDefinition.sort = tableOrderProperties.sort;
				vm.regOutputTableDefinition.columns = columnsOutput;
				vm.regOutputTableDefinition.entryType = 'OUTPUT';
		        vm.regOutputTableDefinition.containerScope = $scope;
				vm.regOutputTableDefinition.useTableObjects = true;
				vm.regOutputTableDefinition.callOrigin = 'tramOutput';
				vm.regOutputTableDefinition.objectIdToSelect = angular.fromJson({dossier:{id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id}});
				vm.regOutputTableDefinition.objectIdToAdded = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id;
				
				vm.registerEntryOutputBox = {
				    boxTitle: 'global.literals.reg_output',
					permissions: (vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier) ? { new: 'new_output_entry_register', view: ['view_output_register','view_all_output_register']} : {},
					tableDefinition: vm.regOutputTableDefinition,
                    isEdit: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier,
                    config: {dossier: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier},
                    origin: 'tram',
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					searchByEntryType: true,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedRegisterEntryOutputBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberRegisterEntryOutputBox
                }
				if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
                    vm.registerEntryOutputBox.search= { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch:addRegisterEntrySave};
                    vm.registerEntryOutputBox.new= {};
				}
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.registerEntryOutputBox = {};
        		try{
					generateRegisterEntryOutputBox();	
					$scope.$on('annexaBoxDossierRegisterOutputsReloadTable', function(event, args) {
			            if(vm.regOutputTableDefinition && vm.regOutputTableDefinition.reloadInternalData) {
							vm.regOutputTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierDossiers',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-dossiers.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.dossierBox = {};
			
			var generateDossierBox= function(){
				if(!vm.dossierTableDefinition){
					vm.dossierTableDefinition = {};
				}

				vm.deleteDossier = function(id, type) {
		        	var titleDeleteMessage = '';
		        	var confirmDeleteMessage = '';
		        	
		        	titleDeleteMessage = 'global.literals.delete';
		        	confirmDeleteMessage = 'global.literals.deleteTaskBox';
		        	
		        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
		                .then(function (data) {
			                    if(vm.dossierBox && vm.dossierBox.isEdit) {
									TramNewFactory.deleteRelatedDossier(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id, id).then(function (data) {
										if(vm.dossierTableDefinition && vm.dossierTableDefinition.reloadInternalData){
											vm.dossierTableDefinition.reloadInternalData(true, true);	
										}
										vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierDossiers');
			                        }).catch(function (error) {
			                        	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
			            				vm.dossierTransactionComponent.dossierTransactionsComponent.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
			                        });
			                    }
		                }).catch(function (data) {
		                    //Empty
		            });
		        }
	            var relatedDossierRelationRender = function (data, type, full, meta) {
					var content = '';
					if(data){
						if(full.related && full.related.id && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id !== full.related.id){
							content +='<span title="'+data[vm.languageColumn]+'" class="material-icons fa-flip-horizontal "> swap_horizontal_circle </span>';
							if(data.parent){
								content += '<span title="'+$filter('translate')('global.literals.relatedDossierRelationParentSon')+'" class="label label-xxs" translate="global.literals.relatedDossierRelationParentSon">Son</span>';
							}
						}else{
							content +='<span title="'+data[vm.dossierInfoComponent.inverseLanguageColumn]+'" class="material-icons"> swap_horizontal_circle </span>';
							if(data.parent){
								content += '<span title="'+$filter('translate')('global.literals.relatedDossierRelationParentFather')+'" class="label label-xxs" translate="global.literals.relatedDossierRelationParentFather">Father</span>';
							}
						}
					}
	                return content;
	            }
				var relatedDossierNumberRender = function (data, type, full, meta) {
					var content = '';
					if(full.related && full.related.id && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id !== full.related.id){
						if(full.related && full.related.dossierStatus && full.related.dossierStatus != 'ARCHIVED' && full.related.dossierStatus != 'REJECTED'  && full.related.dossierStatus != 'TRANSFER'){
							content = '<a class="text-primary _400 small-lg" href="" ui-sref="annexa.tram.pending.viewdossier({dossier: '+full.related.id+', dossierTransaction: 0})" target="_blank">'+full.related.dossierNumber+'</a>';
						}else{
							content = '<span class="text-primary _400 small-lg">'+full.related.dossierNumber+'</span>';
						}
					}else if(full.actual && full.actual.id){
						if(full.actual && full.actual.dossierStatus && full.actual.dossierStatus != 'ARCHIVED' && full.actual.dossierStatus != 'REJECTED'  && full.actual.dossierStatus != 'TRANSFER'){
							content = '<a class="text-primary _400 small-lg" href="" ui-sref="annexa.tram.pending.viewdossier({dossier: '+full.actual.id+', dossierTransaction: 0})" target="_blank">'+full.actual.dossierNumber+'</a>';
						}else{
							content = '<span class="text-primary _400 small-lg">'+full.actual.dossierNumber+'</span>';
						}
					}
	                return content;
	            }
            
				var relatedDossierSubjectRender = function (data, type, full, meta) {
					var content = '';
					if(full.related && full.related.id && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id !== full.related.id){
						content = '<span class=" _600">'+((full.related.subject)?full.related.subject:'')+'</span>';
					}else if(full.actual && full.actual.id){
						content = '<span class=" _600">'+((full.actual.subject)?full.actual.subject:'')+'</span>';
					}
	                return content;
	            }

				var relatedDossierStatusRender = function (data, type, full, meta) {
					var content = '';
	                if(full.related && full.related.id && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id !== full.related.id){
						if(full.related.closed){
							if(full.related.dossierStatus != 'CANCELLED'){
								content += '<span class="label label-xxs success" translate="global.literals.ended">Ended</span>';
							}else{
								content += '<span class="label label-xxs danger" translate="CANCELLED">Cancelled</span>';
							}
							content += '<span class="small text-success">{{dossier.related.endDate | date: \'dd/MM/yyyy HH:mm:ss\'}}</span>';
						}else{
							content += '<span class="label label-xxs" translate="global.literals.in_process">In proces</span>';
						}
					}else if(full.actual && full.actual.id){
						if(full.actual.closed){
							if(full.actual.dossierStatus != 'CANCELLED'){
								content += '<span class="label label-xxs success" translate="global.literals.ended">Ended</span>';
							}else{
								content += '<span class="label label-xxs danger" translate="CANCELLED">Cancelled</span>';
							}
							content += '<span class="small text-success">{{dossier.actual.endDate | date: \'dd/MM/yyyy HH:mm:ss\'}}</span>';
						}else{
							content += '<span class="label label-xxs" translate="global.literals.in_process">In proces</span>';
						}
					}
					return content;
	            }

				var actionsDossierRender = function (data, type, full, meta) {
					var content = '';
					if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
						content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteDossier('+full.id+')" annexa-permission annexa-permission-only="[\'process_dossier\']">';
		                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
		                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
		                content += '</button>';
					}
	                return content;
	            }
				var addDossierSave = function(modal) {
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
						var model = modal.annexaFormly.model.modal_body;
						var relation = ((model)?model.relation:undefined);
						var relateds = undefined;
						if(model && model.relateds && model.relateds.object && !Array.isArray(model.relateds.object)){
							relateds = [model.relateds.object];
						}else if(model && model.relateds && model.relateds.object){
							relateds = model.relateds.object;
						}
						if(relateds && relateds.length > 0){
							var dossierIds = $linq(relateds).select("x => x.id").toArray();
							TramNewFactory.newRelatedDossier(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id, dossierIds, ((relation)?relation.id:undefined)).then(function(data){
								if(vm.dossierTableDefinition && vm.dossierTableDefinition.reloadInternalData){
									vm.dossierTableDefinition.reloadInternalData(true, true);	
								}
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierDossiers');
								if(modal.extra){
			                        modal.extra.searchedValue = {id: -1, value: ''};
								}
								modal.close();
                            }).catch(function (error) {
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
		       					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
                            });
						}else{
							vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	       					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
						}
					}else{
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	       				vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
					}
				}
				
				var completeAddDossierSave = function(){
                    var modal = angular.copy(globalModals.relatedDossierRelation);;
                    modal.size = 'modal-md';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = { relateds: this.searchedValue, relation: undefined };
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false, data: []};
                    modal.extra = this;
                    AnnexaFormlyFactory.showModal("modalSearchAdd", modal, this.saveSearch, false);
				}
					
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	            }

				var columnsDossier = [
					{ id: 'relatedDossierRelation', width: '60px', title: '', render:relatedDossierRelationRender},
					{ id: 'related.dossierNumber', width: '150px', title: $filter('translate')('global.literals.dossierNumber'), render:relatedDossierNumberRender},
					{ id: 'related.subject', width: '450px', title: $filter('translate')('global.literals.subject'), render:relatedDossierSubjectRender},
	                { id: 'related.dossierStatus', width: '150px', title: $filter('translate')('global.literals.status'), render:relatedDossierStatusRender},
					{ id: 'actions', columnName: 'id', className: 'text-center', title: actionsTitle(), render: actionsDossierRender, sortable: false }
		        ];

				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.dossierTableDefinition.id = 'tableDRelatedDossiers';
				vm.dossierTableDefinition.origin = 'tram';
		        vm.dossierTableDefinition.objectType = 'RelatedDossier';
				vm.dossierTableDefinition.objectIdToSelectOrigin ='tram';
				vm.dossierTableDefinition.objectIdToSelectType = 'Dossier';
		        vm.dossierTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.dossierTableDefinition.sort = tableOrderProperties.sort;
				vm.dossierTableDefinition.columns = columnsDossier;
		        vm.dossierTableDefinition.containerScope = $scope;
				vm.dossierTableDefinition.useTableObjects = true;
				vm.dossierTableDefinition.callOrigin = 'tram';
				vm.dossierTableDefinition.objectIdToSelect = angular.fromJson({id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id});
				vm.dossierTableDefinition.objectIdToAdded = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id;
				
				vm.dossierBox = {
				    boxTitle: 'global.literals.related_dossiers',
					permissions: (vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier) ? { view: ['process_dossier']} : {},
					tableDefinition: vm.dossierTableDefinition,
                    isEdit: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier,
                    config: {dossier: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier},
                    origin: 'tram',
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedDossierBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberDossierBox
                }
				if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
                    vm.dossierBox.search = { placeholder: 'global.literals.placeholder_search_dossiers', advanced: true, saveSearch: addDossierSave, completeAdd: completeAddDossierSave};
				}
			}

			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.dossierBox = {};
        		try{
					generateDossierBox();
					$scope.$on('annexaBoxDossierDossiersReloadTable', function(event, args) {
			            if(vm.dossierTableDefinition && vm.dossierTableDefinition.reloadInternalData) {
							vm.dossierTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierDossiers');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierNotifications',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-notifications.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.notificationBox = {};
        
			var generateNotificationBox= function(){
				if(!vm.notificationTableDefinition){
					vm.notificationTableDefinition = {};
				}
				vm.viewNotification = function (notificationId) {
					if(notificationId && $scope.tableObjects && $scope.tableObjects[notificationId]){
		                var notificationViewModal = NotificationFactory.notificationViewModal($scope.tableObjects[notificationId], vm.origin);
		                AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);
					}
	            }
				
				var addNotificationSave = function(notifications) {
					if(!Array.isArray(notifications)){
						notifications = [notifications];
					}					
	                if(notifications && notifications.length > 0) {
	                    NotificationFactory.createMultipleNotifications(notifications).then(function(data) {
	                        if(vm.notificationTableDefinition && vm.notificationTableDefinition.reloadInternalData) {
								vm.notificationTableDefinition.reloadInternalData(true, true);	
							}
							vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierNotifications');
							$rootScope.$broadcast('annexaBoxDossierRegisterOutputsReloadTable', { reload:true });
                        }).catch(function (error) {
                       		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
                        	if(error && error.data && error.data.message == 'No notification type'){
	       						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.sec.errors.noNotificationType')});
                        	}else if(error && error.data && error.data.message == 'channelNoValidSendNotifications'){
                        		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.channelNoValidSendNotifications')});
							}else if(error && error.data && error.data.message == 'No documents to this third notification'){
                        		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.noDocumentsForThird')});
                        	}else if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
								var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:msg});
							}else if(error && error.data && error.data.message === "Is not valid document from template not found"){
								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.documents.definitiveNotificationNoValidError')});
							}else{
                        		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
                        	}
                        	console.error(error);
	                    });
	                }
	            }
				var actionsNotificationRender = function (data, type, full, meta) {
					var content = '';
					content += '<div class="btn-group flexbuttons">';
					content += '<button class="btn primary btn-sm" ng-click="$ctrl.viewNotification('+full.id+')">';
	                content += '<i class="fa fa-eye" aria-hidden="true"></i>';
	                content += '<span>{{\'global.literals.see\' | translate}}</span>';
	                content += '</button>';
					content += '</div>';
	                return content;
	            }
				
				var notificationTypeRender = function (data, type, full, meta) {
					var content = '';
					var iClass = '';
					if(full && full.globalStatus){
						if(full.globalStatus === 'PENDING'){
							iClass = 'text-warn';
						}else if(full.globalStatus === 'SEND'){
							iClass = 'text-info';
						}else if(full.globalStatus === 'COMPLETE'){
							iClass = 'text-success';
						}else if(full.globalStatus === 'ERROR'){
							iClass = 'text-danger';
						}
					}
					content += '<i class="fa fa-circle m-r-xs '+iClass+'" title="{{\'global.literals.' + full.globalStatus+'\' | translate}}"></i>';
	                if(data && data[vm.languageColumn]){
						content += ' '+data[vm.languageColumn];
					}
	                return content;
	            }
				
				var notificationThirdsRender = function (data, type, full, meta) {
					var content = '';
					if(full.thirds && full.thirds.length > 0){
						content = '<annexa-tooltip-thirds thirds="tableObjects[' + full.id + '].thirds" show-first="{{true}}"></annexa-tooltip-thirds>';
					}
	                return content;
	            }
					
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	            }
				
				var renderNotificationLastStatus = function (data, type, full, meta) {
					if(data) {
			    		var select = []
			    		_.forEach(data, function(value){
			    			select = $linq(select).union(value.statuses).toArray();
	                    });
					}
					if (select) { 
						var orderedByDateStatuses = $linq(select).orderBy("x => x.createdDate").toArray();
					}
					
		            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ?
		                orderedByDateStatuses[orderedByDateStatuses.length - 1].status[Language.getActiveColumn()] : '';
		        };

				var columnsNotification = [
					{ id: 'notificationType', width: '150px', title: $filter('translate')('global.literals.type'), render:notificationTypeRender},
					{ id: 'extract', width: '150px', title: $filter('translate')('global.literals.subject')},
					{ id: 'thirds.third.completeName', width: '150px', title: $filter('translate')('global.literals.receivers'), render:notificationThirdsRender},
	                { id: 'createdDate', width: '150px', column: new DateTimeColumn($filter, 'global.literals.creation_date')},
	                { id: 'thirds', title: $filter('translate')('global.literals.state'), render: renderNotificationLastStatus, sortable: false},
	                { id: 'thirds', width: '150px', column: new LastStatusUpdateNotifications($filter, 'global.literals.lastStatusUpdate'), sortable: false},
					{ id: 'actions', columnName: 'id', className: 'text-center', title: actionsTitle(), render: actionsNotificationRender, sortable: false }
		        ];
				
				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.notificationTableDefinition.id = 'tableDNotificationDossier';
				vm.notificationTableDefinition.origin = 'reg';
		        vm.notificationTableDefinition.objectType = 'Notification';
				vm.notificationTableDefinition.objectIdToSelectOrigin ='tram';
				vm.notificationTableDefinition.objectIdToSelectType = 'Dossier';
		        vm.notificationTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.notificationTableDefinition.sort = tableOrderProperties.sort;
				vm.notificationTableDefinition.columns = columnsNotification;
		        vm.notificationTableDefinition.containerScope = $scope;
				vm.notificationTableDefinition.useTableObjects = true;
				vm.notificationTableDefinition.callOrigin = 'tram';
				vm.notificationTableDefinition.objectIdToSelect = angular.fromJson({id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id});
				
				vm.notificationBox = {
	                permissions: ((vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier)?{ new: ['new_output_entry_register']}:{}),
	                tableDefinition: vm.notificationTableDefinition,
	                isEdit: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier,
                    config: { 	
						dossier: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier,
	                	dossierTransactionId: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierTransactionId
					},
	                origin: 'tram',
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedNotificationBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberNotificationBox
            	}
				if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
	                vm.notificationBox.search= { saveSearch: addNotificationSave };
                    vm.notificationBox.new= {};
				}
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.notificationBox = {};
        		try{
					generateNotificationBox();
					$scope.$on('annexaBoxDossierNotificationsReloadTable', function(event, args) {
			            if(vm.notificationTableDefinition && vm.notificationTableDefinition.reloadInternalData) {
							vm.notificationTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierNotifications');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierOperations',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-operations.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
       controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 'AnnexaModalFactory', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http, AnnexaModalFactory) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.operationsAndInvoicesBox = {};

			var generateOperationsAndInvoicesBox= function(){
				if(!vm.operationTableDefinition){
					vm.operationTableDefinition = {};
				}
				
				vm.viewDossierOperationInvoice = function(item){
	        		if(item){
	        			TramNewFactory.getOperationInvoice(item).then(function (data) {
	        				var modal = angular.copy(globalModals.seeOperationInvoices);
	        				modal.data = data;
							if(modal.data && modal.data.operationType && modal.data.operationType[vm.languageColumn] && modal.data.operationSign && modal.data.operationSign == '1'){
								modal.data.operationType[vm.languageColumn] = $filter('translate')('global.operationsAndInvoices.cancellationOf')+" "+modal.data.operationType[vm.languageColumn]; 
							}
	        				if(modal.data && modal.data.third){
	        					var name = ((modal.data.third && modal.data.third.thirdType == "PHISICAL" && modal.data.third.name)?modal.data.third.name:"");
	                    		name = name + ((modal.data.third && modal.data.third.thirdType == "PHISICAL" && modal.data.third.surename1)?(((modal.data.third.surname1Particles)?" "+modal.data.third.surname1Particles:"")+" "+modal.data.third.surename1):"");
	                    		name = name + ((modal.data.third && modal.data.third.thirdType == "PHISICAL" && modal.data.third.surename2)?(((modal.data.third.surname2Particles)?" "+modal.data.third.surname2Particles:"")+" "+modal.data.third.surename2):"");
	                    		name = name + ((modal.data.third && modal.data.third.thirdType != "PHISICAL" && modal.data.third.corporateName)?modal.data.third.corporateName:"");
	                    		modal.data.third.completename = name;
	        				}
	        				if(modal.data.prevDef){
	        					var prevDef = $linq(GlobalDataFactory.previousDefinitive).firstOrDefault(undefined,"x => x.id == '"+modal.data.prevDef+"'");
	        					var name = ((prevDef)?prevDef.name:undefined);
	        					if(name){
	        						name = $filter('translate')('global.operationsAndInvoices.'+name);
	        					}else{
	        						name = modal.data.prevDef;
	        					}
	        					modal.data.prevDefAux = name;
	        				}else{
	        					modal.data.prevDefAux = modal.data.prevDef;
	        				}
	        				if(modal.data.createDocument){
	        					modal.data.createDocumentAux = $filter('translate')('global.literals.yes');
	        				}else{
	        					modal.data.createDocumentAux = $filter('translate')('global.literals.no');
	        				}
	        				if(!modal.data.amount){
	        					modal.data.amount = 0.00;
	        				}
							if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.userAccountInstances && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.userAccountInstances.length > 0){
								var uai = $linq(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.userAccountInstances).firstOrDefault(undefined, "x => x.code == '"+modal.data.instanceCode+"' && x.withProject");
								if(uai){
									modal.withProject = true;
				        		}else{
				        			modal.withProject = false;
				        		}
							}else{
								modal.withProject = false;
							}
	        				modal.languageColumn = vm.languageColumn;
	        				modal.submitFunction = function() {
	                            var self = this;
	                            self.alerts.length = 0;
	                            self.close();
	                        }
	                        AnnexaModalFactory.showModal('seeOperationInvoices', modal);
	        			}).catch(function (error) {
	                	}); 
	        		}
	        	}
				var actionsOperationRender = function (data, type, full, meta) {
					var content = '';
					if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier && full.instanceCode && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.userAccountInstances && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.userAccountInstances.length > 0){
        				var uai = $linq(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.userAccountInstances).firstOrDefault(undefined, "x => x.code == '"+full.instanceCode+"'");
						if(uai && uai.id){
							content += '<div class="btn-group flexbuttons">';
							content += '<button class="btn primary btn-sm" ng-click="$ctrl.viewDossierOperationInvoice(' + full.id + ')">';
			                content += '<i class="fa fa-eye" aria-hidden="true"></i>';
		    	            content += '<span>{{\'global.literals.see\' | translate}}</span>';
		        	        content += '</button>';
							content += '</div>';
						} 
					}
	                return content;
	            }
				
				var operationTypeCodeRender = function (data, type, full, meta) {
					var content = '';
					if(data){
						content += $filter('translate')('global.operationsAndInvoices.'+data);
					}
	                return content;
	            }
				
				var operationTypeRender = function (data, type, full, meta) {
					var content = '';
					if(full.operationSign && full.operationSign === 1){
						content += $filter('translate')('global.operationsAndInvoices.cancellationOf');
					}else if(data){
						content += data
					}
	                return content;
	            }
				
				var operationDocumentRender = function (data, type, full, meta) {
					var content = '';
					if(full.createDocument && full.document && full.document.id){
						content = '<annexa-href-permission-document document="tableObjects[' + full.id + '].document" name-properties="\'seeDocument\'" origin="DOSSIER"></annexa-href-permission-document></span>';
					}
	                return content;
	            }
	
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	            }

				var columnsOperation = [
					{ id: 'instanceCode', width: '150px', title: $filter('translate')('global.operationsAndInvoices.instance')},
					{ id: 'operationTypeCode', width: '150px', title: $filter('translate')('global.operationsAndInvoices.object'), render:operationTypeCodeRender},
					{ id: 'operationType.type', width: '150px', title: $filter('translate')('global.literals.type'), render:operationTypeRender},
	                { id: 'accountingReference', width: '150px', title:$filter('translate')('global.literals.code')},
					{ id: 'document.id', width: '150px', title:$filter('translate')('global.commonAdmin.literals.documentIdTitle'), render:operationDocumentRender},
					{ id: 'actions', columnName: 'id', className: 'text-center', title: actionsTitle(), render: actionsOperationRender, sortable: false }
		        ];
				
				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.operationTableDefinition.id = 'tableDOperationDossier';
				vm.operationTableDefinition.origin = 'tram';
		        vm.operationTableDefinition.objectType = 'DossierOperationInvoice';
				vm.operationTableDefinition.objectIdToSelectOrigin ='tram';
				vm.operationTableDefinition.objectIdToSelectType = 'DossierOperationInvoice';
		        vm.operationTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.operationTableDefinition.sort = tableOrderProperties.sort;
				vm.operationTableDefinition.columns = columnsOperation;
		        vm.operationTableDefinition.containerScope = $scope;
				vm.operationTableDefinition.useTableObjects = true;
				vm.operationTableDefinition.callOrigin = 'tram';
				vm.operationTableDefinition.objectIdToSelect = angular.fromJson({dossier:{id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id}});
				
				vm.operationsAndInvoicesBox = {
					boxTitle: 'global.operationsAndInvoices.title',
	                permissions: ((vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier)?{ new: ['process_dossier']}:{}),
	                tableDefinition: vm.operationTableDefinition,
                    config: { 	dossierComponent: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent},
	                origin: 'tram',
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedOperationsAndInvoicesBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberOperationsAndInvoicesBox,
					operationsActiveInDossier: vm.operationsActiveInDossier
            	}
			}

			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.operationsAndInvoicesBox = {};
        		try{
					generateOperationsAndInvoicesBox();
					$scope.$on('annexaBoxDossierOperationsReloadTable', function(event, args) {
			            if(vm.operationTableDefinition && vm.operationTableDefinition.reloadInternalData) {
							vm.operationTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierOperations');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierAddresses',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-addresses.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.showLongAddressLanguage = $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value;
			vm.addressBox = {};
			var generateAddressBox= function(){
				if(!vm.addressTableDefinition){
					vm.addressTableDefinition = {};
				}
				vm.deleteAddress = function(id) {
		        	var titleDeleteMessage = '';
		        	var confirmDeleteMessage = '';
		        	
		        	titleDeleteMessage = 'global.literals.delete';
		        	confirmDeleteMessage = 'global.literals.deleteAddressBox';
		        	
		        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
		                .then(function (data) {
							if(vm.addressBox && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier) {
								TramNewFactory.deleteAddress(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id, id).then(function (data) {
									if(vm.addressTableDefinition && vm.addressTableDefinition.reloadInternalData){
										vm.addressTableDefinition.reloadInternalData(true, true);	
									}
									vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierAddresses');
		                        }).catch(function (error) {
		                        	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
		            				vm.dossierTransactionComponent.dossierTransactionsComponent.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		                        });
		                    }
		                }).catch(function (data) {
		                    //Empty
		            });
		        }

				var addAddressSave = function(address) {
					if(!Array.isArray(address)){
						address = [address];
					}
					TramNewFactory.addAddresses(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id, address).then(function (data) {
						if(vm.addressTableDefinition && vm.addressTableDefinition.reloadInternalData) {
							vm.addressTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierAddresses');
					}).catch(function(error) {
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
                   		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
					});
				}
				var actionsAddressRender = function (data, type, full, meta) {
					var content = '';
					if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
						content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteAddress('+full.id+')" annexa-permission annexa-permission-only="[\'process_dossier\']">';
		                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
		                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
		                content += '</button>';
					}
	                return content;
	            }
					
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	            }

				var columnsAddress = [
					{ id: 'address.'+(vm.showLongAddressLanguage?'address':'shortAddress')+vm.languageColumn, width: '95%', title: $filter('translate')('global.territory.list.address')},
					{ id: 'actions', columnName: 'id', className: 'text-center', title: actionsTitle(), render: actionsAddressRender, sortable: false }
		        ];
				
				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.addressTableDefinition.id = 'tableDAddress';
				vm.addressTableDefinition.origin = 'tram';
		        vm.addressTableDefinition.objectType = 'DossierAddress';
				vm.addressTableDefinition.objectIdToSelectOrigin ='tram';
				vm.addressTableDefinition.objectIdToSelectType = 'DossierAddress';
		        vm.addressTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.addressTableDefinition.sort = tableOrderProperties.sort;
				vm.addressTableDefinition.columns = columnsAddress;
		        vm.addressTableDefinition.containerScope = $scope;
				vm.addressTableDefinition.useTableObjects = true;
				vm.addressTableDefinition.callOrigin = 'tram';
				vm.addressTableDefinition.objectIdToSelect = angular.fromJson({dossier:{id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id}});
				vm.addressTableDefinition.objectIdToAdded = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id;
				
				vm.addressBox = {
	                permissions: ((vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier)?{ new: ['process_dossier'], view:['process_dossier']}:{}),
                    tableDefinition: vm.addressTableDefinition,
	                config: { 	dossier: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier},
	                origin: 'tram',
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedAddressBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberAddressBox
            	}
				if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier && vm.allowHaveTerritorialAddress){
					vm.addressBox.search = {  placeholder: 'global.literals.placeholderSearchAddressBox', advanced: true, saveSearch: addAddressSave };
	                vm.addressBox.new =  ((TerritoryFactory.canCreateAddress())?{}:undefined);
				}
			}

			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.allowHaveTerritorialAddress = ((vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.procedure && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.procedure.allowHaveTerritorialAddress)?true:false);
				vm.haveAddresses = ((vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.haveAddresses)?true:false);
				vm.addressBox = {};
				try{
					generateAddressBox();
					$scope.$on('annexaBoxDossierAddressesReloadTable', function(event, args) {
			            if(vm.addressTableDefinition && vm.addressTableDefinition.reloadInternalData) {
							vm.addressTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierAddresses');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierCfs',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-cfs.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.dossierCustomFieldsBox = {};
			var generateDossierCustomFieldsBox= function(){
				var dossierCFs = ((vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.customFields)?vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.customFields : []);  
				if(dossierCFs){
					dossierCFs = $linq(dossierCFs).where("x => !x.hiddenField").toArray();
				}
				
				vm.dossierCustomFieldsBox = {
	                boxTitle: 'global.literals.customFields',
	                permissions: {},
	                content:  dossierCFs,
	                config: { 	dossier: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier},
					isEdit: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier,
	                origin: 'tram-view',
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedDossierCustomFieldsBox
            	}
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.dossierCustomFieldsBox = {};
        		try{
					generateDossierCustomFieldsBox();	
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierRightAccess',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-right-access.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.rightAccessBox = {};
			var generateRightAccessBox= function(){
				if(!vm.rightAddressTableDefinition){
					vm.rightAddressTableDefinition = {};
				}
				var isEditRightAccess = ((AnnexaPermissionsFactory.havePermission(['admin_access_rights']))?true:false);
				var addAccessRight = function() {
					TramNewFactory.addAccessRight();
				}
				var rightAccessStateRender = function(data, type, full, meta) {
		            var content = '';
					if(data){
						var classIcon = '';
						if (data === 'REQUEST'){
		                        classIcon = 'text-warn';
		                }else if (data === 'ACCEPTED'){
		                        classIcon = 'text-success';
		                }else if (data === 'DENIED'){
		                        classIcon = 'text-danger';
		                }else if (data === 'REVOKED'){
		                        classIcon = 'text-grey';
		                }else if(data === 'EXPIRED'){
			 					classIcon = 'text-blue';
						}
						content +='<i class="fa fa-circle m-r-xs '+classIcon+'" title="'+$filter('translate')('global.literals.rightAccessStatus.' + data)+'"></i>';
					}
		            return content;
		        }	
				var rightAccessTypeRender = function(data, type, full, meta) {
		            var content = '';
					if(full){
						if(full.user && full.user.id){
							content +=$filter('translate')('global.literals.user');
						}else if(full.profile && full.profile.id){
							content +=$filter('translate')('global.literals.profile');
						}else if(full.third && full.third.id){
							content +=$filter('translate')('global.thirds.literals.third');
						}
					}
		            return content;
		        }	
				var rightAccessAssignedRender = function(data, type, full, meta) {
		            var content = '';
					if(full){
						if(full.user && full.user.id){
							content +=full.user.completeName;
						}else if(full.profile && full.profile.id){
							content +=full.profile[vm.languageColumn];
						}else if(full.third && full.third.id){
							content +=full.third.completeName;
						}
					}
		            return content;
		        }	
				vm.viewRightAccess = function(ra){
					TramNewFactory.viewRightAccess(ra, $state);
				}
				
				vm.acceptRightAccess = function(ra){
					DialogsFactory.confirm('global.literals.rightAccess', 'global.literals.acceptRightAccessConfirm').then(function (data) {
	    		    	TramNewFactory.acceptRightAccess(ra.id).then(function(data) {
							if(vm.rightAddressTableDefinition && vm.rightAddressTableDefinition.reloadInternalData) {
								vm.rightAddressTableDefinition.reloadInternalData(true, true);	
							}
							vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRightAccess');
	                    }).catch(function (error) {
	                   		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	                   		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                    });
	               }).catch(function (data) {
	    	       });
				}
				
				vm.denyRightAccess = function(ra){
					DialogsFactory.confirm('global.literals.rightAccess', 'global.literals.denyRightAccessConfirm').then(function (data) {
	    		    	TramNewFactory.denyRightAccess(ra.id).then(function(data) {
							if(vm.rightAddressTableDefinition && vm.rightAddressTableDefinition.reloadInternalData) {
								vm.rightAddressTableDefinition.reloadInternalData(true, true);	
							}
							vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRightAccess');
	                    }).catch(function (error) {
	                    	vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	                   		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                    });
	               }).catch(function (data) {
	    	       });
				}
				
				vm.deleteRightAccess = function(ra){
					DialogsFactory.confirm('global.literals.rightAccess', 'global.literals.deleteRightAccessConfirm').then(function (data) {
	    		    	TramNewFactory.deleteRightAccess(ra.id).then(function(data) {
							if(vm.rightAddressTableDefinition && vm.rightAddressTableDefinition.reloadInternalData) {
								vm.rightAddressTableDefinition.reloadInternalData(true, true);	
							}
		                }).catch(function (error) {
		                	vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	                   		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                    });
	               }).catch(function (data) {
	    	       });
				}
				
				vm.revokeRightAccess = function(ra){
					DialogsFactory.confirm('global.literals.rightAccess', 'global.literals.revokeRightAccessConfirm').then(function (data) {
	    		    	TramNewFactory.revokeRightAccess(ra.id).then(function(data) {
							if(vm.rightAddressTableDefinition && vm.rightAddressTableDefinition.reloadInternalData) {
								vm.rightAddressTableDefinition.reloadInternalData(true, true);	
							}
							vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRightAccess');
		                }).catch(function (error) {
	                    	vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
	                   		vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	                    });
	               }).catch(function (data) {
	    	       });
				}
				var actionsRightAccessRender = function (data, type, full, meta) {
					var content = '';
					if(isEditRightAccess){
						var date = new Date();
						var finished = false;
						if(full.endDate && full.endDate < date){
							finished = true;
						}
						var current = false;
						if(full.startDate && full.endDate && full.startDate <= date && full.endDate >= date){
							current = true;
						}
						var canModifyStatus = false;
						if((full.rightAccessStatus == 'REQUEST' && !finished) || (full.rightAccessStatus == 'ACCEPTED' && !current  && !finished) || (full.rightAccessStatus == 'ACCEPTED' && current)){
							canModifyStatus = true;
						}
						content += '<div class="btn-group dropdown flexbuttons">';
					    content += '    <a class="btn primary" tabindex="0" href=""  ng-click="$ctrl.viewRightAccess(tableObjects[' + full.id + '])">';
						content += '        <i class="fa fa-eye" aria-hidden="true"></i>'+$filter('translate')('global.literals.see');
						content += '    </a>';
						if(canModifyStatus){
	                        content += '    <div class="btn-group" >';
	                        content += '    	<button type="button" class="btn primary dropdown-toggle p-x-sm" data-toggle="dropdown" title="'+$filter('translate')('global.literals.showOptions')+'">';
							content += '            <span class="sr-only">'+$filter('translate')('global.literals.showOptions')+'</span>';
							content += '        </button>';
							content += '        <div class="dropdown-menu primary pull-right">';
							if(full.rightAccessStatus == 'REQUEST' && !finished){
								content += '        	<button type="button" class="dropdown-item" ng-click="$ctrl.acceptRightAccess(tableObjects[' + full.id + '])">';
								content += '                <span>'+$filter('translate')('global.literals.accept')+'</span>';
								content += '            </button>';
							}
							if(full.rightAccessStatus == 'REQUEST' && !finished){
								content += '            <button type="button" class="dropdown-item" ng-click="$ctrl.denyRightAccess(tableObjects[' + full.id + '])">';
								content += '                <span>'+$filter('translate')('global.literals.deny')+'</span>';
								content += '            </button>';
							}
							if(full.rightAccessStatus == 'ACCEPTED' && !current){
								content += '            <button type="button" class="dropdown-item" ng-click="$ctrl.deleteRightAccess(tableObjects[' + full.id + '])">';
								content += '                <span>'+$filter('translate')('global.literals.delete')+'</span>';
								content += '            </button>';
							}
							if(full.rightAccessStatus == 'ACCEPTED' && current){
								content += '            <button type="button" class="dropdown-item" ng-click="$ctrl.revokeRightAccess(tableObjects[' + full.id + '])">';
								content += '                <span>'+$filter('translate')('global.literals.revoke')+'</span>';
								content += '            </button>';
							}
	        				content += '		</div>';
	                        content += '    </div>';
						}
                        content += '</div>';
					}
	                return content;
	            }
	
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	            }

				var columnsRightAccessAudit = [
					{ id: 'rightAccessStatus', width: '10px', title: $filter('translate')('global.literals.state'), render:rightAccessStateRender},
	            	{ id: 'id', width: '10%', title:$filter('translate')('global.literals.type'), render:rightAccessTypeRender},
		        	{ id: 'id', width: '50%', column: new DateTimeColumn($filter, 'global.literals.assigned_to'), render:rightAccessAssignedRender},
					{ id: 'startDate', width: '10px', column: new DateTimeColumn($filter, 'global.delegate.startDate')},
	            	{ id: 'endDate', width: '10%', column: new DateTimeColumn($filter, 'global.literals.dateTo')},
		        ];
				if(isEditRightAccess){
					columnsRightAccessAudit.push({ id: 'actions', columnName: 'id', className: 'text-center', title: actionsTitle(), render: actionsRightAccessRender, sortable: false });
				}
				var tableOrderProperties = {sortName: 'actionDate', sort: [[2,'desc']]};
		        vm.rightAddressTableDefinition.id = 'tableDRightAccess';
				vm.rightAddressTableDefinition.origin = 'tram';
		        vm.rightAddressTableDefinition.objectType = 'RightAccess';
				vm.rightAddressTableDefinition.objectIdToSelectOrigin ='tram';
				vm.rightAddressTableDefinition.objectIdToSelectType = 'RightAccess';
		        vm.rightAddressTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.rightAddressTableDefinition.sort = tableOrderProperties.sort;
				vm.rightAddressTableDefinition.columns = columnsRightAccessAudit;
		        vm.rightAddressTableDefinition.containerScope = $scope;
				vm.rightAddressTableDefinition.useTableObjects = true;
				vm.rightAddressTableDefinition.callOrigin = 'tram';
				vm.rightAddressTableDefinition.objectIdToSelect = angular.fromJson({dossier:{id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id}});
				
				var adminRightAccess = false;
				if(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
					adminRightAccess = true;
				}else if($state.current.name == 'annexa.archive.finally.see' || $state.current.name == 'annexa.archive.close.see'){
        			if(!(vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus == 'ARCHIVED' && vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.archiveDefinitiveDate)){
                		adminRightAccess = true;
                	}
        		};

				vm.rightAccessBox = {
					boxTitle: 'global.archive.accessRights', 
	                permissions: ((adminRightAccess)?{new:['admin_access_rights']}:{}),
	                tableDefinition: vm.rightAddressTableDefinition,
	                new: ((adminRightAccess)?{newFunc: addAccessRight}:{}),
					search: ((adminRightAccess)?{}:undefined),
	                config: { dossier: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier },
	                origin: 'tram',
					canShow: ((adminRightAccess && AnnexaPermissionsFactory.havePermission(['admin_access_rights']))?true:false),
	                origin: 'tram',
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: GlobalDataFactory.rightAccessStatuses,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedRightAccessBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberRightAccessBox
				};
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.rightAccessBox = {};
        		try{
					generateRightAccessBox();	
					$scope.$on('annexaBoxDossierRightAccessReloadTable', function(event, args) {
			            if(vm.rightAddressTableDefinition && vm.rightAddressTableDefinition.reloadInternalData) {
							vm.rightAddressTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRightAccess');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierSessions',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-sessions.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.sessionBox = {};
        
			var generateSessionBox= function(){
				if(!vm.sessionTableDefinition){
					vm.sessionTableDefinition = {};
				}
				var canShowSession = AnnexaPermissionsFactory.havePermission('view_sessions');
				
				var sessionStatusRender = function(data, type, full, meta) {
		            var content = '';
					var state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
	            	if(data){
	            		state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == '"+data+"'");
	            	}
	            	if(state){
	            		content += '<span title="'+$filter('translate')(state.name)+'">';
						content +='<i class="fa fa-'+state.icon+' '+state.style+' fa-lg" aria-hidden="true"></i>';
						content +='<span class="sr-only">'+$filter('translate')(state.name)+'</span>';
						content +='</span>';
	            	}
		            return content;
		        }
				vm.redirectSession = function(sessionId){
					window.open($state.href('annexa.sec.sessions.view', { session: sessionId }), '_blank');
				}
				
				var sessionNumberRender = function(data, type, full, meta) {
		            var content = '';
					if(data){
						if(canShowSession){
							content += '<a class="text-primary _400" ng-click="$ctrl.redirectSession('+full.id+')" tabindex="0" >'+data+'</a>';
						}else{
							content += '<span>'+data+'</span>';
						}
					}else if(full.sessionDateFirstConvene){
						if(canShowSession){
							content += '<a class="text-primary _400" ng-click="$ctrl.redirectSession('+full.id+')" tabindex="0" >'+full.sessionDateFirstConvene+'</a>';
						}else{
							content += '<span>'+full.sessionDateFirstConvene+'</span>';
						}
					}
					return content;
		        }
				var sessionOrganRender = function(data, type, full, meta) {
		            var content = '';
					if(data){
						if(canShowSession){
							content += '<a class="text-primary _400" ng-click="$ctrl.redirectSession('+full.id+')" tabindex="0" >'+data[vm.languageColumn]+'</a>';
						}else{
							content += '<span>'+data[vm.languageColumn]+'</span>';
						}
					}
		            return content;
		        }

				var columnsSession = [
					{ id: 'state', width: '53px', title: $filter('translate')('global.literals.state'), render: sessionStatusRender},
	            	{ id: 'sessionNumber', width: '25%', title:$filter('translate')('global.sec.literals.sessionNumber'), render:sessionNumberRender},
		        	{ id: 'organ', title:$filter('translate')('global.sec.literals.organ'), render:sessionOrganRender},
		        ];

				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.sessionTableDefinition.id = 'tableDSession';
				vm.sessionTableDefinition.origin = 'sec';
		        vm.sessionTableDefinition.objectType = 'Session';
				vm.sessionTableDefinition.objectIdToSelectOrigin ='tram';
				vm.sessionTableDefinition.objectIdToSelectType = 'Dossier';
		        vm.sessionTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.sessionTableDefinition.sort = tableOrderProperties.sort;
				vm.sessionTableDefinition.columns = columnsSession;
		        vm.sessionTableDefinition.containerScope = $scope;
				vm.sessionTableDefinition.useTableObjects = true;
				vm.sessionTableDefinition.callOrigin = 'tram';
				vm.sessionTableDefinition.objectIdToSelect = angular.fromJson({id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id});
				
				vm.sessionBox = {
	                tableDefinition: vm.sessionTableDefinition,
	                config: {},
	                origin: 'tram',
					isEdit: false,
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedSessionBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberSessionBox
            	}
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.sessionBox = {};
        		try{
					generateSessionBox();	
					$scope.$on('annexaBoxDossierSessionsReloadTable', function(event, args) {
			            if(vm.sessionTableDefinition && vm.sessionTableDefinition.reloadInternalData) {
							vm.sessionTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierSessions');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierDecrees',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-decrees.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.decreeBox = {};
        
			var generateDecreeBox= function(){
				if(!vm.decreeTableDefinition){
					vm.decreeTableDefinition = {};
				}
				var canShowDecree = AnnexaPermissionsFactory.havePermission('view_decrees');
				
				var decreeStatusRender = function(data, type, full, meta) {
		            var content = '';
					var state = $linq(SecFactory.decreeStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
	            	if(data){
	            		state = $linq(SecFactory.decreeStates).singleOrDefault(undefined, "x => x.id == '"+data+"'");
	            	}
	            	if(state){
	            		content += '<span title="'+$filter('translate')(state.name)+'">';
						content +='<i class="fa fa-'+state.icon+' '+state.style+' fa-lg" aria-hidden="true"></i>';
						content +='<span class="sr-only">'+$filter('translate')(state.name)+'</span>';
						content +='</span>';
	            	}
		            return content;
		        }
				vm.redirectDecree = function(decreeId){
					window.open($state.href('annexa.sec.decrees.edit', { decrees: decreeId }), '_blank');
				}
				
				var decreeNumberRender = function(data, type, full, meta) {
		            var content = '';
					if(data){
						if(canShowDecree){
							content += '<a class="text-primary _400" ng-click="$ctrl.redirectDecree('+full.id+')" tabindex="0" >'+data+'</a>';
						}else{
							content += '<span>'+data+'</span>';
						}
					}else{
						if(canShowDecree){
							content += '<a class="text-primary _400" ng-click="$ctrl.redirectDecree('+full.id+')" tabindex="0" >'+$filter('translate')('global.sec.literals.noDecreeNumber')+'</a>';
						}else{
							content += '<span>'+$filter('translate')('global.sec.literals.noDecreeNumber')+'</span>';
						}
					}
					return content;
		        }

				var decreeDateRender = function(data, type, full, meta) {
		            var content = '';
					if(data){
						if(canShowDecree){
							content += '<a class="text-primary _400" ng-click="$ctrl.redirectDecree('+full.id+')" tabindex="0" >'+$filter('date')(new Date(data), 'dd/MM/yyyy HH:mm:ss')+'</a>';
						}else{
							content += '<span>'+$filter('date')(new Date(data), 'dd/MM/yyyy HH:mm:ss')+'</span>';
						}
					}else{
						if(canShowDecree){
							content += '<a class="text-primary _400" ng-click="$ctrl.redirectDecree('+full.id+')" tabindex="0" >'+$filter('translate')('global.sec.literals.noDecreeDate')+'</a>';
						}else{
							content += '<span>'+$filter('translate')('global.sec.literals.noDecreeDate')+'</span>';
						}
					}
					return content;
		        }
				var decreeExtractRender = function(data, type, full, meta) {
		            var content = '';
					if(data){
						if(canShowDecree){
							content += '<a class="text-primary _400" ng-click="$ctrl.redirectDecree('+full.id+')" tabindex="0" >'+data+'</a>';
						}else{
							content += '<span>'+data+'</span>';
						}
					}
		            return content;
		        }

				var columnsDecree = [
					{ id: 'status', width: '53px', title: $filter('translate')('global.literals.state'), render: decreeStatusRender},
	            	{ id: 'decreeNumber', width: '25%', title:$filter('translate')('global.sec.literals.decreeNumberAbrv'), render:decreeNumberRender},
		        	{ id: 'decreeDate', width: '25%', title:$filter('translate')('global.sec.literals.decreeDate'), render:decreeDateRender},
		        	{ id: 'extract', title:$filter('translate')('global.literals.extract'), render:decreeExtractRender}
		        ];
				
				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
		        vm.decreeTableDefinition.id = 'tableDDecree';
				vm.decreeTableDefinition.origin = 'sec';
		        vm.decreeTableDefinition.objectType = 'Decree';
				vm.decreeTableDefinition.objectIdToSelectOrigin ='tram';
				vm.decreeTableDefinition.objectIdToSelectType = 'Dossier';
		        vm.decreeTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.decreeTableDefinition.sort = tableOrderProperties.sort;
				vm.decreeTableDefinition.columns = columnsDecree;
		        vm.decreeTableDefinition.containerScope = $scope;
				vm.decreeTableDefinition.useTableObjects = true;
				vm.decreeTableDefinition.callOrigin = 'tram';
				vm.decreeTableDefinition.objectIdToSelect = angular.fromJson({id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id});
				
				vm.decreeBox = {
	                tableDefinition: vm.decreeTableDefinition,
	                config: {},
	                origin: 'tram',
					isEdit: false,
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedDecreeBox,
					numberBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.numberDecreeBox
            	}
			}

			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.decreeBox = {};
        		try{
					generateDecreeBox();	
					$scope.$on('annexaBoxDossierDecreesReloadTable', function(event, args) {
			            if(vm.decreeTableDefinition && vm.decreeTableDefinition.reloadInternalData) {
							vm.decreeTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierDecrees');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierAudit',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-audit.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.dossierAuditBox = {};
        
			var generateDossierAuditBox= function(){
				if(!vm.auditTableDefinition){
					vm.auditTableDefinition = {};
				}
			
				var auditActionRender = function(data, type, full, meta) {
		            var content = '';
					if(data){
						var auditAction = $linq(GlobalDataFactory.auditActions).firstOrDefault({language1:data, language2:data,languge3:data},"x=> x.id == '"+data+"'");
            			content =  $filter('translate')(auditAction[vm.languageColumn]);
					}
		            return content;
		        }	
				var columnsArchiveAudit = [
					{ id: 'action', width: '25px', title: $filter('translate')('global.literals.action'), render:auditActionRender},
	            	{ id: 'userAction.completeName', width: '50%', title:$filter('translate')('global.literals.user')},
		        	{ id: 'actionDate', width: '25%', column: new DateTimeColumn($filter, 'global.literals.creation_date')}
		        ];
				var tableOrderProperties = {sortName: 'actionDate', sort: [[2,'desc']]};
		        vm.auditTableDefinition.id = 'tableDArchiveAudit';
				vm.auditTableDefinition.origin = 'tram';
		        vm.auditTableDefinition.objectType = 'ArchiveAudit';
				vm.auditTableDefinition.objectIdToSelectOrigin ='tram';
				vm.auditTableDefinition.objectIdToSelectType = 'ArchiveAudit';
		        vm.auditTableDefinition.sortName = tableOrderProperties.sortName;
		        vm.auditTableDefinition.sort = tableOrderProperties.sort;
				vm.auditTableDefinition.columns = columnsArchiveAudit;
		        vm.auditTableDefinition.containerScope = $scope;
				vm.auditTableDefinition.useTableObjects = true;
				vm.auditTableDefinition.callOrigin = 'tram';
				vm.auditTableDefinition.objectIdToSelect = angular.fromJson({dossier:{id:vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id}});
				
				vm.dossierAuditBox = {
					tableDefinition: vm.auditTableDefinition,
	                origin: 'tram',
					show: _.contains(['CLOSED', 'TRANSFER', 'REJECTED', 'ARCHIVED'],vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.dossierStatus),
					alerts: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
					legend: undefined,
					closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedDossierAuditBox
				};
			}
			
			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				vm.dossierAuditBox = {};
        		try{
					generateDossierAuditBox();	
					$scope.$on('annexaBoxDossierAuditReloadTable', function(event, args) {
			            if(vm.auditTableDefinition && vm.auditTableDefinition.reloadInternalData) {
							vm.auditTableDefinition.reloadInternalData(true, true);	
						}
						vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierAudit');
			        });
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    })
	.component('annexaBoxObjectDossierDocumentationToProvide',{
        templateUrl: './components/tram/annexa-box-object-dossier/annexa-box-object-dossier-documentation-to-provide.html',
        require: {
            dossierInfoComponent: '^^annexaBoxObjectDossierInfo'
        },
        controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 
		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http) {
            var vm = this;
			vm.languageColumn = undefined;
			vm.documentationToProvideBox = {};
			vm.documentationToProvideUpdate = function(item){
				if(item){
					item.objectType = 'DOSSIER';
					item.objectId = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id;
				}
				return TramNewFactory.updateDocumentationToProvideUpate(item, vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier.id);	
			}
			this.$onInit = function() {
				vm.languageColumn = vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.languageColumn;
				try{
					vm.documentationToProvideBox = {
						type: "DOSSIER",
						closeBox: vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.closedDocumentationToProvideBox,
						content: {
							globals:[],
							thirds:[],
							edit:true,
							show:true							
						},
						updateFunction: vm.documentationToProvideUpdate,
						object:  vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossier
					};
					if(TramNewFactory.dossierDocumentationToProvide){
						if(TramNewFactory.dossierDocumentationToProvide.dossierGlobals){
							vm.documentationToProvideBox.content.globals = TramNewFactory.dossierDocumentationToProvide.dossierGlobals;
						}
						if(TramNewFactory.dossierDocumentationToProvide.dossierThirds){
							vm.documentationToProvideBox.content.thirds = TramNewFactory.dossierDocumentationToProvide.dossierThirds;
						}
						if(!TramNewFactory.dossierDocumentationToProvide.userCanModify || !vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.isUpdateDossier){
							vm.documentationToProvideBox.content.edit = false;
						}
						if((!TramNewFactory.dossierDocumentationToProvide.procedureGlobalsOrig || TramNewFactory.dossierDocumentationToProvide.procedureGlobalsOrig.length === 0) && 
								(!TramNewFactory.dossierDocumentationToProvide.procedureThirdsOrig || TramNewFactory.dossierDocumentationToProvide.procedureThirdsOrig.length === 0) &&
								(!TramNewFactory.dossierDocumentationToProvide.archiveClassificationGlobalsOrig || TramNewFactory.dossierDocumentationToProvide.archiveClassificationGlobalsOrig.length === 0) &&
								(!TramNewFactory.dossierDocumentationToProvide.archiveClassificationThirdsOrig || TramNewFactory.dossierDocumentationToProvide.archiveClassificationThirdsOrig.length === 0) &&
								(!TramNewFactory.dossierDocumentationToProvide.dossierGlobals || TramNewFactory.dossierDocumentationToProvide.dossierGlobals.length === 0) && 
								(!TramNewFactory.dossierDocumentationToProvide.dossierThirds || TramNewFactory.dossierDocumentationToProvide.dossierThirds.length === 0)){
							vm.documentationToProvideBox.content.show = false;
						}
					}
				}catch(error){
					 console.error(error);
				}
			}
        }],
        bindings: {}
    });