/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaTransactionSubprocedure', {
        templateUrl: './components/tram/annexa-transaction-subprocedure/annexa-transaction-subprocedure.html',
        controller: ['Language', 'TramNewFactory','$rootScope', 'GlobalDataFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', 'apiAdmin', 'RestService', '$scope', function (Language, TramNewFactory, $rootScope, GlobalDataFactory, $filter, globalModals, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory, apiAdmin, RestService, $scope) {
            var vm = this;
            vm.languageColumn = "language1";
			$scope.$on('newSubprocessSelected', function(event, args) {
				vm.data.transaction = undefined;
	            if(args && args.item && args.item.id){
					if(vm.data && vm.data.subprocedures && vm.data.subprocedures.length > 0){
						var t = $linq(vm.data.subprocedures).firstOrDefault(undefined, "x => x.procedure && x.procedure.id == "+args.item.id);
						if(t && t.trans){
							vm.data.transaction = t.trans;
						}
					}		
				}
	        });
			vm.showUsersProfiles = false;
            vm.loadProfilesFunction = function (value) {
                if (!value || !value.val) {
                    return [];
                } else if (value.val != '*' && value.val.length < 3) {
                    return [];
                } else {
                    if (value.val == '*') {
                        value.val = '';
                    }
                    return RestService.filterData('common', 'Profile', {}, {search: value.val, langColumn:vm.languageColumn}, 0, 10, '', 3).then(function (data) {
                        var items = [];
                        if (data.data && data.data.content) {
                            _.forEach(JSOG.decode(data.data.content), function (item) {
                                items.push({id: item.id, value: item[vm.languageColumn], userProfiles:item.userProfiles});
                            })
                        }
                        return items;
                    }).catch(function () {
                        return [];
                    });
                }
            };

            vm.getUserProfilesDTIProfile = function (profile) {
            	var userProfiles = [];
            	if(profile && profile.userProfiles){
            		_.forEach(profile.userProfiles, function (value, key) {
            			userProfiles.push(value);
            		});
            	}
            	return userProfiles;
            }
            
			this.$onInit = function () {
				vm.datatransaction = undefined;
				vm.languageColumn = Language.getActiveColumn();
            	vm.showUsersProfiles = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
					($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria' || $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Opcional')) || $rootScope.esetMode);
			}
			
        }],
        bindings: {
            data: '='
        }
    })