angular
    .module('annexaApp')
    .component('annexaSpelFunctionParams', {
        templateUrl: './components/tram/annexa-spel-function-params/annexa-spel-function-params.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'AdminFactory', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addParameter = function () {
            	var modal = angular.copy(CommonAdminModals.spelFunctionParamNew);
                modal.annexaFormly.model = {};
                modal.annexaFormly.options = { watchAllExpressions: true };
				var typeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'type'");
				if(typeField && typeField.templateOptions){
					typeField.templateOptions.options = AdminFactory.projectEntities;
				}
				var typeListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeList'");
				if(typeListField && typeListField.templateOptions){
					var ent = angular.copy(AdminFactory.projectEntities);
					ent.shift();//traiem el list
					typeListField.templateOptions.options = ent;
				}
                AnnexaFormlyFactory.showModal('modalNewStreetNumber', modal, vm.saveParameter, false);
            }
            
            vm.saveParameter = function(parameter){
            	if(parameter && parameter.annexaFormly && parameter.annexaFormly.model && parameter.annexaFormly.model.modal_body){
            		var model = {
            			name:parameter.annexaFormly.model.modal_body.name,
            			type:parameter.annexaFormly.model.modal_body.type.id,
						typeList:((parameter.annexaFormly.model.modal_body.typeList && parameter.annexaFormly.model.modal_body.typeList.id)?parameter.annexaFormly.model.modal_body.typeList.id:undefined)
            		}
		        	if(vm.spelFunctionId){
		        		model.function = {id:vm.spelFunctionId};
            		}
		        	if(!vm.isNew){
	        			RestService.insert('./api/tram/spel_function_param', model).then(function(data) {
	        				if(data){
	                            vm.params.push(JSOG.decode(data));	        					
		        			}
	        				parameter.close();
							if(GlobalDataFactory.spelFunctions){
		                    	GlobalDataFactory.spelFunctions.length = 0;
		                    }else{
		                    	GlobalDataFactory.spelFunctions = [];
		                    }
		                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('spelFunctions')){
		                    	CacheFactory.get('globalDataCache').remove('spelFunctions');
		                    }
		                    GlobalDataFactory.loadGlobalData();
                        }).catch(function(error) {
                    		parameter.alerts.push({ msg: "Error"});
                        });
		        	}else{
		        		vm.params.push(model);
		        		parameter.close();
		        	}
            	}
            }

            vm.editParameter = function (parameter, index) {
            	var modal = angular.copy(CommonAdminModals.spelFunctionParamNew);
            	var parameterUpdate = angular.copy(parameter);
				if(parameterUpdate.type){
					parameterUpdate.type = {id:parameterUpdate.type, name:parameterUpdate.type};	
				}
            	if(parameterUpdate.typeList){
					parameterUpdate.typeList = {id:parameterUpdate.typeList, name:parameterUpdate.typeList};	
				}
            	modal.extra = {
            		parameter:parameter,
	            	index:index
            	}
				var typeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'type'");
				if(typeField && typeField.templateOptions){
					typeField.templateOptions.options = AdminFactory.projectEntities;
				}
				var typeListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeList'");
				if(typeListField && typeListField.templateOptions){
					var ent = angular.copy(AdminFactory.projectEntities);
					ent.shift();//traiem el list
					typeListField.templateOptions.options = ent;
				}
            	modal.annexaFormly.model = {modal_body:parameterUpdate};
            	modal.annexaFormly.options = { watchAllExpressions: true };
            	AnnexaFormlyFactory.showModal('modalparameterUpdate', modal, vm.updateParameter, false);
            }
            
            vm.updateParameter = function(parameter){
            	if(parameter.extra.parameter){
	            	if(parameter && parameter.annexaFormly && parameter.annexaFormly.model && parameter.annexaFormly.model.modal_body){
			        	if(!vm.isNew){
			        		var parameterAux = angular.copy(parameter.extra.parameter);
			        		parameterAux.name = parameter.annexaFormly.model.modal_body.name; 
			        		parameterAux.type = parameter.annexaFormly.model.modal_body.type.id;
							parameterAux.typeList = ((parameterAux.type && parameterAux.type === 'List' && parameter.annexaFormly.model.modal_body.typeList && parameter.annexaFormly.model.modal_body.typeList.id)?parameter.annexaFormly.model.modal_body.typeList.id:undefined);  
		            		if(vm.spelFunctionId){
		            			parameterAux.function = {id:vm.spelFunctionId};
		            		}
		            		RestService.update('./api/tram/spel_function_param/' + parameterAux.id, parameterAux).then(function (data) {
		            			if(data){
                    				var decoded = JSOG.decode(data);
       			        			parameter.extra.parameter.name = decoded.name; 
       			        			parameter.extra.parameter.type = decoded.type;
									parameter.extra.parameter.typeList = decoded.typeList;
       			        			parameter.extra.parameter.function = decoded.function; 
                    			}
                    			parameter.close();
								if(GlobalDataFactory.spelFunctions){
			                    	GlobalDataFactory.spelFunctions.length = 0;
			                    }else{
			                    	GlobalDataFactory.spelFunctions = [];
			                    }
			                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('spelFunctions')){
			                    	CacheFactory.get('globalDataCache').remove('spelFunctions');
			                    }
			                    GlobalDataFactory.loadGlobalData();
                            }).catch(function (error) {
                        		parameter.alerts.push({ msg: "Error"});
                            });
			        	}else{
			        		if(parameter.annexaFormly.model.name){
			        			parameter.extra.parameter.name = parameter.annexaFormly.model.name; 
				        	}
			        		if(parameter.annexaFormly.model.type && parameter.annexaFormly.model.type.id){
			        			parameter.extra.parameter.type = parameter.annexaFormly.model.type;  
		            		}
							if(parameter.extra.parameter.type && parameter.extra.parameter.type === 'List' && parameter.annexaFormly.model.typeList && parameter.annexaFormly.model.typeList.id){
			        			parameter.extra.parameter.typeList = parameter.annexaFormly.model.typeList;  
		            		}else{
			        			parameter.extra.parameter.typeList = undefined;
							}
		            		parameter.close();
			        	}
	            	}
            	}
            }
            
            vm.deleteParameter = function (parameter, index) {
               	DialogsFactory.confirm('global.literals.spelFunctionparameterTitle', 'global.literals.spelFunctionparameterTitleBody').then(function (dataAux) {
           			if(parameter){
		        		var parameterIndex = -1;
		        		if(parameter.id){
		        			parameterIndex = $linq(vm.params).indexOf("x => x.id == "+parameter.id);
		        		}else{
		        			parameterIndex = index;
		        		}
		        		if(parameterIndex > -1){
				        	if(!vm.isNew){
				        		if(parameter && parameter.id && ( parameterIndex || parameterIndex == 0) && vm.params && vm.params.length >= parameterIndex){
		                    		RestService.delete('./api/tram/spel_function_param/' + parameter.id).then(function (data) {
		                    			vm.params.splice(parameterIndex, 1);
										if(GlobalDataFactory.spelFunctions){
					                    	GlobalDataFactory.spelFunctions.length = 0;
					                    }else{
					                    	GlobalDataFactory.spelFunctions = [];
					                    }
					                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('spelFunctions')){
					                    	CacheFactory.get('globalDataCache').remove('spelFunctions');
					                    }
					                    GlobalDataFactory.loadGlobalData();
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		                        }
				        	}else{
				        		vm.params.splice(parameterIndex, 1);
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
				if(vm.isNew){
					vm.canEdit = true;
				}else{
					if(vm.canEdit == undefined){
						vm.canEdit = true;
					}
				}
            }
            
        }],
        bindings: {
        	params: '=',
            isNew: '=',
            spelFunctionId:'=?',
			canEdit: '=?'
        }
    })