angular
    .module('annexaApp')
    .component('annexaExpressionEvaluatorParams', {
        templateUrl: './components/tram/annexa-expression-evaluator-params/annexa-expression-evaluator-params.html',
        controller: ['$http','CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 
        function ($http,CommonService, Language, RestService, $rootScope, $filter, RestService, globalModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory) {
            var vm = this;
			vm.entities = [];
            vm.languageColumn = Language.getActiveColumn();
            vm.addParameter = function () {
            	var modal = angular.copy(globalModals.expressionEvaluatorParam);
                modal.annexaFormly.model = {};
                modal.annexaFormly.options = { watchAllExpressions: true };
				var typeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'type'");
				if(typeField && typeField.templateOptions){
					typeField.templateOptions.options = vm.entities;
				}
				var typeListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeList'");
				if(typeListField && typeListField.templateOptions){
					var ent = angular.copy(vm.entities);
					ent.shift();//traiem el sense
					ent.shift();//traiem el list
					typeListField.templateOptions.options = ent;
				}
                AnnexaFormlyFactory.showModal('modalExpressionEvaluatorParam', modal, vm.saveParameter, false);
            }
            vm.saveParameter = function(parameter){
            	if(parameter && parameter.annexaFormly && parameter.annexaFormly.model && parameter.annexaFormly.model.modal_body){
            		var model = {
            			name:parameter.annexaFormly.model.modal_body.name,
						type: parameter.annexaFormly.model.modal_body.type,
						typeList: parameter.annexaFormly.model.modal_body.typeList
            		}
					if(parameter.annexaFormly.model.modal_body.type && parameter.annexaFormly.model.modal_body.type.id){
						if(parameter.annexaFormly.model.modal_body.type.id.endsWith("-ENUM") || _.contains(['Boolean','Double','Integer','Long','String','List'], parameter.annexaFormly.model.modal_body.type.id)){
							model.value = parameter.annexaFormly.model.modal_body.value;
						}else{
							model.id = parameter.annexaFormly.model.modal_body.id;
						}
					}else{
						model.value = parameter.annexaFormly.model.modal_body.value;
					}
					
		        	vm.params.push(model);
		        	parameter.close();
            	}
            }

            vm.editParameter = function (parameter, index) {
            	var modal = angular.copy(globalModals.expressionEvaluatorParam);
            	var parameterUpdate = angular.copy(parameter);
            	modal.extra = {
            		parameter:parameter,
	            	index:index
            	}
            	modal.annexaFormly.model = {modal_body:parameterUpdate};
				if(modal.annexaFormly.model.modal_body && (!modal.annexaFormly.model.modal_body.type || !modal.annexaFormly.model.modal_body.type.id)){
					modal.annexaFormly.model.modal_body.type = vm.entities[0];
				}
            	modal.annexaFormly.options = { watchAllExpressions: true };
				var typeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'type'");
				if(typeField && typeField.templateOptions){
					typeField.templateOptions.options = vm.entities;
				}
				var typeListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeList'");
				if(typeListField && typeListField.templateOptions){
					var ent = angular.copy(vm.entities);
					ent.shift();//traiem el list
					typeListField.templateOptions.options = ent;
				}
            	AnnexaFormlyFactory.showModal('modalparameterUpdate', modal, vm.updateParameter, false);
            }
            
            vm.updateParameter = function(parameter){
            	if(parameter.extra.parameter){
	            	if(parameter && parameter.annexaFormly && parameter.annexaFormly.model && parameter.annexaFormly.model.modal_body){
		        		if(parameter.annexaFormly.model.modal_body.name){
		        			parameter.extra.parameter.name = parameter.annexaFormly.model.modal_body.name; 
			        	}
		        		if(parameter.annexaFormly.model.modal_body.type && parameter.annexaFormly.model.modal_body.type.id){
		        			parameter.extra.parameter.type = parameter.annexaFormly.model.modal_body.type;
							if(_.contains(['List'], parameter.annexaFormly.model.modal_body.type.id)){
								if(parameter.annexaFormly.model.modal_body.typeList && parameter.annexaFormly.model.modal_body.typeList.id){
				        			parameter.extra.parameter.typeList = parameter.annexaFormly.model.modal_body.typeList;  
			            		}else{
									parameter.extra.parameter.typeList = undefined;
								}
						  	}else{
								parameter.extra.parameter.typeList = undefined;
							}
	            		}else{
							parameter.extra.parameter.type = undefined;
						}
						if(parameter.annexaFormly.model.modal_body.type && parameter.annexaFormly.model.modal_body.type.id){
							if(parameter.annexaFormly.model.modal_body.type.id.endsWith("-ENUM") || _.contains(['Boolean','Double','Integer','Long','String','List'], parameter.annexaFormly.model.modal_body.type.id)){
								if(parameter.annexaFormly.model.modal_body.value){
				        			parameter.extra.parameter.value = parameter.annexaFormly.model.modal_body.value;  
								}else{
				        			parameter.extra.parameter.value = undefined;
								}
								parameter.extra.parameter.id = undefined; 
							}else{
								if(parameter.annexaFormly.model.modal_body.id){
				        			parameter.extra.parameter.id = parameter.annexaFormly.model.modal_body.id;  
								}else{
				        			parameter.extra.parameter.id = undefined;  
								}
								parameter.extra.parameter.value = undefined;
							}
		        		}else{
							parameter.extra.parameter.id = undefined;
							parameter.extra.parameter.value = undefined;
						}
	            		parameter.close();
	            	}
            	}
            }
            
            vm.deleteParameter = function (parameter, index) {
               	DialogsFactory.confirm('global.literals.spelFunctionparameterTitle', 'global.literals.spelFunctionparameterTitleBody').then(function (dataAux) {
           			if(parameter){
		        		if(index > -1){
			        		vm.params.splice(index, 1);
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
				$http({
	                method: 'GET',
	                url: './api/tram/spel_function/evaluator/entities/SPEL'
	            }).success(function (data, status) {
					vm.entities.length = 0;
					var ents = JSOG.decode(data);
					if(ents && ents.length > 0){
						_.forEach(ents, function(ent){
							vm.entities.push({id:ent, name:ent});
						})
					}
	            }).error(function (msg, code) {
					vm.entities.length = 0;
	            });
            }
            
        }],
        bindings: {
        	params: '='
        }
    })