angular
    .module('annexaApp')
    .component('annexaOrgansOpinionCouncillorProcedure', {
        templateUrl: './components/tram/annexa-organs-opinion-councillor-procedure/annexa-organs-opinion-councillor-procedure.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'AdminFactory', 'TramNewFactory', '$state', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, globalModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin, AdminFactory, TramNewFactory, $state) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.isActiveCouncillor = false;
			
            vm.addOpinionOrgan = function(item) {
            	var options = [];
            	_.forEach(vm.data.organs, function(organ){
            		var organs = $linq(vm.data.organsOpinion).where("x => x.organ && x.organ.id == "+organ.id).toArray();
            		if(organs && organs.length > 0){
        				if(item && item.organ && item.organ.id && $linq(options).count("x => x.id == "+item.organ.id) == 0){
       						options.push(organ);
						}
            		}else{
                       	options.push(organ);
            		}
            	});
            	
            	if(options.length > 0){
					var newModal = {
	                    title: 'global.sec.literals.addOpinionOrgan',
	                    size: '',
	                    fieldsDefinition: [
	                    	{ type: 'field', id: 'organ', fieldType: 'select', data: options, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.organ',defaultValue:((item && item.organ && item.organ.id)?item.organ.id:undefined)},
							{ type: 'field', id: 'delegationType', fieldType: 'select', data: vm.data.delegationTypes, colClass: 'col-sm-12', required: true, label: 'global.commonAdmin.literals.councillorDelegationType',defaultValue:((item && item.delegationType && item.delegationType.id)?item.delegationType.id:undefined)}
	                    ],
	                    alerts: [],
	                    submitModal: function() {
	                    	this.form.$setSubmitted();
	                        if(this.form.$valid) {
	                        	var organAux = $linq(vm.data.organs).firstOrDefault(undefined, "x => x.id == "+this.model.organ);
	                        	var delegationTypeAux = $linq(vm.data.delegationTypes).firstOrDefault(undefined, "x => x.id == "+this.model.delegationType);
	                        	if(organAux && delegationTypeAux){
	                        		if(!vm.data.addOpinionOrgan){
	                                    vm.data.organsOpinion.push({organ: organAux, delegationType:delegationTypeAux});
	                                    newModal.close();
		                        	}else{
		                        		vm.data.addOpinionOrgan({ organ: organAux, delegationType:delegationTypeAux, modal:newModal });                    		
		                        	}
	                        	}else{
	                        		newModal.close();	
	                        	}
	                        }
	                    },
	                    hideSubmit: false,
	                    closeLabel: 'global.literals.close'
	                }
	
	                AnnexaFormlyFactory.showAnnexaFormModal('modalAddOpinionOrgan', newModal);
            	}else{
            		DialogsFactory.notify('global.sec.literals.noOpinionOrganToAdd');
            	}
            }
            vm.delete = function(organ, index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteOpinionOrganBox')
                    .then(function (data) {
                    	if(!vm.data.deleteOpinionOrgan){
                    		vm.data.organsOpinion.splice(index, 1);
                    	}else{
                    		vm.data.deleteOpinionOrgan({ index: index });                    		
                    	}
                    }).catch(function (data) {
                        //Empty
                });
            }
            
            this.$onInit = function () {
				if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
    				vm.isActiveCouncillor = true;
                }else{
    				vm.isActiveCouncillor = false;
    			}
            	if(!vm.data){
            		vm.data = {
                        organs: [],
                        delegationTypes: [],
						organsOpinion: [],
						deleteOpinionOrgan: undefined,
						addOpinionOrgan:undefined
                    }
            	}else if(vm.data){
            		if(!vm.data.organs){
            			vm.data.organs = [];
            		}
            		if(!vm.data.delegationTypes){
            			vm.data.delegationTypes = [];
            		}
            		if(!vm.data.organsOpinion){
            			vm.data.organsOpinion = [];
            		}
            		if(!vm.data.deleteOpinionOrgan){
						vm.data.deleteOpinionOrgan = undefined;
            		}
            		if(!vm.data.addOpinionOrgan){
						vm.data.addOpinionOrgan = undefined;
            		}
            	}
            }
            
        }],
        bindings: {
        	data:'='
        }
    })