angular
    .module('annexaApp')
    .component('annexaBoxAdminDocumentationToProvide',{
        templateUrl: './components/tram/annexa-box-admin-documentation-to-provide/annexa-box-admin-documentation-to-provide.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.getScopeApplication = function(application){
				var scopeApplication = $linq(apiAdmin.scopeApplication).firstOrDefault(undefined,"x => x.id == '"+application+"'");
				if(scopeApplication){
					return $filter('translate')(scopeApplication.description);
				}else{
					return "";
				}
			}
			vm.saveNewDocumentation = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var dt = undefined;
					if(model.documentType){
						dt = $linq(GlobalDataFactory.documentTypes).firstOrDefault({id:model.documentType},"x => x.id == "+model.documentType);
					}
					var docProvided = {
						documentType: dt,
						description: model.description,
						scopeApplication: model.scopeApplication,
						interoperability:  ((model.interoperability_true)? true: false),
						original:  ((model.original_true)? true: false),
						required: ((model.scopeApplication === 'GLOBAL')?((model.required_true)? true: false):undefined),
						idService: model.idService,
						service: model.service,
			            modality: model.modality,
			            purpose: model.purpose,
						docThirds: []
					}
					if(model.scopeApplication === 'THIRD'){
						var docThirdsField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'docThirds'");
				        if(docThirdsField && docThirdsField.fieldGroup && docThirdsField.fieldGroup.length > 0 && docThirdsField.fieldGroup[0].data && docThirdsField.fieldGroup[0].data.docThirds){
				            docProvided.docThirds = docThirdsField.fieldGroup[0].data.docThirds;
				        }
					}
					var docProvidedOk = true;
					if(docProvided.scopeApplication === 'THIRD'){
						if(!docProvided.docThirds || docProvided.docThirds.length == 0){
							docProvidedOk = false;
							DialogsFactory.error($filter('translate')('global.tram.literals.documentationToProvideThirdsEmptyError'));
						}	
					}	
					if(docProvidedOk){
						if(vm.isNew){
							vm.documentationToProvide.push(docProvided);
							self.close();
						}else{
							var url = "";
							if(vm.type === 'PROCEDURE'){
								url = "./api/procedure/documentationProvice/";
								docProvided.procedure = {id:((vm.object && vm.object.id)?vm.object.id:undefined)};
							}else if(vm.type === 'QDC'){
								url = "./api/archiveClassifications/documentationProvice/";
								docProvided.archiveClassification = {id:((vm.object && vm.object.id)?vm.object.id:undefined)};
							}
							if(url){
								RestService.insert(url, docProvided).then(function (data) {
									vm.documentationToProvide.push(data);
					        		self.close();
					            }).catch(function (error) {
					            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
					            });
							}
						}
					}
				}
			}
			
			vm.deleteDocumentation = function(item, index){
				DialogsFactory.confirm('global.literals.deleteDocumentProvide', 'global.literals.deleteDocumentProvideConfirm').then(function (data) {
					if(vm.isNew){
						vm.documentationToProvide.splice(index, 1);
					}else{
						if(item && item.id){
							var url = "";
							if(vm.type === 'PROCEDURE'){
								url = "./api/procedure/documentationProvice/";
							}else if(vm.type === 'QDC'){
								url = "./api/archiveClassifications/documentationProvice/";
							}
							if(url){
								RestService.delete(url + item.id).then(function (data) {
				        			vm.documentationToProvide.splice(index, 1);
					            }).catch(function (error) {
					            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
					            });
							}
						}else{
							vm.documentationToProvide.splice(index, 1);
						}
					}	
	            }).catch(function (data) {
		               //Empty
		        });
			}
			
			vm.newDocumentation = function(){
				var modal = angular.copy(CommonAdminModals.documentationToProvideAdd);
				modal.annexaFormly.options = {};
				var roles = [];
               	if(vm.type === 'PROCEDURE' && vm.object){
					if(vm.isNew){
						if(vm.object && vm.object.model && vm.object.model.procedureRoleInterested && vm.object.model.procedureRoleInterested.length > 0 && vm.object.fields){
							var roleField = $linq(vm.object.fields).firstOrDefault(undefined, "x => x.key == 'procedureRoleInterested'");
							if(roleField && roleField.templateOptions && roleField.templateOptions.options && roleField.templateOptions.options.length > 0){
								_.forEach(vm.object.model.procedureRoleInterested, function(r){
									var role = $linq(roleField.templateOptions.options).firstOrDefault(undefined, "x => x.id == "+r);
									if(role){
										roles.push(role);		
									}
								});
							}
						}
					}else{
						
					} 
				}
				if(!roles || roles.length == 0){
					roles = $linq(GlobalDataFactory.roleInteresteds).where("x => x.canUseDossier").toArray();
				}
				var roleField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'docThirds'");
				if(roleField && roleField.fieldGroup.length > 0 && roleField.fieldGroup[0].data){
					roleField.fieldGroup[0].data.roles = roles;
					roleField.fieldGroup[0].data.type = vm.type;
					roleField.fieldGroup[0].data.isNew = vm.isNew;
				}
				AnnexaFormlyFactory.showModal('modalDocumentationToProvideAdd', modal, vm.saveNewDocumentation, false);
			}
			
			vm.saveEditDocumentation = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var dt = undefined;
					if(model.documentType){
						dt = $linq(GlobalDataFactory.documentTypes).firstOrDefault({id:model.documentType},"x => x.id == "+model.documentType);
					}
					
					this.temp.item.documentType = dt;
					this.temp.item.description = model.description;
					this.temp.item.scopeApplication = model.scopeApplication;
					this.temp.item.interoperability =  ((model.interoperability_true)? true: false);
					this.temp.item.original =  ((model.original_true)? true: false);
					this.temp.item.required = ((model.scopeApplication === 'GLOBAL')?((model.required_true)? true: false):undefined);
					this.temp.item.idService = model.idService;
			        this.temp.item.service =  model.service;
			        this.temp.item.modality = model.modality;
			        this.temp.item.purpose = model.purpose;
					this.temp.item.docThirds = [];
					if(model.scopeApplication === 'THIRD'){
						var docThirdsField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'docThirds'");
				        if(docThirdsField && docThirdsField.fieldGroup && docThirdsField.fieldGroup.length > 0 && docThirdsField.fieldGroup[0].data && docThirdsField.fieldGroup[0].data.docThirds){
				            this.temp.item.docThirds = docThirdsField.fieldGroup[0].data.docThirds;
				        }
					}
					var docProvidedOk = true;
					if(this.temp.item.scopeApplication === 'THIRD'){
						if(!this.temp.item.docThirds || this.temp.item.docThirds.length == 0){
							docProvidedOk = false;
							DialogsFactory.error($filter('translate')('global.tram.literals.documentationToProvideThirdsEmptyError'));
						}	
					}	
					if(docProvidedOk){
						if(vm.isNew){
							self.close();
						}else{
							var url = "";
							if(vm.type === 'PROCEDURE'){
								url = "./api/procedure/documentationProvice/";
							}else if(vm.type === 'QDC'){
								url = "./api/archiveClassifications/documentationProvice/";
							}
							if(url){
								RestService.update(url + this.temp.item.id, this.temp.item).then(function (data) {
									if(self.temp.itemAux){
										self.temp.itemAux.documentType = dt;
										self.temp.itemAux.description = model.description;
										self.temp.itemAux.scopeApplication = model.scopeApplication;
										self.temp.itemAux.interoperability =  ((model.interoperability_true)? true: false);
										self.temp.itemAux.original =  ((model.original_true)? true: false);
										self.temp.itemAux.required = ((model.scopeApplication === 'GLOBAL')?((model.required_true)? true: false):undefined);
										self.temp.itemAux.idService = model.idService;
								        self.temp.itemAux.service =  model.service;
								        self.temp.itemAux.modality = model.modality;
								        self.temp.itemAux.purpose = model.purpose;
										self.temp.itemAux.docThirds = [];
										if(model.scopeApplication === 'THIRD'){
											self.temp.itemAux.docThirds = self.temp.item.docThirds;
										}
									}
					        		self.close();
					            }).catch(function (error) {
					            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
					            });
							}
						}
					}
				}
			}
			
			vm.editDocumentation = function(item){
				var openModal = function(item, itemAux){
					var modal = angular.copy(CommonAdminModals.documentationToProvideAdd);
					modal.annexaFormly.options = {};
					modal.temp = {item:item, itemAux: itemAux};
					var roles = [];
	               	if(vm.type === 'PROCEDURE' && vm.object){
						if(vm.isNew){
							if(vm.object && vm.object.model && vm.object.model.procedureRoleInterested && vm.object.model.procedureRoleInterested.length > 0 && vm.object.fields){
								var roleField = $linq(vm.object.fields).firstOrDefault(undefined, "x => x.key == 'procedureRoleInterested'");
								if(roleField && roleField.templateOptions && roleField.templateOptions.options && roleField.templateOptions.options.length > 0){
									_.forEach(vm.object.model.procedureRoleInterested, function(r){
										var role = $linq(roleField.templateOptions.options).firstOrDefault(undefined, "x => x.id == "+r);
										if(role){
											roles.push(role);		
										}
									});
								}
							}
						}else{
							
						} 
					}
					if(!roles || roles.length == 0){
						roles = $linq(GlobalDataFactory.roleInteresteds).where("x => x.canUseDossier").toArray();
					}
					var roleField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'docThirds'");
					if(roleField && roleField.fieldGroup.length > 0 && roleField.fieldGroup[0].data){
						roleField.fieldGroup[0].data.roles = roles;
						roleField.fieldGroup[0].data.isNew = vm.isNew;
						roleField.fieldGroup[0].data.type = vm.type;
						if(item && item.id){
							roleField.fieldGroup[0].data.documentationProvide = item.id;	
						}
					}
					modal.annexaFormly.model = {modal_body: {
						documentType:((item && item.documentType && item.documentType.id)?item.documentType.id:undefined), 
						description:((item && item.description)?item.description:''), 
						scopeApplication:((item && item.scopeApplication)?item.scopeApplication:undefined), 
						interoperability_true:((item && item.interoperability)?item.interoperability:false), 
						original_true:((item && item.original)?item.original:false), 
						required_true:((item && item.scopeApplication === 'GLOBAL')?((item && item.required)?item.required:false):undefined), 
						idService:((item && item.idService)?item.idService:undefined), 
						service: ((item && item.service)?item.service:undefined),
				        modality:((item && item.modality)?item.modality:undefined), 
						purpose:((item && item.purpose)?item.purpose:undefined)
					}};
					if(item.scopeApplication === 'THIRD'){
						var docThirdsField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'docThirds'");
					    if(docThirdsField && docThirdsField.fieldGroup && docThirdsField.fieldGroup.length > 0 && docThirdsField.fieldGroup[0].data && docThirdsField.fieldGroup[0].data.docThirds){
					    	docThirdsField.fieldGroup[0].data.docThirds = ((item && item.docThirds)?item.docThirds:[]);
					    }
					}
					AnnexaFormlyFactory.showModal('modalDocumentationToProvideEdit', modal, vm.saveEditDocumentation, false);
				}
				if(!vm.isNew && item && item.id){
					var url = "";
					if(vm.type === 'PROCEDURE'){
						url = "ProcedureDocumentationProvide";
					}else if(vm.type === 'QDC'){
						url = "ArchiveClassificationDocumentationProvide";
					}
					if(url){
						RestService.findOne(url, item.id).then(function (data) {
      		        		openModal(JSOG.decode(data.data), item);
			            }).catch(function (error) {
		            		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
			            });
					}else{
						DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
					}
				}else{
					openModal(item);
				}
			}

        	this.$onInit = function () {
				if(!vm.isNew){
					vm.isNew = false;
				}
				if(!vm.documentationToProvide){
					vm.documentationToProvide = [];
				}
            }
        }],
        bindings: {
            object: '=',
            isNew: '=?',
            type:'@',
			documentationToProvide: '='
        }
    })