angular
    .module('annexaApp')
    .component('annexaBoxReceiversPaginated', {
        templateUrl: './components/tram/annexa-box-receivers-paginated/annexa-box-receivers-paginated.html',
        controller:['AnnexaObjectBoxFactory', 'Language', '$rootScope', 'RestService', 'RegFactory', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', '$scope', '$timeout', 'globalModals','AnnexaEntityFactory','ModalService', 'CommonService', 'TramNewFactory', 'ThirdFactory', function (AnnexaObjectBoxFactory, Language, $rootScope, RestService, RegFactory, GlobalDataFactory, $filter, AnnexaFormlyFactory, $scope, $timeout, globalModals, AnnexaEntityFactory, ModalService, CommonService, TramNewFactory, ThirdFactory) {
            var vm = this;
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASReceivers') {
                    var addedObjects = [];
                    var id = undefined;
                    _.forEach(args.selected, function (item) {
                		if(id){
                    		id =id+','+item.id;
                    	}else{
                    		id = item.id;
                    	}
                    	addedObjects.push(item);
                    });
                        
                    if(addedObjects && addedObjects.length > 0){
                    	vm.box.search.searchedValue = { id: id, object: addedObjects };
                        vm.box.search.completeAdd();
                    }
                    args.modal.close();
                } else if(args.id == 'modalASExternalReceivers') {
                    _.forEach(args.selected, function (item) {
                    	if (item && item.identificationReferences) {
                    		ThirdFactory.getPersonByDboid(item.identificationReferences[0].identificationReference).then(function (data) {
                    			vm.box.new.newFunction(JSOG.decode(data[0]));
                    		}).catch(function (data) {
                    			//Empty
                    		});
                    	} else {
                    		vm.box.new.newFunction(item);
                    	}
                    });
                    args.modal.close();
                }
            });

            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
	        		vm.thirdIdentificationDocumentTypes = [
						{id: "DNI", language1:"DNI", language2:"DNI", language3:"DNI"}, 
						{id: "CIF", language1:"CIF", language2:"CIF", language3:"CIF"}, 
						{id: "NIE", language1:"NIE", language2:"NIE", language3:"NIE"}, 
						{id: "NIF", language1:"NIF", language2:"NIF", language3:"NIF"}, 
						{id: "PASSPORT", language1:$filter('translate')('global.thirds.literals.passport'), language2:$filter('translate')('global.thirds.literals.passport'), language3:$filter('translate')('global.thirds.literals.passport')}, 
						{id: "OTHER", language1:$filter('translate')('global.literals.other'), language2:$filter('translate')('global.literals.other'), language3:$filter('translate')('global.literals.other')}
					];
			        vm.thirdTypes = [
						{id: "PHISICAL", language1:$filter('translate')('global.thirds.literals.phisical'), language2:$filter('translate')('global.thirds.literals.phisical'), language3:$filter('translate')('global.thirds.literals.phisical')}, 
						{id: "LEGAL", language1:$filter('translate')('global.thirds.literals.legal'), language2:$filter('translate')('global.thirds.literals.legal'), language3:$filter('translate')('global.thirds.literals.legal')}, 
						{id: "PERSONWITHOUTLEGALPERSONALITY", language1:$filter('translate')('global.thirds.literals.personwithoutlegalpersonality'), language2:$filter('translate')('global.thirds.literals.personwithoutlegalpersonality'), language3:$filter('translate')('global.thirds.literals.personwithoutlegalpersonality')}
						
					];
					
					vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(
	                    vm.box.permissions,
	                    'new_third',
	                    ['new_third', 'view_thirds'],
	                    vm.box.isEdit
	                );
                   
	                vm.tramModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
					vm.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
	                if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.literals.receivers';
	                }
	
	                if(vm.box.search) {
	                    vm.box.search.origin = 'thirds';
	                    vm.box.search.type = 'Third';

	                    vm.box.search.searchFunc = function(val) {
							var additionalFilter = vm.box.search.additionalFilter ? vm.box.search.additionalFilter : {};
							if(vm.box.config && vm.box.config.dossier){
								additionalFilter.entityId = vm.box.config.dossier.id;
	                        } 
                            additionalFilter.loggedUser = $rootScope.LoggedUser.id;
	                        additionalFilter.paginated = true;
	                        additionalFilter.langColumn = Language.getActiveColumn();
							additionalFilter.searchBox = true;
							additionalFilter.active = true;
							var filter = { "name": val.val };
	                        
							return RestService.filterData(vm.box.search.origin, vm.box.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
	                            .then(function (data) {
	                                var response = [];
	
	                                _.forEach(data.data.content, function(value, key) {
	                                    response.push({
	                                        id: value.id,
	                                        object: JSOG.decode(value),
	                                        value: vm.box.search.processSearch(JSOG.decode(value))
	                                    });
	                                });
	                                response = new SortedArray(response, 'value').sort();
	                                return response;
	                            });
	                    }
	                    vm.box.search.processSearch = function(object) {
	                        var value = object.completeName + '(' + object.identificationDocument+')';
	                        return value;
	                    }
	
	                    if(!vm.box.search.completeAdd) {
	                        vm.box.search.completeAdd = function() {
	                        	var newContent = [];
	                        	if(vm.box.search.searchedValue && vm.box.search.searchedValue.object && Array.isArray(vm.box.search.searchedValue.object)){
	                        		_.forEach(vm.box.search.searchedValue.object, function(obj){
	                        			newContent.push(JSOG.decode(obj));
	                        		});
	                        	}else{
	                        		newContent.push(JSOG.decode(vm.box.search.searchedValue.object));
	                        	}
	  
	                    		vm.box.search.searchedValue = { id: -1, value: '' };
	                            if(vm.box.search.saveSearch ) {
	                                vm.box.search.saveSearch(newContent);
	                            }
	                        }
	                    }
	
	                    vm.box.search.advancedSearch = function() {
							var filter = [];	
							filter.push({ id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier'});
							filter.push({ id: 'name', type: 'text', order: 1, label: 'global.literals.name'});
							filter.push({ id: 'identificationReference', type: 'text', order: 2, label: 'global.thirds.literals.identificationReference'});
							filter.push({ id: 'onlyActive', type: 'checkbox', order: 3, label: 'global.thirds.literals.onlyActive', callAux: true, model: true});
							filter.push({ id: 'city', type: 'text', order: 4, label: 'global.literals.city', callAux: true, userChoosen: true});
							filter.push({ id: 'mail', type: 'text', order: 5, label: 'global.profile.email', callAux: true, userChoosen: true});
							filter.push({ id: 'phone', type: 'text', order: 6, label: 'global.profile.phone', callAux: true, userChoosen: true});
							filter.push({ id: 'identificationDocumentType', type: 'select', order: 7, label: 'global.thirds.literals.identificationdocumenttype', dataType: 'LOCAL', data: vm.thirdIdentificationDocumentTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true});
							filter.push({ id: 'thirdType', type: 'select', order: 8, label: 'global.literals.type', dataType: 'LOCAL', data: vm.thirdTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true});
							filter.push({ id: 'dataThird', type: 'dateRange', order: 9, label: 'global.thirds.literals.dateCreated', callAux: true, userChoosen: true });
						
							var addressRender = function (data, type, full, meta) {
								var content = '';
								if(full){
		                            var thirdAddress = new ThirdAddress(full, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
		                            content = thirdAddress.getDefaultAddressHtml();
								}
								return content;
							}

							var thirdTypeColumn = new IconFilterColumn($rootScope, 'filterData', 'thirdType', [
	                            new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
	                            new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
	                            new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
	                            new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
	                        ], 'tableThirds');

                            var advancedSearchModal = {
	                            title: 'global.literals.advanced_search_receivers',
	                            size: 'modal-lg',
	                            filter: filter,
	                            tableDefinition: {
	                                id: 'tableAdvancedSearchThirds',
	                                origin: 'thirds',
	                                objectType: 'Third',
	                                sortName: 'completeName',
	                                sort: [[3, 'asc']],
	                                columns: [
	                                    { id: 'thirdType', width: '150px', title: $filter('translate')('global.literals.type'), render:thirdTypeColumn.getRender},
	                                    { id: 'identificationDocument', width: '300px', title:$filter('translate')('global.literals.identifier') },
	                                    { id: 'completeName', width: '300px', title: $filter('translate')('global.thirds.literals.datatable.col2')},
	                                    { id: 'addresses', title: $filter('translate')('global.thirds.literals.datatable.col4'), render:addressRender }
	                                ],
	                                noLoading: true,
	                                skipFirstSearch: true,
	                                callAuxToAdd: {paginated:true,  filterOrigin:"dossierAdvancedView"}
	                            }
	                        }
	                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASReceivers', advancedSearchModal);
	                    }
	                    
	                    vm.box.search.advancedExternalSearch = function() {
							var filter = [];	
							filter.push({ id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier'});
							filter.push({ id: 'completeName', type: 'text', order: 1, label: 'global.literals.name'});
							filter.push({ id: 'identificationReference', type: 'text', order: 2, label: 'global.thirds.literals.identificationReference'});
						
							var addressRender = function (data, type, full, meta) {
								var content = '';
								if(full){
		                            var thirdAddress = new ThirdAddress(full, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
		                            content = thirdAddress.getDefaultAddressHtml();
								}
								return content;
							}

							var thirdTypeColumn = new IconFilterColumn($rootScope, 'filterData', 'thirdType', [
	                            new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
	                            new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
	                            new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
	                            new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
	                        ], 'tableThirds');

                            var advancedSearchModal = {
	                            title: (($rootScope.app.configuration.conectaNucli_config && $rootScope.app.configuration.conectaNucli_config.title_external_search) ? $rootScope.app.configuration.conectaNucli_config.title_external_search : 'global.literals.advanced_external_search'),
	                            size: 'modal-lg',
	                            filter: filter,
	                            tableDefinition: {
	                                id: 'tableAdvancedExternalSearchThirds',
	                                origin: 'thirds',
	                                objectType: 'Third',
	                                sortName: 'completeName',
	                                sort: [[3, 'asc']],
	                                columns: [
	                                    { id: 'thirdType', title: $filter('translate')('global.literals.type'), render:thirdTypeColumn.getRender},
	                                    { id: 'identificationDocument', title:$filter('translate')('global.literals.identifier') },
	                                    { id: 'completeName', width: '25%', column: new ThirdColumn($filter, 'global.thirds.literals.datatable.col2', true) },
	                                    { id: 'addresses', title: $filter('translate')('global.thirds.literals.defaultAddress'), render:addressRender }
	                                ],
	                                noLoading: true,
	                                skipFirstSearch: true,
	                                callAuxToAdd: {externalSearch:true, paginated:true, filterOrigin:"dossierAdvancedView"}
	                            }
	                        }
	                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASExternalReceivers', advancedSearchModal);
	                    }
	                }
					if(vm.box.new) {
	                    vm.box.new.newFunction = function (model) {
		                    var modal = angular.copy(globalModals.receiverNew);
		                    modal.title = 'global.literals.new_receiver_modal_title';
		                    
							//region Això s'haurà de canviar quan arreglem tercers
		                    var proccesNew = function(item, form) {
		                        vm.box.search.searchedValue = { id: item.third.id, object: item.third }
		                        $rootScope.$broadcast('annexaObjectBoxSearchAdd');
		                        form.close();
		                    }
		                    if (model) {
		                    	modal.annexaFormly.model = model;
		                    }
		                    var entity = new AnnexaEntity(new Date().getTime() + '', 'modal', AnnexaEntityFactory.getThirdBoxNewForm(modal, proccesNew), AnnexaEntityFactory.getThirdBoxes());
		                    if (model) {
		                    	if (model.identificationReferences) {
			                    	var modelIR = [];
	    							_.forEach(model.identificationReferences, function(val) {
	    								var identificationReference = { identificationReference: val.identificationReference, identificationReferenceType: val.identificationReferenceType }
	    								modelIR.push(identificationReference);
	    							});
			                    	entity.boxes[0].boxDefinition.content = modelIR;
		                    	}
			                    if (model.addresses) {
			                    	entity.boxes[1].boxDefinition.content = model.addresses;
			                    }
		                    }
		                    modal.annexaFormly = entity.form;
		                    modal.boxes = entity.boxes;
		                    modal.submitModal = modal.annexaFormly.onSubmit;
		                    modal.alerts = [];
		                    entity.modal = modal;
		                    ModalService.showModal({
		                        template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
		                        controller: 'AnnexaFormlyModalController',
		                        modalObj: entity
		                    }).then(function(modalThen) {
		                        modal.modal = modalThen;
		                        modal.close = modalThen.element.scope().close;
		                        modal.annexaFormly.close = modalThen.element.scope().close;
		                        modalThen.element.modal();
		                        angular.element(".modal-title").focus();
		                    });
		                    //endregion
	                    }
						if(!vm.box.new.newFunc) {
			                vm.box.new.newFunc = vm.box.new.newFunction;
			                
							if ($rootScope.app.configuration.conectaNucli_config && $rootScope.app.configuration.conectaNucli_config.active) {
								vm.box.new.newFunc = vm.box.search.advancedExternalSearch;
							}
						}
					}
					if(vm.box.importThirds) {
						if(!vm.box.importThirds.newFunc) {
							vm.box.importThirds.newFunc = function() {
								var modal;
								var allThirds = [];
								TramNewFactory.getDossierThirds(vm.box.config.dossier.id).then(function(data) {
            						if(data && data.length > 0) {
            							_.forEach(JSOG.decode(data), function(val) {
            								allThirds.push(val);
            							});
            						}
            						modal = angular.copy(globalModals.importThirds);
    								var submitFunction = function() {
    									var self = this;
    									var fields = [];
    									var dossier_id;
    									var selecteds = [];
    									if(modal && modal.annexaFormly && modal.annexaFormly.fields[0] && modal.annexaFormly.fields[0].fieldGroup){
        									fields = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'thirds'");
    									}
    									if(vm.box && vm.box.config.dossier && vm.box.config.dossier.id){
    										dossier_id = vm.box.config.dossier.id;
    									}
    									if(fields && fields.data && fields.data.thirds){
    										selecteds = $linq(fields.data.thirds).where("x => x.selected").toArray()
    									}
    									var thirdsToImport = angular.copy(selecteds); 
    									_.forEach(selecteds, function(third) {
    										if(third.representated != null){
    											var interested = $linq(fields.data.thirds).where("x => x.third.id ==" + third.representated.id + "&& x.representated == null && x.selected == null").firstOrDefault(null);
    											if(interested != null) {
    												thirdsToImport.push(interested);
    											}
    										}
    									});
    									TramNewFactory.importThirds(dossier_id, thirdsToImport).then(function(data) {
    										$rootScope.$broadcast('annexaBoxDossierReceiversReloadTable', { reload:true });
    										self.close();
    									});
    									
    								}
    								AnnexaFormlyFactory.showModal('addAccessRightsModal', modal, submitFunction, false, false);
            					})
            					.catch(function() {
            					});
            					
								$scope.$on('importThirdsOnSelect', function(event, args) {
									var importedThirds = angular.copy(args.item.dossier.thirds); 
									var actualThirdsInterested = $linq(allThirds).where("x => x.representated == null").toArray()
									var actualThirdsRepresent = $linq(allThirds).where("x => x.representated != null").toArray()
									var correctList = [];
	                        		_.forEach(importedThirds, function(third){
	                        			if(third.representated != null) {
	                        				var existing = $linq(actualThirdsRepresent).count("x => x.representated.id ==" + third.representated.id + "&& x.third.id ==" + third.third.id)
	                        				if(existing == 0){
	                        					third.label = CommonService.getThirdCompleteName(third.third);
	                        					third.labelHtml = CommonService.getThirdRelationType(third);
	                        					correctList.push(third);
	                        				}
	                        			} else {
	                        				var existing = $linq(actualThirdsInterested).count("x => x.third.id ==" + third.third.id)
	                        				if(existing == 0){
	                        					third.label = CommonService.getThirdCompleteName(third.third);
	                        					third.labelHtml = CommonService.getThirdRelationType(third);
	                        					correctList.push(third);
	                        				}
	                        			}
	                        		});
									var fields = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'thirds'");
	                        		fields.data.thirds = correctList;
	                            });
							}
						}
					}
				}
            }
        }],
        bindings: {
            box: '='
        }
    })
