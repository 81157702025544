angular
    .module('annexaApp')
    .component('annexaBoxAdminDocumentationToProvideThirds',{
        templateUrl: './components/tram/annexa-box-admin-documentation-to-provide-thirds/annexa-box-admin-documentation-to-provide-thirds.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.rolesToWork
			vm.getRelationType = function(th){
				var thirdRelationType = $linq(apiAdmin.thirdRelationTypes).firstOrDefault(undefined,"x => x.id == '"+th+"'");
				if(thirdRelationType){
					return $filter('translate')(thirdRelationType.description);
				}else{
					return $filter('translate')('global.literals.all');
				}
			}
			
			vm.getThirdType = function(tt){
				var tt = $linq(GlobalDataFactory.thirdTypes).firstOrDefault(undefined,"x => x.id == '"+tt+"'");
				if(tt && vm.languageColumn && tt[vm.languageColumn]){
					return $filter('translate')(tt[vm.languageColumn]);
				}else if(tt && tt.language1){
					return $filter('translate')(tt.language1);
				}else{
					return $filter('translate')('global.literals.all');
				}
			}
			
			vm.getRoleInterested = function(ri){
				if(ri && ri[vm.languageColumn]){
					return ri[vm.languageColumn];
				}else{
					return $filter('translate')('global.literals.all');
				}
			}
			
			vm.getRequired = function(r){
				var thirdRelationType = $linq(apiAdmin.yesNoBoolean).firstOrDefault(undefined,"x => x.id == "+((r && r === true)?true:false));
				if(thirdRelationType){
					return $filter('translate')(thirdRelationType.description);
				}else{
					return $filter('translate')('global.literals.no');
				}
			}
			
			vm.saveNewThird = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var ri = undefined;
					if(model.roleInterested){
						ri = $linq(vm.rolesToWork).firstOrDefault({id:model.roleInterested},"x => x.id == '"+model.roleInterested+"'");
					}
					var docThird = {
						thirdType: model.thirdType,
						relationType: model.relationType,
						roleInterested: ri,
						required: ((model.required_true)? true: false)
					}
					var docThirdOk = true;
					if(docThirdOk){
						if(vm.isNew){
							vm.docThirds.push(docThird);
							self.close();
						}else{
							if(vm.documentationProvide){
								var url = "";
								if(vm.type === 'PROCEDURE'){
									url = "./api/procedure/documentationProviceThird/";
									docThird.procedureDocumentationProvide = {id:vm.documentationProvide};
								}else if(vm.type === 'QDC'){
									url = "./api/archiveClassifications/documentationProviceThird/";
									docThird.archiveClassificationDocumentationProvide = {id:vm.documentationProvide};
								}
								if(url){
									RestService.insert(url, docThird).then(function (data) {
										vm.docThirds.push(data);
						        		self.close();
						            }).catch(function (error) {
						            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
						            });
								}
							}else{
								vm.docThirds.push(docThird);
								self.close();
							}
						}
					}
				}
			}
        	
			vm.newThird = function(){
				var modal = angular.copy(CommonAdminModals.documentationToProvideThirdAdd);
	            modal.annexaFormly.model = {};
				modal.annexaFormly.options = { watchAllExpressions: true };
				var roleField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'roleInterested'");
				if(roleField && roleField.templateOptions){
					roleField.templateOptions.options = vm.rolesToWork;
				}
	            AnnexaFormlyFactory.showModal('modalDocumentationToProvideThirdAdd', modal, vm.saveNewThird, false);
			}
			
			vm.saveEditThird = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var ri = undefined;
					if(model.roleInterested){
						ri = $linq(vm.rolesToWork).firstOrDefault({id:model.roleInterested},"x => x.id == '"+model.roleInterested+"'");
					}
					this.temp.item.thirdType = model.thirdType;
					this.temp.item.relationType = model.relationType;
					this.temp.item.roleInterested = ri;
					this.temp.item.required = ((model.required_true)? true: false);
					
					var docThirdOk = true;
					if(docThirdOk){
						if(vm.isNew){
							self.close();
						}else{
							if(vm.documentationProvide){
								var url = "";
								if(vm.type === 'PROCEDURE'){
									url = "./api/procedure/documentationProviceThird/";
								}else if(vm.type === 'QDC'){
									url = "./api/archiveClassifications/documentationProviceThird/";
								}
								if(url){
									RestService.update(url + this.temp.item.id, this.temp.item).then(function (data) {
						        		self.close();
						            }).catch(function (error) {
						            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
						            });
								}
							}else{
								self.close();			
							}
						}
					}
				}
			}
			
			vm.editThird = function(item){
				var modal = angular.copy(CommonAdminModals.documentationToProvideThirdAdd);
				modal.temp = {item:item};
	            modal.annexaFormly.model = {modal_body: {
					thirdType:((item && item.thirdType)?item.thirdType:undefined), 
					relationType:((item && item.relationType)?item.relationType:undefined), 
					roleInterested:((item && item.roleInterested && item.roleInterested.id)?item.roleInterested.id:undefined), 
					required_true:((item && item.required)?item.required:false)
				}};
				modal.annexaFormly.options = { watchAllExpressions: true };
				var roleField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'roleInterested'");
				if(roleField && roleField.templateOptions){
					roleField.templateOptions.options = vm.rolesToWork;
				}
	            AnnexaFormlyFactory.showModal('modalDocumentationToProvideThirdEdit', modal, vm.saveEditThird, false);
			}
			
			vm.deleteThird = function(item,index){
				if(vm.isNew){
					vm.docThirds.splice(index, 1);
				}else{
					if(vm.documentationProvide){
						var url = "";
						if(vm.type === 'PROCEDURE'){
							url = "./api/procedure/documentationProviceThird/";
						}else if(vm.type === 'QDC'){
							url = "./api/archiveClassifications/documentationProviceThird/";
						}
						if(url){
							RestService.delete(url + item.id).then(function (data) {
				        		if(data){
									vm.docThirds.splice(index, 1);
								}else{
									DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
								}
				            }).catch(function (error) {
				            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
				            });
						}
					}else{
						vm.docThirds.splice(index, 1);
					}
				}
				
			}
        	
			this.$onInit = function () {
				if(!vm.isNew){
					vm.isNew = false;
				}
				if(!vm.docThirds){
					vm.docThirds = [];
				}
				if(!vm.roles){
					vm.roles = [];
				}
				vm.rolesToWork = angular.copy(vm.roles);
				vm.rolesToWork.unshift({id:undefined, language1:$filter('translate')('global.literals.all'), language2:$filter('translate')('global.literals.all'), language3:$filter('translate')('global.literals.all')});
            }
        }],
        bindings: {
            docThirds: '=',
            isNew: '=?',
			roles: '=',
			type: '=',
			documentationProvide: '='
        }
    })