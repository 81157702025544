angular
    .module('annexaApp')
    .component('annexaBoxRightAccessPaginated',{
        templateUrl: './components/tram/annexa-box-right-access-paginated/annexa-box-right-access-paginated.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', 'CustomFieldFactory', 
        function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope, CustomFieldFactory) {
            var vm = this;
        
            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
					vm.tramModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
	                
	            	if(!vm.boxTitle) {
	                    vm.boxTitle = 'global.archive.accessRights';
	            	}
				}
            }
        }],
        bindings: {
            box: '='
        }
    });
