angular
    .module('annexaApp')
    .component('annexaBoxDossierAuditPaginated',{
        templateUrl: './components/tram/annexa-box-dossier-audit-paginated/annexa-box-dossier-audit-paginated.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
        	this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
	            	if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.archive.auditBoxTitle';
	                }

 					vm.tramModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
				}
            }
        }],
        bindings: {
            box: '='
        }
    })