/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDossierCustomFieldsBox', {
        templateUrl: './components/tram/annexa-dossier-custom-fields-box/annexa-dossier-custom-fields-box.html',
        controller: ['Language', 'GlobalDataFactory', 'AnnexaFormlyFactory', 'DialogsFactory', 'CustomFieldFactory', '$state', '$filter', 
		function (Language, GlobalDataFactory, AnnexaFormlyFactory, DialogsFactory, CustomFieldFactory, $state, $filter) {
            var vm = this;
			vm.canChangeDocumentType = false;
            vm.languageColumn = Language.getActiveColumn();

 			this.$onInit = function() {
				if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.customFields';
            	}
				if(!vm.emptyText) {
                    vm.emptyText = 'global.literals.noCustomFields';
            	}
           }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            emptyText: '@',
			newTitle: '@?',
            valuesFromLinkedCustomFields: '=?'
        }
    })
	.component('annexaDossierCustomFieldsBoxRender', {
        templateUrl: './components/tram/annexa-dossier-custom-fields-box/annexa-dossier-custom-fields-box-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'SecFactory', '$http', 'HelperService', 
        function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, GlobalDataFactory, globalModals, AnnexaModalFactory, SecFactory, $http, HelperService) {
    		var vm = this;
        	vm.languageColumn = Language.getActiveColumn();
        	vm.extra = {};
            vm.descriptionLanguageColumn = "descriptionLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
            vm.nameLanguageColumn = "nameLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
			vm.updateCustomField =  function(cfId, value){
                if(cfId) {
                    var cf = $linq(vm.content).firstOrDefault(undefined, "x => x.customField && x.customField.id == " + cfId);
                    if(cf){
                        if(value || value == ''){
                            cf.value = value;
                        }
						if(cf.requiredString){
	                        cf.required = cf.requiredString == 'REQUIRED';
			                cf.requiredForEndDossier = cf.requiredString == 'REQUIRED_TO_END';
			                cf.hiddenField = cf.requiredString == 'HIDDEN_FIELD';
			                cf.noEditable = cf.requiredString == 'NO_EDITABLE';
						}
						var linkedCFs = $linq(vm.content).where("x => x.customField && x.customField.frontendType == 'SELECT_LINKED' && x.customField.linkedCustomField && x.customField.linkedCustomField.id == "+cfId).toArray();
						if(linkedCFs && linkedCFs.length > 0){
							_.forEach(linkedCFs, function(customField){
								if(customField && customField.customField && customField.customField.fromQuery && customField.customField.query && customField.customField.query.id){
	                        		if(vm.valuesFromLinkedCustomFields.parentId && vm.valuesFromLinkedCustomFields.inConfig != undefined && vm.valuesFromLinkedCustomFields.objectParent && customField.identifierField && customField.labelPropField && value) {
	                        			var url = "";
	                        			if(vm.valuesFromLinkedCustomFields.objectParent === "PROCEDURE"){
	                        				url = './api/procedures/calculateCustomFields/'+vm.valuesFromLinkedCustomFields.parentId+'/'+vm.valuesFromLinkedCustomFields.inConfig+'/'+customField.customField.id+'/'+value+'/'+cf.customField.id;
	                        			}
	                        			if(url && url !== ""){
	                        				$http({method: 'GET',url: url}).then(function(data){
		                            		  if(data){
		                            			  var opts = ((data && data.data && data.data.length > 0)?JSOG.decode(data.data):[]);
		                            			  var newopts = [];
		                            			  _.forEach(opts, function(lv){
		                            					if(lv[customField.identifierField]){
		                            						var label = '';
		                            						var labelPropFieldS = customField.labelPropField.split("--@--@--");
		                            						_.forEach(labelPropFieldS, function(lp){
		                            							label = label + ((label)?((customField.labelFieldsSeparator)?customField.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
		                            						});
		                            						newopts.push({id: lv[customField.identifierField], language1:label, language2:label, language3:label, value:lv[customField.identifierField] });
		                            					}
		                            				});
		                            		  }
		                            		  customField.customField.listValues = newopts;
		                            		  $scope.$broadcast('customFieldSelectLinkedModifyListValues', {customField: customField});
	                        				}).catch(function(error){
	                        					customField.customField.listValues = [];
	                        					$scope.$broadcast('customFieldSelectLinkedModifyListValues', {customField: customField});
	                        				});
	                        				
	                        			 } else {
	                        				 customField.customField.listValues = [];
	                        				 $scope.$broadcast('customFieldSelectLinkedModifyListValues', {customField: customField});
	                                     }
	                                } else {
	                                	customField.customField.listValues = [];
	                                	$scope.$broadcast('customFieldSelectLinkedModifyListValues', {customField: customField});
	                                }
	                        	}else if(cf.id && value) {
	                                var options = $linq(customField.listValues).where("x => x.linkedCustomFieldId == " + cf.id + " && x.parentValue.id == " + value).toArray();
	                                customField.customField.listValues = options;
	                                $scope.$broadcast('customFieldSelectLinkedModifyListValues', {customField: customField});
	                            } else if (cf.id && !value) {
	                            	customField.customField.listValues = [];
	                            	$scope.$broadcast('customFieldSelectLinkedModifyListValues', {customField: customField});
	                            }
							});
						}
						if(cf && cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
							var okRelated = true;
							if(cf.customField && (cf.customField.backendType === 'MULTIPLESELECT' || cf.customField.backendType === 'CHECKBOX')){
								if(value && value.split(',').length > 1){
									okRelated = false;
								}
							}
							var lvalue = undefined;
							if(okRelated){
								if(cf.customField && cf.customField.listValues){
									lvalue = $linq(cf.customField.listValues).firstOrDefault(undefined, "x=> x.id == "+value);
								}
							}
							var relatedCFs = $linq(vm.content).intersect(cf.relatedCustomFields, "(x,y) => x.customFieldTemplateTag != undefined && y.customField != undefined && y.customField.templateTag != undefined && y.customField.templateTag === x.customFieldTemplateTag").toArray();
							if(lvalue && relatedCFs && relatedCFs.length > 0){
								_.forEach(relatedCFs, function(related){
									if(related.customField && related.customField.templateTag && (!linkedCFs || (linkedCFs && linkedCFs.length == 0) || (linkedCFs && !$linq(linkedCFs).firstOrDefault(undefined, "x => x.id === "+related.id)))){
										if(lvalue[related.customField.templateTag]){
											try{
												if(related.customField.backendType === 'DATETIME'){
													try{
														var dateParts = lvalue[related.customField.templateTag].split("/");
								                        var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
								    					if(dateObject != "Invalid Date") {
								    						related.value = dateObject;
								    					} else {
								    						dateParts = HelperService.dateMaskToDate(dateParts);
								    						related.value = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
								    					}
													}catch(e){
														var dateTime = Date.parse(lvalue[related.customField.templateTag]);
														if(dateTime){
															related.value = new Date(dateTime);
														}else{
															related.value = undefined;
														}
													}
												}else if(related.customField.frontendType == 'INPUT' && related.customField.backendType === 'INTEGER'){
													related.value = parseInt(lvalue[related.customField.templateTag]);
												}else if(related.customField.frontendType == 'INPUT' && related.customField.backendType === 'DECIMAL'){
													var valueLiteral_parts = (lvalue[related.customField.templateTag]).split('.');
							                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
							                            if(valueLiteral_parts[1]){
							                                if(related.customField.decimalPositions){
							                                	related.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(related.customField.decimalPositions));
							                                }else{
							                                	related.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
							                                }
							                            }else{
							                                if(related.customField.decimalPositions){
							                                	related.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(related.customField.decimalPositions));
							                                }else {
							                                	related.value =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
							                                }
							                            }
							                        }
												}else if(related.customField.frontendType == 'INPUT' && related.customField.backendType === 'ADDRESS'){
													//TODO no pot arribar un address des de BBDD
												}else if((related.customField.frontendType === 'SELECT' || related.customField.frontendType === 'SELECT_LINKED'  || related.customField.frontendType === 'RADIO') || 
														related.customField.frontendType === 'MULTIPLESELECT' || related.customField.frontendType === 'CHECKBOX' || related.customField.frontendType === 'STRUCTURAL_SELECT'){
													related.value = parseInt(lvalue[related.customField.templateTag]);
												}
											}catch(e){
												related.value = undefined;
											}
										}else{
											related.value = undefined;
										}
										$scope.$broadcast('customFieldSelectValueModifyRelatedValues', {customField: related});
									}
								});
							}
						}
                    }
                }
			}

			this.$onInit = function() {
				vm.extra.seeSelectedLinked = true;
				vm.extra.valuesFromLinkedCustomFields = vm.valuesFromLinkedCustomFields; 
				if(vm.content && vm.content.length){
					_.forEach(vm.content, function(cf){
						if(cf && cf.customField && !cf.customField.listValues){
							cf.customField.listValues = [];
						}
					})
				}
           }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            valuesFromLinkedCustomFields: '=?'
        }
    })
	.component('annexaDossierCustomFieldsEditBox', {
        templateUrl: './components/tram/annexa-dossier-custom-fields-box/annexa-dossier-custom-fields-edit-box.html',
        controller: ['Language', 'GlobalDataFactory', 'AnnexaFormlyFactory', 'DialogsFactory', 'CustomFieldFactory', '$state', '$filter', 
		function (Language, GlobalDataFactory, AnnexaFormlyFactory, DialogsFactory, CustomFieldFactory, $state, $filter) {
            var vm = this;
			vm.canChangeDocumentType = false;
            vm.languageColumn = Language.getActiveColumn();

 			this.$onInit = function() {
				vm.dossier = ((vm.box.config && vm.box.config.dossier && vm.box.config.dossier.id)?vm.box.config.dossier:undefined);
				if(!vm.box.boxTitle) {
                    vm.box.boxTitle = 'global.literals.customFields';
            	}
				if(!vm.box.emptyText) {
                    vm.box.emptyText = 'global.literals.noCustomFields';
            	}
           }
        }],
        bindings: {
			box: '='
        }
    })
	.component('annexaDossierCustomFieldsEditBoxRender', {
        templateUrl: './components/tram/annexa-dossier-custom-fields-box/annexa-dossier-custom-fields-edit-box-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'TramNewFactory', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'SecFactory', 'CustomFieldFactory', 
        function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, TramNewFactory,GlobalDataFactory, globalModals, AnnexaModalFactory, SecFactory, CustomFieldFactory) {
    		var vm = this;
        	vm.languageColumn = Language.getActiveColumn();
        	vm.objectParent = "DOSSIER";
			vm.updateCustomField =  function(field){
                if(field) {
					if(!field.dossier || !field.dossier.id){
						field.dossier = {id: ((vm.dossier && vm.dossier.id)?vm.dossier.id:undefined)}
					}
					TramNewFactory.updateDossierCustomField(field).then(function (dataUPDATE) {
                       if (field.customField.frontendType == 'SELECT') {
                            $scope.$broadcast('customFieldSelectSelected', {
                                customField: field.customField.id,
                                selectedValue: field.id
                            });
                        }  else if (field.customField.backendType == 'ADDRESS') {
							if(field.value){
								var aux = undefined;
								try{
        							aux = angular.fromJson(field.value);
								}catch(e){
									console.error(e);
								}
			                    if(aux && aux.value){
									field.valueFromJSON = aux.value;
								}else {
									field.valueFromJSON = '';
								}
							}else{
								field.valueFromJSON = '';
							}
						}
                        if(field.relatedCustomFields && field.relatedCustomFields.length > 0){
                        	_.forEach(field.relatedCustomFields,function(related){
                        		var rel = $linq(vm.content).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+related.customFieldTemplateTag+"'");
                        		if(rel && rel.customField && rel.customField.templateTag){
                        			if(field.valuesRelatedCustomFields && field.valuesRelatedCustomFields[rel.customField.templateTag]){
                        				 rel.valueFromJSON = field.valuesRelatedCustomFields[rel.customField.templateTag];
                        			}
                        			if(field.jsonValuesRelatedCustomFields && field.jsonValuesRelatedCustomFields[rel.customField.templateTag]){
                        				rel.value = field.jsonValuesRelatedCustomFields[rel.customField.templateTag];
                        			}
                        		}
                        	});
                        }
                        if(dataUPDATE){
                        	if(dataUPDATE.showCFs){
								var keys = Object.keys(dataUPDATE.showCFs);
	                            _.forEach(keys, function(key){
	                            	var indexCFk = $linq(vm.content).indexOf("x => x.id == "+key );
	                            	if(indexCFk > -1){
	                            		if(dataUPDATE.showCFs[key]){
	                            			vm.content[indexCFk].invisible = true;
		            					}else{
		            						vm.content[indexCFk].invisible = false;
		            					}
	                            	}
	                            });
							}
							if(dataUPDATE.showCFGs){
								var deleteGCFFromGroup = function(groups, key, show){
									var deleted = false;
									if(groups){
										var indexCFk = $linq(groups).indexOf("x => x.id == "+key );
										if(indexCFk > -1){
											if(show){
												groups[indexCFk].invisible = true;
											}else{
												groups[indexCFk].invisible = false;
											}
											deleted = true;
										}else{
											_.forEach(groups, function(cfAux){
												if(!deleted && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
													deleted = deleteGCFFromGroup(cfAux.groups, key, show);
												}
											});
										}
									}
									return deleted;
								}
								var keys = Object.keys(dataUPDATE.showCFGs);
	                            _.forEach(keys, function(key){
	                           		var deleted = false;
	           						_.forEach(vm.content, function(cfAux){
	           							if(!deleted && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	           								deleted = deleteGCFFromGroup(cfAux.groups, key, dataUPDATE.showCFGs[key]);
	           							}
	           						});
	                            });
							}
	                        if(dataUPDATE.calculatedCFs && vm.content){
								var keys = Object.keys(dataUPDATE.calculatedCFs);
	                            _.forEach(keys, function(key){
	                            	var cfToChange = $linq(vm.content).firstOrDefault(undefined, "x => x.id == "+key );
	            					if(cfToChange){
	            						cfToChange.value = dataUPDATE.calculatedCFs[key];
	            						CustomFieldFactory.calculateValueJSONCustomField(cfToChange);
	            					}
	                            });
							}
							if(dataUPDATE.calculatedCFGs){
								var calculateGCFFromGroup = function(groups, key, calculateds){
									var calc = false;
									if(groups){
										var cFk = $linq(groups).firstOrDefault(undefined, "x => x.id == "+key );
										if(cFk){
											cFk.value = calculateds[key];
											CustomFieldFactory.calculateValueJSONGroupCustomField(cFk);
											calc = true;
										}else{
											_.forEach(groups, function(cfAux){
												if(!calc && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
													calc = calculateGCFFromGroup(cfAux.groups, key, calculateds);
												}
											});
										}
									}
									return calc;
								}
								var keys = Object.keys(dataUPDATE.calculatedCFGs);
	                            _.forEach(keys, function(key){
	    	 						var calc = false;
	        						_.forEach(vm.content, function(cfAux){
	        							if(!calc && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	        								calc = calculateGCFFromGroup(cfAux.groups, key, dataUPDATE.calculatedCFGs);
	        							}
	        						});
	                        	});
							}
                        }
                    }).catch(function (error) {
						DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
                        console.error(error);
                    });
                }
			}

			this.$onInit = function() {
				
           }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            dossier: '='
        }
    }) 