angular
    .module('annexaApp')
    .component('annexaBoxDossierPaginated', {
        templateUrl: './components/tram/annexa-box-dossier-paginated/annexa-box-dossier-paginated.html',
        controller:['AnnexaObjectBoxFactory', 'Language', '$rootScope', 'RestService', 'RegFactory', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', '$scope', '$timeout', function (AnnexaObjectBoxFactory, Language, $rootScope, RestService, RegFactory, GlobalDataFactory, $filter, AnnexaFormlyFactory, $scope, $timeout) {
            var vm = this;
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASDossiers') {
                    var addedObjects = [];
                    var id = undefined;
                    _.forEach(args.selected, function (item) {
                		if(id){
                    		id =id+','+item.id;
                    	}else{
                    		id = item.id;
                    	}
                    	addedObjects.push(item);
                    });
                        
                    if(addedObjects && addedObjects.length > 0){
                    	vm.box.search.searchedValue = { id: id, object: addedObjects };
                        vm.box.search.completeAdd();
                    }
                    args.modal.close();
                }
            });

            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
					vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(
	                    vm.box.permissions,
	                    'start_dossier',
	                    ['view_dossier', 'process_dossier', 'see_all_dossier'],
	                    vm.box.isEdit
	                );
	
	                vm.tramModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
	
	                if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.literals.related_dossiers';
	                }
	
	                if(vm.box.search) {
	                    vm.box.search.origin = 'tram';
	                    vm.box.search.type = 'Dossier';

	                    vm.box.search.searchFunc = function(val) {
							var additionalFilter = vm.box.search.additionalFilter ? vm.box.search.additionalFilter : {};
							if(vm.box.config && vm.box.config.dossier){
								additionalFilter.entityId = vm.box.config.dossier.id;
	                        } 
                            additionalFilter.loggedUser = $rootScope.LoggedUser.id;
	                        additionalFilter.paginated = true;
	                        additionalFilter.paginatedObject = vm.box.tableDefinition.objectIdToSelectType;
	                        additionalFilter.paginatedObjectId = vm.box.tableDefinition.objectIdToAdded;
	                        additionalFilter.langColumn = Language.getActiveColumn();
							
							var filter ={ "subject": val.val, "dossierNumber": val.val };
	                        
							return RestService.filterData(vm.box.search.origin, vm.box.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
	                            .then(function (data) {
	                                var response = [];
	
	                                _.forEach(data.data.content, function(value, key) {
	                                    response.push({
	                                        id: value.id,
	                                        object: JSOG.decode(value),
	                                        value: vm.box.search.processSearch(JSOG.decode(value))
	                                    });
	                                });
	                                response = new SortedArray(response, 'value').sort();
	                                return response;
	                            });
	                    }
	                    vm.box.search.processSearch = function(object) {
	                        var value = object.dossierNumber + (object.subject ? '(' + object.subject+')' : '');
	                        return value;
	                    }
	
	                    if(!vm.box.search.completeAdd) {
	                        vm.box.search.completeAdd = function() {
	                        	var newContent = [];
	                        	if(vm.box.search.searchedValue && vm.box.search.searchedValue.object && Array.isArray(vm.box.search.searchedValue.object)){
	                        		_.forEach(vm.box.search.searchedValue.object, function(obj){
	                        			newContent.push(JSOG.decode(obj));
	                        		});
	                        	}else{
	                        		newContent.push(JSOG.decode(vm.box.search.searchedValue.object));
	                        	}
	  
	                    		vm.box.search.searchedValue = { id: -1, value: '' };
	                            if(vm.box.search.saveSearch ) {
	                                vm.box.search.saveSearch(newContent);
	                            }
	                        }
	                    }
	
	                    vm.box.search.advancedSearch = function() {
							var filter = [];	                        
							filter.push({ id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true});
                            filter.push({ id: 'family', type: 'select', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true});
                            filter.push({ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, userChoosen: true });
                            filter.push({ id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, userChoosen: true });
                            filter.push({ id: 'thirds', type: 'text', order: 4, label: 'global.literals.thirdsInt', callAux: true, userChoosen: true });
                            filter.push({ id: 'thirds_identification', type: 'text', order: 5, label: 'global.literals.thirds_identification', callAux: true, userChoosen: true });
                            filter.push({ id: 'tramitation', type: 'text', order: 6, label: 'global.literals.transaction', callAux: true, userChoosen: true });
							filter.push({ id: 'openDossierTransaction', type: 'select', order: 7, label: 'global.tram.literals.dossier_transactions_status', dataType: 'LOCAL', data:[{"id":"0"}, {"id":"1"}, {"id":"2"}], addAll: true, nameProperty: 'name', callAux: true, userChoosen: true });
                            filter.push({ id: 'custom_fields_search', type: 'text', order: 8, label: 'global.literals.customFields', callAux: true, userChoosen: true });
                            filter.push({ id: 'dates', type: 'dateRange', order: 9, label: 'global.delegate.startDate', callAux: true, userChoosen: true });
                            filter.push({ id: 'creator', type: 'text', order: 10, label: 'global.literals.creator', callAux: true, userChoosen: true });
	                        filter.push({ id: 'register_entry', type: 'text', order: 11, label: 'global.literals.register_entry_INI', callAux: true, userChoosen: true });
	                        filter.push({ id: 'dossierClosed', type: 'checkbox', order: 14, label: 'global.literals.showClosed', callAux: true}); 
                            var advancedSearchModal = {
	                            title: 'global.literals.advanced_search_registerEntry',
	                            size: 'modal-lg',
	                            filter: filter,
	                            tableDefinition: {
	                                id: 'tableAdvancedSearchDocuments',
	                                origin: 'tram',
	                                objectType: 'Dossier',
	                                sortName: 'dossierNumber',
	                                sort: [[0, 'asc']],
	                                columns: [
	                                    { id: 'dossierNumber', width: '150px', title: $filter('translate')('global.literals.code')},
	                                    { id: 'procedure', width: '300px', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.procedure', Language.getActiveColumn()) },
	                                    { id: 'subject', title: $filter('translate')('global.literals.dossier') }
	                                ],
	                                noLoading: true,
	                                skipFirstSearch: true,
	                                callAuxToAdd: { paginated:true, paginatedObject:vm.box.tableDefinition.objectIdToSelectType, paginatedObjectId:vm.box.tableDefinition.objectIdToAdded,  filterOrigin:"dossierAdvancedView"}
	                            },
	                            multiple: ((vm.box.origin === 'thirds')?false:true)
	                        }
	                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDossiers', advancedSearchModal);
	                    }
	                }
				}
            }
        }],
        bindings: {
            box: '='
        }
    })
