angular
    .module('annexaApp')
    .component('annexaProcedureProposalTypes', {
        templateUrl: './components/tram/annexa-procedure-proposal-types/annexa-procedure-proposal-types.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'AdminFactory', 'TramNewFactory', '$state', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, globalModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin, AdminFactory, TramNewFactory, $state) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.canAddTramInSecNotification = true;
            vm.isActiveCouncillor = false;
            vm.addProposalType = function () {
            	var modal = angular.copy(globalModals.procedureProposalTypeModal);
                modal.annexaFormly.model = {modal_body:{}};
                if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
                	modal.annexaFormly.model.modal_body = {"internalControl": true};
                } else {
                	modal.annexaFormly.model.modal_body = {"internalControl": false};
                }
                modal.annexaFormly.options = { watchAllExpressions: true };
                var proposalTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'proposalType'");
				if(proposalTypeField && proposalTypeField.templateOptions){
					var options = angular.copy(GlobalDataFactory.proposalTypes);
					proposalTypeField.templateOptions.options = angular.copy(options);
				}
				var proposalTemplateField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'proposalTemplate'");
				if(proposalTemplateField){
					proposalTemplateField.templateOptions.labelProp = vm.languageColumn;
		            TramNewFactory.getTramTemplates(-1, (vm.procedureId?vm.procedureId:-1)).then(function (data) {
			            proposalTemplateField.templateOptions.options = $linq(TramNewFactory.TramTemplates).orderBy("x => x." + vm.languageColumn, linq.caseInsensitiveComparer).toArray();
		            });
				}
                var conditionedSignCircuitListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
                if(conditionedSignCircuitListField) {
                    conditionedSignCircuitListField.data.conditionedSignCircuitList = [];
                    conditionedSignCircuitListField.data.removeAll = true;
                }
				var preExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addSpelExpression'");
				if(preExpressionField){
					preExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
						params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spel)?modal.annexaFormly.model.modal_body.spel:undefined), params);	
		        	};
				}
				var preExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addInspectionInterventionExpression'");
				if(preExpressionField){
					preExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
						params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spelInspectionIntervention)?modal.annexaFormly.model.modal_body.spelInspectionIntervention:undefined), params);	
		        	};
				}
				var secNotificationField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'secNotification'");
				if(secNotificationField){
					secNotificationField.templateOptions.executeFunction = function() {
		        		TramNewFactory.configureSecNotifications(modal.annexaFormly.model, undefined, undefined, vm.canAddTramInSecNotification);
		        	};
				}
				var organsOpinionCouncillorField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organsOpinionCouncillor'");
				if(organsOpinionCouncillorField){
					var organsOpi = angular.copy(GlobalDataFactory.organs);
					organsOpinionCouncillorField.data.organs = $linq(organsOpi).where("x => x.resolutionType == 'OPINION'").toArray();
					organsOpinionCouncillorField.data.delegationTypes = angular.copy(AdminFactory.councillorProposalTypeDelegationType);
					organsOpinionCouncillorField.data.organsOpinion = [];
				}
                AnnexaFormlyFactory.showModal('modalProcedureProposalTypeModal', modal, vm.saveProposalType, false);
            }
            
            vm.saveProposalType = function(ppt){
            	if(ppt && ppt.annexaFormly && ppt.annexaFormly.model && ppt.annexaFormly.model.modal_body){
            		var modelAux = ppt.annexaFormly.model.modal_body; 
            		var proposalTypeWithOrgans = false;
            		if(modelAux.proposalType && modelAux.proposalType.id &&  modelAux.proposalType.proposalTypeSubtype && modelAux.proposalType.proposalTypeSubtype == 'PROPOSAL'){
            			proposalTypeWithOrgans = true;
            		}
            		var model = {
               			language1:((modelAux.language1)?modelAux.language1:'NotTransltated'),
               			language2:((modelAux.language2)?modelAux.language2:'NotTransltated'),
               			language3:((modelAux.language3)?modelAux.language3:'NotTransltated'),
               			enabledType:((modelAux.enabledType && modelAux.enabledType.id)?true:false),
						proposalType:((modelAux.proposalType && modelAux.proposalType.id)?{id:modelAux.proposalType.id, language1:modelAux.proposalType.language1, language2:modelAux.proposalType.language2, language3:modelAux.proposalType.language3}:undefined),
						organsOpinion: [],
						organsAgreement:((proposalTypeWithOrgans && modelAux.organsAgreement && modelAux.organsAgreement.id)?{id:modelAux.organsAgreement.id}:undefined),
						organsAgreementDelegationType:((vm.isActiveCouncillor && proposalTypeWithOrgans && modelAux.organsAgreementDelegationType && modelAux.organsAgreementDelegationType.id)?{id:modelAux.organsAgreementDelegationType.id}:undefined),
						proposalTitle:((modelAux.proposalTitle)?modelAux.proposalTitle:undefined),
						footerClaims:[],
						notificatorProfile:((modelAux.notificatorProfile && modelAux.notificatorProfile.id)?{id:modelAux.notificatorProfile.id}:undefined),
						internalProfiles:[],
						ammendmentTransactionType:((modelAux.ammendmentTransactionType && modelAux.ammendmentTransactionType.id)?{id:modelAux.ammendmentTransactionType.id}:undefined),
						transferTransactionType:((modelAux.transferTransactionType && modelAux.transferTransactionType.id)?{id:modelAux.transferTransactionType.id}:undefined),
						cancelTransactionType:((modelAux.cancelTransactionType && modelAux.cancelTransactionType.id)?{id:modelAux.cancelTransactionType.id}:undefined),
						spel:((modelAux.spel)?modelAux.spel:undefined),
						inspectionIntervention:((modelAux.inspectionIntervention)?modelAux.inspectionIntervention:undefined),
						spelInspectionIntervention:((modelAux.spel)?modelAux.spel:undefined),
						inspectionInterventionType:((modelAux.inspectionInterventionType)?modelAux.inspectionInterventionType:undefined)
					}
    				var proposalTemplateField = $linq(ppt.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'proposalTemplate'");
    				if(proposalTemplateField && modelAux.proposalTemplate){
    					if(proposalTemplateField.templateOptions.options) {
    						_.forEach(proposalTemplateField.templateOptions.options, function(pttf){
        						if(pttf.id == modelAux.proposalTemplate){
        							model.proposalTemplate = pttf;
        						}
        					});
    					}
    				}
    				var conditionedSignCircuitListField = $linq(ppt.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
                    if(conditionedSignCircuitListField) {
                        model.proposalSignCircuit = conditionedSignCircuitListField.data.conditionedSignCircuitList;
                    }
            		var organsOk = true;
            		var organsOpinionCouncillorField = $linq(ppt.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organsOpinionCouncillor'");
            		if(vm.isActiveCouncillor){
            			if(model.organsAgreement && model.organsAgreement.id && (!model.organsAgreementDelegationType || !model.organsAgreementDelegationType.id)){
            				ppt.alerts.push({ msg: $filter('translate')('global.sec.literals.organWithoutDelegationType') });
            				organsOk = false;
            			}else{
            				if(organsOpinionCouncillorField && organsOpinionCouncillorField.data && organsOpinionCouncillorField.data.organsOpinion){
            					_.forEach(organsOpinionCouncillorField.data.organsOpinion, function(ooaux){
            						if(organsOk && (!ooaux.organ || !ooaux.organ.id || !ooaux.delegationType || !ooaux.delegationType.id)){
            							ppt.alerts.push({ msg: $filter('translate')('global.sec.literals.organWithoutDelegationType') });
                        				organsOk = false;
            						}
            					});
            				}
            			}
                    }
                    if(organsOk){	
	            		if(ppt && ppt.annexaFormly && ppt.annexaFormly.model && ppt.annexaFormly.model.secretaryNotificationConfiguration){
	            			model.secNotification = angular.copy(ppt.annexaFormly.model.secretaryNotificationConfiguration);
							if(model.secNotification.registerOffice && model.secNotification.registerOffice.id){
								model.secNotification.registerOffice = {id:model.secNotification.registerOffice.id};
							}
							if(model.secNotification.profile && model.secNotification.profile.id){
								model.secNotification.profile = {id:model.secNotification.profile.id};
							}
							if(model.secNotification.archiveClassification && model.secNotification.archiveClassification.id){
								model.secNotification.archiveClassification = {id:model.secNotification.archiveClassification.id};
							}
							if(model.secNotification.docs && model.secNotification.docs.length > 0){
								_.forEach(model.secNotification.docs, function(doc){
									if(doc.type && doc.type.id){
										doc.type = {id:doc.type.id};
									}
									if(doc.template && doc.template.id){
										doc.template = {id:doc.template.id};
									}
									if(doc.archiveClassification && doc.archiveClassification.id){
										doc.archiveClassification = {id:doc.archiveClassification.id};
									}
								});
							}
	            		}
	            		if(vm.isActiveCouncillor){
	            			if(proposalTypeWithOrgans && organsOpinionCouncillorField && organsOpinionCouncillorField && organsOpinionCouncillorField.data && organsOpinionCouncillorField.data.organsOpinion){
	            				_.forEach(organsOpinionCouncillorField.data.organsOpinion, function(ooaux){
	            					model.organsOpinion.push({organ:{id:ooaux.organ.id}, delegationType:{id:ooaux.delegationType.id}});
            					});
	            			}
	            		}else{
		            		if(proposalTypeWithOrgans && modelAux.organsOpinion){
		            			_.forEach(modelAux.organsOpinion, function(oo){
		            				if(oo.id){
		            					model.organsOpinion.push({organ:{id:oo.id}});
		            				}
		            			});
		            		}
	            		}
	            		if(modelAux.footerClaims){
	            			_.forEach(modelAux.footerClaims, function(fc){
	            				if(fc.id){
	            					model.footerClaims.push({footerClaim:{id:fc.id}});
	            				}
	            			});
	            		}
	            		if(modelAux.internalProfiles){
	            			_.forEach(modelAux.internalProfiles, function(ip){
	            				if(ip.id){
	            					model.internalProfiles.push({profile:{id:ip.id}});
	            				}
	            			});
	            		}
			        	if(vm.procedureId){
			        		model.procedure = {id:vm.procedureId};
	            		}
			        	if(!vm.isNew){
		        			RestService.insert('./api/tram/procedure_proposal_types', model).then(function(data) {
		        				if(data){
		                            vm.procedureProposalTypes.push(data);	        					
			        			}
		        				ppt.close();
	                        }).catch(function(error) {
	                        	ppt.alerts.push({ msg: "Error"});
	                        });
			        	}else{
	            			vm.procedureProposalTypes.push(model);
	            			ppt.close();
			        	}
            		}
            	}
            }

            vm.editProposalType = function (ppt, index) {
            	var modal = angular.copy(globalModals.procedureProposalTypeModal);
            	var pptUpdate = angular.copy(ppt);
            	pptUpdate.enabledType = ((pptUpdate.enabledType)?{id:true, description: $filter('translate')('global.literals.yes')}:{id:false, description: $filter('translate')('global.literals.no')});
            	if(pptUpdate.proposalType && pptUpdate.proposalType.id){
            		pptUpdate.proposalType = $linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined, "x => x.id == "+pptUpdate.proposalType.id);
            	}
            	if(!vm.isActiveCouncillor){
	            	var orgs = [];
	            	if(pptUpdate && pptUpdate.organsOpinion){
	            		_.forEach(pptUpdate.organsOpinion, function(oo){
	            			var organOpinion = $linq(GlobalDataFactory.organs).firstOrDefault(undefined, "x => x.id == "+oo.organ.id);
	                		if(organOpinion){
	                			orgs.push(organOpinion);
	                		}
	            		});
	            	}
	            	pptUpdate.organsOpinion = orgs;
            	}
            	if(pptUpdate.organsAgreement && pptUpdate.organsAgreement.id){
            		pptUpdate.organsAgreement = $linq(GlobalDataFactory.organs).firstOrDefault(undefined, "x => x.id == "+pptUpdate.organsAgreement.id);
            	}
            	if(pptUpdate.organsAgreementDelegationType && pptUpdate.organsAgreementDelegationType.id){
            		pptUpdate.organsAgreementDelegationType = $linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault(undefined, "x => x.id == "+pptUpdate.organsAgreementDelegationType.id);
            	}
            	var footerClaims = [];
            	if(pptUpdate && pptUpdate.footerClaims){
            		_.forEach(pptUpdate.footerClaims, function(oo){
            			var footerClaim = $linq(GlobalDataFactory.footerClaims).firstOrDefault(undefined, "x => x.id == "+oo.footerClaim.id);
                		if(footerClaim){
                			footerClaims.push(footerClaim);
                		}
            		});
            	}
            	pptUpdate.footerClaims = footerClaims;
            	if(pptUpdate.notificatorProfile && pptUpdate.notificatorProfile.id){
            		pptUpdate.notificatorProfile =  $linq(AdminFactory.profiles).firstOrDefault(undefined, "x => x.id == "+pptUpdate.notificatorProfile.id);
            	}
            	var internalp = [];
            	if(pptUpdate && pptUpdate.internalProfiles){
            		_.forEach(pptUpdate.internalProfiles, function(oo){
            			var internalProfile = $linq(AdminFactory.profiles).firstOrDefault(undefined, "x => x.id == "+oo.profile.id);
                		if(internalProfile){
                			internalp.push(internalProfile);
                		}
            		});
            	}
            	pptUpdate.internalProfiles = internalp;
            	if(pptUpdate.ammendmentTransactionType && pptUpdate.ammendmentTransactionType.id){
            		pptUpdate.ammendmentTransactionType = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+pptUpdate.ammendmentTransactionType.id);
            	}
            	if(pptUpdate.transferTransactionType && pptUpdate.transferTransactionType.id){
            		pptUpdate.transferTransactionType = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+pptUpdate.transferTransactionType.id);
            	}
            	if(pptUpdate.cancelTransactionType && pptUpdate.cancelTransactionType.id){
            		pptUpdate.cancelTransactionType = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+pptUpdate.cancelTransactionType.id);
            	}
            	modal.annexaFormly.model = {modal_body:pptUpdate};
            	modal.annexaFormly.model.secretaryNotificationConfiguration = pptUpdate.secNotification;
            	if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
                	modal.annexaFormly.model.modal_body.internalControl =  true;
                } else {
                	modal.annexaFormly.model.modal_body.internalControl =  false;
                }
                modal.annexaFormly.options = { watchAllExpressions: true };
                modal.extra = {
            		ppt:ppt,
	            	index:index
            	}
                var proposalTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'proposalType'");
				if(proposalTypeField && proposalTypeField.templateOptions){
					proposalTypeField.templateOptions.options = angular.copy(GlobalDataFactory.proposalTypes);
				}
				var proposalTemplateField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'proposalTemplate'");
				if(proposalTemplateField){
					proposalTemplateField.templateOptions.labelProp = vm.languageColumn;
		            TramNewFactory.getTramTemplates(-1, (vm.procedureId?vm.procedureId:-1)).then(function (data) {
			            proposalTemplateField.templateOptions.options = $linq(TramNewFactory.TramTemplates).orderBy("x => x." + vm.languageColumn, linq.caseInsensitiveComparer).toArray();
		            });
				}

                var conditionedSignCircuitListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
                if(conditionedSignCircuitListField) {
                    conditionedSignCircuitListField.data.conditionedSignCircuitList = [];
                    conditionedSignCircuitListField.data.removeAll = true;
                    if(pptUpdate.proposalSignCircuit){
                        conditionedSignCircuitListField.data.conditionedSignCircuitList = pptUpdate.proposalSignCircuit;
                    }
                }
				var preExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addSpelExpression'");
				if(preExpressionField){
					preExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
						params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spel)?modal.annexaFormly.model.modal_body.spel:undefined), params);	
		        	};
				}
				var preExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addInspectionInterventionExpression'");
				if(preExpressionField){
					preExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
						params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spelInspectionIntervention)?modal.annexaFormly.model.modal_body.spelInspectionIntervention:undefined), params);	
		        	};
				}
				var secNotificationField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'secNotification'");
				if(secNotificationField){
					secNotificationField.templateOptions.executeFunction = function() {
						if(!vm.isNew && ppt.id){
							TramNewFactory.configureSecNotifications(undefined, 'ProcedureProposalType', ppt.id, vm.canAddTramInSecNotification);
			        	}else{
			        		TramNewFactory.configureSecNotifications(modal.annexaFormly.model, undefined, undefined, vm.canAddTramInSecNotification);			        	}
		        	};
				}
				var organsOpinionCouncillorField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organsOpinionCouncillor'");
				if(organsOpinionCouncillorField){
					var organsOpi = angular.copy(GlobalDataFactory.organs);
					organsOpinionCouncillorField.data.organs = $linq(organsOpi).where("x => x.resolutionType == 'OPINION'").toArray();
					organsOpinionCouncillorField.data.delegationTypes = angular.copy(AdminFactory.councillorProposalTypeDelegationType);
					if(vm.isActiveCouncillor){
						var orgs = [];
						if(pptUpdate && pptUpdate.organsOpinion){
		            		_.forEach(pptUpdate.organsOpinion, function(oo){
		            			if(oo.organ && oo.organ.id){
		            				oo.organ = $linq(GlobalDataFactory.organs).firstOrDefault({id:oo.organ.id}, "x => x.id == "+oo.organ.id);
		            			}
		            			if(oo.delegationType && oo.delegationType.id){
		            				oo.delegationType = $linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault({id:oo.delegationType.id}, "x => x.id == "+oo.delegationType.id);
		            			}
		                		orgs.push(oo);
		            		});
		            	}
						organsOpinionCouncillorField.data.organsOpinion = orgs;
	                }else{
	                	organsOpinionCouncillorField.data.organsOpinion = [];
	                }
				}
                
				AnnexaFormlyFactory.showModal('modalProcedureProposalTypeModal', modal, vm.updateProposalType, false);
            }
            
            vm.updateProposalType = function(ppt){
            	if(ppt && ppt.extra && ppt.extra.ppt){
	            	if(ppt.annexaFormly && ppt.annexaFormly.model && ppt.annexaFormly.model.modal_body){
	            		var organsOpinionCouncillorField = $linq(ppt.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organsOpinionCouncillor'");
			        	if(!vm.isNew){
			        		var pptAux = angular.copy(ppt.extra.ppt);
			        		var modelAux = ppt.annexaFormly.model.modal_body; 
			        		var proposalTypeWithOrgans = false;
		            		if(modelAux.proposalType && modelAux.proposalType.id &&  modelAux.proposalType.proposalTypeSubtype && modelAux.proposalType.proposalTypeSubtype == 'PROPOSAL'){
		            			proposalTypeWithOrgans = true;
		            		}
		            		if(vm.isActiveCouncillor && modelAux.organsAgreement && modelAux.organsAgreement.id && (!modelAux.organsAgreementDelegationType || !modelAux.organsAgreementDelegationType.id)){
		            			ppt.alerts.push({ msg: $filter('translate')('global.sec.literals.organWithoutDelegationType') });
		            		}else{
			            		pptAux.language1 = ((modelAux.language1)?modelAux.language1:'NotTransltated');
			            		pptAux.language2 = ((modelAux.language2)?modelAux.language2:'NotTransltated');
			            		pptAux.language3 = ((modelAux.language3)?modelAux.language3:'NotTransltated');
			            		pptAux.enabledType = ((modelAux.enabledType && modelAux.enabledType.id)?true:false);
			            		pptAux.proposalType = ((modelAux.proposalType && modelAux.proposalType.id)?{id:modelAux.proposalType.id, language1:modelAux.proposalType.language1, language2:modelAux.proposalType.language2, language3:modelAux.proposalType.language3}:undefined);
			            		pptAux.organsAgreement = ((proposalTypeWithOrgans && modelAux.organsAgreement && modelAux.organsAgreement.id)?{id:modelAux.organsAgreement.id}:undefined);
			            		pptAux.organsAgreementDelegationType = ((vm.isActiveCouncillor && proposalTypeWithOrgans && modelAux.organsAgreementDelegationType && modelAux.organsAgreementDelegationType.id)?{id:modelAux.organsAgreementDelegationType.id}:undefined);
			            		pptAux.proposalTitle = ((modelAux.proposalTitle)?modelAux.proposalTitle:undefined);
			            		pptAux.notificatorProfile = ((modelAux.notificatorProfile && modelAux.notificatorProfile.id)?{id:modelAux.notificatorProfile.id}:undefined);
			            		pptAux.ammendmentTransactionType = ((modelAux.ammendmentTransactionType && modelAux.ammendmentTransactionType.id)?{id:modelAux.ammendmentTransactionType.id}:undefined);
			            		pptAux.transferTransactionType = ((modelAux.transferTransactionType && modelAux.transferTransactionType.id)?{id:modelAux.transferTransactionType.id}:undefined);
			            		pptAux.cancelTransactionType = ((modelAux.cancelTransactionType && modelAux.cancelTransactionType.id)?{id:modelAux.cancelTransactionType.id}:undefined);
			            		pptAux.spel = ((modelAux.spel)?modelAux.spel:undefined);
			            		pptAux.inspectionIntervention = ((modelAux.inspectionIntervention)?modelAux.inspectionIntervention:undefined);
			            		pptAux.spelInspectionIntervention = ((modelAux.inspectionIntervention  && modelAux.inspectionIntervention === 'CONDITIONAL' && modelAux.spelInspectionIntervention)?modelAux.spelInspectionIntervention:undefined);
			            		pptAux.inspectionInterventionType = ((modelAux.inspectionInterventionType)?modelAux.inspectionInterventionType:undefined);
			            		pptAux.organsOpinion =  [];
			            		pptAux.footerClaims = [];
			            		pptAux.internalProfiles = [];
			            		pptAux.proposalTemplate = undefined;
			            		pptAux.proposalSignCircuit = undefined;
			            		if(vm.isActiveCouncillor){
			            			if(proposalTypeWithOrgans && organsOpinionCouncillorField && organsOpinionCouncillorField && organsOpinionCouncillorField.data && organsOpinionCouncillorField.data.organsOpinion){
			            				_.forEach(organsOpinionCouncillorField.data.organsOpinion, function(ooaux){
			            					pptAux.organsOpinion.push({organ:{id:ooaux.organ.id}, delegationType:{id:ooaux.delegationType.id}});
		            					});
			            			}
			            		}else{
				            		if(proposalTypeWithOrgans && modelAux.organsOpinion){
				            			_.forEach(modelAux.organsOpinion, function(oo){
				            				if(oo.id){
				            					pptAux.organsOpinion.push({organ:{id:oo.id}});
				            				}
				            			});
				            		}
			            		}
			            		if(modelAux.footerClaims){
			            			_.forEach(modelAux.footerClaims, function(fc){
			            				if(fc.id){
			            					pptAux.footerClaims.push({footerClaim:{id:fc.id}});
			            				}
			            			});
			            		}
			            		if(modelAux.internalProfiles){
			            			_.forEach(modelAux.internalProfiles, function(ip){
			            				if(ip.id){
			            					pptAux.internalProfiles.push({profile:{id:ip.id}});
			            				}
			            			});
			            		}
			            		

			    				var proposalTemplateField = $linq(ppt.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'proposalTemplate'");
			    				if(proposalTemplateField && modelAux.proposalTemplate){
			    					if(proposalTemplateField.templateOptions.options) {
			    						_.forEach(proposalTemplateField.templateOptions.options, function(pttf){
			        						if(modelAux.proposalTemplate && modelAux.proposalTemplate.id && 
			        								pttf.id == modelAux.proposalTemplate.id) {
			        							pptAux.proposalTemplate = pttf;
			        						}else if(pttf.id == modelAux.proposalTemplate){
			        							pptAux.proposalTemplate = pttf;
			        						}
			        					});
			    					}
			    				}
			    				var conditionedSignCircuitListField = $linq(ppt.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
			                    if(conditionedSignCircuitListField && modelAux.proposalSignCircuit) {
			                        if(conditionedSignCircuitListField.data.conditionedSignCircuitList && conditionedSignCircuitListField.data.conditionedSignCircuitList.length > 0){
			                        	pptAux.proposalSignCircuit = conditionedSignCircuitListField.data.conditionedSignCircuitList;
			                        }
			                    }
	
			            		RestService.update('./api/tram/procedure_proposal_types/' + pptAux.id, pptAux).then(function (data) {
			            			ppt.extra.ppt.language1 = data.language1;
				            		ppt.extra.ppt.language2 = data.language2;
				            		ppt.extra.ppt.language3 = data.language3;
				            		ppt.extra.ppt.enabledType = data.enabledType;
				            		ppt.extra.ppt.proposalType = data.proposalType;
				            		ppt.extra.ppt.organsOpinion =  data.organsOpinion;
				            		ppt.extra.ppt.organsAgreement = data.organsAgreement;
				            		ppt.extra.ppt.organsAgreementDelegationType = data.organsAgreementDelegationType;
				            		ppt.extra.ppt.proposalTitle = data.proposalTitle;
				            		ppt.extra.ppt.footerClaims = data.footerClaims;
				            		ppt.extra.ppt.notificatorProfile = data.notificatorProfile;
				            		ppt.extra.ppt.internalProfiles = data.internalProfiles;
				            		ppt.extra.ppt.ammendmentTransactionType = data.ammendmentTransactionType;
				            		ppt.extra.ppt.transferTransactionType = data.transferTransactionType;
				            		ppt.extra.ppt.cancelTransactionType = data.cancelTransactionType;
				            		ppt.extra.ppt.spel = data.spel;
				            		ppt.extra.ppt.inspectionIntervention = data.inspectionIntervention;
				            		ppt.extra.ppt.spelInspectionIntervention = data.spelInspectionIntervention;
				            		ppt.extra.ppt.inspectionInterventionType = data.inspectionInterventionType;
				            		ppt.extra.ppt.proposalTemplate = data.proposalTemplate;
				            		ppt.extra.ppt.proposalSignCircuit = data.proposalSignCircuit;
									ppt.close();
	                            }).catch(function (error) {
	                        		ppt.alerts.push({ msg: "Error"});
	                            });
		                    }
			        	}else{
		            		var modelAux = ppt.annexaFormly.model.modal_body; 
		            		var proposalTypeWithOrgans = false;
		            		if(modelAux.proposalType && modelAux.proposalType.id &&  modelAux.proposalType.proposalTypeSubtype && modelAux.proposalType.proposalTypeSubtype == 'PROPOSAL'){
		            			proposalTypeWithOrgans = true;
		            		}
		            		if(vm.isActiveCouncillor && modelAux.organsAgreement && modelAux.organsAgreement.id && (!modelAux.organsAgreementDelegationType || !modelAux.organsAgreementDelegationType.id)){
		            			ppt.alerts.push({ msg: $filter('translate')('global.sec.literals.organWithoutDelegationType') });
		            		}else{
				            	ppt.extra.ppt.language1 = ((modelAux.language1)?modelAux.language1:'NotTransltated');
			            		ppt.extra.ppt.language2 = ((modelAux.language2)?modelAux.language2:'NotTransltated');
			            		ppt.extra.ppt.language3 = ((modelAux.language3)?modelAux.language3:'NotTransltated');
			            		ppt.extra.ppt.enabledType = ((modelAux.enabledType && modelAux.enabledType.id)?true:false);
			            		ppt.extra.ppt.proposalType = ((modelAux.proposalType && modelAux.proposalType.id)?{id:modelAux.proposalType.id, language1:modelAux.proposalType.language1, language2:modelAux.proposalType.language2, language3:modelAux.proposalType.language3}:undefined);
			            		ppt.extra.ppt.organsOpinion =  [];
			            		ppt.extra.ppt.organsAgreement = ((proposalTypeWithOrgans && modelAux.organsAgreement && modelAux.organsAgreement.id)?{id:modelAux.organsAgreement.id}:undefined);
			            		ppt.extra.ppt.organsAgreementDelegationType = ((vm.isActiveCouncillor && proposalTypeWithOrgans && modelAux.organsAgreementDelegationType && modelAux.organsAgreementDelegationType.id)?{id:modelAux.organsAgreementDelegationType.id}:undefined);
			            		ppt.extra.ppt.proposalTitle = ((modelAux.proposalTitle)?modelAux.proposalTitle:undefined);
			            		ppt.extra.ppt.footerClaims = [];
			            		ppt.extra.ppt.notificatorProfile = ((modelAux.notificatorProfile && modelAux.notificatorProfile.id)?{id:modelAux.notificatorProfile.id}:undefined);
			            		ppt.extra.ppt.internalProfiles = [];
			            		ppt.extra.ppt.ammendmentTransactionType = ((modelAux.ammendmentTransactionType && modelAux.ammendmentTransactionType.id)?{id:modelAux.ammendmentTransactionType.id}:undefined);
			            		ppt.extra.ppt.transferTransactionType = ((modelAux.transferTransactionType && modelAux.transferTransactionType.id)?{id:modelAux.transferTransactionType.id}:undefined);
			            		ppt.extra.ppt.cancelTransactionType = ((modelAux.cancelTransactionType && modelAux.cancelTransactionType.id)?{id:modelAux.cancelTransactionType.id}:undefined);
			            		ppt.extra.ppt.spel = ((modelAux.spel)?modelAux.spel:undefined);
			            		ppt.extra.ppt.inspectionIntervention = ((modelAux.inspectionIntervention)?modelAux.inspectionIntervention:undefined);
			            		ppt.extra.ppt.spelInspectionIntervention = ((modelAux.inspectionIntervention  && modelAux.inspectionIntervention === 'CONDITIONAL' && modelAux.spelInspectionIntervention)?modelAux.spelInspectionIntervention:undefined);
			            		ppt.extra.ppt.inspectionInterventionType = ((modelAux.inspectionInterventionType)?modelAux.inspectionInterventionType:undefined);
			            		ppt.extra.ppt.proposalTemplate = undefined;
			            		ppt.extra.ppt.proposalSignCircuit = undefined;
			            		if(ppt && ppt.annexaFormly && ppt.annexaFormly.model && ppt.annexaFormly.model.secretaryNotificationConfiguration){
			            			ppt.extra.ppt.secNotification = angular.copy(ppt.annexaFormly.model.secretaryNotificationConfiguration);
									if(ppt.extra.ppt.secNotification.registerOffice && ppt.extra.ppt.secNotification.registerOffice.id){
										ppt.extra.ppt.secNotification.registerOffice = {id:ppt.extra.ppt.secNotification.registerOffice.id};
									}
									if(ppt.extra.ppt.secNotification.profile && ppt.extra.ppt.secNotification.profile.id){
										ppt.extra.ppt.secNotification.profile = {id:ppt.extra.ppt.secNotification.profile.id};
									}
									if(ppt.extra.ppt.secNotification.archiveClassification && ppt.extra.ppt.secNotification.archiveClassification.id){
										ppt.extra.ppt.secNotification.archiveClassification = {id:ppt.extra.ppt.secNotification.archiveClassification.id};
									}
									if(ppt.extra.ppt.secNotification.docs && ppt.extra.ppt.secNotification.docs.length > 0){
										_.forEach(ppt.extra.ppt.secNotification.docs, function(doc){
											if(doc.type && doc.type.id){
												doc.type = {id:doc.type.id};
											}
											if(doc.template && doc.template.id){
												doc.template = {id:doc.template.id};
											}
											if(doc.archiveClassification && doc.archiveClassification.id){
												doc.archiveClassification = {id:doc.archiveClassification.id};
											}
										});
									}
			            		}
			    				var proposalTemplateField = $linq(ppt.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'proposalTemplate'");
			    				if(proposalTemplateField && modelAux.proposalTemplate){
			    					if(proposalTemplateField.templateOptions.options) {
			    						_.forEach(proposalTemplateField.templateOptions.options, function(pttf){
			        						if(modelAux.proposalTemplate && modelAux.proposalTemplate.id && 
			        								pttf.id == modelAux.proposalTemplate.id) {
			        							ppt.extra.ppt.proposalTemplate = pttf;
			        						}else if(pttf.id == modelAux.proposalTemplate){
			        							ppt.extra.ppt.proposalTemplate = pttf;
			        						}
			        					});
			    					}
			    				}
			    				var conditionedSignCircuitListField = $linq(ppt.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
			                    if(conditionedSignCircuitListField && modelAux.proposalSignCircuit) {
			                        if(conditionedSignCircuitListField.data.conditionedSignCircuitList && conditionedSignCircuitListField.data.conditionedSignCircuitList.length > 0){
			                        	ppt.extra.ppt.proposalSignCircuit = conditionedSignCircuitListField.data.conditionedSignCircuitList;
			                        }
			                    }
			            		if(vm.isActiveCouncillor){
			            			if(proposalTypeWithOrgans && organsOpinionCouncillorField && organsOpinionCouncillorField && organsOpinionCouncillorField.data && organsOpinionCouncillorField.data.organsOpinion){
			            				_.forEach(organsOpinionCouncillorField.data.organsOpinion, function(ooaux){
			            					ppt.extra.ppt.organsOpinion.push({organ:{id:ooaux.organ.id}, delegationType:{id:ooaux.delegationType.id}});
		            					});
			            			}
			            		}else{
				            		if(proposalTypeWithOrgans && modelAux.organsOpinion){
				            			_.forEach(modelAux.organsOpinion, function(oo){
				            				if(oo.id){
				            					ppt.extra.ppt.organsOpinion.push({organ:{id:oo.id}});
				            				}
				            			});
				            		}
			            		}
			            		if(modelAux.footerClaims){
			            			_.forEach(modelAux.footerClaims, function(fc){
			            				if(fc.id){
			            					ppt.extra.ppt.footerClaims.push({footerClaim:{id:fc.id}});
			            				}
			            			});
			            		}
			            		if(modelAux.internalProfiles){
			            			_.forEach(modelAux.internalProfiles, function(ip){
			            				if(ip.id){
			            					ppt.extra.ppt.internalProfiles.push({profile:{id:ip.id}});
			            				}
			            			});
			            		}
			            		ppt.close();
		            		}
			        	}
	            	}
            	}
            }
            
            vm.deleteProposalType = function (ppt, index) {
            	var canDeleteProposalType = function(pptId){
//            		if(vm.procedure && pptId){
//            			var canDelete = true;
//            			if(vm.procedure.transactions){
//            				var keys = Object.keys(vm.procedure.transactions);
//                            _.forEach(keys, function(key){
//                            	if(canDelete && vm.procedure.transactions[key].transactionProcedureProposalTypes){
//                            		_.forEach(vm.procedure.transactions[key].transactionProcedureProposalTypes, function(tppt){
//                            			if(canDelete && tppt === pptId){
//                            				canDelete = false;
//                            			}
//                            		});
//                            	}
//                            });
//            			}
//            			return canDelete;
//            		}else{
            			return true;
//            		}
            	}
            	
               	DialogsFactory.confirm('global.sec.literals.deleteProcedureProposalTypesTitle', 'global.sec.literals.deleteProcedureProposalTypesTitleBody').then(function (dataAux) {
           			if(ppt){
           				if(canDeleteProposalType(((ppt.id)?ppt.id:((ppt.proposalType.id)?ppt.proposalType.id:undefined)))){
			        		var pptIndex = -1;
			        		if(ppt.id){
			        			pptIndex = $linq(vm.procedureProposalTypes).indexOf("x => x.id == "+ppt.id);
			        		}else{
			        			pptIndex = index;
			        		}
			        		if(pptIndex > -1){
					        	if(!vm.isNew){
					        		if(ppt && ppt.id && ( pptIndex || pptIndex == 0) && vm.procedureProposalTypes && vm.procedureProposalTypes.length >= pptIndex){
			                    		RestService.delete('./api/tram/procedure_proposal_types/' + ppt.id).then(function (data) {
			                    			vm.procedureProposalTypes.splice(pptIndex, 1);
		                                }).catch(function (error) {
			                                console.log(error);
			                            });
			                        }
					        	}else{
					        		vm.procedureProposalTypes.splice(pptIndex, 1);
					        	}
				        	}
		        		}else{
		        			DialogsFactory.error($filter('translate')('global.sec.literals.errorDeleteProposalTypeHaveTransaction'));
		        		}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
            	if($state.current.name === 'annexa.admin.guided_procedure.new_guided_procedure' || $state.current.name === 'annexa.admin.guided_procedure.edit_guided_procedure'){
            		vm.canAddTramInSecNotification = false;
            	}
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
    				vm.isActiveCouncillor = true;
                }else{
    				vm.isActiveCouncillor = false;
    			}
            }
            
        }],
        bindings: {
        	procedureProposalTypes: '=',
            isNew: '=',
            procedureId:'=?',
            procedure:'=?'
        }
    })