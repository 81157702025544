angular
    .module('annexaApp')
    .factory('AnnexaBoxDossierFactory',['GlobalDataFactory', 'Language', '$filter', function(GlobalDataFactory, Language, $filter) {
        var factory = {};

        factory.getAdvancedSearchModal = function (content, addFilters) {
        	var filter = [{ id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true },
                { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
                { id: 'procedure', type: 'text', order: 0, label: 'global.literals.procedure', userChoosen: true, callAux: true },
                { id: 'subject', type: 'text', order: 1, label: 'global.literals.subject', userChoosen: true, callAux: true },
                { id: 'thirds', type: 'text', order: 2, label: 'global.literals.thirdsInt', userChoosen: true, callAux: true },
                { id: 'thirds_identification', type: 'text', order: 3, label: 'global.literals.thirds_identification', userChoosen: true, callAux: true },
                { id: Language.getActiveColumn(), type: 'text', order: 4, label: 'global.literals.transaction', userChoosen: true },
                { id: 'custom_fields_search', type: 'text', order: 5, label: 'global.literals.customFields', userChoosen: true, callAux: true },
                { id: 'dates', type: 'dateRange', order: 6, label: 'global.delegate.startDate', userChoosen: true, callAux: true },
                { id: 'creator', type: 'text', order: 7, label: 'global.literals.creator', userChoosen: true, callAux: true },
                { id: 'register_entry', type: 'text', order: 8, label: 'global.literals.register_entry_INI', userChoosen: true, callAux: true },
                { id: 'dossierClosed', type: 'checkbox', order: 2, label: 'global.literals.showClosed', callAux: true} 
            ];
        	if(addFilters && addFilters.length > 0){
        		var i=9;
        		_.forEach(addFilters, function(addFilter){
        			addFilter.order = i;
        			filter.push(addFilter);
        			i++;
        		});
        	}
            return {
                title: 'global.literals.advanced_search',
                size: 'modal-lg',
                filter: filter,
                tableDefinition: {
                    id: 'tableAdvancedSearchDossiers',
                    origin: 'tram',
                    objectType: 'Dossier',
                    sortName: 'dossierNumber',
                    sort: [[1, 'asc']],
                    columns: [
                        { id: 'dossierNumber', title: $filter('translate')('global.literals.code') },
                        { id: 'procedure.' + Language.getActiveColumn(), title: $filter('translate')('global.literals.procedure') },
                        { id: 'subject', title: $filter('translate')('global.literals.dossier') }
                    ],
                    noLoading: true,
                    skipFirstSearch: true,
                    callAuxToAdd: { filterOrigin: 'dossierAdvancedView', added: $linq(content).select("x => x.id").toArray() }
                },
                multiple: true
            };
        }

        return factory;
    }])
    .component('annexaBoxDossier', {
        templateUrl: './components/tram/annexa-box-dossier/annexa-box-dossier.html',
        controller:['AnnexaObjectBoxFactory', 'Language', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$rootScope', 'HelperService', '$scope', 'AnnexaBoxDossierFactory', function(AnnexaObjectBoxFactory, Language, AnnexaFormlyFactory, GlobalDataFactory, $filter, $rootScope, HelperService, $scope, AnnexaBoxDossierFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASDossiers') {
                	var addedObjects = [];
                    var id = undefined;
                    _.forEach(args.selected, function (item) {
                		if(id){
                    		id =id+','+item.id;
                    	}else{
                    		id = item.id;
                    	}
                    	addedObjects.push(item);
                    });
                    
                    if(addedObjects && addedObjects.length > 0){
                    	vm.search.searchedValue = { id: id, object: addedObjects };
                        vm.search.completeAdd();
                    }

                    args.modal.close();
                }
            });

            this.$onInit = function() {
                vm.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.permissions, 'start_dossier', ['view_dossier', 'see_all_dossier'], vm.isEdit);

                vm.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.related_dossiers';
                }

                if(vm.search) {
                    vm.search.origin = 'tram';
                    vm.search.type = 'Dossier';
                    vm.search.customFilter = '{ "subject": "@VAL@", "dossierNumber": "@VAL@" }';
                    vm.search.processSearch = function(object) {
                        return object.dossierNumber + ' (' + object.subject + ')';
                    }

                    if(!vm.search.completeAdd) {
                        vm.search.completeAdd = function() {
                        	var newContent = [];
                        	if(vm.search.searchedValue && vm.search.searchedValue.object && Array.isArray(vm.search.searchedValue.object)){
                        		_.forEach(vm.search.searchedValue.object, function(obj){
                        			newContent.push(JSOG.decode(obj));
                        		});
                        	}else{
                        		newContent.push(JSOG.decode(vm.search.searchedValue.object));
                        	}

                			_.forEach(newContent, function(obj){
                				vm.content.push(obj);
                        	});
                        	vm.search.searchedValue = { id: -1, value: '' };
                            if(vm.search.saveSearch ) {
                                vm.search.saveSearch(newContent);
                            }
                        }
                    }

                    vm.search.advancedSearch = function() {
                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDossiers', AnnexaBoxDossierFactory.getAdvancedSearchModal(vm.content));
                    }
                }

                if(vm.new) {

                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@'
        }
    })
    .component('annexaBoxDossiersRender', {
        templateUrl: './components/tram/annexa-box-dossier/annexa-box-dossier-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['DialogsFactory', '$rootScope', 'Language', function(DialogsFactory, $rootScope, Language) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.delete = function(index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteDossierBox')
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxDossiersDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })