angular
    .module('annexaApp')
    .component('annexaDossierDocs',{
        templateUrl: './components/tram/annexa-dossier-docs/annexa-dossier-docs.html',
        controller:['$scope', 'DccumentsFactory', 'Language', 'TramNewFactory', 'TableFilterFactory', '$filter', 'AnnexaPermissionsFactory', 'AnnexaDocumentActionsButtonFactory', '$state', 'GlobalDataFactory', '$rootScope', 'RouteStateFactory', 'HelperService', function ($scope, DccumentsFactory, Language, TramNewFactory, TableFilterFactory, $filter, AnnexaPermissionsFactory, AnnexaDocumentActionsButtonFactory, $state, GlobalDataFactory, $rootScope, RouteStateFactory, HelperService) {
            var vm = this;
            
            var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
            vm.showOnlyOnceDocuments = false;
            if($rootScope.app.configuration.dossier_documents_list_configuration && $rootScope.app.configuration.dossier_documents_list_configuration.showOnlyOnce) {
            	vm.showOnlyOnceDocuments = $rootScope.app.configuration.dossier_documents_list_configuration.showOnlyOnce;
            }
            vm.languageColumn = Language.getActiveColumn();
            
            vm.filter = [
                { id: 'code', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('documents','tableDocsTram','code'), negated:HelperService.getPrefilterNegated('documents','tableDocsTram','code') },
                { id: 'name', type: 'text', order: 1, label: 'global.literals.title', preFilter:HelperService.getPrefilter('documents','tableDocsTram','name'), negated:HelperService.getPrefilterNegated('documents','tableDocsTram','name') },
                { id: 'type', type: 'select', order: 2, label: 'global.literals.docType', dataType: 'LOCAL', data: GlobalDataFactory.documentTypes, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('documents','tableDocsTram','type',GlobalDataFactory.documentTypes,'id'), negated:HelperService.getPrefilterNegated('documents','tableDocsTram','type') },
                { id: 'relationType', type: 'select', order: 3, label: 'global.tram.literals.documentRelationType', dataType: 'LOCAL', data: GlobalDataFactory.documentRelationTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('documents','tableDocsTram','relationType',GlobalDataFactory.documentRelationTypes,'id'), negated:HelperService.getPrefilterNegated('documents','tableDocsTram','relationType') },
                { id: 'createdDate', type: 'dateRange', order: 4, label: 'global.literals.creation_date', callAux: true, preFilter:HelperService.getPrefilter('documents','tableDocsTram','createdDate'), negated:HelperService.getPrefilterNegated('documents','tableDocsTram','createdDate') },
                { id: 'transactionType', type: 'select', order: 5, label: 'global.tram.literals.transaction_type', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('documents','tableDocsTram','transactionType',GlobalDataFactory.transactiontypes,'id'), negated:HelperService.getPrefilterNegated('documents','tableDocsTram','transactionType') }
            ];

            var routeState = RouteStateFactory.getRouteState($state.current);
            
            if(routeState) {
            	vm.filter = routeState.state;
            }

            var getFilterCall = function() {
                var filterCall = {
                    document: TableFilterFactory.getFilterCall(vm.filter),
                    dossierTransaction: { dossier: { id: vm.dossierId } }
                };

                var callAux = TableFilterFactory.getFilterCallAux(vm.filter);
                if(callAux && callAux.transactionType && callAux.transactionType.id) {
                    filterCall.dossierTransaction.transactionType = { id: callAux.transactionType.id };
                }
                if(callAux && callAux.relationType && callAux.relationType.id) {
                	if(callAux.relationType.id == 'PAPER_BIN') {
                		filterCall.paperBin = true;
                	} else {
                		filterCall.relationType = callAux.relationType.id;
                		filterCall.paperBin = false;
                	}
                }

                return filterCall;
            }

            var getFilterCallAux = function () {
            	var filterCallAux = TableFilterFactory.getFilterCallAux(vm.filter);
            	filterCallAux.showOnlyOnceDocuments = vm.showOnlyOnceDocuments;
                return filterCallAux;
            }
            var documentTypeColumn = new IconFilterColumn($scope, 'filterData', 'document.docStatus',
                [
                    new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                    new IconSearchOption('NEW','NEW', 'fa-circle-o text-blue fa-lg', 'fa-circle-o text-blue fa-lg', 'global.documentState.NEW'),
                    new IconSearchOption('NEW_AND_ATTACHMENT','NEW_AND_ATTACHMENT', 'fa-adjust text-blue fa-lg', 'fa-adjust text-blue fa-lg', 'global.documentState.NEW_AND_ATTACHMENT'),
                    new IconSearchOption('PENDING_SIGN','PENDING_SIGN', 'fa-circle text-blue fa-lg', 'fa-circle text-blue fa-lg', 'global.documentState.PENDING_SIGN'),
                    new IconSearchOption('SIGNED','SIGNED', 'fa-circle text-success fa-lg', 'fa-circle text-success fa-lg', 'global.documentState.SIGNED'),
                    new IconSearchOption('REJECTED','REJECTED', 'fa-circle text-danger fa-lg', 'fa-circle text-danger fa-lg', 'global.documentState.REJECTED'),
                    new IconSearchOption('COMPLETE','COMPLETE', 'fa-dot-circle-o text-success fa-lg', 'fa-dot-circle-o text-success fa-lg', 'global.documentState.COMPLETE'),
                    new IconSearchOption('ARCHIVED','ARCHIVED', 'fa-circle-o text-success fa-lg', 'fa-circle-o text-success fa-lg', 'global.documentState.ARCHIVED')
                ], 'tableDocs');

            var actionsTitle = function () {
                return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
            }

            var relationTypeRender = function(data, type, object, meta) {
            	if(object && object.paperBin) {
                    return $filter('translate')('global.literals.paperBin') + ' (' + $filter('translate')('global.tram.documentRelationType.' + data) + ')';
            	} else {
                    return $filter('translate')('global.tram.documentRelationType.' + data);
            	}
            };
            
            var actionsRender = function (data, type, full, meta) {
                return '<annexa-document-actions-button module="EXPED" dossier-transaction-document="tableObjects[' + full.id + ']" transaction-init="tableObjects[' + full.id + '].dossierTransaction" list="true" object-to-call="objectToCall"></annexa-document-actions-button>';
            }

            $scope.$on('SendToSignModalFinished', function(event, args) {
                vm.tableDefinition.reloadInternalData(false, true);
                $rootScope.$broadcast('SendToSignModalFinishedFromTable', args);
            });

            $scope.$on('documentActionButtonExecuted', function(event ,args) {
                vm.tableDefinition.reloadInternalData(false,true);
                $rootScope.$broadcast('documentActionButtonExecutedFromTable', args);
            });
            
            $scope.$on('annexaDocumentActionsButtonReloadTable', function(event ,args) {
                vm.tableDefinition.reloadInternalData(false,true);
            });
            
            $scope.$on('allowGenerateFromContentDocumentTypeAddedToTransaction', function(event, args){
            	vm.tableDefinition.reloadInternalData(false,true);
            });
            $scope.$on('annexaBoxDocumentsTramUpdateCounterReloadTable', function(event, args){
            	vm.tableDefinition.reloadInternalData(true,true);
            });
            $scope.$on('created_multiple_notifications', function (event, args) {
       		    vm.tableDefinition.reloadInternalData(false,true);
            });

            $scope.$on('createdRegisterEntry', function (event, args) {
        		vm.tableDefinition.reloadInternalData(false,true);
            });
            
			$scope.$on('annexaBoxDossierTransactionDocumentsReloadTable', function(event, args) {
            	vm.tableDefinition.reloadInternalData(false,true);
	        });

			$scope.$on('documentActionButtonExecuting', function(event, args) {
            	vm.tableDefinition.reloadInternalData(false,true);
	        });



            this.$onInit = function () {
            	if(vm.modify == undefined || vm.modify == null){
            		vm.modify = false;
            	}
				
				vm.openDocument = function(documentId){
					$scope.showLoadingdiv = true;
	                var windowReference = window.open();
	                DccumentsFactory.getDocContent(documentId).then(function (data) {
                        $scope.showLoadingdiv = false;
                        windowReference.location = data;
                    }).catch(function (error) {
	                    $scope.showLoadingdiv = false;
	                    windowReference.close();
	                });
	            }
				
				vm.authCopyDocument = function(documentId){
					$scope.showLoadingdiv = true;
	                var windowReference = window.open();
	                DccumentsFactory.getAuthCopyContent(documentId)
	                .then(function(data) {
	                	$scope.showLoadingdiv = false;
	                    windowReference.location = data;
	                }).catch(function (error) {
	                	$scope.showLoadingdiv = false;
	                    $rootScope.loading(false);
	                });
	            }
				
				vm.legend = [];

		        _.forEach($linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), function (value) {
		            if(value.id) {
		                vm.legend.push({
		                    icon: value.icon,
		                    style: 'text-' + value.style,
		                    name: 'global.documentState.' + value.name
		                });
		            }
		        })

                vm.tableDefinition = {
                    id: ((vm.modify)?'tableDocsTramModify':'tableDocsTramView'),
                    origin: 'tram',
                    objectType: 'DossierTransactionDocument',
                    sortName: 'createdDate',
                    sort: [[((vm.modify)?(($state.current.name != 'annexa.tram.view.viewdossier')?8:7):7), 'asc']],
                    filterCall: getFilterCall(),
                    filterCallAux: getFilterCallAux(),
                    filterCallFunc: getFilterCall,
                    filterCallAuxFunc: getFilterCallAux,
                    columns: [
                        { id: 'document.publicDocument', width: '40px', column: new BooleanColumn($filter, 'global.documentState.public', 'public'), sortable: false },
                        { id: 'document.docStatus', width: '40px', title: $filter('translate')('global.literals.state'), render: documentTypeColumn.getRender },
                        { id: 'document.type', width: '40px', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
                        { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.document.id; if(full.document.code) { code = full.document.code.substr((full.document.code.indexOf(full.document.id) - 2), full.document.id.toString().length + 2); } code;', true,undefined,(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.showArchivedDocument)?'$ctrl.openDocument([data])':undefined)) },
                        { id: 'document.name', width: '250px', column: new UIRouterStateColumnDataLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'full.document.id', true,(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.showArchivedDocument)?'$ctrl.openDocument([data])':undefined), '$ctrl.openDocument([data])', '$ctrl.authCopyDocument([data])') },
                        { id: 'dossierTransaction.transactionType.' + Language.getActiveColumn(), title: $filter('translate')('global.tram.literals.transaction_type') },
                        { id: 'dossierTransaction.' + Language.getActiveColumn(), column: new TransactionColumn($scope, $filter, 'global.literals.transaction', 'dossierTransactionsComponent', vm.showOnlyOnceDocuments)},
                        { id: 'document.createdDate', column: new DateTimeColumn($filter, 'global.literals.creation_date') },
                        { id: 'document.signProcesses', width: '140px', column: new LastSignColumn($filter, 'global.literals.lastSignDate'), sortable: false },
                        { id: 'relationType', title: $filter('translate')('global.tram.literals.documentRelationType'), render: relationTypeRender },
                        { id: 'actions', columnName: 'document.id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
                    ],
                    containerScope: $scope,
                    useTableObjects: true,
                    multipleActions: (($state.current.name == 'annexa.tram.pending.viewdossier' || $state.current.name == 'annexa.tram.responsable.viewdossier' || ($state.current.name == 'annexa.tram.view.viewdossier' && TramNewFactory && TramNewFactory.Dossier && TramNewFactory.Dossier.dossierStatus == 'FINISHED')) ? true : false),
                    functionMultipleActionsColumnShow: function(item) {
                        if (item && item.document) {
                            var havePermission = false;
                            if(item.dossierTransaction){
                                _.forEach(item.dossierTransaction.processProfiles, function (itemProf) {
                                    if(!havePermission){
                                        havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', itemProf.profile)
                                    }
                                })
                                if(!havePermission && TramNewFactory.Dossier && TramNewFactory.Dossier.dossierTransactions){
                                    _.forEach(TramNewFactory.Dossier.dossierTransactions, function (dt) {
                                        _.forEach(dt.processProfiles, function (dtItem) {
                                            if (!havePermission) {
                                                havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', dtItem.profile);
                                            }
                                        });
                                    });
                                }
                            }
                            if(!havePermission && item.document){
                                if(item.document.globalProcessProfileIds){
                                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile").toArray();
                                    var intersect = $linq(userProfiles).intersect(item.document.globalProcessProfileIds, "(x,y) => x == y.id").toArray();
                                    _.forEach(intersect, function (dtItem) {
                                        if (!havePermission) {
                                            havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', dtItem);
                                        }
                                    });
                                }
                                if(!havePermission) {
                                    havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',item.document.profiles);
                                }
                            }
                            return havePermission;
                        }
                    },
                    functionMultipleActionsReload: function(items) {
                        var options = [];
                        var optionItems = [];

                        _.forEach(items, function(item) {
                            if(item.selected) {
                                var evaluatedOptions = AnnexaDocumentActionsButtonFactory.getShowOptions(item.document, item, item.dossierTransaction.actual, true, undefined, 'EXPED');

                                if (options.length == 0) {
                                    options = evaluatedOptions;
                                } else {
                                    options = $linq(options).intersect(evaluatedOptions, "(x, y) => x == y").toArray();
                                }

                                optionItems.push(item);
                            }
                        });

                        if(options.length != 0) {
                            vm.tableDefinition.multipleActionsFunction(AnnexaDocumentActionsButtonFactory.getMultipleActionsButton(options, optionItems, 'EXPED', undefined, TramNewFactory.Dossier));
                        } else {
                            vm.tableDefinition.multipleActionsFunction();
                        }

                    },
                    parentObject: TramNewFactory.Dossier,
                    parentObjectAttribute: 'archiveClassification'                    
                };

            }

        }],
        bindings: {
            dossierId: '=',
            modify:'=?'
        }
    });